import { AxiosResponse } from "axios";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ActionMeta, SingleValue } from "react-select";
import axiosInstance from "../../api";
import { RightStyledToolTip } from "../../components/StyledToolTip";
import { ArticleIcon, TwitterIcon } from "../../components/TabIcons";
import { SUBSCRIBED_REGION, THEME_COLOUR_PRIMARY } from "../../constants";
import { RootState } from "../../store";
import { FilterTypes } from "../../types/filterTypes";
import { DropdownOption } from "../../types/types";
import { ModifiedReactSelect } from "../../components/ModifiedReactSelect";
import { Params } from "../opinion-ai-m3";
import { DigitalTab } from "../opinion-ai-m3/DigitalTab";
import { SocialTab } from "../opinion-ai-m3/SocialTab";

interface Filters {
  issue?: Array<DropdownOption>;
}

interface SelectedFilters {
  issue: DropdownOption;
}

export const InsightsOpinionAI = () => {
  const { preferredRegion } = useSelector(
    (state: RootState) => state.preference
  );
  const [selectedFilters, setSelectedFilters] = React.useState<
    SelectedFilters | undefined
  >(undefined);

  const [isDigitalIconActive, setIsDigitalIconActive] = React.useState(false);

  const [isTwitterIconActive, setIsTwitterIconActive] = React.useState(false);

  const selectedFiltersRef = React.useRef<SelectedFilters | undefined>(
    undefined
  );

  const [filterOptions, setFilterOptions] = React.useState<Filters>({
    issue: [],
  });

  const params: { filters: Params } = React.useMemo(() => {
    return {
      subscribed_region: preferredRegion,
      filters: {
        issue: selectedFilters?.issue.value
          ? { type: FilterTypes.eq, value: selectedFilters?.issue.value }
          : undefined,
        region: {
          type: FilterTypes.eq,
          value:
            preferredRegion === SUBSCRIBED_REGION.TAIWAN_REGION ? "EA" : "MENA",
        },
        country: {
          type: FilterTypes.eq,
          value:
            preferredRegion === SUBSCRIBED_REGION.TAIWAN_REGION ? "TW" : "IR",
        },
      },
    };
  }, [selectedFilters?.issue.value, preferredRegion]);

  const getOpinionAIFilters = React.useCallback(() => {
    axiosInstance
      .post("/m3/opinionAI/get-filters", {
        params: { subscribed_region: preferredRegion },
      })
      .then((res: { data: AxiosResponse<Required<Filters>> }) => {
        setFilterOptions({
          issue: [...res.data.data.issue],
        });
        setSelectedFilters({
          issue: res.data.data.issue[0],
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [preferredRegion]);

  React.useEffect(() => {
    getOpinionAIFilters();
  }, [getOpinionAIFilters]);

  const onChangeFilterOption = (
    newValue: SingleValue<unknown>,
    meta: ActionMeta<unknown>
  ) => {
    selectedFiltersRef.current = {
      issue: newValue as DropdownOption,
    };
    setSelectedFilters({
      issue: newValue as DropdownOption,
    });
  };
  return (
    <>
      <Row className="mt-2 mb-3">
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <ModifiedReactSelect
            isSearchable={false}
            options={filterOptions.issue}
            name="issue"
            onChange={onChangeFilterOption}
            value={selectedFilters?.issue}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <Card>
            <Card.Body className="px-0 pt-0">
              <Card.Title
                style={{
                  borderBottom: "1px solid #ccc",
                  textAlign: "left",
                  display: "flex",
                }}
                className="ps-3"
              >
                <div
                  className="my-1 p-3 rounded-circle"
                  onMouseEnter={() => setIsDigitalIconActive(true)}
                  onMouseLeave={() => setIsDigitalIconActive(false)}
                  style={{
                    backgroundColor: isDigitalIconActive
                      ? THEME_COLOUR_PRIMARY
                      : "transparent",
                    transition: "all 0.25s ease-in-out",
                    fontSize: 16,
                  }}
                  id="digital-opinion-title"
                >
                  <ArticleIcon color="#475569" isActive={isDigitalIconActive} />
                  <RightStyledToolTip
                    place="right"
                    anchorSelect={`#digital-opinion-title`}
                    html={"Digital"}
                  ></RightStyledToolTip>
                </div>
              </Card.Title>
              <DigitalTab filters={params} />
            </Card.Body>
          </Card>
        </Col>

        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <Card>
            <Card.Body className="px-0 pt-0">
              <Card.Title
                style={{
                  borderBottom: "1px solid #ccc",
                  textAlign: "left",
                  display: "flex",
                }}
                className="ps-3"
              >
                <div
                  className="my-1 p-3 rounded-circle"
                  onMouseEnter={() => setIsTwitterIconActive(true)}
                  onMouseLeave={() => setIsTwitterIconActive(false)}
                  style={{
                    backgroundColor: isTwitterIconActive
                      ? THEME_COLOUR_PRIMARY
                      : "transparent",
                    transition: "all 0.25s ease-in-out",
                    fontSize: 16,
                  }}
                  id="twitter-opinion-title"
                >
                  <TwitterIcon color="#475569" isActive={isTwitterIconActive} />
                  <RightStyledToolTip
                    place="right"
                    anchorSelect={`#twitter-opinion-title`}
                    html={"Twitter"}
                  ></RightStyledToolTip>
                </div>
              </Card.Title>

              <SocialTab filters={params} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
