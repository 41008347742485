import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { TelegramPost } from "../types/types";
import {
  StyledContent,
  StyledMeta,
  StyledSubTitle,
  StyledTitle,
  StyledRow,
  MetaLabel,
  StyledTagPill,
} from "./ModalCss";
import {
  getIssueNameInNative,
  isContentRtl,
  isDirtyHashtag,
  replacer,
  showEnglishContent,
} from "../helpers";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { THEME_COLOUR_PRIMARY } from "../constants";
import styled from "styled-components";
import { Circle } from "@styled-icons/material";
import { LanguageIcon } from "./LanguageIcon";

interface Props extends TelegramPost {
  onHide: () => void;
  issue: string;
  highlightKeywords?: string[];
}

export const TelegramDetailModal = ({
  account_handle,
  english_sub_issue,
  language,
  date,
  message_text_translated,
  hashtags,
  account_subscribers,
  message_forwards,
  message_link,
  account_name,
  message_text,
  message_views,
  sub_issue,
  issue,
  highlightKeywords,
  onHide,
}: Props) => {
  const { preferredLanguage, preferredRegion } = useSelector(
    (state: RootState) => state.preference
  );

  const handleTitleClick = React.useCallback(() => {
    window.open(message_link, "_blank");
  }, [message_link]);

  const hashTagArr = React.useMemo(() => {
    return hashtags?.length ? hashtags.split(",") : [];
  }, [hashtags]);

  const content = React.useMemo(() => {
    return showEnglishContent(message_text_translated, preferredLanguage)
      ? message_text_translated
      : message_text;
  }, [message_text, message_text_translated, preferredLanguage]);

  const highlightedContent = React.useMemo(() => {
    let formattedContent = content;
    if (highlightKeywords && highlightKeywords.length) {
      highlightKeywords.forEach((keyword) => {
        formattedContent = replacer(
          formattedContent,
          keyword,
          "<span style='background-color:yellow; font-weight: bold'>$1</span>"
        );
      });
    }
    formattedContent.replaceAll("\n", "<br />");
    return formattedContent;
  }, [content, highlightKeywords]);

  const highlightedTitle = React.useMemo(() => {
    let formattedContent = account_handle;
    if (highlightKeywords && highlightKeywords.length) {
      highlightKeywords.forEach((keyword) => {
        formattedContent = replacer(
          formattedContent,
          keyword,
          "<span style='background-color:yellow; font-weight: bold'>$1</span>"
        );
      });
    }
    formattedContent.replaceAll("\n", "<br />");
    return formattedContent;
  }, [account_handle, highlightKeywords]);

  const originalIssueName = React.useMemo(() => {
    return getIssueNameInNative(preferredRegion);
  }, [preferredRegion]);

  return (
    <Modal onHide={onHide} keyboard scrollable show size={"lg"} centered>
      <Modal.Header>
        <Modal.Title className="w-100">
          <StyledTitle>
            <div
              style={{
                direction: isContentRtl(preferredLanguage, language)
                  ? "rtl"
                  : "ltr",
              }}
              dangerouslySetInnerHTML={{ __html: highlightedTitle }}
            />
            <button
              type="button"
              className="btn-close float-end"
              aria-label="Close"
              onClick={onHide}
            ></button>
          </StyledTitle>
          <StyledSubTitle className="mt-2 mb-2">
            {issue} ({originalIssueName})
          </StyledSubTitle>
          <div
            className="d-flex align-items-center"
            style={{ fontSize: 12.5, lineHeight: 1.75 }}
          >
            <div>
              <StyledFooterItem>
                <LanguageIcon postLanguage={language} />
                {/* <span className="ps-1">{postLanguage}</span> */}
              </StyledFooterItem>
            </div>
            <div>
              <SeparatorIcon height={10} width={10} />
            </div>
            <div>
              <StyledFooterItem>{date}</StyledFooterItem>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <StyledContent
          style={{ padding: 0 }}
          isrtl={isContentRtl(preferredLanguage, language)}
          dangerouslySetInnerHTML={{
            __html: highlightedContent,
          }}
        ></StyledContent>
        {hashTagArr.length ? (
          <>
            <div className="mt-3">Hashtags</div>
            <div className="my-3">
              {hashTagArr.map((hashtag) =>
                isDirtyHashtag(hashtag) ? (
                  ""
                ) : (
                  <StyledTagPill className="me-2">{hashtag}</StyledTagPill>
                )
              )}
            </div>
          </>
        ) : null}
      </Modal.Body>
      <Modal.Footer style={{ display: "block" }} className="mb-4">
        <Row>
          <Col xl={5} lg={5} sm={12} xs={12}>
            <StyledRow className="my-3">
              <MetaLabel>Post Views</MetaLabel>
              <MetaLabel>{message_views}</MetaLabel>
            </StyledRow>
            <StyledRow className="my-3">
              <MetaLabel>Post Forwards</MetaLabel>
              <MetaLabel>{message_forwards}</MetaLabel>
            </StyledRow>
          </Col>
          <Col
            xl={1}
            lg={1}
            sm={12}
            xs={12}
            style={{ borderRight: "1px solid #CCC" }}
          ></Col>
          <Col xl={1} lg={1} sm={12} xs={12}></Col>
          <Col xl={5} lg={5} sm={12} xs={12}>
            <StyledRow className="my-3">
              <MetaLabel>Username</MetaLabel>
              <MetaLabel
                onClick={handleTitleClick}
                style={{ cursor: "pointer", color: `${THEME_COLOUR_PRIMARY}` }}
              >
                {account_handle}
              </MetaLabel>
            </StyledRow>
            <StyledRow className="my-3">
              <MetaLabel>Account Subscribers</MetaLabel>
              <MetaLabel>{account_subscribers}</MetaLabel>
            </StyledRow>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

const StyledFooterItem = styled.div`
  color: #64748b;
`;

const SeparatorIcon = styled(Circle)`
  color: #cdd4e1;
  margin-right: 12px;
  margin-left: 12px;
`;

const StyledFooterImage = styled.img`
  border: 1px solid #ccc !important;
`;
