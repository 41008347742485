import axios from "axios";
import { SendGAEvent } from "../helpers/googleAnalyticsHepler";

export const axiosInstance = axios.create({
  baseURL: "/api/",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use((config) => {
  // TODO : Read from session storage
  const token = localStorage.getItem("Auth");
  config.headers.Authorization = `${token}`;
  return config;
});

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error.response.status === 401) {
      sessionStorage.clear();
      localStorage.clear();
      window.location.replace("/?st=y");
    }
    SendGAEvent({
      category: "API-ERROR",
      action: "API-ERROR",
      includeUserDetail: true,
      label: error?.response?.data?.message,
    });
    return Promise.reject(error);
  }
);

export default axiosInstance;
