import * as React from "react";
import { Card } from "react-bootstrap";
import styled, { css, keyframes } from "styled-components";
import { THEME_COLOUR_PRIMARY } from "../constants";

type InfluencerWithProbability = {
  name: string;
  probability: number;
  probabilityLabel?: string;
};

export const InfluencerCard = ({
  name,
  probability,
  probabilityLabel,
}: InfluencerWithProbability) => {
  return (
    <Card>
      <Card.Body style={{ padding: 8.5 }}>
        <InfluencerContainer percentage={probability}>
          <InfluencerName>{name}</InfluencerName>
          <InfluencePercentage>
            {probability * 100 > 99.99 ? 99.99 : (probability * 100).toFixed(2)}
            %
          </InfluencePercentage>
        </InfluencerContainer>
      </Card.Body>
    </Card>
  );
};

const filler = keyframes`
    0% {
      width: 0;
    }
`;

const InfluencerContainer = styled("div").withConfig({
  shouldForwardProp: (prop) => prop !== "percentage",
})<{ percentage: number }>(({ percentage }) => {
  return css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:after {
      content: "";
      position: absolute;
      background: rgba(47, 111, 237, 0.08);
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      animation-name: ${filler};
      animation-duration: 2s;
      animation-fill-mode: forwards;
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
    }
    &:hover {
      &:after {
        background: ${THEME_COLOUR_PRIMARY};
      }
      div {
        color: #fff !important;
        z-index: 1;
      }
    }
  `;
});

const InfluencerName = styled.div`
  font-size: 14px;
  color: #475569;
`;
const InfluencePercentage = styled.div`
  font-size: 14px;
  color: ${THEME_COLOUR_PRIMARY};
`;
