import * as React from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import { toast, ToastContainer } from "react-toastify";
import styled from "styled-components";
import {
  Email,
  Domain,
  Person,
  Phone,
  ArrowBack,
} from "@styled-icons/material-outlined";
import axiosInstance from "../../api";
import { isOnlyCharacters, isOnlyNumeric, validateEmail } from "../../helpers";
import { SITE_CONSTANTS, THEME_COLOUR_PRIMARY } from "../../constants";
import { ButtonContainer } from "../../components/CommonStyledComponents";
import { ReactGA } from "../../components/GoogleAnalytics";

interface FormError {
  name: boolean;
  lastName: boolean;
  email: boolean;
  phone: boolean;
  companyName: boolean;
}

const DEFAULT_FORM_ERRORS: FormError = {
  name: false,
  lastName: false,
  email: false,
  phone: false,
  companyName: false,
};

export const Register = () => {
  const [isRequestInProgress, setIsRequestInProgress] =
    React.useState<boolean>(false);
  const navigate = useNavigate();

  const [formError, setFormError] = React.useState<FormError>({
    ...DEFAULT_FORM_ERRORS,
  });

  const [form, setForm] = React.useState<{
    name: string;
    lastName: string;
    email: string;
    phone: string;
    companyName: string;
  }>({
    name: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
  });

  React.useEffect(() => {
    ReactGA.event({
      category: "SOTOS_Request_Access_Page_Landed",
      action: "SOTOS_Request_Access_Page_Landed",
    });
  }, []);

  const onInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputName: string
  ) => {
    setForm({
      ...form,
      [inputName]: e.target.value,
    });
  };

  const validateForm = () => {
    let isFormValid = true;
    const newFormErrors = { ...DEFAULT_FORM_ERRORS };

    if (!form.name.trim() || !isOnlyCharacters(form.name)) {
      isFormValid = false;
      newFormErrors["name"] = true;
    }

    if (!form.lastName.trim() || !isOnlyCharacters(form.lastName)) {
      isFormValid = false;
      newFormErrors["lastName"] = true;
    }

    if (!form.email.trim() || !validateEmail(form.email)) {
      isFormValid = false;
      newFormErrors["email"] = true;
    }

    if (
      form.phone.trim() &&
      (form.phone.length !== 10 || !isOnlyNumeric(form.phone))
    ) {
      isFormValid = false;
      newFormErrors["phone"] = true;
    }

    setFormError({ ...newFormErrors });
    return isFormValid;
  };

  const handleFormSubmission = () => {
    if (!validateForm()) {
      return;
    }

    setIsRequestInProgress(true);

    axiosInstance
      .post("/register/add-to-queue", {
        params: {
          ...form,
        },
      })
      .then(
        (
          res: AxiosResponse<{
            message: string;
            data: {
              isRecordCreated: boolean;
              isEmailSent: boolean;
            };
          }>
        ) => {
          if (res.status === 500) {
            ReactGA.event({
              category: "Request_Access_Error",
              action: "Request_Access_error",
              label: `${form.email?.replace("@", "at").replace(".", "dot")}/${
                res.data.message
              }`,
            });
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
              pauseOnHover: true,
              toastId: "error-toast",
            });
            return;
          }
          if (res.data.data.isRecordCreated) {
            ReactGA.event({
              category: "SOTOS_Request_Access_Success",
              action: "SOTOS_Request_Access_Success",
              label: `${form.email?.replace("@", "at").replace(".", "dot")}`,
            });
            toast.success(
              <>
                We have successfully received your inquiry. Team SOTOS will
                shortly get in touch with you.
              </>,
              {
                position: "top-right",
                autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS * 2,
                pauseOnHover: false,
                toastId: "success-toast",
                onClose: () => {
                  toast.loading(
                    <>
                      <b>
                        Please wait while we redirect you back to login page...
                      </b>
                    </>,
                    {
                      position: "top-right",
                      autoClose:
                        SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS * 2,
                      pauseOnHover: false,
                      toastId: "success-toast",
                    }
                  );
                  setTimeout(() => {
                    navigate("/login");
                  }, SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS);
                },
              }
            );
          }

          if (!res.data.data.isRecordCreated) {
            ReactGA.event({
              category: "SOTOS_Request_Access_Error",
              action: "SOTOS_Request_Access_error",
              label: `${form.email
                ?.replace("@", "at")
                .replace(".", "dot")}/DB-Record-Creation_Failure`,
            });
            toast.error(
              "Sorry we could not process your request at the moment. Please try again after sometime",
              {
                position: "top-right",
                autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
                pauseOnHover: true,
                toastId: "error-toast",
              }
            );
          }
        }
      )
      .catch((err) => {
        if (err.response?.status === 500) {
          ReactGA.event({
            category: "SOTOS_Request_Access_Error",
            action: "SOTOS_Request_Access_error",
            label: `${form.email?.replace("@", "at").replaceAll(".", "dot")}/${
              err.response.data.message
            }`,
          });
          toast.error(
            err.response.data.message ?? "Sorry!! Something went wrong"
          );
          return;
        }
      })
      .finally(() => {
        setIsRequestInProgress(false);
      });
  };

  const navigateToLogin = () => {
    navigate("/login");
  };

  return (
    <div>
      <Row
        className="g-0 justify-content-center align-items-center"
        style={{ height: "85vh" }}
      >
        <Col xl={3} lg={3} md={12} sm={12}>
          <StyledFormContainer>
            <div className="d-flex justify-content-center pt-3 pb-4">
              <img height={100} src="quantum_politics_logo.png" alt="logo" />
            </div>

            <div
              className="text-center pb-3"
              style={{
                color: THEME_COLOUR_PRIMARY,
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              Create your SOTOS account
            </div>

            <Form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              className="login-form"
            >
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <InputGroup>
                  <InputGroup.Text>
                    <Person size={20} />
                  </InputGroup.Text>
                  <Form.Control
                    name="namformErrore"
                    type="input"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onInputChange(e, "name")
                    }
                    placeholder="First Name*"
                    isInvalid={formError.name}
                    value={form.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter valid name.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <InputGroup>
                  <InputGroup.Text>
                    <Person size={20} />
                  </InputGroup.Text>
                  <Form.Control
                    name="lastName"
                    type="text"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onInputChange(e, "lastName")
                    }
                    placeholder="Last Name*"
                    isInvalid={formError.lastName}
                    value={form.lastName}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter valid last name.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <InputGroup>
                  <InputGroup.Text>
                    <Email size={20} />
                  </InputGroup.Text>
                  <Form.Control
                    name="lastName"
                    type="text"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onInputChange(e, "email")
                    }
                    placeholder="Company email*"
                    isInvalid={formError.email}
                    value={form.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter valid email.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <InputGroup>
                  <InputGroup.Text>
                    <Phone size={20} />
                  </InputGroup.Text>
                  <Form.Control
                    name="phone"
                    type="text"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onInputChange(e, "phone")
                    }
                    placeholder="phone"
                    isInvalid={formError.phone}
                    value={form.phone}
                  />
                  <Form.Control.Feedback type="invalid">
                    Phone number should only be numeric and should not be
                    greater than 10 digit.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <InputGroup>
                  <InputGroup.Text>
                    <Domain size={20} />
                  </InputGroup.Text>
                  <Form.Control
                    name="companyName"
                    type="text"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onInputChange(e, "companyName")
                    }
                    placeholder="Company name"
                    isInvalid={formError.companyName}
                    value={form.companyName}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter valid organisational name.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <div className="text-center">
                By clicking "Register" you agree that you have read PersuMedia's{" "}
                <a
                  href="https://www.persumedia.com/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: THEME_COLOUR_PRIMARY }}
                >
                  Term's of Service and Privacy Policy
                </a>
              </div>

              <ButtonContainer>
                {isRequestInProgress ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  <Button
                    onClick={handleFormSubmission}
                    variant="primary"
                    type="submit"
                    style={{
                      borderRadius: 30,
                      color: "#63f3dc",
                      backgroundColor: THEME_COLOUR_PRIMARY,
                      borderColor: THEME_COLOUR_PRIMARY,
                    }}
                  >
                    Register
                  </Button>
                )}
              </ButtonContainer>
            </Form>
            <ButtonContainer>
              <Button
                style={{ color: THEME_COLOUR_PRIMARY }}
                onClick={navigateToLogin}
                variant="link"
              >
                <ArrowBack color={THEME_COLOUR_PRIMARY} size={20} />
                Back to Login
              </Button>
            </ButtonContainer>
          </StyledFormContainer>
        </Col>
      </Row>
      <div className="d-flex justify-content-between align-items-center pb-5">
        <div className="ps-5">
          <img src="QP.svg" alt="Quantum Politics Logo" />
        </div>
        <div className="pe-5">
          <img
            title="Powered By: PersuMedia"
            src="PersuMedia.svg"
            alt="Persumedia Logo"
            className="img-fluid"
          />
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

const StyledFormContainer = styled.div`
  margin: 50px;
`;
