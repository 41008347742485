import { configureStore } from "@reduxjs/toolkit";
import { ReduxFilterReducer } from "./slice/filters";
import { InfluencerReducer } from "./slice/influencerEntities";
import { PreferenceReducer } from "./slice/issuePreference";
import { NewsMediaAgencyReducer } from "./slice/newsMediaAgency";
import { SearchScreenReducer } from "./slice/search";
import { UserReducer } from "./slice/user";

export const store = configureStore({
  reducer: {
    user: UserReducer,
    preference: PreferenceReducer,
    reduxFilter: ReduxFilterReducer,
    searchScreen: SearchScreenReducer,
    influencerRedux: InfluencerReducer,
    newsMediaAgency: NewsMediaAgencyReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
