import React from "react";
import { Badge } from "react-bootstrap";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setSelectedIssueM3 } from "../../store/slice/issuePreference";
import { TabsContainer } from "./TabsContainer";
import { BottomStyledToolTip } from "../../components/StyledToolTip";
import {
  SUBSCRIBED_REGION,
  TAB_TITLE,
  THEME_COLOUR_PRIMARY,
} from "../../constants";
import { SendGAEvent } from "../../helpers/googleAnalyticsHepler";
import { Loader } from "../../components/Loader";

export const EmergingIssues = () => {
  const dispatch = useDispatch();
  const preferenceIssues = useSelector((state: RootState) => state.preference);
  const {
    issuesM3,
    taiwanIssues,
    chinaIssues,
    selectedIssueM3,
    selectedTaiwanIssue,
    selectedChinaIssue,
    defaultSelectedChinaIssue,
    defaultSelectedIssueM3,
    defaultSelectedTaiwanIssue,
    preferredRegion,
  } = preferenceIssues;

  const defaultSelectedIssue = React.useMemo(() => {
    switch (preferredRegion) {
      case SUBSCRIBED_REGION.CHINA_REGION:
        return defaultSelectedChinaIssue;

      case SUBSCRIBED_REGION.TAIWAN_REGION:
        return defaultSelectedTaiwanIssue;

      default:
        return defaultSelectedIssueM3;
    }
  }, [
    defaultSelectedChinaIssue,
    defaultSelectedIssueM3,
    defaultSelectedTaiwanIssue,
    preferredRegion,
  ]);

  React.useEffect(() => {
    return () => {
      dispatch(
        setSelectedIssueM3({
          selectedIssue: defaultSelectedIssue,
          selectedTab: undefined,
          region: preferredRegion,
        })
      );
    };
  }, [dispatch, preferredRegion, defaultSelectedIssue]);

  const selectedIssuePill = React.useMemo(() => {
    if (preferredRegion === SUBSCRIBED_REGION.TAIWAN_REGION) {
      if (selectedTaiwanIssue) {
        return selectedTaiwanIssue;
      }
      return defaultSelectedTaiwanIssue;
    } else if (preferredRegion === SUBSCRIBED_REGION.CHINA_REGION) {
      if (selectedChinaIssue) {
        return selectedChinaIssue;
      }
      return defaultSelectedChinaIssue;
    } else {
      if (selectedIssueM3) {
        return selectedIssueM3;
      }
      return defaultSelectedIssueM3;
    }
  }, [
    defaultSelectedChinaIssue,
    defaultSelectedIssueM3,
    defaultSelectedTaiwanIssue,
    preferredRegion,
    selectedChinaIssue,
    selectedIssueM3,
    selectedTaiwanIssue,
  ]);

  React.useEffect(() => {
    document.title = TAB_TITLE.emerging_issue;
    SendGAEvent({
      category: "SOTOS_Emerging_Issue_Page_Landed",
      action: "SOTOS_Emerging_Issue_Page_Landed",
      includeUserDetail: true,
    });
  }, []);

  const handleIssueClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      SendGAEvent({
        category: "SOTOS_Emerging_Issue_Pill_Clicked",
        action: "SOTOS_Emerging_Issue_Pill_Clicked",
        includeUserDetail: true,
        label: `Issue-${(event.target as HTMLElement).innerText}`,
      });
      dispatch(
        setSelectedIssueM3({
          selectedIssue: (event.target as HTMLElement).innerText,
          region: preferredRegion,
        })
      );
    },
    [dispatch, preferredRegion]
  );

  const issueList = React.useMemo(() => {
    if (!preferredRegion || !issuesM3) {
      return null;
    }
    switch (preferredRegion) {
      case SUBSCRIBED_REGION.CHINA_REGION:
        return chinaIssues;

      case SUBSCRIBED_REGION.TAIWAN_REGION:
        return taiwanIssues;
      default:
        return issuesM3;
    }
  }, [chinaIssues, issuesM3, preferredRegion, taiwanIssues]);

  if (!issueList) {
    <Loader />;
  }

  return !issueList ? (
    <Loader />
  ) : (
    <>
      {
        <div className="mt-4 mb-2">
          {issueList &&
            issueList.englishIssues.map((issue, index) =>
              issueList.emergingIssueEnabledRecords[issue] ? (
                <React.Fragment key={index}>
                  <StyledIssuePill
                    className={`my-2 mx-2 bg-transparent border border-secondary `}
                    onClick={handleIssueClick}
                    isactive={issue === selectedIssuePill}
                    id={`tooltop-anchor-${index}`}
                  >
                    {issue}
                  </StyledIssuePill>
                  <BottomStyledToolTip
                    anchorSelect={`#tooltop-anchor-${index}`}
                    content={issueList.originalLanguageIssues[index]}
                    place="bottom"
                  >
                    {issue}
                  </BottomStyledToolTip>
                </React.Fragment>
              ) : null
            )}
        </div>
      }

      <div className="mb-2">
        <TabsContainer activeIssue={selectedIssuePill} />
      </div>
    </>
  );
};

const StyledIssuePill = styled(Badge)<{ isactive: boolean }>`
  border-radius: 35px;
  line-height: 20px;
  font-size: 14px;
  border: 1px solid #cbd4e1;
  padding: 8px 20px;
  border-color: ${(p) =>
    p.isactive ? `${THEME_COLOUR_PRIMARY} !important` : "#cbd4e1 !important"};
  color: ${(p) => (p.isactive ? "#fff" : "#64748b")};
  background-color: ${(p) =>
    p.isactive ? `${THEME_COLOUR_PRIMARY} !important` : "none !important"};
  cursor: pointer;
  margin-right: 0 !important;
  font-weight: normal;
  &:hover {
    border-color: ${THEME_COLOUR_PRIMARY} !important;
  }
`;

export const StyledTabSubHeading = styled.div`
  margin-bottom: 0.5rem;
`;
