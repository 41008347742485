import React from "react";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { USFormattedNumber } from "../../helpers";

interface Props {
  series: Array<number>;
  colors: Array<string>;
  labels?: Array<string>;
  min?: number;
  max?: number;
  onClickChart?: (clickedSeriesIndex: number, seriesName?: string) => void;
  seriesName?: string;
}

export const PolarRadarChart = ({
  series,
  colors,
  labels,
  onClickChart,
  seriesName,
}: Props) => {
  const options: ApexOptions = React.useMemo(() => {
    return {
      series,
      chart: {
        type: "polarArea",
        events: {
          dataPointSelection: (e: any, chart?: any, options?: any) => {
            onClickChart && onClickChart(options.dataPointIndex, seriesName);
          },
        },
      },
      colors,
      stroke: {
        colors: ["#fff"],
      },
      fill: {
        opacity: 0.8,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      yaxis: {
        forceNiceScale: true,
        tickAmount: 8,
        logBase: 10,
        decimalsInFloat: 0,
        labels: {
          formatter: (value) => {
            return value > 1000
              ? USFormattedNumber(value)
              : Math.ceil(value).toString();
          },
        },
      },
    };
  }, [colors, onClickChart, series, seriesName]);

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          height={350}
          options={options}
          series={series}
          type="polarArea"
        />
      </div>
    </div>
  );
};
