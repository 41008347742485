import React from "react";
import { AxiosResponse } from "axios";
import { ActionMeta, SingleValue } from "react-select";
import { Col, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from "luxon";
import { axiosInstance } from "../../api/index";
import { FilterTypes, FilterOperations } from "../../types/filterTypes";
import {
  DEFAULT_POST_LANGUAGE,
  DEFAULT_POST_LOCATION,
  DEFAULT_START_DATE,
  M3_PARAM,
  MAX_ALLOWED_BACK_DATE,
  SUBSCRIBED_REGION,
} from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setSelectedIssueM3 } from "../../store/slice/issuePreference";
import {
  setTelegramFilterValue,
  setTwitterFilterValue,
} from "../../store/slice/filters";
import { MapStats } from "./MapStats";
import { ModifiedReactSelect } from "../../components/ModifiedReactSelect";
import { StyledDateSelecterM3 } from "../../components/CommonStyledComponents";
import { HighChartDonut } from "./highChartOption";
import { EmptyChartMessage } from "../../components/EmptyChartMessage";
import { ReactGA } from "../../components/GoogleAnalytics";
import { SendGAEvent } from "../../helpers/googleAnalyticsHepler";
import { RootState } from "../../store";

type Option = { label: string; value: string };
type FilterValue = {
  social_media: Option;
  language: Option;
  location: Option;
  date: {
    startDate: Date | null;
    endDate: Date | null;
  };
};

export const SocialMediaCoverage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dateSelectorRef = React.useRef<any>();
  const { preferredRegion } = useSelector(
    (state: RootState) => state.preference
  );
  const { twitter: reduxTwitterFilter, telegram: reduxTelegramFilter } =
    useSelector((state: RootState) => state.reduxFilter);

  const [pieChartData, setPieChartData] = React.useState<
    Array<{ label: string; issue_count: number; overall_percentage: number }>
  >([]);

  const [filterOptions, setFilterOptions] = React.useState<{
    social_media?: Array<Option>;
    language?: Array<Option>;
    location?: Array<Option>;
  }>({
    social_media: undefined,
    language: undefined,
    location: undefined,
  });

  const [selectedFilters, setSelectedFilters] = React.useState<FilterValue>({
    social_media: { label: "Twitter", value: "Twitter" },
    language: reduxTwitterFilter.language
      ? { ...reduxTwitterFilter.language }
      : { ...DEFAULT_POST_LANGUAGE },
    location: { ...DEFAULT_POST_LOCATION },
    date: {
      startDate: reduxTwitterFilter.date.startDate
        ? new Date(reduxTwitterFilter.date.startDate)
        : new Date(DEFAULT_START_DATE),
      endDate: reduxTwitterFilter.date.endDate
        ? new Date(reduxTwitterFilter.date.endDate)
        : new Date(DateTime.now().toMillis()),
    },
  });

  const [dateFilter, setDateFilter] = React.useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: reduxTwitterFilter.date.startDate
      ? new Date(reduxTwitterFilter.date.startDate)
      : new Date(DEFAULT_START_DATE),
    endDate: reduxTwitterFilter.date.endDate
      ? new Date(reduxTwitterFilter.date.endDate)
      : new Date(DateTime.now().toMillis()),
  });

  const selectedFiltersRef = React.useRef<FilterValue>({
    social_media: { label: "Twitter", value: "Twitter" },
    language: { ...DEFAULT_POST_LANGUAGE },
    location: { ...DEFAULT_POST_LOCATION },
    date: {
      startDate: new Date(DEFAULT_START_DATE),
      endDate: new Date(DateTime.now().toMillis()),
    },
  });

  React.useEffect(() => {
    setSelectedFilters({
      social_media: selectedFiltersRef.current.social_media,
      language: reduxTwitterFilter.language
        ? { ...reduxTwitterFilter.language }
        : { ...DEFAULT_POST_LANGUAGE },
      location: selectedFiltersRef.current.location,
      date: {
        startDate: reduxTwitterFilter.date.startDate
          ? new Date(reduxTwitterFilter.date.startDate)
          : new Date(DEFAULT_START_DATE),
        endDate: reduxTwitterFilter.date.endDate
          ? new Date(reduxTwitterFilter.date.endDate)
          : new Date(DateTime.now().toMillis()),
      },
    });
    if (reduxTwitterFilter.date.startDate && reduxTwitterFilter.date.endDate) {
      setDateFilter({
        startDate: new Date(reduxTwitterFilter.date.startDate),
        endDate: new Date(reduxTwitterFilter.date.endDate),
      });
    }
  }, [reduxTwitterFilter]);

  const refinedParams = React.useMemo(() => {
    ReactGA.event({
      category: "SOTOS_Insights_Social_Filter_Updated",
      action: "SOTOS_Insights_Social_Filter_Updated",
      label: `SM-${selectedFilters.social_media.value},SL-${
        selectedFilters.language.value
      },location-${selectedFilters.location.value},SD-${DateTime.fromJSDate(
        selectedFilters.date.startDate ??
          DateTime.fromMillis(DEFAULT_START_DATE).toJSDate()
      ).toFormat("yyyy-LL-dd")},ED-${DateTime.fromJSDate(
        selectedFilters.date.endDate ?? DateTime.now().toJSDate()
      ).toFormat("yyyy-LL-dd")}`,
    });
    return {
      subscribed_region: preferredRegion,
      filters: {
        social_media: {
          value: selectedFilters.social_media.value,
          type: FilterTypes.eq,
        },
        language: {
          value: selectedFilters.language.value,
          type: FilterTypes.eq,
        },
        location: {
          value: selectedFilters.location.value,
          type: FilterTypes.eq,
        },
        date: {
          operator: FilterOperations.AND,
          conditions: [
            {
              value: DateTime.fromJSDate(
                selectedFilters.date.startDate ??
                  DateTime.fromMillis(DEFAULT_START_DATE).toJSDate()
              ).toFormat("yyyy-LL-dd"),
              type: FilterTypes.gte,
            },
            {
              value: DateTime.fromJSDate(
                selectedFilters.date.endDate ?? DateTime.now().toJSDate()
              ).toFormat("yyyy-LL-dd"),
              type: FilterTypes.lte,
            },
          ],
        },
      },
    };
  }, [
    selectedFilters.date.endDate,
    selectedFilters.date.startDate,
    selectedFilters.language.value,
    selectedFilters.location.value,
    selectedFilters.social_media.value,
    preferredRegion,
  ]);

  React.useEffect(() => {
    axiosInstance
      .post("insights/social-media-coverage", {
        params: { ...refinedParams, ...M3_PARAM },
      })
      .then(
        (
          res: AxiosResponse<{
            pieChartData: Array<{
              label: string;
              issue_count: number;
              overall_percentage: number;
            }>;
          }>
        ) => {
          setPieChartData(res.data?.pieChartData);
        }
      )
      .catch((error) => {
        console.log(error);
        SendGAEvent({
          category: "SOTOS_Insights_Social_Media_Error",
          action: "SOTOS_Insights_Social_Media_Error",
          includeUserDetail: true,
          label: `Error-${error?.response?.data?.message}`,
        });
      });
  }, [refinedParams]);

  React.useEffect(() => {
    axiosInstance
      .post("insights/social-media-coverage/filters", {
        params: {
          ...M3_PARAM,
          subscribed_region: preferredRegion,
        },
      })
      .then(
        (
          res: AxiosResponse<{
            data: {
              social_media: Array<Option>;
              language: Array<Option>;
              location: Array<Option>;
            };
          }>
        ) => {
          setFilterOptions({
            social_media: [...res.data.data.social_media],
            language: [{ ...DEFAULT_POST_LANGUAGE }, ...res.data.data.language],
            location: [{ ...DEFAULT_POST_LOCATION }, ...res.data.data.location],
          });
        }
      )
      .catch((err) => console.log(err));
  }, [preferredRegion]);

  const onDateChange = React.useCallback(
    ([newStartDate, newEndDate]: [Date | null, Date | null]) => {
      if (dateSelectorRef.current.state.open && newEndDate) {
        dateSelectorRef.current.setOpen(false);
      }
      selectedFiltersRef.current = {
        ...selectedFiltersRef.current,
        date: {
          startDate: newStartDate
            ? new Date(DateTime.fromJSDate(newStartDate).toMillis())
            : null,
          endDate: newEndDate
            ? new Date(DateTime.fromJSDate(newEndDate).toMillis())
            : null,
        },
      };
      setDateFilter({
        startDate: newStartDate
          ? new Date(DateTime.fromJSDate(newStartDate).toMillis())
          : null,
        endDate: newEndDate
          ? new Date(DateTime.fromJSDate(newEndDate).toMillis())
          : null,
      });

      if (newStartDate && newEndDate) {
        dispatch(
          setTwitterFilterValue({
            ...reduxTwitterFilter,
            date: {
              startDate: newStartDate
                ? DateTime.fromJSDate(newStartDate).toMillis()
                : null,
              endDate: newEndDate
                ? DateTime.fromJSDate(newEndDate).toMillis()
                : null,
            },
          })
        );
      }
    },
    [dispatch, reduxTwitterFilter]
  );

  const onChangeFilterOption = React.useCallback(
    (newValue: SingleValue<unknown>, meta: ActionMeta<unknown>) => {
      selectedFiltersRef.current = {
        ...selectedFiltersRef.current,
        [meta.name ?? ""]: newValue,
      };

      /**
       * If Date or Language is updated, update it to redux other wise just update local state to reduce unneccesory renders
       *
       * */

      if (meta.name && !["date", "language"].includes(meta.name)) {
        setSelectedFilters({
          ...selectedFilters,
          [meta.name ?? ""]: newValue,
        });
      } else {
        dispatch(
          selectedFilters.social_media.value === "Twitter"
            ? setTwitterFilterValue({
                ...reduxTwitterFilter,
                [meta.name ?? ""]: newValue,
              })
            : setTelegramFilterValue({
                ...reduxTelegramFilter,
                [meta.name ?? ""]: newValue,
              })
        );
      }
    },
    [dispatch, reduxTelegramFilter, reduxTwitterFilter, selectedFilters]
  );

  const onSelectHighchartIssue = React.useCallback(
    (issueName: string) => {
      const selectedTab = selectedFiltersRef.current.social_media.value as
        | "Telegram"
        | "Twitter";
      SendGAEvent({
        category: "SOTOS_Insights_Social_Chart_Issue_Clicked",
        action: "SOTOS_Insights_Social_Chart_Issue_Clicked",
        includeUserDetail: true,
        label: `issue-${issueName},media-${selectedTab}`,
      });
      if (issueName) {
        dispatch(
          setSelectedIssueM3({
            selectedIssue: issueName,
            selectedTab,
            region: preferredRegion,
          })
        );
        dispatch(
          selectedFiltersRef.current.social_media.value === "Twitter"
            ? setTwitterFilterValue({
                country: selectedFiltersRef.current.location,
                language: selectedFiltersRef.current.language,
                date: {
                  startDate: reduxTwitterFilter.date.startDate,
                  endDate: reduxTwitterFilter.date.endDate,
                },
              })
            : setTelegramFilterValue({
                country: selectedFiltersRef.current.location,
                language: selectedFiltersRef.current.language,
                date: {
                  startDate: reduxTwitterFilter.date.startDate,
                  endDate: reduxTwitterFilter.date.endDate,
                },
              })
        );
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          navigate(`/${preferredRegion.toLowerCase()}/issue-watch`);
        }, 300);
      }
    },
    [
      dispatch,
      navigate,
      preferredRegion,
      reduxTwitterFilter.date.endDate,
      reduxTwitterFilter.date.startDate,
    ]
  );

  return (
    <>
      <Row className="d-flex flex-row mt-2">
        <Col xl={2} lg={2} md={6} sm={12} xs={12}>
          <ModifiedReactSelect
            isSearchable={false}
            options={filterOptions.social_media}
            name="social_media"
            onChange={onChangeFilterOption}
            value={selectedFilters.social_media}
          />
        </Col>

        <Col xl={3} lg={2} md={6} sm={12} xs={12}>
          <ModifiedReactSelect
            isSearchable={false}
            options={filterOptions.language}
            name="language"
            onChange={onChangeFilterOption}
            value={selectedFilters.language}
          />
        </Col>

        <Col xl={2} lg={2} md={6} sm={12} xs={12}>
          <ModifiedReactSelect
            isSearchable={false}
            options={filterOptions.location}
            name="location"
            onChange={onChangeFilterOption}
            value={selectedFilters.location}
          />
        </Col>

        <Col xl={2} lg={2} md={6} sm={12} xs={12}>
          <StyledDateSelecterM3
            className="w-100 text-center"
            selected={dateFilter.startDate}
            wrapperClassName="w-100"
            onChange={onDateChange}
            minDate={new Date(MAX_ALLOWED_BACK_DATE)}
            maxDate={new Date(DateTime.now().toMillis())}
            startDate={dateFilter.startDate}
            endDate={dateFilter.endDate}
            selectsRange
            dateFormat={"MM/dd"}
            showDisabledMonthNavigation
            ref={dateSelectorRef}
            onCalendarOpen={() => {
              dateSelectorRef.current.input.classList.add("primary-border");
            }}
            onCalendarClose={() => {
              dateSelectorRef.current.input.classList.remove("primary-border");
            }}
          />
        </Col>
      </Row>
      <Row className="my-3 align-items-center">
        {!!pieChartData.length ? (
          <>
            <Col xs={12} sm={12} md={7} lg={7} xl={7}>
              <HighChartDonut
                data={pieChartData}
                onSelect={onSelectHighchartIssue}
              />
            </Col>
            <Col xs={12} sm={12} md={5} lg={5} xl={5}>
              <MapStats data={pieChartData} />
            </Col>
          </>
        ) : (
          <EmptyChartMessage />
        )}
        {/* <PieChartOpt data={pieChartData} onSelect={onSelect} /> */}
      </Row>
    </>
  );
};
