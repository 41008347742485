import React from "react";
import { AxiosResponse } from "axios";
import { v4 as uuid4 } from "uuid";
import axiosInstance from "../../api";
import {
  DATE_ORDER_BY_OPTIONS,
  DEFAULT_ALL_ISSUES,
  DEFAULT_END_DATE,
  DEFAULT_POST_LANGUAGE,
  DEFAULT_POST_LOCATION,
  DEFAULT_START_DATE,
  EMERGING_ISSUES_ARTICLE_CHAR_LIMIT,
  EMERGING_ISSUES_RECORD_LIMIT,
  EMPTY_CONTENT_STRING,
  ERROR_MSG_GENERIC,
  M3_PARAM,
  MAX_ALLOWED_BACK_DATE,
  SUBSCRIBED_REGION,
} from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { FilterOperations, FilterTypes } from "../../types/filterTypes";
import { SectionSubTitle } from "../../components/SectionSubTitle";
import { Info } from "@styled-icons/material-outlined";
import { Col, Row } from "react-bootstrap";
import { ActionMeta, SingleValue } from "react-select";
import { DropdownOption, TelegramPost } from "../../types/types";
import { DateTime } from "luxon";
import { TelegramDetailModal } from "../../components/TelegramDetailModal";
import useOnScreen from "../../helpers/useOnScreen";
import { usePrevious } from "../../helpers/usePrevious";
import { GoToTop } from "../../components/GoToTop";
import { StyledTabSubHeading } from ".";
import { setTelegramFilterValue } from "../../store/slice/filters";
import { getBackDate, showEnglishContent } from "../../helpers";
import { ModifiedReactSelect } from "../../components/ModifiedReactSelect";
import {
  StyledDateSelecterM3,
  StyledDateSelecterM3V2,
} from "../../components/CommonStyledComponents";
import { PostCardM3 } from "../../components/PostCardM3";
import { SendGAEvent } from "../../helpers/googleAnalyticsHepler";
import { ModifiedReactSelectV2 } from "../../components/ModifiedReactSelect";
import { CalendarIcon } from "../../components/CalendarIcon";

interface Props {
  activeIssue: string;
  updateCounter: (count: number) => void;
  activeTab: string;
}

interface Filters {
  country: Array<DropdownOption>;
  sub_issue: Array<DropdownOption>;
  language: Array<DropdownOption>;
}

export const TelegramList = ({
  activeIssue,
  updateCounter,
  activeTab,
}: Props) => {
  const dispatch = useDispatch();
  const dateSelectorRef = React.useRef<any>();

  const preference = useSelector((state: RootState) => state.preference);

  const reduxFilter = useSelector((state: RootState) => state.reduxFilter);
  const { telegram: reduxTelegramFilter } = reduxFilter;
  const {
    selectedIssueM3: selectedIranIssue,
    selectedTaiwanIssue,
    preferredLanguage,
    preferredRegion,
  } = preference;

  const elementRef = React.useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(elementRef);
  const prevVisibility = usePrevious(isOnScreen);
  const previousSelectedIssue = usePrevious(
    preferredRegion === SUBSCRIBED_REGION.TAIWAN_REGION
      ? selectedTaiwanIssue
      : selectedIranIssue
  );

  const [selectedIssueM3, setSelectedIssue] = React.useState<
    undefined | string
  >();

  const [articles, setArticles] = React.useState<Array<TelegramPost>>([]);

  const [dateFilter, setDateFilter] = React.useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: reduxTelegramFilter.date.startDate
      ? new Date(reduxTelegramFilter.date.startDate)
      : new Date(DEFAULT_START_DATE),
    endDate: reduxTelegramFilter.date.endDate
      ? new Date(reduxTelegramFilter.date.endDate)
      : new Date(DateTime.now().toMillis()),
  });

  const [hasMoreRecords, setHasMoreRecords] = React.useState(true);
  const [filterOptions, setFilterOptions] = React.useState<{
    sub_issue?: Array<DropdownOption>;
    language?: Array<DropdownOption>;
    country?: Array<DropdownOption>;
  }>({
    language: undefined,
    sub_issue: undefined,
    country: undefined,
  });
  const [error, setError] = React.useState("");
  const [telgramDetailModal, setTelgramDetailModal] = React.useState<{
    show: boolean;
    telegram?: TelegramPost;
  }>({
    show: false,
    telegram: undefined,
  });
  const [dateOrder, setDateOrder] = React.useState<SingleValue<DropdownOption>>(
    DATE_ORDER_BY_OPTIONS[0]
  );
  const [subIssue, setSubIssue] = React.useState<Array<DropdownOption>>([]);
  const isApiReqInProgressRef = React.useRef(false);
  const articlesRef = React.useRef<Array<TelegramPost>>([]);
  const offsetLimitRef = React.useRef({
    limit: EMERGING_ISSUES_RECORD_LIMIT,
    offset: 0,
  });

  React.useEffect(() => {
    setSelectedIssue(activeIssue);
  }, [activeIssue]);

  React.useEffect(() => {
    if (
      reduxTelegramFilter.date.startDate &&
      reduxTelegramFilter.date.endDate
    ) {
      setDateFilter({
        startDate: new Date(reduxTelegramFilter.date.startDate),
        endDate: new Date(reduxTelegramFilter.date.endDate),
      });
    }
  }, [reduxTelegramFilter]);

  React.useEffect(() => {
    offsetLimitRef.current.offset = 0;
    articlesRef.current = [];
    setSubIssue([]);
    setArticles([]);
    setHasMoreRecords(false);
  }, [selectedIssueM3]);

  React.useEffect(() => {
    if (!selectedIssueM3) {
      return;
    }

    axiosInstance
      .post("emerging-issues/telegram-list-filters", {
        params: {
          filters: {
            issue: { type: FilterTypes.eq, value: selectedIssueM3 },
          },
          ...M3_PARAM,
        },
      })
      .then((res: { data: AxiosResponse<Filters> }) => {
        setFilterOptions({
          country: [{ ...DEFAULT_POST_LOCATION }, ...res.data.data.country],
          language: [{ ...DEFAULT_POST_LANGUAGE }, ...res.data.data.language],
          sub_issue: [{ ...DEFAULT_ALL_ISSUES }, ...res.data.data.sub_issue],
        });
      })
      .catch((err) => console.log(err));
  }, [selectedIssueM3]);

  const fetchArticleList = React.useCallback(
    (params: { [key: string]: unknown }, reset?: boolean) => {
      if (!isApiReqInProgressRef.current) {
        isApiReqInProgressRef.current = true;
        axiosInstance
          .post("emerging-issues/telegram-list", {
            params: {
              ...params,
              ...M3_PARAM,
            },
          })
          .then((res: { data: AxiosResponse<Array<TelegramPost>> }) => {
            if (reset) {
              updateCounter(res.data.data[0]?.total_records ?? 0);
              if (!res.data.data.length) {
                setError("No Records Available with this criteria");
              } else if (error) {
                setError("");
              }
            }

            if (res.data.data.length < EMERGING_ISSUES_RECORD_LIMIT) {
              setHasMoreRecords(false);
            } else if (res.data.data.length) {
              setHasMoreRecords(true);
            }

            if (!res.data.data.length) {
              return;
            }

            if (reset && res.data.data.length) {
              articlesRef.current = res.data.data;
              setArticles([...articlesRef.current]);
            } else if (res.data.data.length) {
              articlesRef.current =
                res.data.data.length &&
                articlesRef.current.length >= EMERGING_ISSUES_RECORD_LIMIT
                  ? articlesRef.current.concat(res.data.data)
                  : ([] as TelegramPost[]).concat(res.data.data);
              setArticles([...articlesRef.current]);
            }
          })
          .catch((error) => {
            console.log(error);
            setError(ERROR_MSG_GENERIC);
          })
          .finally(() => {
            isApiReqInProgressRef.current = false;
          });
      }
    },
    [error, updateCounter]
  );

  const filters = React.useCallback(() => {
    const subIssueFilterVal: Array<string> = [];
    subIssue.forEach((data) => {
      if (data.value) {
        subIssueFilterVal.push(data.value);
      }
    });
    return {
      limit: offsetLimitRef.current.limit,
      offset: offsetLimitRef.current.offset,
      order: [["date", dateOrder?.value]],
      filters: {
        issue: {
          value: selectedIssueM3,
          type: FilterTypes.eq,
        },
        language: reduxTelegramFilter.language?.value
          ? {
              value: reduxTelegramFilter.language.value,
              type: FilterTypes.eq,
            }
          : undefined,
        sub_issue: subIssueFilterVal.length
          ? {
              value: subIssueFilterVal,
              type: FilterTypes.includes,
            }
          : undefined,
        country: reduxTelegramFilter.country?.value
          ? {
              value: reduxTelegramFilter.country.value,
              type: FilterTypes.eq,
            }
          : undefined,
        date: {
          operator: FilterOperations.AND,
          conditions: [
            {
              value: reduxTelegramFilter.date.startDate
                ? DateTime.fromMillis(
                    reduxTelegramFilter.date.startDate
                  ).toFormat("yyyy-LL-dd")
                : getBackDate("week", 1),
              type: FilterTypes.gte,
            },
            {
              value: reduxTelegramFilter.date.endDate
                ? DateTime.fromMillis(
                    reduxTelegramFilter.date.endDate
                  ).toFormat("yyyy-LL-dd")
                : getBackDate("today", 0),
              type: FilterTypes.lte,
            },
          ],
        },
      },
    };
  }, [
    dateOrder?.value,
    reduxTelegramFilter.country?.value,
    reduxTelegramFilter.language?.value,
    reduxTelegramFilter.date?.startDate,
    reduxTelegramFilter.date?.endDate,
    selectedIssueM3,
    subIssue,
  ]);
  React.useEffect(() => {
    if (!selectedIssueM3 || isApiReqInProgressRef.current) {
      return;
    }
    const params = {
      ...filters(),
    };
    fetchArticleList(params, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, selectedIssueM3]);

  const getTagNumberString = React.useCallback((hashtags: string) => {
    const hashtagsArr = hashtags.split(",");
    return hashtagsArr.length > 10
      ? "10+ hashtags"
      : `${hashtagsArr.length} hashtags`;
  }, []);

  const onChangeFilterOption = React.useCallback(
    (newValue: SingleValue<unknown>, meta: ActionMeta<unknown>) => {
      articlesRef.current = [];
      offsetLimitRef.current.offset = 0;
      setArticles([]);

      dispatch(
        setTelegramFilterValue({
          ...reduxTelegramFilter,
          [meta.name ?? ""]: newValue,
        })
      );
    },
    [dispatch, reduxTelegramFilter]
  );

  const getPostParams = React.useCallback(
    (telegramPost: TelegramPost) => {
      return {
        handle: telegramPost.account_handle,
        date: telegramPost.date,
        postCategory: telegramPost.sub_issue,
        postContent: showEnglishContent(
          telegramPost.message_text_translated,
          preferredLanguage
        )
          ? telegramPost.message_text_translated
          : telegramPost.message_text,
        postLanguage: telegramPost.language,
        postLink: telegramPost.message_link,
        hashTags: telegramPost.hashtags
          ? getTagNumberString(telegramPost.hashtags)
          : "No Hashtag Provided",
        charLimit: EMERGING_ISSUES_ARTICLE_CHAR_LIMIT,
        selectedFilterLang: reduxTelegramFilter.language?.value ?? "English",
      };
    },
    [getTagNumberString, preferredLanguage, reduxTelegramFilter.language?.value]
  );

  const onDateChange = React.useCallback(
    ([newStartDate, newEndDate]: [Date | null, Date | null]) => {
      if (dateSelectorRef.current.state.open && newEndDate) {
        dateSelectorRef.current.setOpen(false);
      }
      articlesRef.current = [];
      offsetLimitRef.current.offset = 0;
      setDateFilter({
        startDate: newStartDate
          ? new Date(DateTime.fromJSDate(newStartDate).toMillis())
          : null,
        endDate: newEndDate
          ? new Date(DateTime.fromJSDate(newEndDate).toMillis())
          : null,
      });
      if (newEndDate) {
        dispatch(
          setTelegramFilterValue({
            ...reduxTelegramFilter,
            date: {
              startDate: newStartDate
                ? DateTime.fromJSDate(newStartDate).toMillis()
                : null,
              endDate: newEndDate
                ? DateTime.fromJSDate(newEndDate).toMillis()
                : null,
            },
          })
        );
      }
    },
    [dispatch, reduxTelegramFilter]
  );

  const handleReadMore = React.useCallback((telegram: TelegramPost) => {
    SendGAEvent({
      category: "SOTOS_Emerging_Issue_Telegram_Detail_View",
      action: "SOTOS_Emerging_Issue_Telegram_Detail_View",
      includeUserDetail: true,
      label: `telegram-handle:${telegram.account_handle.replace(
        "@",
        "at-"
      )},telegram-date:${telegram.date},issue-${telegram.issue}`,
    });
    setTelgramDetailModal({
      show: true,
      telegram: { ...telegram, english_sub_issue: telegram.sub_issue },
    });
  }, []);

  const onHideModal = React.useCallback(() => {
    setTelgramDetailModal({
      show: false,
      telegram: undefined,
    });
  }, []);

  React.useEffect(() => {
    if (isOnScreen && hasMoreRecords && !isApiReqInProgressRef.current) {
      offsetLimitRef.current = {
        ...offsetLimitRef.current,
        ...filters(),
        offset: articles.length,
      };
      fetchArticleList(offsetLimitRef.current);
    }
  }, [
    articles.length,
    fetchArticleList,
    filters,
    hasMoreRecords,
    isOnScreen,
    prevVisibility,
  ]);

  const onChangeFilterOrderBy = React.useCallback(
    (newValue: SingleValue<unknown>, actionMeta: ActionMeta<unknown>) => {
      articlesRef.current = [];
      offsetLimitRef.current = {
        offset: 0,
        limit: 20,
      };
      setDateOrder(newValue as DropdownOption);
    },
    []
  );

  const calendarDates = React.useMemo(() => {
    return {
      startDate: dateFilter.startDate,
      endDate: dateFilter.endDate,
    };
  }, [dateFilter]);

  const LoadMoreElement = React.useMemo(() => {
    if (previousSelectedIssue !== selectedIssueM3 || hasMoreRecords) {
      return (
        <div
          ref={elementRef}
          id={`telegram-${selectedIssueM3?.toLowerCase().replaceAll(" ", "-")}`}
        >
          Loading...
        </div>
      );
    }
  }, [hasMoreRecords, previousSelectedIssue, selectedIssueM3]);

  return (
    <div id="telegramScrollableDiv">
      <StyledTabSubHeading>
        <SectionSubTitle
          text="Related Posts"
          icon={<Info size={25} color="#475569" className="info-icon" />}
          iconPosition="Trailing"
          description="Related posts to issues of interest on Telegram."
        />
      </StyledTabSubHeading>

      <div className="mb-2">
        <Row className="d-flex flex-row">
          {/* <Col lg={3} sm={12} xs={12} xl={3}>
            <div>{labels.subIssue}</div>
            <ReactSelect
              isSearchable={false}
              options={filterOptions.sub_issue}
              name="sub_issue"
              isMulti
              onChange={onChangeMultiFilter}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              components={{
                Option: MultiSelectOptionsWithCB,
                ValueContainer,
                Menu,
              }}
              value={selectedSubIssue}
            />
          </Col> */}

          <Col lg={3} sm={12} xs={12} xl={3}>
            <ModifiedReactSelectV2
              isSearchable={false}
              options={filterOptions.language}
              name="language"
              onChange={onChangeFilterOption}
              value={reduxTelegramFilter.language}
            />
          </Col>

          <Col lg={3} sm={12} xs={12} xl={3}>
            <ModifiedReactSelectV2
              isSearchable={false}
              options={filterOptions.country}
              name="country"
              onChange={onChangeFilterOption}
              value={reduxTelegramFilter.country}
            />
          </Col>

          <Col lg={3} xs={12} sm={12} xl={3}>
            <StyledDateSelecterM3V2
              className="w-100 text-center"
              selected={calendarDates.startDate}
              wrapperClassName="w-100"
              onChange={onDateChange}
              minDate={new Date(MAX_ALLOWED_BACK_DATE)}
              maxDate={new Date(DEFAULT_END_DATE)}
              startDate={calendarDates.startDate}
              endDate={calendarDates.endDate}
              selectsRange
              dateFormat={"MM/dd"}
              showDisabledMonthNavigation
              ref={dateSelectorRef}
              onCalendarOpen={() => {
                dateSelectorRef.current.input.classList.add("primary-border");
              }}
              onCalendarClose={() => {
                dateSelectorRef.current.input.classList.remove(
                  "primary-border"
                );
              }}
              showIcon
              icon={
                <span
                  style={{
                    right: 10,
                    lineHeight: 0,
                    margin: "auto",
                    top: 0,
                    bottom: 0,
                  }}
                  onClick={() => {
                    dateSelectorRef.current.setOpen(true);
                  }}
                >
                  <CalendarIcon />
                </span>
              }
            />
          </Col>
          <Col
            lg={3}
            xl={3}
            md={3}
            sm={12}
            xs={12}
            className="d-flex justify-content-between align-items-center"
          >
            <ModifiedReactSelectV2
              isSearchable={false}
              options={DATE_ORDER_BY_OPTIONS}
              onChange={onChangeFilterOrderBy}
              value={dateOrder}
              className="flex-fill"
            />
          </Col>
        </Row>
        {/* <Row style={{ marginTop: 24, marginBottom: 24 }}>

        </Row> */}
      </div>

      {telgramDetailModal.show && telgramDetailModal.telegram && (
        <TelegramDetailModal
          {...telgramDetailModal.telegram}
          issue={selectedIssueM3 ?? ""}
          onHide={onHideModal}
        />
      )}

      {error ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 30,
          }}
          className="py-5"
        >
          {error}
        </div>
      ) : !articles.length ? (
        <div
          style={{
            height: "600px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>Loading...</div>
        </div>
      ) : (
        <div>
          {articles.map((article) => {
            return (
              <PostCardM3
                onClickReadMore={() => handleReadMore(article)}
                key={uuid4()}
                {...getPostParams(article)}
              />
            );
          })}
        </div>
      )}
      <GoToTop offset={800} />

      {hasMoreRecords && (
        <div
          ref={elementRef}
          id={`telegram-${selectedIssueM3?.toLowerCase().replaceAll(" ", "-")}`}
        >
          Loading...
        </div>
      )}
    </div>
  );
};
