import React from "react";
import { components, OptionProps } from "react-select";
import { DropdownOption } from "../types/types";
import { THEME_COLOUR_PRIMARY } from "../constants";
import styled from "styled-components";

export const MultiSelectOptionsWithCB = React.memo(
  (props: OptionProps<DropdownOption>) => {
    return (
      <div>
        <components.Option {...props} className="h-7 d-flex align-items-center">
          <div
            className={`custom-checkbox ${
              props.isSelected ? "custom-checked" : ""
            }`}
          >
            <StyledCheckbox
              type="checkbox"
              checked={props.isSelected}
              onChange={() => {}}
            />
            <StyledLabel>&nbsp;{props.label}</StyledLabel>
          </div>
        </components.Option>
      </div>
    );
  }
);

const StyledCheckbox = styled.input`
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
`;

const StyledLabel = styled.label`
  position: relative;
  cursor: pointer;
  &:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid ${THEME_COLOUR_PRIMARY};
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }
`;
