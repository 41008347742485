import React from "react";
import { AxiosResponse } from "axios";
import { v4 as uuid4 } from "uuid";
import axiosInstance from "../../api";
import {
  DATE_ORDER_BY_OPTIONS,
  DEFAULT_END_DATE,
  DEFAULT_POST_LANGUAGE,
  DEFAULT_POST_LOCATION,
  DEFAULT_START_DATE,
  EMERGING_ISSUES_ARTICLE_CHAR_LIMIT,
  EMERGING_ISSUES_RECORD_LIMIT,
  EMPTY_CONTENT_STRING,
  ERROR_MSG_GENERIC,
  labels,
  M3_PARAM,
  MAX_ALLOWED_BACK_DATE,
  SUBSCRIBED_REGION,
} from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { FilterOperations, FilterTypes } from "../../types/filterTypes";
import { SectionSubTitle } from "../../components/SectionSubTitle";
import { Info } from "@styled-icons/material-outlined";
import { Col, Row } from "react-bootstrap";
import ReactSelect, {
  ActionMeta,
  components,
  MenuProps,
  MultiValue,
  SingleValue,
  ValueContainerProps,
} from "react-select";
import { MultiSelectOptionsWithCB } from "../../components/MultiSelectCBOptions";
import { MultiSelectMenu } from "../../components/MultiSelectMenu";
import { DropdownOption, Twitter } from "../../types/types";
import { DateTime } from "luxon";
import { StyledDatePicker } from "../../components/StyledDatePicker";
import { TwitterDetailModal } from "../../components/TwitterDetailModal";
import useOnScreen from "../../helpers/useOnScreen";
import { usePrevious } from "../../helpers/usePrevious";
import { GoToTop } from "../../components/GoToTop";
import { StyledTabSubHeading } from ".";
import { setTwitterFilterValue } from "../../store/slice/filters";
import { getBackDate, showEnglishContent } from "../../helpers";
import { ModifiedReactSelect } from "../../components/ModifiedReactSelect";
import {
  StyledDateSelecterM3,
  StyledDateSelecterM3V2,
} from "../../components/CommonStyledComponents";
import { PostCardM3 } from "../../components/PostCardM3";
import { SendGAEvent } from "../../helpers/googleAnalyticsHepler";
import { ModifiedReactSelectV2 } from "../../components/ModifiedReactSelect";
import { CalendarIcon } from "../../components/CalendarIcon";

interface Filters {
  country: Array<DropdownOption>;
  sub_issue: Array<DropdownOption>;
  language: Array<DropdownOption>;
}

interface Props {
  activeIssue: string;
  updateCounter: (count: number) => void;
  activeTab: string;
}

export const TwitterList = ({
  activeIssue,
  updateCounter,
  activeTab,
}: Props) => {
  const dispatch = useDispatch();
  const dateSelectorRef = React.useRef<any>();

  const preference = useSelector((state: RootState) => state.preference);
  const reduxFilter = useSelector((state: RootState) => state.reduxFilter);

  const {
    selectedIssueM3: selectedIranIssue,
    selectedTaiwanIssue,
    preferredLanguage,
    preferredRegion,
  } = preference;
  const elementRef = React.useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(elementRef);
  const prevVisibility = usePrevious(isOnScreen);

  const [selectedIssueM3, setSelectedIssue] = React.useState<
    undefined | string
  >();

  const { twitter: reduxTwitterFilter } = reduxFilter;
  const [articles, setArticles] = React.useState<Array<Twitter>>([]);
  const [hasMoreRecords, setHasMoreRecords] = React.useState(false);
  const [error, setError] = React.useState("");
  const [twitterModal, setTwitterModal] = React.useState<{
    show: boolean;
    twitter?: Twitter;
  }>({
    show: false,
    twitter: undefined,
  });
  const [filterOptions, setFilterOptions] = React.useState<{
    sub_issue?: Array<DropdownOption>;
    language?: Array<DropdownOption>;
    country?: Array<DropdownOption>;
  }>({
    language: undefined,
    sub_issue: undefined,
    country: undefined,
  });

  const [dateFilter, setDateFilter] = React.useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: reduxTwitterFilter.date.startDate
      ? new Date(reduxTwitterFilter.date.startDate)
      : new Date(DEFAULT_START_DATE),
    endDate: reduxTwitterFilter.date.endDate
      ? new Date(reduxTwitterFilter.date.endDate)
      : new Date(DateTime.now().toMillis()),
  });

  const [dateOrder, setDateOrder] = React.useState<SingleValue<DropdownOption>>(
    DATE_ORDER_BY_OPTIONS[0]
  );
  const [subIssue, setSubIssue] = React.useState<Array<DropdownOption>>([]);
  const [selectedSubIssue, setSelectedSubIssue] = React.useState<
    Array<DropdownOption>
  >([]);

  const isApiReqInProgressRef = React.useRef(false);
  const articlesRef = React.useRef<Array<Twitter>>([]);
  const offsetLimitRef = React.useRef({
    limit: EMERGING_ISSUES_RECORD_LIMIT,
    offset: 0,
  });

  React.useEffect(() => {
    if (reduxTwitterFilter.date.startDate && reduxTwitterFilter.date.endDate) {
      setDateFilter({
        startDate: new Date(reduxTwitterFilter.date.startDate),
        endDate: new Date(reduxTwitterFilter.date.endDate),
      });
    }
  }, [reduxTwitterFilter]);

  React.useEffect(() => {
    setSelectedIssue(activeIssue);
  }, [activeIssue]);

  React.useEffect(() => {
    offsetLimitRef.current.offset = 0;
    articlesRef.current = [];
    setHasMoreRecords(false);
    setSubIssue([]);
    setArticles([]);
  }, [selectedIssueM3, preferredRegion]);

  React.useEffect(() => {
    if (!selectedIssueM3) {
      return;
    }

    axiosInstance
      .post("emerging-issues/twitter-list-filters", {
        params: {
          filters: {
            issue: { type: FilterTypes.eq, value: selectedIssueM3 },
          },
          ...M3_PARAM,
          subscribed_region: preferredRegion,
        },
      })
      .then((res: { data: AxiosResponse<Filters> }) => {
        setFilterOptions({
          country: [{ ...DEFAULT_POST_LOCATION }, ...res.data.data.country],
          language: [{ ...DEFAULT_POST_LANGUAGE }, ...res.data.data.language],
          sub_issue: [{ label: "All", value: "" }, ...res.data.data.sub_issue],
        });
        setSelectedSubIssue([
          { label: "All", value: "" },
          ...res.data.data.sub_issue,
        ]);
      })
      .catch((err) => console.log(err));
  }, [selectedIssueM3, preferredRegion]);

  const fetchArticleList = React.useCallback(
    (params: { [key: string]: unknown }, reset?: boolean) => {
      if (!isApiReqInProgressRef.current) {
        isApiReqInProgressRef.current = true;
        axiosInstance
          .post("emerging-issues/twitter-list", {
            params: {
              ...params,
              ...M3_PARAM,
              subscribed_region: preferredRegion,
            },
          })
          .then((res: { data: AxiosResponse<Array<Twitter>> }) => {
            if (reset) {
              updateCounter(res.data.data[0]?.total_records ?? 0);
              if (!res.data.data.length) {
                setError("No Records Available with this criteria");
              } else if (error) {
                setError("");
              }
            }

            if (res.data.data.length < EMERGING_ISSUES_RECORD_LIMIT) {
              setHasMoreRecords(false);
            } else if (res.data.data.length) {
              setHasMoreRecords(true);
            }

            if (reset && res.data.data.length) {
              articlesRef.current = res.data.data;
              setArticles([...articlesRef.current]);
            } else if (res.data.data.length) {
              articlesRef.current =
                res.data.data.length &&
                articlesRef.current.length >= EMERGING_ISSUES_RECORD_LIMIT
                  ? articlesRef.current.concat(res.data.data)
                  : ([] as Twitter[]).concat(res.data.data);
              setArticles([...articlesRef.current]);
            }
          })
          .catch((error) => {
            setError(ERROR_MSG_GENERIC);
          })
          .finally(() => {
            isApiReqInProgressRef.current = false;
          });
      }
    },
    [error, updateCounter, preferredRegion]
  );

  const filters = React.useCallback(() => {
    const subIssueFilterVal: Array<string> = [];
    subIssue.forEach((data) => {
      if (data.value) {
        subIssueFilterVal.push(data.value);
      }
    });
    return {
      limit: offsetLimitRef.current.limit,
      offset: offsetLimitRef.current.offset,
      order: [["date", dateOrder?.value]],
      filters: {
        issue: {
          value: selectedIssueM3,
          type: FilterTypes.eq,
        },
        language: reduxTwitterFilter.language?.value
          ? {
              value: reduxTwitterFilter.language.value,
              type: FilterTypes.eq,
            }
          : undefined,
        sub_issue: subIssueFilterVal.length
          ? {
              value: subIssueFilterVal,
              type: FilterTypes.includes,
            }
          : undefined,
        country: reduxTwitterFilter.country?.value
          ? {
              value: reduxTwitterFilter.country.value,
              type: FilterTypes.eq,
            }
          : undefined,
        date: {
          operator: FilterOperations.AND,
          conditions: [
            {
              value: reduxTwitterFilter.date.startDate
                ? DateTime.fromMillis(
                    reduxTwitterFilter.date.startDate
                  ).toFormat("yyyy-LL-dd")
                : getBackDate("week", 1),
              type: FilterTypes.gte,
            },
            {
              value: reduxTwitterFilter.date.endDate
                ? DateTime.fromMillis(reduxTwitterFilter.date.endDate).toFormat(
                    "yyyy-LL-dd"
                  )
                : getBackDate("today", 0),
              type: FilterTypes.lte,
            },
          ],
        },
      },
    };
  }, [
    dateOrder?.value,
    reduxTwitterFilter.country?.value,
    reduxTwitterFilter.language?.value,
    reduxTwitterFilter.date?.startDate,
    reduxTwitterFilter.date?.endDate,
    selectedIssueM3,
    subIssue,
  ]);
  React.useEffect(() => {
    if (!selectedIssueM3 || isApiReqInProgressRef.current) {
      return;
    }

    const params = {
      ...filters(),
    };
    fetchArticleList(params, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, selectedIssueM3]);

  const getTagNumberString = React.useCallback((hashtags: string) => {
    const hashtagsArr = hashtags.split(",");
    return hashtagsArr.length > 10
      ? "10+ hashtags"
      : `${hashtagsArr.length} hashtags`;
  }, []);

  const getPostParams = React.useCallback(
    (tweet: Twitter) => {
      return {
        handle: tweet.twitter_handle,
        date: tweet.date,
        postCategory: tweet.sub_issue,
        postContent: showEnglishContent(
          tweet.tweet_text_translated,
          preferredLanguage
        )
          ? tweet.tweet_text_translated
          : tweet.tweet_text,
        postLanguage: tweet.language,
        postLink: tweet.tweet_original_link,
        hashTags: tweet.hashtag
          ? getTagNumberString(tweet.hashtag)
          : "No Hashtag Provided",
        charLimit: EMERGING_ISSUES_ARTICLE_CHAR_LIMIT,
        selectedFilterLang: reduxTwitterFilter.language?.value ?? "English",
      };
    },
    [getTagNumberString, preferredLanguage, reduxTwitterFilter.language?.value]
  );

  const onDateChange = React.useCallback(
    ([newStartDate, newEndDate]: [Date | null, Date | null]) => {
      if (dateSelectorRef.current.state.open && newEndDate) {
        dateSelectorRef.current.setOpen(false);
      }
      articlesRef.current = [];
      offsetLimitRef.current.offset = 0;
      setDateFilter({
        startDate: newStartDate
          ? new Date(DateTime.fromJSDate(newStartDate).toMillis())
          : null,
        endDate: newEndDate
          ? new Date(DateTime.fromJSDate(newEndDate).toMillis())
          : null,
      });
      if (newEndDate) {
        dispatch(
          setTwitterFilterValue({
            ...reduxTwitterFilter,
            date: {
              startDate: newStartDate
                ? DateTime.fromJSDate(newStartDate).toMillis()
                : null,
              endDate: newEndDate
                ? DateTime.fromJSDate(newEndDate).toMillis()
                : null,
            },
          })
        );
      }
    },
    [dispatch, reduxTwitterFilter]
  );

  const onChangeFilterOption = React.useCallback(
    (newValue: SingleValue<unknown>, meta: ActionMeta<unknown>) => {
      articlesRef.current = [];
      offsetLimitRef.current.offset = 0;
      setArticles([]);
      dispatch(
        setTwitterFilterValue({
          ...reduxTwitterFilter,
          [meta.name ?? ""]: newValue,
        })
      );
    },
    [dispatch, reduxTwitterFilter]
  );

  const onChangeMultiFilter = React.useCallback(
    (
      newValue: MultiValue<DropdownOption>,
      meta: ActionMeta<DropdownOption>
    ) => {
      if (meta.action === "select-option" && meta.option?.label === "All") {
        const filterOptsSub = filterOptions.sub_issue?.length
          ? filterOptions.sub_issue
          : [];
        setSelectedSubIssue([...filterOptsSub]);
        return;
      }

      if (meta.action === "deselect-option" && meta.option?.label === "All") {
        setSelectedSubIssue([]);
        return;
      }

      if (newValue.length && !newValue[0].value) {
        const selectedOpts = [...newValue].slice(1);
        setSelectedSubIssue([...selectedOpts]);
      } else {
        setSelectedSubIssue([...newValue]);
      }
    },
    [filterOptions.sub_issue]
  );

  const ValueContainer = React.useCallback(
    ({ children, ...props }: ValueContainerProps<DropdownOption>) => {
      let label = "";
      if (props.getValue().length === filterOptions.sub_issue?.length) {
        label = "All";
      } else if (props.getValue().length) {
        label = `${props.getValue().length} option selected`;
      }

      return (
        <components.ValueContainer {...props}>
          {label}
        </components.ValueContainer>
      );
    },
    [filterOptions.sub_issue?.length]
  );

  const onConfirmSubIssueSelection = React.useCallback(() => {
    articlesRef.current = [];
    offsetLimitRef.current.offset = 0;
    setArticles([]);
    setSubIssue([...selectedSubIssue]);
  }, [selectedSubIssue]);

  const Menu = React.useCallback(
    (menuProps: MenuProps<DropdownOption>) => (
      <MultiSelectMenu
        onConfirmSubIssueSelection={onConfirmSubIssueSelection}
        menuProps={menuProps}
      />
    ),
    [onConfirmSubIssueSelection]
  );

  const handleReadMore = React.useCallback((twitter: Twitter) => {
    setTwitterModal({
      show: true,
      twitter: { ...twitter, english_sub_issue: twitter.sub_issue },
    });
    SendGAEvent({
      category: "SOTOS_Emerging_Issue_Twitter_Detail_View",
      action: "SOTOS_Emerging_Issue_Twitter_Detail_View",
      includeUserDetail: true,
      label: `tweeter-handle:${twitter.twitter_handle},tweet-date:${twitter.date},issue-${twitter.issue}`,
    });
  }, []);

  const onHideModal = React.useCallback(() => {
    setTwitterModal({
      show: false,
      twitter: undefined,
    });
  }, []);

  React.useEffect(() => {
    if (
      isOnScreen &&
      hasMoreRecords &&
      !isApiReqInProgressRef.current &&
      articles.length >= EMERGING_ISSUES_RECORD_LIMIT
    ) {
      offsetLimitRef.current = {
        ...offsetLimitRef.current,
        ...filters(),
        offset: articles.length,
      };
      fetchArticleList(offsetLimitRef.current);
    }
  }, [
    articles.length,
    fetchArticleList,
    filters,
    hasMoreRecords,
    isOnScreen,
    prevVisibility,
  ]);

  const onChangeFilterOrderBy = React.useCallback(
    (newValue: SingleValue<unknown>, actionMeta: ActionMeta<unknown>) => {
      articlesRef.current = [];
      offsetLimitRef.current = {
        offset: 0,
        limit: 20,
      };
      setDateOrder(newValue as DropdownOption);
    },
    []
  );

  const calendarDates = React.useMemo(() => {
    return {
      startDate: dateFilter.startDate,
      endDate: dateFilter.endDate,
    };
  }, [dateFilter]);

  return (
    <div>
      <StyledTabSubHeading>
        <SectionSubTitle
          text="Related Tweets"
          icon={<Info size={25} color="#475569" className="info-icon" />}
          iconPosition="Trailing"
          description="Deep listening to selected issues on X."
        />
      </StyledTabSubHeading>

      <div className="mb-2">
        <Row className="d-flex flex-row">
          {/* <Col lg={3} sm={12} xs={12} xl={3}>
            <div>{labels.subIssue}</div>
            <ReactSelect
              isSearchable={false}
              options={filterOptions.sub_issue}
              name="sub_issue"
              isMulti
              onChange={onChangeMultiFilter}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              components={{
                Option: MultiSelectOptionsWithCB,
                ValueContainer,
                Menu,
              }}
              value={selectedSubIssue}
            />
          </Col> */}

          <Col lg={3} sm={12} xs={12} xl={3}>
            <ModifiedReactSelectV2
              isSearchable={false}
              options={filterOptions.language}
              name="language"
              onChange={onChangeFilterOption}
              value={reduxTwitterFilter.language}
            />
          </Col>

          <Col lg={3} sm={12} xs={12} xl={3}>
            <ModifiedReactSelectV2
              isSearchable={false}
              options={filterOptions.country}
              name="country"
              onChange={onChangeFilterOption}
              value={reduxTwitterFilter.country}
            />
          </Col>

          <Col lg={3} xs={12} sm={12} xl={3}>
            <StyledDateSelecterM3V2
              className="w-100 text-center"
              selected={calendarDates.startDate}
              wrapperClassName="w-100"
              onChange={onDateChange}
              minDate={new Date(MAX_ALLOWED_BACK_DATE)}
              maxDate={new Date(DEFAULT_END_DATE)}
              startDate={calendarDates.startDate}
              endDate={calendarDates.endDate}
              selectsRange
              dateFormat={"MM/dd"}
              showDisabledMonthNavigation
              ref={dateSelectorRef}
              onCalendarOpen={() => {
                dateSelectorRef.current.input.classList.add("primary-border");
              }}
              onCalendarClose={() => {
                dateSelectorRef.current.input.classList.remove(
                  "primary-border"
                );
              }}
              showIcon
              icon={
                <span
                  style={{
                    right: 10,
                    lineHeight: 0,
                    margin: "auto",
                    top: 0,
                    bottom: 0,
                  }}
                  onClick={() => {
                    dateSelectorRef.current.setOpen(true);
                  }}
                >
                  <CalendarIcon />
                </span>
              }
            />
          </Col>
          <Col
            lg={3}
            xl={3}
            md={3}
            sm={12}
            xs={12}
            className="d-flex justify-content-between align-items-center"
          >
            <ModifiedReactSelectV2
              isSearchable={false}
              options={DATE_ORDER_BY_OPTIONS}
              onChange={onChangeFilterOrderBy}
              value={dateOrder}
              className="flex-fill"
            />
          </Col>
        </Row>
        {/*  <Row style={{ marginTop: 24, marginBottom: 24 }}>

        </Row> */}
      </div>
      {twitterModal.show && twitterModal.twitter && (
        <TwitterDetailModal
          {...twitterModal.twitter}
          onHide={onHideModal}
          issue={selectedIssueM3 ?? ""}
        />
      )}
      {error ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 30,
          }}
          className="py-5"
        >
          {error}
        </div>
      ) : !articles.length ? (
        <div
          style={{
            height: "600px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>Loading...</div>
        </div>
      ) : (
        <div>
          {articles.map((article) => {
            return (
              <PostCardM3
                onClickReadMore={() => handleReadMore(article)}
                key={uuid4()}
                {...getPostParams(article)}
              />
            );
          })}
        </div>
      )}
      <GoToTop offset={800} />

      {hasMoreRecords && <div ref={elementRef}>Loading...</div>}
    </div>
  );
};
