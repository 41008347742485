import React from "react";
import { useLocation } from "react-router";

type Props = {
  children?: React.ReactNode;
};

export const RouteWrapper: React.FC<Props> = ({ children }) => {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  return <>{children}</>;
};
