import * as React from "react";

export const ArticleIcon = ({
  isActive = false,
  color,
}: {
  isActive: boolean;
  color?: string;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={isActive ? `#FFF` : `none`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 1H5C3.9 1 3 1.9 3 3V21C3 22.11 3.9 23 5 23H19C20.11 23 21 22.11 21 21V3C21 1.9 20.11 1 19 1ZM13.57 18.43C13.43 18.57 13.24 18.66 13.03 18.66H6.89C6.69 18.66 6.49 18.57 6.35 18.43C6.21 18.29 6.12 18.09 6.12 17.89C6.12 17.68 6.21 17.49 6.35 17.35C6.49 17.2 6.69 17.12 6.89 17.12H13.03C13.24 17.12 13.43 17.2 13.57 17.35C13.72 17.49 13.8 17.68 13.8 17.89C13.8 18.1 13.72 18.29 13.57 18.43ZM17.67 15.36C17.52 15.5 17.33 15.59 17.12 15.59H6.89C6.69 15.59 6.49 15.5 6.35 15.36C6.21 15.22 6.12 15.02 6.12 14.82C6.12 14.61 6.21 14.42 6.35 14.28C6.49 14.13 6.69 14.05 6.89 14.05H17.12C17.33 14.05 17.52 14.13 17.67 14.28C17.81 14.42 17.89 14.61 17.89 14.82C17.89 15.03 17.81 15.22 17.67 15.36ZM17.89 11.24C17.89 11.44 17.81 11.63 17.66 11.78C17.52 11.92 17.33 12 17.12 12H6.89C6.69 12 6.5 11.92 6.35 11.78C6.21 11.63 6.13 11.44 6.12 11.24V4.95C6.13 4.75 6.21 4.55 6.35 4.41C6.5 4.27 6.69 4.19 6.89 4.18H17.12C17.33 4.19 17.52 4.27 17.66 4.41C17.81 4.55 17.89 4.75 17.89 4.95V11.24Z"
        fill={isActive ? `#FFF` : color ? color : `#94A3B8`}
      />
    </svg>
  );
};
export const TwitterIcon = ({
  isActive = false,
  color,
}: {
  isActive: boolean;
  color?: string;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={isActive ? `#FFF` : `none`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99572 2H1.16797L9.26322 12.8762L1.68584 22H5.18682L10.9179 15.0994L16.0069 21.9365H22.8346L14.5041 10.7442L14.5189 10.7635L21.6916 2.12701H18.1906L12.864 8.54064L7.99572 2ZM4.93678 3.90478H7.06237L19.0658 20.0317H16.9402L4.93678 3.90478Z"
        fill={isActive ? `#FFF` : color ? color : `#94A3B8`}
      />
    </svg>
  );
};

export const TelegramIcon = ({
  isActive = false,
  color,
}: {
  isActive: boolean;
  color?: string;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={isActive ? `#FFF` : `none`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.02438 10.392L21.5348 2.86941C22.4403 2.54227 23.2312 3.09032 22.9378 4.45959L22.9395 4.4579L19.6175 20.1084C19.3713 21.218 18.7119 21.4878 17.7895 20.965L12.7307 17.2366L10.2906 19.5873C10.0208 19.8571 9.79314 20.0848 9.27038 20.0848L9.62957 14.9365L19.0054 6.46628C19.4134 6.1071 18.9143 5.90474 18.3764 6.26224L6.78985 13.5571L1.79504 11.999C0.710756 11.655 0.687148 10.9147 2.02438 10.392Z"
        fill={isActive ? `#FFF` : color ? color : `#94A3B8`}
      />
    </svg>
  );
};

export const HashtagIcon = ({
  isActive = false,
  color,
}: {
  isActive: boolean;
  color?: string;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.91535 2.5L5.41535 17.5M14.582 2.5L12.082 17.5M17.082 6.66667H2.91536M16.2487 13.3333H2.08203"
        stroke={color ?? "#475569"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
