import React from "react";
import { MoversAndShaperRecord } from "../../types/types";
import styled from "styled-components";
import { MANDSPROFILE_PREFIX_URL } from "../../constants";
import { BottomStyledToolTip } from "../../components/StyledToolTip";

interface MandSProfileProps extends MoversAndShaperRecord {
  borderColor?: string;
  onClick?: (
    corrected_name: string,
    native_name: string,
    other_name: string
  ) => void;
}

export const MandSProfileAvatar = React.memo(
  ({
    corrected_name,
    native_name,
    other_name,
    name,
    borderColor,
    onClick,
    photo,
  }: MandSProfileProps) => {
    const imageEleRef = React.useRef<HTMLImageElement>(null);
    const handleImageLoadError = () => {
      console.log("HAHA");
      if (imageEleRef.current) {
        imageEleRef.current.src =
          process.env.PUBLIC_URL + "/default_profile_image.png";
      }
    };
    const onClickPhoto = () => {
      onClick && onClick(corrected_name, native_name, other_name);
    };
    return (
      <>
        <MandSPhoto
          src={`${MANDSPROFILE_PREFIX_URL + photo}`}
          alt={corrected_name}
          color={borderColor ?? ""}
          className="img-fluid rounded-circle"
          onError={handleImageLoadError}
          onClick={onClickPhoto}
          id={`mands-photo-${corrected_name.replace(/[^\w]/gi, "-")}`}
        />
        <BottomStyledToolTip
          anchorSelect={`#mands-photo-${corrected_name.replace(
            /[^\w]/gi,
            "-"
          )}`}
          place="bottom"
        >
          {corrected_name}
        </BottomStyledToolTip>
      </>
    );
  }
);

MandSProfileAvatar.displayName = "MandSProfileAvatar";

const MandSPhoto = styled("img").withConfig({
  shouldForwardProp: (prop) => prop !== "color",
})<{ color: string }>(({ color }) => ({
  height: "60px",
  width: "60px",
  objectFit: "cover",
  border: `3px solid ${color}`,
  span: {
    paddingTop: "0.2rem",
  },
}));
