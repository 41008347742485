import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { PIE_CHART_COLOUR_PALLETE, SUBSCRIBED_REGION } from "../../constants";
import { RootState } from "../../store";
import { Circle } from "@styled-icons/material";

interface Props {
  data: Array<{
    label: string;
    issue_count: number;
    overall_percentage: number;
  }>;
}

export const MapStats = ({ data }: Props) => {
  const { issuesM3, taiwanIssues, preferredRegion } = useSelector(
    (state: RootState) => state.preference
  );
  const getOriginalIssueName = React.useCallback(
    (issueName: string) => {
      if (
        preferredRegion === SUBSCRIBED_REGION.IRAN_REGION &&
        (!issuesM3.englishIssues?.length ||
          !issuesM3.originalLanguageIssues?.length)
      ) {
        return "";
      } else if (
        preferredRegion === SUBSCRIBED_REGION.TAIWAN_REGION &&
        (!taiwanIssues.englishIssues?.length ||
          !taiwanIssues.originalLanguageIssues?.length)
      ) {
        return "";
      }
      const selectedIssues =
        preferredRegion === SUBSCRIBED_REGION.IRAN_REGION
          ? issuesM3
          : taiwanIssues;
      const indexOfEngIssue = selectedIssues.englishIssues.indexOf(issueName);
      return selectedIssues.originalLanguageIssues[indexOfEngIssue];
    },
    [issuesM3, preferredRegion, taiwanIssues]
  );
  return (
    <>
      <div>
        <Row className={"border-bottom py-2"}>
          <Col xs={12} sm={12} md={5} xl={5} lg={5}>
            <StyledColumnName className="ps-3">Issues</StyledColumnName>
          </Col>

          <Col className={"text-center"} xs={12} sm={12} md={3} xl={3} lg={3}>
            <StyledColumnName>Issue Count</StyledColumnName>
          </Col>

          <Col className={"text-center"} xs={12} sm={12} md={3} xl={3} lg={3}>
            {" "}
            <StyledColumnName>% of all Issues</StyledColumnName>
          </Col>
        </Row>
      </div>

      <div>
        {data.slice(0, 5).map((record, index) => {
          return (
            <Row
              key={index}
              className={`py-2 ${index !== 4 ? "border-bottom" : ""}`}
            >
              <Col className="text-left" xs={12} sm={12} md={5} xl={5} lg={5}>
                <div className="d-flex align-items-center">
                  <span className="pe-2">
                    <Circle color={PIE_CHART_COLOUR_PALLETE[index]} size={11} />
                  </span>
                  <span>
                    <StyledColumnData style={{ fontWeight: "bold" }}>
                      {record.label}
                    </StyledColumnData>
                    <StyledOriginalIssue>
                      (&nbsp;{getOriginalIssueName(record.label)}&nbsp;)
                    </StyledOriginalIssue>
                  </span>
                </div>
              </Col>
              <Col
                className={"text-center py-2"}
                xs={12}
                sm={12}
                md={3}
                xl={3}
                lg={3}
              >
                <StyledColumnData>{record.issue_count}</StyledColumnData>
              </Col>
              <Col
                className={"text-center py-2"}
                xs={12}
                sm={12}
                md={3}
                xl={3}
                lg={3}
              >
                <StyledColumnData>
                  {record.overall_percentage}%
                </StyledColumnData>
              </Col>
            </Row>
          );
        })}
      </div>
    </>
  );
};

const StyledColumnName = styled.div`
  color: #94a3b8;
  font-size: 16px;
`;

const StyledOriginalIssue = styled.div<{ color?: string }>`
  font-size: 14px;
  padding-left: 2px;
  color: #475569;
  font-weight: bold;
`;

const StyledColumnData = styled.div<{ color?: string }>`
  color: 475569;
  font-size: 14px;
`;
