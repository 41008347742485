import { Circle } from "@styled-icons/material";
import React from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  NEWS_AGENCY_PREFIX_URL,
  SUBSCRIBED_REGION,
  TAIWANESE_NEWS_AGENCY_PREFIX_URL,
  THEME_COLOUR_PRIMARY,
  DEFAULT_VISIBLE_NEWS_AGENCIES,
} from "../constants";
import { RootState } from "../store";
import { BottomStyledToolTip } from "./StyledToolTip";
import { LanguageIcon } from "./LanguageIcon";
import { GenAISummary } from "../types/types";
import { htmlStringEncoder } from "../helpers";
import {
  getActiveNewsMediaAgency,
  getActiveNewsMediaPrefix,
} from "../helpers/mediaAgenciesHelper";

export const SummaryCard = React.memo<GenAISummary>(
  ({ summary, numberOfArticles, issueName, languages, date, sources }) => {
    const { preferredRegion } = useSelector(
      (state: RootState) => state.preference
    );

    const newsMediaAgency = React.useMemo(() => {
      return getActiveNewsMediaAgency(preferredRegion);
    }, [preferredRegion]);

    const PREFIX_URL = React.useMemo(() => {
      return getActiveNewsMediaPrefix(preferredRegion);
    }, [preferredRegion]);

    React.useEffect(() => {
      const element = document.querySelector("#summary-card-scorllable-area");
      if (element) {
        element.scrollTop = 0;
      }
    }, [issueName]);

    return (
      <StyledCard className="p-2 my-1">
        <Card.Body style={{ paddingBottom: 5, paddingTop: 0 }}>
          <StyledContent
            id="summary-card-scorllable-area"
            isrtl={false}
            className="custom-scroll"
          >
            {summary.map((point, index) => {
              return (
                <Card.Text
                  dangerouslySetInnerHTML={{ __html: htmlStringEncoder(point) }}
                  key={index}
                ></Card.Text>
              );
            })}
          </StyledContent>
        </Card.Body>
        <Card.Footer className="border-0 bg-transparent pb-0 pt-0 text-center">
          <div className="d-flex align-items-center justify-content-center pt-1">
            <div>
              <StyledFooterItem>
                {numberOfArticles} article{numberOfArticles > 1 ? "s" : ""}
              </StyledFooterItem>
            </div>
            <div>
              <SeparatorIcon height={10} width={10} />
            </div>
            <div>
              <StyledFooterItem>{issueName}</StyledFooterItem>
            </div>
            <div>
              <SeparatorIcon height={10} width={10} />
            </div>
            <div
              className="d-flex align-items-center"
              style={{ lineHeight: 1.75 }}
            >
              <StyledFooterItem
                className="d-flex align-items-center  position-relative news-media-agency"
                style={{ marginRight: languages.length > 1 ? 50 : 30 }}
              >
                {languages.map((language) => (
                  <div
                    className="d-flex align-items-center news-media-agency-logo"
                    key={language}
                  >
                    <LanguageIcon postLanguage={language} />
                  </div>
                ))}
              </StyledFooterItem>

              <div>
                <SeparatorIcon height={10} width={10} />
              </div>
              <div>
                <StyledFooterItem>{date}</StyledFooterItem>
              </div>
              <div>
                <SeparatorIcon height={10} width={10} />
              </div>
              <div className="d-flex align-items-center position-relative news-media-agency">
                {sources &&
                  sources
                    .slice(0, DEFAULT_VISIBLE_NEWS_AGENCIES)
                    .map((source, index) => {
                      return (
                        <>
                          {newsMediaAgency[source] && (
                            <StyledFooterItem
                              style={{
                                color: `${THEME_COLOUR_PRIMARY}`,
                              }}
                              className="d-flex align-items-center news-media-agency-logo"
                              id={`source-${index}`}
                              key={index}
                            >
                              <StyledFooterImage
                                src={`${PREFIX_URL}${newsMediaAgency[source].news_media_photo_png}`}
                                alt="Agency Logo"
                                height={30}
                                className="rounded-circle rounded-circle"
                              />
                            </StyledFooterItem>
                          )}
                        </>
                      );
                    })}
                {sources.length > DEFAULT_VISIBLE_NEWS_AGENCIES && (
                  <div
                    className="d-flex align-items-center justify-content-center news-media-agency-logo rounded-circle no-magnify"
                    style={{
                      backgroundColor: THEME_COLOUR_PRIMARY,
                      height: 30,
                      width: 30,
                      color: "#FFF",
                      opacity: 1,
                    }}
                  >
                    +{sources.length - DEFAULT_VISIBLE_NEWS_AGENCIES}
                  </div>
                )}
                {sources &&
                  sources
                    .slice(0, DEFAULT_VISIBLE_NEWS_AGENCIES)
                    .map((source, index) => {
                      return (
                        <BottomStyledToolTip
                          anchorSelect={`#source-${index}`}
                          content={source}
                          place="bottom"
                          key={index}
                        >
                          {source}
                        </BottomStyledToolTip>
                      );
                    })}
              </div>
            </div>
          </div>
        </Card.Footer>
      </StyledCard>
    );
  }
);

const StyledContent = styled.div<{ isrtl: boolean }>`
  border-bottom: 2px solid #989df2;
  padding-bottom: 16px;
  direction: ${(p) => (p.isrtl ? "rtl" : "ltr")};
  color: #64748b;
  max-height: 250px;
  overflow-y: auto;
  b {
    font-family: Arial, sans-serif;
  }
`;

const StyledCard = styled(Card)`
  border: 3px solid #989df2;
  border-radius: 20px;
`;

const StyledFooterItem = styled.div`
  color: #64748b;
  font-size: 14px;
`;

const SeparatorIcon = styled(Circle)`
  color: #cdd4e1;
  margin-right: 12px;
  margin-left: 12px;
  vertical-align: baseline;
`;

const StyledFooterImage = styled.img`
  border: 1px solid #ccc !important;
`;
