import React from "react";
import { Col, Row, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ArticeDetailsModal } from "../../components/ArticleDetailsModal";
import { IssueSubIssueMapTreeM3 } from "../../components/IssueSubIssueMapTreeM3";
import { PostCardM3 } from "../../components/PostCardM3";
import { SectionTitle } from "../../components/SectionTitle";
import { StyledTabs } from "../../components/StyledTab";
import { RightStyledToolTip } from "../../components/StyledToolTip";
import {
  TelegramIcon,
  TwitterIcon,
  ArticleIcon,
} from "../../components/TabIcons";
import { TelegramDetailModal } from "../../components/TelegramDetailModal";
import { TwitterDetailModal } from "../../components/TwitterDetailModal";
import { SUBSCRIBED_REGION, TABS } from "../../constants";
import { showEnglishContent } from "../../helpers";
import {
  getPostParams,
  getTelegramPostParams,
  getTwitterPostParams,
} from "../../helpers/paramHelper";
import { RootState } from "../../store";
import { Article, TelegramPost, Twitter } from "../../types/types";

export type ArticleSearchResult = {
  [key: string]: {
    results: Array<{
      sub_issue: string;
      records: Array<Article | Twitter | TelegramPost>;
    }>;
  };
};

export type SearchResultImprovised = {
  [key: string]: Array<Article | Twitter | TelegramPost>;
};

export interface ArticleSearchResultProps {
  articleResults: SearchResultImprovised;
  twitterResults: SearchResultImprovised;
  telegramResults: SearchResultImprovised;
  totalArticles: number;
  totalMessages: number;
  totalTweets: number;
  filterLanguage: string;
  searchKeywords: string[];
}

const SearchResultComponent = ({
  articleResults: articles,
  twitterResults: twitter,
  telegramResults: telegram,
  totalArticles = 0,
  totalMessages = 0,
  totalTweets = 0,
  filterLanguage,
  searchKeywords,
}: ArticleSearchResultProps) => {
  const { preferredLanguage, selectedIssue, preferredRegion } = useSelector(
    (state: RootState) => state.preference
  );
  const [modal, setModal] = React.useState<{
    type: "twitter" | "telegram" | "article";
    show: boolean;
    details?: {
      article: Article;
      twitter: Twitter;
      telegram: TelegramPost;
    };
  }>({
    type: "article",
    show: false,
  });
  const articleIssues = React.useMemo(() => Object.keys(articles), [articles]);
  const twitterIssues = React.useMemo(() => Object.keys(twitter), [twitter]);
  const telegramIssues = React.useMemo(() => Object.keys(telegram), [telegram]);
  const [activeTab, setActiveTab] = React.useState(() => {
    if (articleIssues.length) {
      return TABS.Article;
    } else if (twitterIssues.length) {
      return TABS.Twitter;
    } else if (telegramIssues.length) {
      return TABS.Telegram;
    } else {
      return TABS.Article;
    }
  });

  const onClickPost = React.useCallback(
    (
      type: "twitter" | "telegram" | "article",
      details: {
        article: Article;
        twitter: Twitter;
        telegram: TelegramPost;
      }
    ) => {
      setModal({
        show: true,
        details,
        type,
      });
    },
    []
  );

  const handleIssueClick = React.useCallback((dataId?: string) => {
    const domElement = dataId && document.getElementById(dataId);
    if (dataId && domElement) {
      window.scrollTo({
        top: domElement.offsetTop - 320,
        behavior: "smooth",
      });
    }
  }, []);

  const generateListing = React.useCallback(
    (
      issues: string[],
      resultSet: SearchResultImprovised,
      tab: "twitter" | "telegram" | "article"
    ) => {
      return issues.map((issue, index) => {
        return (
          <div key={issue} id={`${tab}-${issue}`}>
            <SectionTitle title={`${issue} (${resultSet[issue].length})`} />
            <div>
              {resultSet[issue].map((subIssue, index) => {
                const postParam =
                  tab === "article"
                    ? getPostParams(
                        subIssue as Article,
                        preferredLanguage,
                        filterLanguage
                      )
                    : tab === "twitter"
                    ? getTwitterPostParams(
                        subIssue as Twitter,
                        preferredLanguage,
                        filterLanguage
                      )
                    : getTelegramPostParams(
                        subIssue as TelegramPost,
                        preferredLanguage,
                        filterLanguage
                      );
                return (
                  <PostCardM3
                    key={`${subIssue.sub_issue}-${index}}`}
                    {...postParam}
                    onClickReadMore={() =>
                      onClickPost(tab, {
                        article: { ...(subIssue as Article) },
                        twitter: { ...(subIssue as Twitter) },
                        telegram: { ...(subIssue as TelegramPost) },
                      })
                    }
                    highlightKeywords={searchKeywords}
                  />
                );
              })}
            </div>
          </div>
        );
      });
    },
    [onClickPost, preferredLanguage, filterLanguage, searchKeywords]
  );

  const articleListing = React.useMemo(() => {
    return (
      <Row>
        <Col xs={12} sm={12} md={3} xl={3} lg={3}>
          <IssueSubIssueMapTreeM3
            handleItemClick={handleIssueClick}
            tab="article"
            data={articles}
          />
        </Col>
        <Col xs={12} sm={12} md={9} xl={9} lg={9}>
          {generateListing(articleIssues, articles, "article")}
        </Col>
      </Row>
    );
  }, [articleIssues, articles, generateListing, handleIssueClick]);

  const twitterListing = React.useMemo(() => {
    return (
      <Row>
        <Col xs={12} sm={12} md={3} xl={3} lg={3}>
          <IssueSubIssueMapTreeM3
            handleItemClick={handleIssueClick}
            tab="twitter"
            data={twitter}
          />
        </Col>
        <Col xs={12} sm={12} md={9} xl={9} lg={9}>
          {generateListing(twitterIssues, twitter, "twitter")}
        </Col>
      </Row>
    );
  }, [generateListing, twitter, twitterIssues, handleIssueClick]);

  const telegramListing = React.useMemo(() => {
    return (
      <Row>
        <Col xs={12} sm={12} md={3} xl={3} lg={3}>
          <IssueSubIssueMapTreeM3
            handleItemClick={handleIssueClick}
            tab="telegram"
            data={telegram}
          />
        </Col>
        <Col xs={12} sm={12} md={9} xl={9} lg={9}>
          {generateListing(telegramIssues, telegram, "telegram")}
        </Col>
      </Row>
    );
  }, [generateListing, telegram, telegramIssues, handleIssueClick]);

  const onHideModal = React.useCallback(() => {
    setModal({ ...modal, show: false });
  }, [modal]);

  const searchTabHeight = React.useMemo(() => {
    let height = 0;
    if (document.getElementsByTagName("nav").length) {
      height += document.getElementsByTagName("nav")[0].clientHeight;
    }
    if (document.getElementById("search-filters")) {
      height += document.getElementById("search-filters")?.clientHeight ?? 0;
    }

    return height;
  }, []);

  if (totalArticles === 0 && totalTweets === 0 && totalMessages === 0) {
    return (
      <div
        className="d-flex align-items-center justify-content-center pt-5"
        style={{ fontSize: "30px" }}
      >
        No records found with this search criteria
      </div>
    );
  }

  return (
    <div>
      <StyledTabsM3
        defaultActiveKey={activeTab}
        onSelect={(tab: any, event: any) => {
          setActiveTab(tab);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
        top={searchTabHeight}
      >
        <Tab
          eventKey={TABS.Article}
          title={
            activeTab === TABS.Article ? (
              <div id="article-tab-title">
                <ArticleIcon isActive={true} />
                <span
                  className="d-inline-block align-bottom"
                  style={{ marginLeft: 5 }}
                >
                  ({totalArticles})
                </span>
              </div>
            ) : (
              <div id="article-tab-title">
                <ArticleIcon isActive={false} />
                <span
                  className="d-inline-block align-bottom"
                  style={{ marginLeft: 5 }}
                >
                  ({totalArticles})
                </span>
              </div>
            )
          }
          color="#94a3b8"
        >
          {activeTab === TABS.Article ? articleListing : "Loading..."}
        </Tab>
        <Tab
          eventKey={TABS.Twitter}
          title={
            activeTab === TABS.Twitter ? (
              <div id="twitter-tab-title">
                <TwitterIcon isActive />
                <span
                  className="d-inline-block align-bottom"
                  style={{ marginLeft: 5 }}
                >
                  ({totalTweets})
                </span>
              </div>
            ) : (
              <div id="twitter-tab-title">
                <TwitterIcon isActive={false} />
                <span
                  className="d-inline-block align-bottom"
                  style={{ marginLeft: 5 }}
                >
                  ({totalTweets})
                </span>
              </div>
            )
          }
          color="#94a3b8"
        >
          {activeTab === TABS.Twitter ? twitterListing : "Loading..."}
        </Tab>
        {![
          SUBSCRIBED_REGION.TAIWAN_REGION,
          SUBSCRIBED_REGION.CHINA_REGION,
        ].includes(preferredRegion) && (
          <Tab
            eventKey={TABS.Telegram}
            title={
              activeTab === TABS.Telegram ? (
                <div className="active" id="telegram-tab-title">
                  <TelegramIcon isActive />
                  <span
                    className="d-inline-block align-bottom"
                    style={{ marginLeft: 5 }}
                  >
                    ({totalMessages})
                  </span>
                </div>
              ) : (
                <div id="telegram-tab-title">
                  <TelegramIcon isActive={false} />
                  <span
                    className="d-inline-block align-bottom"
                    style={{ marginLeft: 5 }}
                  >
                    ({totalMessages})
                  </span>
                </div>
              )
            }
          >
            {activeTab === TABS.Telegram ? telegramListing : "Loading..."}
          </Tab>
        )}
      </StyledTabsM3>
      {modal.show && modal.type === "article" && modal.details?.article ? (
        <ArticeDetailsModal
          title={
            showEnglishContent(
              modal.details.article.english_title,
              preferredLanguage
            )
              ? modal.details.article.english_title
              : modal.details.article.title
          }
          issue={modal.details.article.issue ?? ""}
          sub_issue={modal.details.article.sub_issue}
          source={modal.details.article.content_source}
          source_language={modal.details.article.source_language}
          date={modal.details.article.date}
          content={
            showEnglishContent(
              modal.details.article.english_related_segment,
              preferredLanguage
            )
              ? modal.details.article.english_related_segment
              : modal.details.article.related_segment
          }
          article_link={modal.details.article.website_url}
          onHide={onHideModal}
          highlightKeywords={searchKeywords}
          politicalAffiliation={modal.details.article.political_affiliation}
        />
      ) : null}
      {modal.show && modal.type === "twitter" && modal.details?.twitter ? (
        <TwitterDetailModal
          issue={selectedIssue ?? ""}
          {...modal.details.twitter}
          onHide={onHideModal}
          english_sub_issue={modal.details?.twitter.sub_issue}
          highlightKeywords={searchKeywords}
        />
      ) : null}
      {modal.show && modal.type === "telegram" && modal.details?.telegram ? (
        <TelegramDetailModal
          issue={selectedIssue ?? ""}
          {...modal.details.telegram}
          onHide={onHideModal}
          english_sub_issue={modal.details?.telegram.sub_issue}
          highlightKeywords={searchKeywords}
        />
      ) : null}
      <RightStyledToolTip
        place={"bottom"}
        anchorSelect={`#article-tab-title`}
        html={"Articles"}
        offset={activeTab === TABS.Article ? 20 : 10}
      ></RightStyledToolTip>
      <RightStyledToolTip
        place={"bottom"}
        anchorSelect={`#twitter-tab-title`}
        html={"Twitter"}
        offset={activeTab === TABS.Twitter ? 20 : 10}
      ></RightStyledToolTip>
      <RightStyledToolTip
        place={"bottom"}
        anchorSelect={`#telegram-tab-title`}
        html={"Telegram"}
        offset={activeTab === TABS.Telegram ? 20 : 10}
      ></RightStyledToolTip>
    </div>
  );
};

const StyledTabsM3 = styled(StyledTabs).withConfig({
  shouldForwardProp: (props) => props !== "top",
})<{ top: number }>(({ top }) => ({
  position: "sticky",
  zIndex: 8,
  backgroundColor: "#FFF",
  paddingTop: 10,
  top,
}));

export default React.memo(SearchResultComponent);
