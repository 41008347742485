import { showEnglishContent } from ".";
import { EMERGING_ISSUES_ARTICLE_CHAR_LIMIT } from "../constants";
import { FilterTypes } from "../types/filterTypes";
import { Article, TelegramPost, Twitter } from "../types/types";

export const getTagNumberString = (hashtags: string) => {
  const hashtagsArr = hashtags.split(",");
  return hashtagsArr.length > 10
    ? "10+ hashtags"
    : `${hashtagsArr.length} hashtags`;
};

export const getPostParams = (
  article: Article,
  preferredLanguage: "English" | "Original",
  selectedFilterLanguage: string
) => {
  return {
    handle: showEnglishContent(article.english_title, preferredLanguage)
      ? article.english_title
      : article.title,
    date: article.date,
    postCategory: article.english_sub_issue ?? article.sub_issue,
    postContent: showEnglishContent(
      article.english_related_segment,
      preferredLanguage
    )
      ? article.english_related_segment
      : article.related_segment,
    postLanguage: article.source_language,
    charLimit: EMERGING_ISSUES_ARTICLE_CHAR_LIMIT,
    source: article.content_source,
    postLink: article.website_url,
    selectedFilterLang: selectedFilterLanguage,
    politicalAffiliation: article.political_affiliation,
  };
};

export const getTwitterPostParams = (
  tweet: Twitter,
  preferredLanguage: "English" | "Original",
  selectedFilterLanguage: string
) => {
  return {
    handle: tweet.twitter_handle,
    date: tweet.date,
    postCategory: tweet.english_sub_issue ?? tweet.sub_issue,
    postContent: showEnglishContent(
      tweet.tweet_text_translated,
      preferredLanguage
    )
      ? tweet.tweet_text_translated
      : tweet.tweet_text,
    postLanguage: tweet.language,
    postLink: tweet.tweet_original_link,
    hashTags: tweet.hashtag
      ? getTagNumberString(tweet.hashtag)
      : "No Hashtag Provided",
    charLimit: EMERGING_ISSUES_ARTICLE_CHAR_LIMIT,
    selectedFilterLang: selectedFilterLanguage,
  };
};

export const getTelegramPostParams = (
  telegramPost: TelegramPost,
  preferredLanguage: "English" | "Original",
  selectedFilterLanguage: string
) => {
  return {
    handle: telegramPost.account_handle,
    date: telegramPost.date,
    postCategory: telegramPost.english_sub_issue ?? telegramPost.sub_issue,
    postContent: showEnglishContent(
      telegramPost.message_text_translated,
      preferredLanguage
    )
      ? telegramPost.message_text_translated
      : telegramPost.message_text,
    postLanguage: telegramPost.language,
    postLink: telegramPost.message_link,
    hashTags: telegramPost.hashtags
      ? getTagNumberString(telegramPost.hashtags)
      : "No Hashtag Provided",
    charLimit: EMERGING_ISSUES_ARTICLE_CHAR_LIMIT,
    selectedFilterLang: selectedFilterLanguage,
  };
};

export const splitSearchStringByANDOR = (text: string) => {
  let copySearchString = text;
  const ORKeywords: Array<string> = [];
  const ANDKeywords: Array<string> = [];
  /**
   * Case 1:
   * If search string is wrapped in quotes and AND and OR is not present in it then split the string and search as AND operation
   */
  if (
    copySearchString.indexOf(" OR ") === -1 &&
    copySearchString.indexOf(" AND ") === -1 &&
    copySearchString[0] !== '"' &&
    copySearchString[copySearchString.length - 1] !== '"'
  ) {
    return {
      OR: ORKeywords,
      AND: ANDKeywords.concat(copySearchString.split(" ")),
    };
  }

  /**
   * Start looking at the string from left to right
   * Check if OR appears first or AND
   * Store that OR/AND as last visited operation
   * push next keyword between that OR/AND and next OR/AND
   * until we reach end of string
   *  */

  /**
   * This case covers
   */
  let terminateLoop = false;
  let lastKeyword = "";
  while (!terminateLoop) {
    const indexOfFirstOr = copySearchString.indexOf(" OR ");
    const indexOfFirstAnd = copySearchString.indexOf(" AND ");
    if (indexOfFirstOr > -1 && indexOfFirstAnd > -1) {
      if (indexOfFirstOr < indexOfFirstAnd) {
        if (lastKeyword === "OR") {
          ORKeywords.push(copySearchString.substring(0, indexOfFirstOr));
          copySearchString = copySearchString.substring(
            indexOfFirstOr + " OR ".length
          );
        } else if (lastKeyword === "AND") {
          ANDKeywords.push(copySearchString.substring(0, indexOfFirstOr));
          copySearchString = copySearchString.substring(
            indexOfFirstOr + " OR ".length
          );
        } else {
          ORKeywords.push(copySearchString.substring(0, indexOfFirstOr));
          copySearchString = copySearchString.substring(
            indexOfFirstOr + " OR ".length
          );
        }
        lastKeyword = "OR";
      } else {
        if (lastKeyword === "OR") {
          ORKeywords.push(copySearchString.substring(0, indexOfFirstAnd));
          copySearchString = copySearchString.substring(
            indexOfFirstAnd + " AND ".length
          );
        } else if (lastKeyword === "AND") {
          ANDKeywords.push(copySearchString.substring(0, indexOfFirstAnd));
          copySearchString = copySearchString.substring(
            indexOfFirstAnd + " AND ".length
          );
        } else {
          ANDKeywords.push(copySearchString.substring(0, indexOfFirstAnd));
          copySearchString = copySearchString.substring(
            indexOfFirstAnd + " AND ".length
          );
        }
        lastKeyword = "AND";
      }
    } else if (indexOfFirstOr > -1) {
      if (lastKeyword === "OR") {
        ORKeywords.push(copySearchString.substring(0, indexOfFirstOr));
        copySearchString = copySearchString.substring(
          indexOfFirstOr + " OR ".length
        );
      } else if (lastKeyword === "AND") {
        ANDKeywords.push(copySearchString.substring(0, indexOfFirstOr));
        copySearchString = copySearchString.substring(
          indexOfFirstOr + " OR ".length
        );
      }
      lastKeyword = "OR";
    } else if (indexOfFirstAnd > -1) {
      if (lastKeyword === "OR") {
        ORKeywords.push(copySearchString.substring(0, indexOfFirstAnd));
        copySearchString = copySearchString.substring(
          indexOfFirstAnd + " AND ".length
        );
      } else if (lastKeyword === "AND") {
        ANDKeywords.push(copySearchString.substring(0, indexOfFirstAnd));
        copySearchString = copySearchString.substring(
          indexOfFirstAnd + " AND ".length
        );
      }
      lastKeyword = "AND";
    } else {
      if (lastKeyword === "OR") {
        ORKeywords.push(copySearchString);
      } else if (lastKeyword === "AND") {
        ANDKeywords.push(copySearchString);
      }
      terminateLoop = true;
    }
  }
  return {
    OR: ORKeywords,
    AND: ANDKeywords,
  };
};

export const generateArticleSearchConditionsNew = (
  columns: Array<string>,
  keywords: Array<string>,
  columnOperator: "AND" | "OR",
  conditionOperator: "AND" | "OR"
) => {
  const result: {
    columnOperator: "AND" | "OR";
    conditionOperator: "AND" | "OR";
    conditions: Array<{ columnName: string; value: string; type: string }>;
  } = {
    columnOperator,
    conditionOperator,
    conditions: [],
  };

  columns.forEach((column) => {
    keywords.forEach((keyword) => {
      result.conditions.push({
        columnName: column,
        value: keyword.replaceAll('"', ""),
        type: FilterTypes.contains,
      });
    });
  });

  return [result];
};

export const generateArticleSearchConditions = (
  columns: Array<string>,
  keywords: Array<string>,
  operator: "AND" | "OR"
) => {
  //const result: { [key: string]: MultipleConditionFilterType } = {};
  const result: any = {};
  /* columns.forEach((column) => {
    result[`${column}_${operator}`] = {
      isMultiColumnFilter: true,
      operator,
      type: FilterTypes.contains,
      conditions: keywords.map((keyword) => {
        return { columnName: column, value: keyword };
      }),
    };
  }); */

  columns.forEach((column) => {
    result[column] = {
      operator,
      conditions: keywords.map((keyword) => {
        return { value: keyword, type: FilterTypes.contains };
      }),
    };
  });

  return result;
};
