import React from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  DEFAULT_VISIBLE_NEWS_AGENCIES,
  NEWS_AGENCY_PREFIX_URL,
  SUBSCRIBED_REGION,
  TAIWANESE_NEWS_AGENCY_PREFIX_URL,
  THEME_COLOUR_PRIMARY,
} from "../constants";
import { getIssueNameInNative, isContentRtl } from "../helpers";
import { RootState } from "../store";
import { PoliticalAffiliationBars } from "./AffiliationBars";
import {
  StyledContent,
  StyledMeta,
  StyledSubTitle,
  StyledTitle,
} from "./ModalCss";
import { BottomStyledToolTip } from "./StyledToolTip";
import { v4 as uuid } from "uuid";
import { Circle } from "@styled-icons/material";
import { KeyboardDoubleArrowLeft } from "@styled-icons/material-outlined";
import { LanguageIcon } from "./LanguageIcon";
import { ExternalLinkModal } from "./ExternalLinkModal";
import { getActiveNewsMediaPrefix } from "../helpers/mediaAgenciesHelper";

interface Props {
  title: string;
  issue: string;
  sub_issue: string;
  source: string;
  source_language: string;
  date: string;
  content: string;
  article_link: string;
  onHide: () => void;
  politicalAffiliation: string;
  highlightKeywords?: string[];
}

export const ArticeDetailsModal = ({
  title,
  issue,
  sub_issue,
  source,
  source_language,
  date,
  content,
  onHide,
  article_link,
  highlightKeywords,
  politicalAffiliation,
}: Props) => {
  const newsSourceLinkRef = React.useRef<string | null>(null);

  const { preferredLanguage, issuesM3, preferredRegion, taiwanIssues } =
    useSelector((state: RootState) => state.preference);

  const newsMediaAgency = useSelector(
    (state: RootState) => state.newsMediaAgency
  );
  const [showConfirmationDialogue, setShowConfirmationDialogue] =
    React.useState(false);

  const filterdNewsMediaAgency = React.useMemo(() => {
    return newsMediaAgency[preferredRegion.toLowerCase() as "iran" | "taiwan"];
  }, [newsMediaAgency, preferredRegion]);

  const [showAllAgencies, setShowAllAgencies] = React.useState(false);

  const handlePostLinkClick = React.useCallback((link: string) => {
    setShowConfirmationDialogue(true);
    newsSourceLinkRef.current = link;
  }, []);

  const handleRedirectionConfirm = () => {
    if (newsSourceLinkRef.current) {
      window.open(newsSourceLinkRef.current, "_blank");
      newsSourceLinkRef.current = null;
    }
    setShowConfirmationDialogue(false);
  };

  const handleRedirectionCancel = () => {
    setShowConfirmationDialogue(false);
    newsSourceLinkRef.current = null;
  };

  const onClickTitle = React.useCallback(() => {
    window.open(article_link, "_blank");
  }, [article_link]);

  const replacer = (str: string, replace: string) => {
    const re = new RegExp(`(${replace})`, "gi");
    return str.replaceAll(
      re,
      "<span style='background-color:yellow; font-weight: bold'>$1</span>"
    );
  };

  const highlightedContent = React.useMemo(() => {
    let formattedContent = content;
    if (highlightKeywords && highlightKeywords.length) {
      highlightKeywords.forEach((keyword) => {
        formattedContent = replacer(formattedContent, keyword);
      });
    }
    formattedContent.replaceAll("\n", "<br />");
    return formattedContent;
  }, [content, highlightKeywords]);

  const highlitedTitle = React.useMemo(() => {
    let formattedContent = title;
    if (highlightKeywords && highlightKeywords.length) {
      highlightKeywords.forEach((keyword) => {
        formattedContent = replacer(formattedContent, keyword);
      });
    }
    formattedContent.replaceAll("\n", "<br />");
    return formattedContent;
  }, [highlightKeywords, title]);

  const originalIssueName = React.useMemo(() => {
    return getIssueNameInNative(preferredRegion);
  }, [preferredRegion]);

  const NewsMediaLink = React.useMemo(() => {
    if (
      article_link &&
      article_link.includes(",") &&
      article_link.split(",").length > 1
    ) {
      return article_link.split(",");
    }
    return [article_link];
  }, [article_link]);

  const NewsSource = React.useMemo(() => {
    if (!source.includes(",")) {
      return [source];
    }
    if (source && source.includes(",") && source.split(",").length > 1) {
      return source.split(",");
    }
  }, [source]);

  const NewsMediaAgencyPrefix = React.useMemo(() => {
    return getActiveNewsMediaPrefix(preferredRegion);
  }, [preferredRegion]);

  const identifier = uuid();

  return (
    <>
      <Modal
        onHide={onHide}
        keyboard
        scrollable
        show
        size={"lg"}
        centered
        contentClassName={showConfirmationDialogue ? "fade" : "show"}
      >
        <Modal.Header>
          <Modal.Title className="w-100">
            <StyledTitle>
              <div
                className="me-2"
                dangerouslySetInnerHTML={{ __html: highlitedTitle }}
                style={{
                  direction: isContentRtl(preferredLanguage, source_language)
                    ? "rtl"
                    : "ltr",
                }}
              />
              <button
                type="button"
                className="btn-close float-end"
                aria-label="Close"
                onClick={onHide}
              ></button>
            </StyledTitle>

            <div className="d-flex justify-content-between align-items-center pt-3">
              <div>
                <div className="mb-2">
                  <StyledSubTitle>
                    {issue} ({originalIssueName})
                  </StyledSubTitle>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    className="d-flex align-items-center"
                    style={{ fontSize: 12.5, lineHeight: 1.75 }}
                  >
                    <div>
                      <StyledFooterItem>
                        <LanguageIcon postLanguage={source_language} />
                        {/* <span className="ps-1">{postLanguage}</span> */}
                      </StyledFooterItem>
                    </div>
                    <div>
                      <SeparatorIcon height={10} width={10} />
                    </div>
                    <div>
                      <StyledFooterItem>{date}</StyledFooterItem>
                    </div>
                    {filterdNewsMediaAgency[source.trim()] ||
                    (NewsSource && NewsSource?.length > 1) ? (
                      <div>
                        <SeparatorIcon height={10} width={10} />
                      </div>
                    ) : null}

                    <div>
                      {NewsSource?.length && (
                        <div
                          className={`position-relative d-flex align-items-center ${
                            showAllAgencies ? "all-agency-visible" : ""
                          }`}
                        >
                          {NewsSource.slice(
                            0,
                            showAllAgencies
                              ? NewsSource.length
                              : DEFAULT_VISIBLE_NEWS_AGENCIES
                          ).map((newsAgency, index) => {
                            return filterdNewsMediaAgency[newsAgency.trim()] ? (
                              <div
                                className={`${
                                  !showAllAgencies
                                    ? "news-media-agency-logo"
                                    : "position-static"
                                }`}
                                key={index}
                                style={{ cursor: "pointer" }}
                              >
                                <StyledFooterImage
                                  src={`${NewsMediaAgencyPrefix}${
                                    filterdNewsMediaAgency[newsAgency.trim()]
                                      .news_media_photo_png
                                  }`}
                                  alt="Agency Logo"
                                  height={30}
                                  className="rounded-circle rounded-circle"
                                  id={`News-Agency-${index}-${identifier}`}
                                  onClick={() =>
                                    handlePostLinkClick(NewsMediaLink[index])
                                  }
                                />
                                <BottomStyledToolTip
                                  anchorSelect={`#News-Agency-${index}-${identifier}`}
                                  content={newsAgency}
                                  place="bottom"
                                >
                                  {source}
                                </BottomStyledToolTip>
                              </div>
                            ) : null;
                          })}
                          {!showAllAgencies &&
                            NewsSource.length >
                              DEFAULT_VISIBLE_NEWS_AGENCIES && (
                              <div
                                className={`d-flex align-items-center justify-content-center rounded-circle no-magnify ${
                                  !showAllAgencies
                                    ? "news-media-agency-logo"
                                    : "position-static"
                                }`}
                                style={{
                                  backgroundColor: THEME_COLOUR_PRIMARY,
                                  height: 30,
                                  width: 30,
                                  color: "#FFF",
                                  opacity: 1,
                                }}
                                onClick={() =>
                                  setShowAllAgencies(!showAllAgencies)
                                }
                              >
                                +
                                {NewsSource.length -
                                  DEFAULT_VISIBLE_NEWS_AGENCIES}
                              </div>
                            )}
                          {showAllAgencies && (
                            <div
                              className="d-flex align-items-center justify-content-center position-static rounded-circle "
                              style={{
                                backgroundColor: THEME_COLOUR_PRIMARY,
                                height: 30,
                                width: 30,
                                color: "#FFF",
                                opacity: 1,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                setShowAllAgencies(!showAllAgencies)
                              }
                            >
                              <KeyboardDoubleArrowLeft size={20} />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="p-2 border border-1 rounded"
                style={{ fontSize: 15 }}
              >
                <PoliticalAffiliationBars
                  politicalAffiliation={politicalAffiliation}
                  width={330}
                  showPercentage={!!(source.split(",").length > 1)}
                />
              </div>
            </div>
            <div>{/* --- More Sources --- */}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StyledContent
            dangerouslySetInnerHTML={{
              __html: highlightedContent,
            }}
            isrtl={isContentRtl(preferredLanguage, source_language)}
          ></StyledContent>
        </Modal.Body>
      </Modal>
      {showConfirmationDialogue && (
        <ExternalLinkModal
          onConfirm={handleRedirectionConfirm}
          onCancel={handleRedirectionCancel}
        />
      )}
    </>
  );
};

const StyledFooterItem = styled.div`
  color: #64748b;
`;

const SeparatorIcon = styled(Circle)`
  color: #cdd4e1;
  margin-right: 12px;
  margin-left: 12px;
`;

const StyledFooterImage = styled.img`
  border: 1px solid #ccc !important;
`;
