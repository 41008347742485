import { Circle, KeyboardDoubleArrowLeft } from "@styled-icons/material";
import React from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  NEWS_AGENCY_PREFIX_URL,
  SUBSCRIBED_REGION,
  TAIWANESE_NEWS_AGENCY_PREFIX_URL,
  DEFAULT_VISIBLE_NEWS_AGENCIES,
  THEME_COLOUR_PRIMARY,
  CHINESE_NEWS_AGENCY_PREFIX_URL,
} from "../constants";
import { isContentRtl, replacer } from "../helpers";
import { RootState } from "../store";
import { BottomStyledToolTip } from "./StyledToolTip";
import { v4 as uuid } from "uuid";
import { PoliticalAffiliationBars } from "./AffiliationBars";
import { LanguageIcon } from "./LanguageIcon";
import { ExternalLinkModal } from "./ExternalLinkModal";

interface Props {
  handle: string;
  postContent: string;
  postCategory: string;
  postLanguage: string;
  selectedFilterLang: string;
  date: string;
  postLink: string;
  hashTags?: string;
  charLimit?: number;
  showMore?: boolean;
  source?: string;
  onClickReadMore?: () => void;
  politicalAffiliation?: string;
  highlightKeywords?: string[];
}

export const PostCardM3 = React.memo<Props>(
  ({
    handle,
    postCategory,
    postContent,
    postLanguage,
    date,
    hashTags,
    postLink,
    charLimit,
    showMore,
    source,
    selectedFilterLang,
    politicalAffiliation,
    onClickReadMore,
    highlightKeywords,
  }) => {
    const newsSourceLinkRef = React.useRef<string | null>(null);
    const { preferredLanguage, preferredRegion } = useSelector(
      (state: RootState) => state.preference
    );
    const newsMediaAgencyState = useSelector(
      (state: RootState) => state.newsMediaAgency
    );

    const [showConfirmationDialogue, setShowConfirmationDialogue] =
      React.useState(false);

    const newsMediaAgency = React.useMemo(() => {
      return preferredRegion === SUBSCRIBED_REGION.IRAN_REGION
        ? newsMediaAgencyState.iran
        : preferredRegion === SUBSCRIBED_REGION.TAIWAN_REGION
        ? newsMediaAgencyState.taiwan
        : newsMediaAgencyState.china;
    }, [
      newsMediaAgencyState.china,
      newsMediaAgencyState.iran,
      newsMediaAgencyState.taiwan,
      preferredRegion,
    ]);

    const PREFIX_URL = React.useMemo(() => {
      return preferredRegion === SUBSCRIBED_REGION.IRAN_REGION
        ? NEWS_AGENCY_PREFIX_URL
        : preferredRegion === SUBSCRIBED_REGION.TAIWAN_REGION
        ? TAIWANESE_NEWS_AGENCY_PREFIX_URL
        : CHINESE_NEWS_AGENCY_PREFIX_URL;
    }, [preferredRegion]);

    const [showAllAgencies, setShowAllAgencies] = React.useState(false);

    const handlePostLinkClick = React.useCallback((link: string) => {
      setShowConfirmationDialogue(true);
      newsSourceLinkRef.current = link;
    }, []);

    const handleRedirectionConfirm = () => {
      if (newsSourceLinkRef.current) {
        window.open(newsSourceLinkRef.current, "_blank");
        newsSourceLinkRef.current = null;
      }
      setShowConfirmationDialogue(false);
    };

    const handleRedirectionCancel = () => {
      setShowConfirmationDialogue(false);
      newsSourceLinkRef.current = null;
    };

    const onClickReadMoreDetail = React.useCallback(() => {
      onClickReadMore && onClickReadMore();
    }, [onClickReadMore]);

    const isRtl = React.useMemo(() => {
      return isContentRtl(preferredLanguage, postLanguage);
    }, [postLanguage, preferredLanguage]);

    const highlightedContent = React.useMemo(() => {
      let formattedContent = postContent.replace(/\s\s+/g, " ");
      if (highlightKeywords && highlightKeywords.length) {
        highlightKeywords.forEach((keyword) => {
          formattedContent = replacer(
            formattedContent,
            keyword,
            "<span style='background-color:yellow; font-weight: bold'>$1</span>"
          );
        });
      }
      formattedContent.replaceAll("\n", "<br />");
      return formattedContent;
    }, [postContent, highlightKeywords]);

    const updatedCharLimit = React.useMemo(() => {
      let newCharLimit = charLimit ?? 0;
      const spanNumbers = (
        highlightedContent.slice(0, newCharLimit * 2).match(/<span/g) || []
      ).length;
      return newCharLimit + spanNumbers * 66;
    }, [charLimit, highlightedContent]);

    const highlightedTitle = React.useMemo(() => {
      let formattedContent = handle.replace(/\s\s+/g, " ");
      if (highlightKeywords && highlightKeywords.length) {
        highlightKeywords.forEach((keyword) => {
          formattedContent = replacer(
            formattedContent,
            keyword,
            "<span style='background-color:yellow; font-weight: bold'>$1</span>"
          );
        });
      }
      formattedContent.replaceAll("\n", "<br />");
      return formattedContent;
    }, [handle, highlightKeywords]);

    const newsSourceLink = React.useMemo(() => {
      if (postLink && !postLink.includes(",")) {
        return [postLink];
      }
      return postLink.split(",");
    }, [postLink]);

    const identifier = uuid();

    return (
      <StyledCard className="p-2 my-3">
        <StyledCardHeader className="bg-transparent border-0">
          <div
            className={`d-flex bg-transparent align-items-center ${
              !hashTags && isRtl
                ? "justify-content-end"
                : "justify-content-between"
            }`}
          >
            <div>
              {
                <PostHandler
                  title={handle}
                  isrtl={isRtl}
                  className="pe-2"
                  dangerouslySetInnerHTML={{
                    __html: highlightedTitle,
                  }}
                ></PostHandler>
              }
            </div>
            <div>
              {hashTags && <StyledHashTagCount>{hashTags}</StyledHashTagCount>}
            </div>
          </div>
        </StyledCardHeader>
        <Card.Body
          style={{ paddingBottom: 5, paddingTop: 0 }}
          onClick={onClickReadMoreDetail}
        >
          <StyledContent isrtl={isRtl}>
            {charLimit && postContent && postContent.length > charLimit ? (
              <Card.Text>
                <span
                  dangerouslySetInnerHTML={{
                    __html: highlightedContent.substring(0, updatedCharLimit),
                  }}
                ></span>
                <span>...</span>
                <img
                  title="Read More"
                  height={23}
                  src={process.env.PUBLIC_URL + "/Read_More.svg"}
                  alt="Read more"
                />
              </Card.Text>
            ) : (
              <Card.Text>
                <div
                  dangerouslySetInnerHTML={{ __html: highlightedContent }}
                ></div>
              </Card.Text>
            )}
          </StyledContent>
        </Card.Body>
        <Card.Footer className="border-0 bg-transparent pb-0 pt-0">
          <div className="d-flex align-items-center justify-content-between">
            <div
              className="d-flex align-items-center"
              style={{ fontSize: 12.5, lineHeight: 1.75 }}
            >
              <div>
                <StyledFooterItem>
                  <LanguageIcon postLanguage={postLanguage} />
                  {/* <span className="ps-1">{postLanguage}</span> */}
                </StyledFooterItem>
              </div>
              <div>
                <SeparatorIcon height={10} width={10} />
              </div>
              <div>
                <StyledFooterItem>{date}</StyledFooterItem>
              </div>
              {source && newsMediaAgency[source] ? (
                <>
                  <div>
                    <SeparatorIcon height={10} width={10} />
                  </div>
                  <div>
                    <StyledFooterItem
                      style={{
                        color: `${THEME_COLOUR_PRIMARY}`,
                        cursor: "pointer",
                      }}
                      onClick={() => handlePostLinkClick(newsSourceLink[0])}
                      className="d-flex align-items-center"
                      id={`source-${identifier}`}
                    >
                      <>
                        {newsMediaAgency[source] && (
                          <StyledFooterImage
                            src={`${PREFIX_URL}${newsMediaAgency[source].news_media_photo_png}`}
                            alt="Agency Logo"
                            height={30}
                            className="rounded-circle rounded-circle"
                          />
                        )}

                        {/* <span
                          className={`${newsMediaAgency[source] ? "ps-1" : ""}`}
                          style={{ paddingTop: 2 }}
                        >
                          {source}
                        </span> */}
                      </>
                    </StyledFooterItem>
                    <BottomStyledToolTip
                      anchorSelect={`#source-${identifier}`}
                      content={source}
                      place="bottom"
                    >
                      {source}
                    </BottomStyledToolTip>
                  </div>
                </>
              ) : null}
              {source && source.split(",").length > 1 && (
                <>
                  <div>
                    <SeparatorIcon height={10} width={10} />
                  </div>
                  <div className="d-flex align-items-center position-relative news-media-agency">
                    {source
                      .split(",")
                      .slice(
                        0,
                        showAllAgencies
                          ? source.split(",").length
                          : DEFAULT_VISIBLE_NEWS_AGENCIES
                      )
                      .map((newsSource, index) => {
                        return newsMediaAgency[newsSource.trim()] ? (
                          <StyledFooterItem
                            style={{
                              color: `${THEME_COLOUR_PRIMARY}`,
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handlePostLinkClick(newsSourceLink[index])
                            }
                            className={`d-flex align-items-center ${
                              !showAllAgencies ? "news-media-agency-logo" : ""
                            }`}
                            id={`News-Agency-${index}-${identifier}`}
                            key={index}
                          >
                            <>
                              {newsMediaAgency[newsSource.trim()] && (
                                <StyledFooterImage
                                  src={`${NEWS_AGENCY_PREFIX_URL}${
                                    newsMediaAgency[newsSource.trim()]
                                      .news_media_photo_png
                                  }`}
                                  alt="Agency Logo"
                                  height={30}
                                  className="rounded-circle rounded-circle"
                                />
                              )}

                              {/* <span
                          className={`${newsMediaAgency[source] ? "ps-1" : ""}`}
                          style={{ paddingTop: 2 }}
                        >
                          {source}
                        </span> */}
                            </>
                            <BottomStyledToolTip
                              anchorSelect={`#News-Agency-${index}-${identifier}`}
                              content={newsSource}
                              place="bottom"
                            >
                              {source}
                            </BottomStyledToolTip>
                          </StyledFooterItem>
                        ) : (
                          <></>
                        );
                      })}
                    {!showAllAgencies &&
                      source.split(",").length >
                        DEFAULT_VISIBLE_NEWS_AGENCIES && (
                        <div
                          className="d-flex align-items-center justify-content-center news-media-agency-logo rounded-circle no-magnify"
                          style={{
                            backgroundColor: THEME_COLOUR_PRIMARY,
                            height: 30,
                            width: 30,
                            color: "#FFF",
                            opacity: 1,
                          }}
                          onClick={() => setShowAllAgencies(!showAllAgencies)}
                        >
                          +
                          {source.split(",").length -
                            DEFAULT_VISIBLE_NEWS_AGENCIES}
                        </div>
                      )}
                    {showAllAgencies && (
                      <div
                        className="d-flex align-items-center justify-content-center position-static rounded-circle "
                        style={{
                          backgroundColor: THEME_COLOUR_PRIMARY,
                          height: 30,
                          width: 30,
                          color: "#FFF",
                          opacity: 1,
                          cursor: "pointer",
                        }}
                        onClick={() => setShowAllAgencies(!showAllAgencies)}
                      >
                        <KeyboardDoubleArrowLeft size={20} />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            {politicalAffiliation && (
              <div style={{ fontSize: 12 }}>
                <PoliticalAffiliationBars
                  politicalAffiliation={politicalAffiliation ?? ""}
                  width={300}
                  showPercentage={!!(source && source.split(",").length > 1)}
                />
              </div>
            )}
          </div>
        </Card.Footer>
        {showConfirmationDialogue && (
          <ExternalLinkModal
            onConfirm={handleRedirectionConfirm}
            onCancel={handleRedirectionCancel}
          />
        )}
      </StyledCard>
    );
  }
);

const StyledContent = styled.div<{ isrtl: boolean }>`
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  direction: ${(p) => (p.isrtl ? "rtl" : "ltr")};
  color: #64748b;
  cursor: pointer;
`;

const StyledCard = styled(Card)`
  border: 1px solid #ccc;
  border-radius: 20px;
`;

const PostHandler = styled.span<{ isrtl: boolean }>`
  font-size: 20px;
  line-height: 25px;
  direction: ${(p) => (p.isrtl ? "rtl" : "ltr")};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

const StyledHashTagCount = styled.div`
  background-color: #f1f4f9;
  padding: 4px 10px;
  border-radius: 10px;
  font-size: 13px;
`;

const StyledReadMore = styled.span`
  color: #2f6fed;
  text-decoration: underline;
`;

const StyledFooterItem = styled.div`
  color: #64748b;
`;

const SeparatorIcon = styled(Circle)`
  color: #cdd4e1;
  margin-right: 12px;
  margin-left: 12px;
`;

const StyledCardHeader = styled(Card.Header)`
  padding-top: 10px;
  padding-bottom: 10px;
`;

const StyledFooterImage = styled.img`
  border: 1px solid #ccc !important;
`;
