import { Translate } from "@styled-icons/material-outlined";
import { AccountCircle, ArrowOutward } from "@styled-icons/material";
import React from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Dropdown,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactSelect, {
  ActionMeta,
  components,
  SingleValue,
  ValueContainerProps,
} from "react-select";
import styled from "styled-components";
import { RootState } from "../store";
import {
  setPreferredLanguage,
  setPreferredRegion,
} from "../store/slice/issuePreference";
import { DropdownOption, REGIONS } from "../types/types";
import { Public } from "@styled-icons/material/Public";
import axiosInstance from "../api";
import { AxiosResponse } from "axios";
import { setUser } from "../store/slice/user";
import {
  PROFILE_PICTURE_IMG_PREFIX,
  SUBSCRIBED_REGION,
  THEME_COLOUR_PRIMARY,
} from "../constants";
import { resetAllFilters } from "../store/slice/filters";

const EXTERNAL_LINKS = [
  {
    label: "Contact Support",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSen86j4kW6qUQnf1sNlz6NYs0L1wFGrU5YQS4jL67vRs7grpA/viewform?pli=1",
  },
  {
    label: "Suggest a Source",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfKdquDGL4tpN8skCjihd-ivGXODitgJhyRd8x248sCe3P9Mw/viewform",
  },
  {
    label: "Suggest an Issue",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSdWPFsxHj14qmBIh2sbArTco3MyDfTj7uCVW4hyyk6UDnTILw/viewform",
  },
];

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const preferenceIssues = useSelector((state: RootState) => state.preference);
  const { profile } = useSelector((state: RootState) => state.user);
  const { preferredLanguage, preferredRegion } = preferenceIssues;

  const selectedLanguageOpt = React.useMemo(() => {
    if (preferredLanguage === "English") {
      return { label: "English Only", value: "English" };
    } else {
      return { label: "Original Language", value: "Original" };
    }
  }, [preferredLanguage]);

  const handlePreferredLangChange = React.useCallback(
    (
      newValue: SingleValue<DropdownOption>,
      meta: ActionMeta<DropdownOption>
    ) => {
      dispatch(
        setPreferredLanguage({
          preferredLanguage:
            (newValue?.value as "English" | "Original") ?? "English",
        })
      );
    },
    [dispatch]
  );

  const ValueContainer = React.useCallback(
    ({ children, ...props }: ValueContainerProps<DropdownOption>) => {
      return (
        <components.ValueContainer {...props}>
          <div>
            <div className="d-flex align-items-center">
              <Translate size={20} /> {children}
            </div>
          </div>
        </components.ValueContainer>
      );
    },
    []
  );

  const handleLogout = () => {
    axiosInstance
      .post("/user/logout")
      .then(
        (
          res: AxiosResponse<{
            error: boolean;
            message: string;
          }>
        ) => {
          if (res.status === 200) {
            localStorage.clear();
            sessionStorage.clear();
            dispatch(
              setUser({
                isLoggedIn: false,
                isSessionVaidationInProgress: false,
                profile: undefined,
              })
            );
          }
        }
      )
      .catch((error) => {
        //console.log(error);
      });
  };

  const handleRegionToggle = (region: REGIONS) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(
      setPreferredRegion({
        preferredRegion: region,
      })
    );
    dispatch(resetAllFilters());
    navigate(`/${region.toLowerCase()}`);
  };
  /* navigate("/"); */
  const goToProfilePage = () => {
    navigate("/profile");
  };

  const handleOutwordClick = (link: string) => {
    window.open(link, "_blank");
  };

  const countryFlagImg = React.useMemo(() => {
    switch (preferredRegion) {
      case SUBSCRIBED_REGION.TAIWAN_REGION:
        return process.env.PUBLIC_URL + "/Taiwan_Flag.svg";

      case SUBSCRIBED_REGION.CHINA_REGION:
        return process.env.PUBLIC_URL + "/China_Flag.svg";

      default:
        return process.env.PUBLIC_URL + "/Iran_Flag.svg";
    }
  }, [preferredRegion]);

  return (
    <Navbar
      className="border-bottom bg-light"
      style={{ zIndex: 99 }}
      sticky="top"
    >
      <Navbar.Brand href="#" className="ps-2">
        <Image height={44} src={require("../assets/retailer_stite_logo.png")} />
      </Navbar.Brand>
      <Container fluid>
        <Nav className="">
          <li className="ps-2 pe-3">
            <StyledLink
              active={["/"]
                .concat(
                  Object.values(SUBSCRIBED_REGION).map((region) => {
                    return `/${region.toLocaleLowerCase()}`;
                  })
                )
                .includes(location.pathname)}
              to={`/${preferredRegion.toLocaleLowerCase()}`}
            >
              Insights
            </StyledLink>
          </li>
          {profile?.role === "Admin" ? (
            <li className="ps-2 pe-3">
              <StyledLink
                active={
                  location.pathname === "/insights-v2" ||
                  location.pathname === "/taiwan-insights-v2"
                }
                to={
                  preferredRegion === SUBSCRIBED_REGION.TAIWAN_REGION
                    ? "/taiwan-insights-v1"
                    : "/insights-v1"
                }
              >
                Insights-v1
              </StyledLink>
            </li>
          ) : null}

          {/* <li className="ps-2 pe-3">
            <StyledLink
              active={location.pathname === "/opinion-ai"}
              to="/opinion-ai"
            >
              Opinion AI
            </StyledLink>
          </li> */}

          <li className="ps-2 pe-3">
            <StyledLink
              active={["/issue-watch"]
                .concat(
                  Object.values(SUBSCRIBED_REGION).map((region) => {
                    return `/${region.toLocaleLowerCase()}/issue-watch`;
                  })
                )
                .includes(location.pathname)}
              to={`/${preferredRegion.toLowerCase()}/issue-watch`}
            >
              Issue watch
            </StyledLink>
          </li>

          {/* <li className="ps-2 pe-3">
            <StyledLink
              active={location.pathname === "/issue-watch-2"}
              to="/issue-watch-2"
            >
              Issue watch-2
            </StyledLink>
          </li> */}

          <li className="ps-2 pe-3">
            <StyledLink
              active={["/discover"]
                .concat(
                  Object.values(SUBSCRIBED_REGION).map((region) => {
                    return `/${region.toLocaleLowerCase()}/discover`;
                  })
                )
                .includes(location.pathname)}
              to={`/${preferredRegion.toLowerCase()}/discover`}
            >
              Discover
            </StyledLink>
          </li>

          {profile?.role === "Admin" ? (
            <li className="ps-2 pe-3">
              <StyledLink
                active={location.pathname === "/invitee-list"}
                to="/invitee-list"
              >
                Invitees-list
              </StyledLink>
            </li>
          ) : null}

          {/* <li className="ps-2 pe-2">
            <StyledLink
              active={location.pathname === "/insights-m3"}
              to="/insights-m3"
            >
              Insights M3
            </StyledLink>
          </li>

          <li className="ps-2 pe-2">
            <StyledLink
              active={location.pathname === "/emerging-issue-m3"}
              to="/emerging-issue-m3"
            >
              Emerging Issues M3
            </StyledLink>
          </li>

          <li className="ps-2 pe-2">
            <StyledLink
              active={location.pathname === "/search-m3"}
              to="/search-m3"
            >
              Search M3
            </StyledLink>
          </li> */}
        </Nav>
        <ul className="list-unstyled ms-auto list-inline mb-0 ">
          <li className="list-inline-item align-middle">
            <div style={{ width: "220px" }}>
              <ReactSelect
                value={selectedLanguageOpt}
                isSearchable={false}
                onChange={handlePreferredLangChange}
                options={[
                  { label: "English only", value: "English" },
                  {
                    label: "Original Language",
                    value: "Original",
                  },
                ]}
                components={{ ValueContainer }}
                isMulti={false}
                styles={{
                  singleValue: (baseStyles) => {
                    return {
                      ...baseStyles,
                      fontSize: "14px",
                      paddingLeft: "8px",
                    };
                  },
                  control: (baseStyles, state) => {
                    return {
                      ...baseStyles,
                      borderRadius: "24px",
                      boxShadow: state.isFocused
                        ? `0 0 0 1px ${THEME_COLOUR_PRIMARY}`
                        : baseStyles.boxShadow,
                      borderColor: state.isFocused
                        ? `${THEME_COLOUR_PRIMARY}`
                        : baseStyles.borderColor,
                      ":hover": {
                        borderColor: state.isFocused
                          ? `${THEME_COLOUR_PRIMARY}`
                          : baseStyles[":hover"]?.borderColor,
                      },
                    };
                  },
                  option: (baseStyles, { isSelected }) => {
                    return {
                      ...baseStyles,
                      cursor: "pointer",
                      backgroundColor: isSelected ? "#F0F5FF" : "",
                      color: isSelected ? "#4155fd" : "",
                    };
                  },
                }}
              />
            </div>
          </li>
          {/* <li className="list-inline-item align-middle">
            <Button variant="secondary">
              {profile ? (
                <>
                  {profile.name.charAt(0).toUpperCase() + profile.name.slice(1)}
                  &nbsp;{profile.lastName.charAt(0).toUpperCase()}
                </>
              ) : (
                ""
              )}
            </Button>{" "}
          </li> */}
          <li className="list-inline-item align-middle align-item-center">
            <StyledButtonWrapper>
              <DropdownButton
                as={StyledButtonGroup}
                id={`dropdown-button-drop-${"start"}`}
                drop={"down-centered"}
                variant="primary"
                className="rounded-circle header-country-select align-items-center"
                title={
                  <>
                    {profile?.avatar ? (
                      <ProfileIcon
                        src={PROFILE_PICTURE_IMG_PREFIX + profile.avatar}
                        alt="avatar"
                        className="rounded-circle pe-1"
                      />
                    ) : (
                      <AccountCircle size={27} />
                    )}
                    {profile
                      ? profile.name.charAt(0).toUpperCase() +
                        profile.name.slice(1) +
                        " " +
                        profile.lastName.charAt(0).toUpperCase()
                      : ""}
                  </>
                }
                style={{ backgroundColor: "#000" }}
              >
                <Dropdown.Item onClick={goToProfilePage}>Profile</Dropdown.Item>
                {EXTERNAL_LINKS.map((link) => {
                  return (
                    <Dropdown.Item
                      onClick={() => handleOutwordClick(link.link)}
                      key={link.label}
                    >
                      {link.label} <ArrowOutward size={20} />{" "}
                    </Dropdown.Item>
                  );
                })}
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                {/* <Dropdown.Item href="#">China (Taiwan) </Dropdown.Item> */}
              </DropdownButton>
            </StyledButtonWrapper>
          </li>
          <li className="list-inline-item align-middle">
            <StyledButtonWrapper>
              <DropdownButton
                as={StyledButtonGroup}
                id={`dropdown-button-drop-${"start"}`}
                drop={"down-centered"}
                variant="primary"
                className="rounded-circle header-country-select"
                style={{ borderRadius: "100% !important" }}
                title={
                  <img
                    src={countryFlagImg}
                    alt={preferredRegion}
                    style={{ height: 25, width: 25 }}
                    className="rounded-circle"
                  />
                }
              >
                <Dropdown.Item
                  onClick={() =>
                    handleRegionToggle(SUBSCRIBED_REGION.IRAN_REGION as REGIONS)
                  }
                  style={{
                    backgroundColor:
                      preferredRegion === SUBSCRIBED_REGION.IRAN_REGION
                        ? "#2f6fed14"
                        : "transparent",
                    color:
                      preferredRegion === SUBSCRIBED_REGION.IRAN_REGION
                        ? THEME_COLOUR_PRIMARY
                        : "inherit",
                  }}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/Iran_Flag.svg"}
                    alt="Iran Flag"
                    height={20}
                    width={20}
                    className="rounded rounded-circle"
                  />
                  <span className="ps-2">Iran</span>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleRegionToggle(
                      SUBSCRIBED_REGION.TAIWAN_REGION as REGIONS
                    )
                  }
                  style={{
                    backgroundColor:
                      preferredRegion === SUBSCRIBED_REGION.TAIWAN_REGION
                        ? "#2f6fed14"
                        : "transparent",
                    color:
                      preferredRegion === SUBSCRIBED_REGION.TAIWAN_REGION
                        ? THEME_COLOUR_PRIMARY
                        : "inherit",
                  }}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/Taiwan_Flag.svg"}
                    alt="Taiwan Flag"
                    height={20}
                    width={20}
                    className="rounded rounded-circle"
                  />
                  <span className="ps-2">Taiwan</span>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleRegionToggle(
                      SUBSCRIBED_REGION.CHINA_REGION as REGIONS
                    )
                  }
                  style={{
                    backgroundColor:
                      preferredRegion === SUBSCRIBED_REGION.CHINA_REGION
                        ? "#2f6fed14"
                        : "transparent",
                    color:
                      preferredRegion === SUBSCRIBED_REGION.CHINA_REGION
                        ? THEME_COLOUR_PRIMARY
                        : "inherit",
                  }}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/China_Flag.svg"}
                    alt="China"
                    height={20}
                    width={20}
                    className="rounded rounded-circle"
                  />
                  <span className="ps-2">China</span>
                </Dropdown.Item>
              </DropdownButton>
            </StyledButtonWrapper>
          </li>
          <li className="list-inline-item align-middle">
            <img
              title="Powered By: PersuMedia"
              src={process.env.PUBLIC_URL + "/PersuMedia.svg"}
              alt="Persumedia Logo"
              className="mg-fluid"
              height={41}
            />
          </li>
          {/* <li className="list-inline-item align-middle">
            <SettingsPower
              title="Logout"
              style={{ cursor: "pointer" }}
              onClick={handleLogout}
              color="Black"
              size={30}
            />
          </li> */}
        </ul>
      </Container>
    </Navbar>
  );
};

const StyledLinkEscaped = styled(Link).withConfig({
  shouldForwardProp: (prop) => prop !== "active",
})<{ active: boolean }>(({ active }) => ({
  color: active ? `${THEME_COLOUR_PRIMARY}` : "#94a3b8",
  textDecoration: "none",
  position: "relative",
}));

const StyledLink = styled(StyledLinkEscaped)<{ active: boolean }>`
  &:before {
    border-bottom: 3px solid ${THEME_COLOUR_PRIMARY};
    position: absolute;
    bottom: 0;
    content: "";
    top: 44px;
    width: ${(p) => (p.active ? "100%" : "0px")};
    border-radius: 15px;
  }
`;

const StyledButtonWrapper = styled.div`
  & .dropdown-menu.show {
    right: 0;
    left: auto;
  }
`;

const StyledButtonGroup = styled(ButtonGroup)`
  button {
    background-color: #e2e8f0 !important;
    border-color: #e2e8f0 !important;
    color: #1e2a3b !important;

    border-radius: 24px;
    &:hover,
    &:active,
    &:focus {
      background-color: #e2e8f0 !important;
      border-color: #e2e8f0 !important;
    }
    &:after {
      display: none;
    }
  }
`;

const ProfileIcon = styled.img`
  clip-path: circle();
  height: 27px;
`;
