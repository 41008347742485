import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { NewsMediaAgency } from "../../types/types";

const INITIAL_STATE: Record<
  "iran" | "taiwan" | "china",
  Record<string, NewsMediaAgency>
> = {
  iran: {},
  taiwan: {},
  china: {},
};

export const NewsMediaAgencySlice = createSlice({
  name: "news_media_agency",
  initialState: INITIAL_STATE,
  reducers: {
    setAgencies: (
      state,
      payload: PayloadAction<{
        iran: Array<NewsMediaAgency>;
        taiwan: Array<NewsMediaAgency>;
        china: Array<NewsMediaAgency>;
      }>
    ) => {
      payload.payload.iran.forEach((agency) => {
        state.iran[agency.news_media_name.trim()] = agency;
      });

      payload.payload.taiwan.forEach((agency) => {
        state.taiwan[agency.news_media_name.trim()] = agency;
      });

      payload.payload.china.forEach((agency) => {
        state.china[agency.news_media_name.trim()] = agency;
      });

      return state;
    },
    getAgencies: (state) => {
      return state;
    },
    resetAgency: (state) => {
      state = {
        iran: {},
        taiwan: {},
        china: {},
      };
      return state;
    },
  },
});

export const { reducer: NewsMediaAgencyReducer } = NewsMediaAgencySlice;

export const { setAgencies, resetAgency } = NewsMediaAgencySlice.actions;
