import styled from "styled-components";
import DatePicker from "react-datepicker";

export const StyledDatePicker = styled(DatePicker)`
  min-height: 38px;
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
  outline: 0;
  border-width: 1px;
  border-style: solid;
`;
