import { Add } from "@styled-icons/material-outlined";
import { AxiosResponse } from "axios";
import React from "react";
import { Button, Modal, Tab, Row, Col } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import axiosInstance from "../../api";
import { Loader } from "../../components/Loader";
import { SITE_CONSTANTS, THEME_COLOUR_PRIMARY } from "../../constants";
import { FilterOperations, FilterTypes } from "../../types/filterTypes";
import { CreateInvitee } from "./createInvitee";
import { InviteeTable } from "./InviteeTable";
import { RegisteredQueue } from "./registeredQueue";
import { Invitee, RegisteredMember } from "./types";
import { StyledTabs } from "../../components/StyledTab";
import { UserListRecord } from "../../types/types";
import { UsersList } from "./usersList";

export const InviteeScreen = () => {
  const [activeTab, setActiveTab] = React.useState<"Invitee" | "Register">(
    "Invitee"
  );
  const [isRequestInProgress, setIsRequestInProgress] = React.useState(false);
  const [showAddInvitee, setShowAddInvitee] = React.useState(false);
  const [inviteeList, setInviteeList] = React.useState<Array<Invitee>>([]);
  const [registeredMembers, setRegisteredMembers] = React.useState<
    Array<RegisteredMember>
  >([]);
  const [activeUsers, setActiveUsers] = React.useState<Array<UserListRecord>>(
    []
  );
  const activeUsersRef = React.useRef<Array<UserListRecord>>([]);

  const getInvitees = (params = {}) => {
    axiosInstance
      .post("/invitees/get-all", { params })
      .then(
        (
          res: AxiosResponse<{
            data: {
              invitees: Array<Invitee>;
            };
          }>
        ) => {
          setInviteeList(res.data.data.invitees);
        }
      )
      .catch((err) => {
        console.log(err);
      });
  };

  const getRegistrationQueue = (params = {}) => {
    axiosInstance
      .post("/register/get-all", { params })
      .then(
        (
          res: AxiosResponse<{
            data: {
              registeredMembers: Array<RegisteredMember>;
            };
          }>
        ) => {
          setRegisteredMembers(res.data.data.registeredMembers);
        }
      )
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllUsers = () => {
    axiosInstance
      .post("user/get-all-users")
      .then(
        (
          res: AxiosResponse<{
            data: UserListRecord[];
          }>
        ) => {
          setActiveUsers(res.data.data);
          activeUsersRef.current = res.data.data;
        }
      )
      .catch((err) => {
        console.log(err);
      });
  };

  const createInvitee = React.useCallback(
    (params: {
      name: string;
      lastName: string;
      email: string;
      organisation: string;
    }) => {
      setIsRequestInProgress(true);
      axiosInstance
        .post("/invitees/add", {
          params: {
            name: params.name,
            lastName: params.lastName,
            email: params.email,
            organisation: params.organisation,
          },
        })
        .then(
          (
            res: AxiosResponse<{
              data: {
                isInviteSent: boolean;
                isRecordCreated: boolean;
              };
            }>
          ) => {
            setShowAddInvitee(false);
            toast.success(
              `${
                res.data.data.isRecordCreated
                  ? "User has been successfully added to invited members list."
                  : ""
              }${
                res.data.data.isInviteSent
                  ? "An Email is sent to user with password creation link."
                  : ""
              }`
            );
            getInvitees();
          }
        )
        .catch((error) => {})
        .finally(() => {
          setIsRequestInProgress(false);
        });
    },
    []
  );

  const searchInvitee = React.useCallback((keyword: string) => {
    const inviteesFilters = [
      { columnName: "name", value: keyword },
      {
        columnName: "lastName",
        value: keyword,
      },
      {
        columnName: "email",
        value: keyword,
      },
      {
        columnName: "organisation",
        value: keyword,
      },
    ];
    getInvitees(
      !keyword
        ? {}
        : {
            filters: {
              name: {
                isMultiColumnFilter: true,
                operator: FilterOperations.OR,
                type: FilterTypes.contains,
                conditions: inviteesFilters,
              },
            },
          }
    );
  }, []);

  const searchRegisteredMember = React.useCallback((keyword: string) => {
    const registrationFilters = [
      { columnName: "name", value: keyword },
      {
        columnName: "lastName",
        value: keyword,
      },
      {
        columnName: "email",
        value: keyword,
      },
      {
        columnName: "companyName",
        value: keyword,
      },
    ];

    getRegistrationQueue(
      !keyword
        ? {}
        : {
            filters: {
              name: {
                isMultiColumnFilter: true,
                operator: FilterOperations.OR,
                type: FilterTypes.contains,
                conditions: registrationFilters,
              },
            },
          }
    );
  }, []);

  const resendInvitationLink = (uuid: string) => {
    axiosInstance
      .post("/invitees/resend-invitation-link", {
        params: {
          uuid,
        },
      })
      .then(
        (
          res: AxiosResponse<{
            message: string;
            error: boolean;
          }>
        ) => {
          toast.success(res.data.message);
          getInvitees();
        }
      )
      .catch((error) => {
        console.log(error);
      });
  };

  const onMoveRegisterMemberToInvitee = (uuid: string) => {
    setIsRequestInProgress(true);
    axiosInstance
      .post("/register/move-to-invitee", {
        params: {
          uuid,
        },
      })
      .then(
        (
          res: AxiosResponse<{
            data: {
              isInviteSent: boolean;
              isRecordCreated: boolean;
            };
          }>
        ) => {
          if (res.status === 200) {
            let message = "";
            if (res.data.data.isRecordCreated) {
              message += "Member successfully moved to invitee list.";
            }
            if (res.data.data.isInviteSent) {
              message += "Create password email has been sent to member.";
            }
            if (!res.data.data.isInviteSent) {
              message +=
                "But we could not send an Invite to member at the moment, Please send member an invite using resend button";
            }
            toast.success(message, {
              position: "top-right",
              autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
              pauseOnHover: true,
              toastId: "success-toast",
            });
            getInvitees();
            getRegistrationQueue();
          }
        }
      )
      .catch((err) => {
        if (err.response?.status === 500) {
          toast.error(
            err.response.data.message ?? "Sorry!! Something went wrong"
          );
          return;
        }
      })
      .finally(() => {
        setIsRequestInProgress(false);
      });
  };

  const onClickDeleteInvitee = React.useCallback((uuid: string) => {
    setIsRequestInProgress(true);
    axiosInstance
      .post("/invitees/delete-invitee", {
        params: {
          uuid,
        },
      })
      .then(
        (
          res: AxiosResponse<{
            message: string;
          }>
        ) => {
          if (res.status === 200) {
            toast.success("Record deleted successfully.", {
              position: "top-right",
              autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
              pauseOnHover: true,
              toastId: "success-toast",
            });
            getInvitees();
          }
        }
      )
      .catch((err) => {
        toast.error("Something went wrong!!", {
          position: "top-right",
          autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
          pauseOnHover: true,
          toastId: "success-toast",
        });
      })
      .finally(() => {
        setIsRequestInProgress(false);
      });
  }, []);

  const onClickDeleteRegisteredMember = React.useCallback((uuid: string) => {
    setIsRequestInProgress(true);
    axiosInstance
      .post("/register/delete-member", {
        params: {
          uuid,
        },
      })
      .then(
        (
          res: AxiosResponse<{
            message: string;
          }>
        ) => {
          if (res.status === 200) {
            toast.success("Record deleted successfully.", {
              position: "top-right",
              autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
              pauseOnHover: true,
              toastId: "success-toast",
            });
            getRegistrationQueue();
          }
        }
      )
      .catch((err) => {
        toast.error("Something went wrong!!", {
          position: "top-right",
          autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
          pauseOnHover: true,
          toastId: "success-toast",
        });
      })
      .finally(() => {
        setIsRequestInProgress(false);
      });
  }, []);

  const onChangeUserActivationStatus = (
    email: string,
    activationStatus: boolean
  ) => {
    axiosInstance
      .post("/user/update-user-access", {
        params: {
          email,
          isUserActive: activationStatus,
        },
      })
      .then(
        (
          res: AxiosResponse<{
            error: boolean;
            message: string;
          }>
        ) => {
          if (res.status === 200) {
            toast.success("User Status updated successfully", {
              position: "top-right",
              autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
              pauseOnHover: true,
              toastId: "success-toast",
            });
          }
          getAllUsers();
        }
      )
      .catch((err) => {
        toast.error("Something went wrong!!", {
          position: "top-right",
          autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
          pauseOnHover: true,
          toastId: "error-toast",
        });
      });
  };

  React.useEffect(() => {
    getAllUsers();
  }, []);

  const onSearchUser = (searchTerm: string) => {
    console.log(searchTerm, activeUsersRef.current);
    if (searchTerm && activeUsersRef.current) {
      const newUserRecord = activeUsersRef.current.filter((userRecord) => {
        return (
          userRecord.email.includes(searchTerm.toLowerCase()) ||
          userRecord.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          userRecord.lastName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          userRecord.organisation
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase())
        );
      });

      setActiveUsers(newUserRecord);
    } else {
      setActiveUsers(activeUsersRef.current);
    }
  };

  return (
    <div className="mt-4 mb-2">
      <div className="d-flex justify-content-end align-item-center">
        <Button
          style={{
            backgroundColor: `${THEME_COLOUR_PRIMARY}`,
            borderColor: `${THEME_COLOUR_PRIMARY}`,
          }}
          onClick={() => setShowAddInvitee(true)}
        >
          <span className="d-flex justify-content-center">
            <Add size={25} /> Create Invitee
          </span>
        </Button>
      </div>

      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <StyledTabs
            defaultActiveKey={activeTab}
            onSelect={(tab: any, event: any) => {
              setActiveTab(tab);
            }}
          >
            <Tab
              eventKey={"Invitee"}
              title={`Invited Members`}
              color="#94a3b8"
              style={{ marginBottom: "8px" }}
            >
              <InviteeTable
                inviteeList={inviteeList}
                searchInvitee={searchInvitee}
                resendInvitationLink={resendInvitationLink}
                isRequestInProgress={isRequestInProgress}
                onConfirmDelete={onClickDeleteInvitee}
              />
            </Tab>
            <Tab
              eventKey={"Register"}
              title={`Registration Queue`}
              color="#94a3b8"
              style={{ marginBottom: "8px" }}
            >
              <RegisteredQueue
                membersList={registeredMembers}
                searchMember={searchRegisteredMember}
                moveToInvitee={onMoveRegisterMemberToInvitee}
                isRequestInProgress={isRequestInProgress}
                onConfirmDelete={onClickDeleteRegisteredMember}
              />
            </Tab>
            <Tab
              eventKey={"ActiveUsers"}
              title="Active Users"
              color="#94a3b8"
              style={{ marginBottom: "8px" }}
            >
              <UsersList
                data={activeUsers}
                onDeactivateUser={onChangeUserActivationStatus}
                searchUser={onSearchUser}
              />
            </Tab>
          </StyledTabs>
        </Col>
      </Row>

      <Modal
        show={showAddInvitee}
        centered
        onHide={() => setShowAddInvitee(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create new invite</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateInvitee
            createInvitee={createInvitee}
            isCreateInviteeInProgress={isRequestInProgress}
          />
        </Modal.Body>
      </Modal>
      <ToastContainer limit={1} />
    </div>
  );
};
