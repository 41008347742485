import React from "react";
import styled, { CSSProperties } from "styled-components";

interface Props {
  title: string;
  style?: CSSProperties;
  className?: string;
}

export const PageHeading = ({ title, ...rest }: Props) => {
  return <StyledHeading {...rest}>{title}</StyledHeading>;
};

const StyledHeading = styled.h2`
  color: #1e2a3b;
  line-height: 58px;
  font-weight: 700;
`;
