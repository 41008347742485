import {
  CHINESE_NEWS_AGENCY_PREFIX_URL,
  NEWS_AGENCY_PREFIX_URL,
  SUBSCRIBED_REGION,
  TAIWANESE_NEWS_AGENCY_PREFIX_URL,
} from "../constants";
import { store } from "../store";
import { REGIONS } from "../types/types";

export const getActiveNewsMediaAgency = (preferredRegion: REGIONS) => {
  const { newsMediaAgency } = store.getState();

  switch (preferredRegion) {
    case SUBSCRIBED_REGION.TAIWAN_REGION:
      return newsMediaAgency.taiwan;

    case SUBSCRIBED_REGION.CHINA_REGION:
      return newsMediaAgency.china;

    default:
      return newsMediaAgency.iran;
  }
};

export const getActiveNewsMediaPrefix = (preferredRegion: REGIONS) => {
  switch (preferredRegion) {
    case SUBSCRIBED_REGION.TAIWAN_REGION:
      return TAIWANESE_NEWS_AGENCY_PREFIX_URL;

    case SUBSCRIBED_REGION.CHINA_REGION:
      return CHINESE_NEWS_AGENCY_PREFIX_URL;

    default:
      return NEWS_AGENCY_PREFIX_URL;
  }
};
