import React from "react";
import Tab from "react-bootstrap/Tab";
import { ArticleList } from "./ArticleList";
import { TwitterList } from "./TwitterList";
import { TelegramList } from "./TelegramList";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { SUBSCRIBED_REGION, TABS } from "../../constants";
import { StyledTabs } from "../../components/StyledTab";
import { Col, Row } from "react-bootstrap";
import { MoversAndShapers } from "./MoversAndShapers";
import styled from "styled-components";
import {
  ArticleIcon,
  TelegramIcon,
  TwitterIcon,
} from "../../components/TabIcons";
import { RightStyledToolTip } from "../../components/StyledToolTip";

export const TabsContainer = ({ activeIssue }: { activeIssue?: string }) => {
  const preferenceIssues = useSelector((state: RootState) => state.preference);
  const { selectedTab, preferredRegion } = preferenceIssues;

  const [activeTab, setActiveTab] = React.useState(selectedTab ?? TABS.Article);
  const [articleCounter, setArticleCounter] = React.useState(0);
  const [twitterCounter, setTwitterCounter] = React.useState(0);
  const [telegramCounter, setTelegramCounter] = React.useState(0);

  const updateArticleCounter = React.useCallback((count: number) => {
    setArticleCounter(count);
  }, []);

  const updateTwitterCounter = React.useCallback((count: number) => {
    setTwitterCounter(count);
  }, []);

  const updateTelegramCounter = React.useCallback((count: number) => {
    setTelegramCounter(count);
  }, []);

  if (!activeIssue) {
    return null;
  }
  return (
    <Row>
      <Col xl={9} lg={9} md={12} sm={12} xs={12}>
        <StyledTabs
          defaultActiveKey={activeTab}
          onSelect={(tab: any, event: any) => {
            setActiveTab(tab);
          }}
        >
          <Tab
            eventKey={TABS.Article}
            title={
              <>
                {activeTab === TABS.Article ? (
                  <TabTitleWrapper id="article-tab-title">
                    <ArticleIcon isActive={true} />
                    <span className="d-inline-block" style={{ marginLeft: 5 }}>
                      ({articleCounter})
                    </span>
                  </TabTitleWrapper>
                ) : (
                  <TabTitleWrapper id="article-tab-title">
                    <ArticleIcon isActive={false} />
                    <span className="d-inline-block" style={{ marginLeft: 5 }}>
                      ({articleCounter})
                    </span>
                  </TabTitleWrapper>
                )}
              </>
            }
            color="#94a3b8"
            style={{ marginBottom: "8px" }}
          >
            <ArticleList
              activeIssue={activeIssue}
              updateCounter={updateArticleCounter}
              activeTab={activeTab}
            />
          </Tab>
          <Tab
            eventKey={TABS.Twitter}
            title={
              activeTab === TABS.Twitter ? (
                <TabTitleWrapper id="twitter-tab-title">
                  <TwitterIcon isActive />
                  <span className="d-inline-block" style={{ marginLeft: 5 }}>
                    ({twitterCounter})
                  </span>
                </TabTitleWrapper>
              ) : (
                <TabTitleWrapper id="twitter-tab-title">
                  <TwitterIcon isActive={false} />
                  <span className="d-inline-block" style={{ marginLeft: 5 }}>
                    ({twitterCounter})
                  </span>
                </TabTitleWrapper>
              )
            }
            style={{ marginBottom: "8px" }}
            id="twitter-tab-title"
          >
            <TwitterList
              activeIssue={activeIssue}
              updateCounter={updateTwitterCounter}
              activeTab={activeTab}
            />
          </Tab>
          {![
            SUBSCRIBED_REGION.TAIWAN_REGION,
            SUBSCRIBED_REGION.CHINA_REGION,
          ].includes(preferredRegion) ? (
            <Tab
              eventKey={TABS.Telegram}
              title={
                activeTab === TABS.Telegram ? (
                  <TabTitleWrapper id="telegram-tab-title">
                    <TelegramIcon isActive />
                    <span className="d-inline-block" style={{ marginLeft: 5 }}>
                      ({telegramCounter})
                    </span>
                  </TabTitleWrapper>
                ) : (
                  <TabTitleWrapper id="telegram-tab-title">
                    <TelegramIcon isActive={false} />
                    <span className="d-inline-block" style={{ marginLeft: 5 }}>
                      ({telegramCounter})
                    </span>
                  </TabTitleWrapper>
                )
              }
              style={{ marginBottom: "8px" }}
              id="telegram-tab-title"
            >
              <TelegramList
                activeTab={activeTab}
                activeIssue={activeIssue}
                updateCounter={updateTelegramCounter}
              />
            </Tab>
          ) : null}
        </StyledTabs>
      </Col>
      <Col xl={3} lg={3} md={12} sm={12} xs={12}>
        <MoversAndShapers />
      </Col>
      <RightStyledToolTip
        place={"bottom"}
        anchorSelect={`#article-tab-title`}
        html={"Articles"}
        offset={activeTab === TABS.Article ? 20 : 10}
      ></RightStyledToolTip>
      <RightStyledToolTip
        place={"bottom"}
        anchorSelect={`#twitter-tab-title`}
        html={"Twitter"}
        offset={activeTab === TABS.Twitter ? 20 : 10}
      ></RightStyledToolTip>
      <RightStyledToolTip
        place={"bottom"}
        anchorSelect={`#telegram-tab-title`}
        html={"Telegram"}
        offset={activeTab === TABS.Telegram ? 20 : 10}
      ></RightStyledToolTip>
    </Row>
  );
};

const TabTitleWrapper = styled.div``;
