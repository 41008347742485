import { Tooltip } from "react-tooltip";
import styled from "styled-components";
import { THEME_COLOUR_PRIMARY } from "../constants";

export const LeftStyledToolTip = styled(Tooltip)`
  .tooltip-arrow {
    &:before {
      border-left-color: #2f6fed !important;
    }
  }
  .tooltip-inner {
    background-color: #2f6fed !important;
    color: #fff;
  }
`;

export const BottomStyledToolTip = styled(Tooltip)`
  background-color: ${THEME_COLOUR_PRIMARY} !important;
  z-index: 2;
`;

export const RightStyledToolTip = styled(Tooltip)`
  background-color: ${THEME_COLOUR_PRIMARY} !important;
  z-index: 99;
`;
