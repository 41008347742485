import React from "react";
import { Col, Row } from "react-bootstrap";
import { TopIssueTile } from "../../components/TopIssueTile";
import { NO_RECORD_MESSAGE } from "../../constants";
import { TopIssueTileMeta } from "../../types/types";

export const TileListing = ({
  affiliation,
  onClickTile,
}: {
  affiliation: Array<TopIssueTileMeta>;
  onClickTile: (tileMeta: TopIssueTileMeta) => void;
}) => {
  return affiliation.length ? (
    <Row className="g-1 mt-4">
      {affiliation.map((all_aff) => {
        return (
          <Col
            xl={4}
            lg={4}
            md={12}
            sm={12}
            xs={12}
            key={all_aff.issueName}
            onClick={() => {
              onClickTile(all_aff);
            }}
          >
            <TopIssueTile
              issueName={all_aff.issueName}
              percentage={all_aff.percentage}
              recordCount={all_aff.recordCount}
              indicatorColor={all_aff.indicatorColor}
            />
          </Col>
        );
      })}
    </Row>
  ) : (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: 250 }}
    >
      <div>{NO_RECORD_MESSAGE}</div>
    </div>
  );
};
