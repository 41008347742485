import { Badge } from "react-bootstrap";
import styled from "styled-components";

export const StyledContent = styled("div").withConfig({
  shouldForwardProp: (prop) => prop !== "isrtl",
})<{ isrtl: boolean }>(({ isrtl }) => ({
  padding: "10px",
  direction: isrtl ? "rtl" : "ltr",
  color: "#475569",
}));

export const StyledTitle = styled.div`
  display: flex;
  font-size: 20px;
  color: #000;
`;

export const StyledSubTitle = styled.div`
  font-size: 16px;
  color: #64748b;
`;

export const StyledMeta = styled.span`
  font-size: 14px;
  color: #64748b;
`;

export const StyledTagPill = styled(Badge)`
  border-radius: 35px;
  line-height: 20px;
  font-size: 12px;
  border: 1px solid #cbd4e1;
  padding: 9px 20px;
  border-color: #cbd4e1 !important;
  color: #64748b;
  background-color: transparent !important;
`;

export const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MetaLabel = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #64748b;
`;
