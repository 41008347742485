import ReactSelect, { Props, StylesConfig } from "react-select";
import { THEME_COLOUR_PRIMARY } from "../constants";

type ComponentProps = Props & {
  extraStyles?: StylesConfig;
  inputWidth?: number;
};

export const ModifiedReactSelect = (props: ComponentProps) => {
  return (
    <ReactSelect
      {...props}
      styles={{
        control: (baseStyles, state) => {
          return {
            ...baseStyles,
            borderRadius: "8px",
            boxShadow: state.isFocused
              ? `0 0 0 1px ${THEME_COLOUR_PRIMARY}`
              : baseStyles.boxShadow,
            borderColor: state.isFocused
              ? `${THEME_COLOUR_PRIMARY}`
              : baseStyles.borderColor,
            ":hover": {
              borderColor: state.isFocused
                ? `${THEME_COLOUR_PRIMARY}`
                : baseStyles[":hover"]?.borderColor,
            },
          };
        },
        option: (baseStyles, { isSelected }) => {
          return {
            ...baseStyles,
            cursor: "pointer",
            backgroundColor: isSelected ? "#F0F5FF" : "",
            color: isSelected ? `${THEME_COLOUR_PRIMARY}` : "",
          };
        },
        ...props.extraStyles,
      }}
    />
  );
};

export const ModifiedReactSelectV2 = (props: ComponentProps) => {
  return (
    <ReactSelect
      {...props}
      styles={{
        control: (baseStyles, state) => {
          return {
            ...baseStyles,
            width: props.inputWidth ? props.inputWidth : "auto",
            paddingLeft: "12px",
            borderRadius: "24px",
            boxShadow: state.isFocused
              ? `0 0 0 1px ${THEME_COLOUR_PRIMARY}`
              : baseStyles.boxShadow,
            borderColor: state.isFocused
              ? `${THEME_COLOUR_PRIMARY}`
              : baseStyles.borderColor,
            ":hover": {
              borderColor: state.isFocused
                ? `${THEME_COLOUR_PRIMARY}`
                : baseStyles[":hover"]?.borderColor,
            },
          };
        },
        option: (baseStyles, { isSelected }) => {
          return {
            ...baseStyles,
            cursor: "pointer",
            backgroundColor: isSelected ? "#F0F5FF" : "",
            color: isSelected ? `${THEME_COLOUR_PRIMARY}` : "",
          };
        },
        ...props.extraStyles,
      }}
    />
  );
};
