import { DateTime } from "luxon";

export const SITE_CONSTANTS = {
  CALENDAR_ALLOWED_BACK_DAYS:
    window.location.hostname === "localhost" ? 120 : 6,
  CALENDER_SELECTED_BACK_DAYS: 2,
  TOAST_AUTOMATIC_CLOSE_TIME_MS: 7000,
};

export const STATIC_SITE_DATE =
  process.env.REACT_APP_LAST_DB_UPDATE_DATE ?? "2024-07-15";

export const LAST_DATABASE_UPDATE_DATE =
  process.env.REACT_APP_STATIC_SITE_FLAG === "true"
    ? DateTime.fromISO(STATIC_SITE_DATE)
    : DateTime.now();

export const ALLOWED_BACK_DATE = LAST_DATABASE_UPDATE_DATE.minus({
  days:
    window.location.hostname === "localhost"
      ? 120
      : SITE_CONSTANTS.CALENDER_SELECTED_BACK_DAYS,
});

export const ERROR_MSG_GENERIC = "Something went wrong !!";
export const EMERGING_ISSUES_RECORD_LIMIT = 20;
export const EMERGING_ISSUES_ARTICLE_CHAR_LIMIT = 390;
export const EMPTY_CONTENT_STRING = [
  "No translation needed",
  "No translation available",
];
export const DEFAULT_START_DATE = ALLOWED_BACK_DATE.toMillis();
export const DEFAULT_END_DATE = LAST_DATABASE_UPDATE_DATE.toMillis();
export const MAX_ALLOWED_BACK_DATE = LAST_DATABASE_UPDATE_DATE.minus({
  days: SITE_CONSTANTS.CALENDAR_ALLOWED_BACK_DAYS,
}).toMillis();

export const labels = {
  affiliation: "Affiliations",
  subIssue: "Sub-Issue",
  originalPostLanguage: "Original Post Language",
  date: "Date",
  postLocation: "Post Location",
};

export const DATE_ORDER_BY_OPTIONS = [
  {
    label: "Date (Descending)",
    value: "DESC",
  },
  {
    label: "Date (Ascending)",
    value: "ASC",
  },
];

export const COLOR_PALEETE = {
  DIGITAL_MEDIA_COVERAGE: [
    "#2F6FED",
    "#3976EE",
    "#447DEF",
    "#4E85F0",
    "#598CF1",
    "#6393F2",
    "#6D9AF2",
    "#78A1F3",
    "#82A9F4",
    "#8DB0F5",
    "#97B7F6",
    "#A1BEF7",
    "#ACC5F8",
    "#B6CDF9",
    "#C1D4FA",
    "#CBDBFB",
  ],
  SOCIAL_MEDIA: [
    "#38DC2A",
    "#42DE35",
    "#4CE03F",
    "#56E14A",
    "#60E355",
    "#6AE55F",
    "#74E76A",
    "#7EE875",
    "#88EA7F",
    "#92EC8A",
    "#9CEE95",
    "#A5EF9F",
    "#AFF1AA",
    "#B9F3B4",
    "#C3F5BF",
    "#CDF6CA",
  ],
  HASHTAG: [
    "#2ACBD5",
    "#2FE2ED",
    "#4EE6F0",
    "#59E8F1",
    "#6DEBF2",
    "#82EEF4",
    "#97F1F6",
    "#ACF3F8",
    "#C1F6FA",
    "#D5F9FB",
  ],
};

export const DEFAULT_DROPDOWN_OPTION = { label: "All", value: "" };

export const TABS = {
  Article: "Article",
  Twitter: "Twitter",
  Telegram: "Telegram",
};

export const PIE_CHART_COLOUR_PALLETE = [
  "#FF9D4E",
  "#9967BC",
  "#75CBED",
  "#F96363",
  "#299998",
];

export const ERRORS = {
  AND_OR_PRESENT:
    "Sorry,We do not support this operation right now. Please look into search guide on how to search better!!!",
  INVALID_SEARCH_STRING:
    "Invalid search!! Please look into search guide on how to search better.",
};

export const M3_PARAM = {
  model: "M3",
};

export const DEFAULT_POLITICAL_AFFILIATION = {
  label: "All Affiliations",
  value: "",
};

export const DEFAULT_POST_LANGUAGE = {
  label: "All Languages",
  value: "",
};

export const DEFAULT_POST_LOCATION = {
  label: "All Locations",
  value: "",
};

export const DEFAULT_ALL_ISSUES = {
  label: "All Issues",
  value: "",
};

/* --- Temp Limited Issue Visibility --- */

export const LIMITED_ISSUE_VISIBILITY = [
  "Regional Footprint",
  "Women's Issues",
  "Environmental Issues",
  "Nuclear Negotiations",
  "Sanctions",
  "Stability of the Nezam",
  "Economic Hardship",
];

export const MANDSPROFILE_PREFIX_URL =
  "https://persumedia-django-mns-app.s3.amazonaws.com/media/profile_photos/";

export const INFLUENCIAL_ENTITY_PREFIX_URL =
  "https://persumedia-django-mns-app.s3.amazonaws.com/media/entities_photos/";

export const NEWS_AGENCY_PREFIX_URL =
  "https://persumedia-django-mns-app.s3.amazonaws.com/media/news_media_photos/";

export const THEME_COLOUR_PRIMARY = "#4a55fd";
export const THEME_GREEN = "#63f3dc";
export const THEME_SECTION_TITLE_BLACK = "#475569";

export const TAB_TITLE = {
  default: "SOTOS",
  insights: "SOTOS | Insights",
  emerging_issue: "SOTOS | Issue watch",
  discover: "SOTOS | Discover",
};
export const SUBSCRIBED_REGION = {
  IRAN_REGION: "IRAN",
  TAIWAN_REGION: "TAIWAN",
  CHINA_REGION: "CHINA",
};

export const TAIWANESE_NEWS_AGENCY_PREFIX_URL =
  "https://persumedia-django-mns-app.s3.amazonaws.com/media/taiwanese_news_media_photos/";

export const CHINESE_NEWS_AGENCY_PREFIX_URL =
  "https://persumedia-django-mns-app.s3.amazonaws.com/media/chinese_news_media_photos/";

export const DEFAULT_VISIBLE_NEWS_AGENCIES = 4;

export const POLITICAL_AFFILIATION = ["Left", "Center", "Right"];

export const V2_THEME_BLACK = "#475569";

export const NO_RECORD_MESSAGE = "No Records Available with this criteria";

export const PREPARING_DOWNLOAD =
  "Please wait while we prepare your download...!!!";

export const CSV_HEADERS = [
  {
    label: "Date",
    key: "date",
  },
  {
    label: "Optimism",
    key: "optimism",
  },
  {
    label: "Passimism",
    key: "passimism",
  },
];

export const CHINESE_TRADITIONAL = "Chinese (Traditional)";
export const CHINESE_SIMPLIFIED = "Chinese (Simplified)";

export const PROFILE_PICTURE_IMG_PREFIX =
  "https://persumedia-django-mns-app.s3.amazonaws.com/media/sotos_profile_photos/";
