import React, { CSSProperties } from "react";
import { IconPositionProps } from "./SectionSubTitle";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

type Props = {
  title: string;
  style?: CSSProperties;
  className?: string;
} & IconPositionProps;

export const SectionTitle = ({ title, icon, iconPosition }: Props) => {
  return (
    <Row className="pt-2">
      <Col className="d-flex align-items-center">
        {icon && iconPosition === "Leading" && (
          <div style={{ marginRight: "10px" }}>{icon}</div>
        )}
        <StyledTitle>{title}</StyledTitle>
        {icon && iconPosition === "Trailing" && (
          <div style={{ marginLeft: "10px" }}>{icon}</div>
        )}
      </Col>
    </Row>
  );
};

const StyledTitle = styled.div`
  font-size: 26px;
  line-height: 40px;
  font-weight: bold;
  color: #1e2a3b;
`;
