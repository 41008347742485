import styled from "styled-components";
import { StyledDatePicker } from "./StyledDatePicker";

export const StyledDateSelecterM3 = styled(StyledDatePicker)`
  border-radius: 8px;
`;

export const StyledDateSelecterM3V2 = styled(StyledDatePicker)`
  border-radius: 24px;
  width: 190px;
  padding: 6px 30px 5px 10px !important;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;

  button {
    width: 100%;
  }
`;
