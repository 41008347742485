import React from "react";
import styled, { css } from "styled-components";
import { Circle } from "@styled-icons/material";
import { getIssueNameInNative, hex2rgba } from "../helpers";
import { TopIssueTileMeta } from "../types/types";
import { V2_THEME_BLACK } from "../constants";
import { useSelector } from "react-redux";
import { RootState } from "../store";

export const TopIssueTile = ({
  issueName,
  recordCount,
  percentage,
  indicatorColor,
}: TopIssueTileMeta) => {
  const { preferredRegion, issuesM3 } = useSelector(
    (state: RootState) => state.preference
  );

  const originalIssueName = React.useMemo(() => {
    return issuesM3 ? getIssueNameInNative(preferredRegion, issueName) : "";
  }, [issuesM3, preferredRegion, issueName]);
  return (
    <StyledTileWapper backgroundColor={indicatorColor}>
      <IssueDetailContainer className="d-flex">
        <StyledIndicatorWrapper>
          <StyledIndicator size={10} color={indicatorColor} />
        </StyledIndicatorWrapper>
        <MetaWrapper>
          <div>
            <StyledIssueName>{issueName}</StyledIssueName>
          </div>
          <div>
            <StyledOriginalName>({originalIssueName})</StyledOriginalName>
          </div>
          <div className="pt-3 d-flex align-items-center">
            <IssueRecordCount>{recordCount}</IssueRecordCount>
            <IssuePercentage className="ps-2">
              {percentage.toFixed(2)}%
            </IssuePercentage>
          </div>
        </MetaWrapper>
      </IssueDetailContainer>
    </StyledTileWapper>
  );
};

const StyledTileWapper = styled("div").withConfig({
  shouldForwardProp: (props) => props !== "backgroundColor",
})<{ backgroundColor: string }>(({ backgroundColor }) => {
  return css`
    border: 1px solid #fff;
    border-radius: 10px;
    &:hover {
      background-color: ${hex2rgba(backgroundColor, 0.1)};
    }
    padding-top: 10px;
    padding-bottom: 12px;
    cursor: pointer;
  `;
});

const StyledIndicator = styled(Circle)``;
const StyledIndicatorWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const StyledIssueName = styled.div`
  color: ${V2_THEME_BLACK};
  font-size: 14px;
`;

const StyledOriginalName = styled.div`
  color: #64748b;
  font-size: 12px;
  width: 147px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const MetaWrapper = styled.div`
  overflow: hidden;
  padding-right: 10px;
`;
const IssueRecordCount = styled.div`
  color: ${V2_THEME_BLACK};
  font-size: 18px;
`;
const IssuePercentage = styled.div`
  color: #38dc2a;
  padding: 6px 10px;
  font-size: 10px;
  background-color: ${hex2rgba("#38dc2a", 0.1)};
  border-radius: 12px;
  margin-left: 10px;
`;

const IssueDetailContainer = styled("div")(() => {
  return css`
    @media (max-width: 1389) {
      height: 130px;
    }
    height: 110px;
  `;
});
