import * as React from "react";
import styled from "styled-components";
import { THEME_COLOUR_PRIMARY } from "../constants";
import { DropdownOption } from "../types/types";
interface Props {
  options: Array<{
    label: string;
    value: string;
  }>;
  selectedValue: string;
  onClick: (value: DropdownOption) => void;
}
export const DateSwitcher = ({ options, selectedValue, onClick }: Props) => {
  return (
    <DateRangeContainer>
      {options.map((option) => {
        return (
          <DateOption
            isActive={selectedValue === option.value}
            onClick={() => onClick(option)}
            key={option.label}
          >
            {option.label}
          </DateOption>
        );
      })}
    </DateRangeContainer>
  );
};
const DateRangeContainer = styled.div`
  border-radius: 8px;
  display: flex;
  background: #e2e8f0;
  padding: 3px;
`;
const DateOption = styled("div").withConfig({
  shouldForwardProp: (prop) => ["isActive", "isLast"].includes(prop) === false,
})<{ isActive: boolean; isLast?: boolean }>(({ isActive, isLast }) => ({
  flex: "1 1 auto",
  textAlign: "center",
  cursor: "pointer",
  backgroundColor: isActive ? "white" : "#e2e8f0",
  borderRadius: isActive ? "7px" : "0",
  color: isActive ? "#1E2A3B" : "#94A3B8",
  lineHeight: "2.1",
  margin: "0 2px",
  outline: isActive ? `2px solid ${THEME_COLOUR_PRIMARY}` : "none",
}));
