import React from "react";
import { SectionSubTitle } from "../../components/SectionSubTitle";
import { DigitalMediaCoverage } from "./DigitalMediaCoverage";
import { SocialMediaCoverage } from "./SocialMediaCoverage";
import "./insights.css";
import { HashtageCoverage } from "./hashtagsCoverage";
import { InsightsOpinionAI } from "./OpinionAI";
import {
  DEFAULT_POST_LANGUAGE,
  DEFAULT_START_DATE,
  MAX_ALLOWED_BACK_DATE,
  TAB_TITLE,
  DEFAULT_END_DATE,
} from "../../constants";
import { ReactGA } from "../../components/GoogleAnalytics";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { DropdownOption } from "../../types/types";
import { DateTime } from "luxon";
import { setArticlesFilterValues } from "../../store/slice/filters";
import { ActionMeta, SingleValue } from "react-select";

import { ModifiedReactSelectV2 } from "../../components/ModifiedReactSelect";
import { StyledDateSelecterM3V2 } from "../../components/CommonStyledComponents";
import { CalendarIcon } from "../../components/CalendarIcon";
import { AxiosResponse } from "axios";
import axiosInstance from "../../api";
import { Col, Row } from "react-bootstrap";
import { SendGAEvent } from "../../helpers/googleAnalyticsHepler";

export const Insights = () => {
  const { preferredRegion, articles: reduxArticleFilter } = useSelector(
    (state: RootState) => {
      return {
        preferredRegion: state.preference.preferredRegion,
        articles: state.reduxFilter.articles,
      };
    }
  );
  const dispatch = useDispatch();

  const [filterOptions, setFilterOptions] = React.useState<{
    source_language?: Array<DropdownOption>;
  }>({
    source_language: undefined,
  });

  const [dateFilter, setDateFilter] = React.useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: reduxArticleFilter.date.startDate
      ? new Date(reduxArticleFilter.date.startDate)
      : new Date(DEFAULT_START_DATE),
    endDate: reduxArticleFilter.date.endDate
      ? new Date(reduxArticleFilter.date.endDate)
      : new Date(DateTime.now().toMillis()),
  });

  const dateSelectorRef = React.useRef<any>();

  React.useEffect(() => {
    document.title = TAB_TITLE.insights;
    ReactGA.event({
      category: "SOTOS_Insights_Page_Landed",
      action: "SOTOS_Insights_Page_Landed",
    });
  }, []);

  React.useEffect(() => {
    SendGAEvent({
      category: "SOTOS_Insights_Top_Issues_Filter_Updated",
      action: "SOTOS_Insights_Top_Issues_Filter_Updated",
      label: `SL-${reduxArticleFilter.source_language.label},SD-${
        reduxArticleFilter.date.startDate
          ? DateTime.fromMillis(reduxArticleFilter.date.startDate).toJSDate()
          : DateTime.fromMillis(DEFAULT_START_DATE).toFormat("yyyy-LL-dd")
      },ED-${
        reduxArticleFilter.date.endDate
          ? DateTime.fromMillis(reduxArticleFilter.date.endDate).toJSDate()
          : DateTime.now().toFormat("yyyy-LL-dd")
      }`,
      includeUserDetail: true,
    });
  }, [reduxArticleFilter]);

  React.useEffect(() => {
    axiosInstance
      .post("insights/digital-media-coverage/filters", {
        params: {
          model: "M3",
          subscribed_region: preferredRegion,
        },
      })
      .then(
        (
          res: AxiosResponse<{
            data: {
              political_affiliation: Array<DropdownOption>;
              source_language: Array<DropdownOption>;
            };
          }>
        ) => {
          setFilterOptions({
            source_language: [
              { ...DEFAULT_POST_LANGUAGE },
              ...res.data.data.source_language,
            ],
          });
        }
      )
      .catch((err) => console.log(err));
  }, [preferredRegion]);

  const onDateChange = React.useCallback(
    ([newStartDate, newEndDate]: [Date | null, Date | null]) => {
      if (dateSelectorRef.current.state.open && newEndDate) {
        dateSelectorRef.current.setOpen(false);
      }

      setDateFilter({
        startDate: newStartDate
          ? new Date(DateTime.fromJSDate(newStartDate).toMillis())
          : null,
        endDate: newEndDate
          ? new Date(DateTime.fromJSDate(newEndDate).toMillis())
          : null,
      });

      if (newEndDate) {
        dispatch(
          setArticlesFilterValues({
            ...reduxArticleFilter,
            date: {
              startDate: newStartDate
                ? DateTime.fromJSDate(newStartDate).toMillis()
                : null,
              endDate: newEndDate
                ? DateTime.fromJSDate(newEndDate).toMillis()
                : null,
            },
          })
        );
      }
    },
    [dispatch, reduxArticleFilter]
  );

  const onChangeFilterOption = React.useCallback(
    (newValue: SingleValue<unknown>, meta: ActionMeta<unknown>) => {
      dispatch(
        setArticlesFilterValues({
          ...reduxArticleFilter,
          [meta.name ?? ""]: newValue,
        })
      );
    },
    [dispatch, reduxArticleFilter]
  );

  return (
    <>
      <div className="mb-3">
        <div className="d-flex justify-content-between align-items-center">
          <SectionSubTitle text={"Top Issues"} />
          <div className="my-3 d-flex">
            <ModifiedReactSelectV2
              isSearchable={false}
              options={filterOptions.source_language}
              name="source_language"
              onChange={onChangeFilterOption}
              value={reduxArticleFilter.source_language}
              className="me-2"
              inputWidth={200}
            />
            <StyledDateSelecterM3V2
              className="text-center"
              selected={dateFilter.startDate}
              onChange={onDateChange}
              minDate={new Date(MAX_ALLOWED_BACK_DATE)}
              maxDate={new Date(DEFAULT_END_DATE)}
              startDate={dateFilter.startDate}
              endDate={dateFilter.endDate}
              selectsRange
              dateFormat={"MM/dd"}
              showDisabledMonthNavigation
              shouldCloseOnSelect
              ref={dateSelectorRef}
              onCalendarOpen={() => {
                dateSelectorRef.current.input.classList.add("primary-border");
              }}
              onCalendarClose={() => {
                dateSelectorRef.current.input.classList.remove(
                  "primary-border"
                );
              }}
              showIcon
              icon={
                <span
                  style={{
                    right: 10,
                    lineHeight: 0,
                    margin: "auto",
                    top: 0,
                    bottom: 0,
                  }}
                  onClick={() => {
                    dateSelectorRef.current.setOpen(true);
                  }}
                >
                  <CalendarIcon />
                </span>
              }
            />
          </div>
        </div>
        <Row>
          <Col xl={6} lg={6} md={12} sm={12} xs={12}>
            <DigitalMediaCoverage />
          </Col>
          <Col xl={6} lg={6} md={12} sm={12} xs={12}>
            <SocialMediaCoverage />
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <InsightsOpinionAI />
      </div>

      <div className="mb-3">
        <HashtageCoverage />
      </div>
    </>
  );
};
