import * as React from "react";
import { Card } from "react-bootstrap";
import styled from "styled-components";
import { RightStyledToolTip } from "../../components/StyledToolTip";
import { TelegramIcon, TwitterIcon } from "../../components/TabIcons";
import { THEME_COLOUR_PRIMARY } from "../../constants";

interface Props {
  data: Array<{ x: string; y: number }>;
  onClickRow: (hashtag: string) => void;
  sectionTitle: string;
  tileNumber?: number;
}

export const HashtagTiles = ({
  data,
  onClickRow,
  sectionTitle,
  tileNumber = 2,
}: Props) => {
  const [isTwitterIconActive, setIsTwitterIconActive] = React.useState(false);
  const [isTelegramIconActive, setIsTelegramIconActive] = React.useState(false);
  return (
    <>
      <Card className="h-100">
        <Card.Body className="px-0 pt-0">
          <Card.Title
            style={{
              borderBottom: "1px solid #ccc",
              textAlign: "left",
              display: "flex",
            }}
            className="ps-3"
          >
            {sectionTitle === "Twitter" ? (
              <div
                className="my-1 p-3 rounded-circle"
                onMouseEnter={() => setIsTwitterIconActive(true)}
                onMouseLeave={() => setIsTwitterIconActive(false)}
                style={{
                  backgroundColor: isTwitterIconActive
                    ? THEME_COLOUR_PRIMARY
                    : "transparent",
                  transition: "all 0.25s ease-in-out",
                  fontSize: 16,
                }}
                id="twitter-opinion-title"
              >
                <TwitterIcon color="#475569" isActive={isTwitterIconActive} />
                <RightStyledToolTip
                  place="right"
                  anchorSelect={`#twitter-opinion-title`}
                  html={"Twitter"}
                ></RightStyledToolTip>
              </div>
            ) : (
              <div
                className="my-1 p-3 rounded-circle"
                onMouseEnter={() => setIsTelegramIconActive(true)}
                onMouseLeave={() => setIsTelegramIconActive(false)}
                style={{
                  backgroundColor: isTelegramIconActive
                    ? THEME_COLOUR_PRIMARY
                    : "transparent",
                  transition: "all 0.25s ease-in-out",
                  fontSize: 16,
                }}
                id="telegram-opinion-title"
              >
                <TelegramIcon color="#475569" isActive={isTelegramIconActive} />
                <RightStyledToolTip
                  place="right"
                  anchorSelect={`#telegram-opinion-title`}
                  html={"Telegram"}
                ></RightStyledToolTip>
              </div>
            )}
          </Card.Title>
          {data.length ? (
            <StyledCardContainer className="custom-scroll">
              <div className="d-flex flex-wrap mx-2">
                {data.map((tag) => {
                  return (
                    <StyledHashtagTileWrapper
                      className="wrap-col"
                      onClick={() => onClickRow(tag.x)}
                      tileNumber={tileNumber}
                    >
                      <StyledHashtagTile className="wrap-box d-flex align-items-center justify-content-between">
                        <span className="me-3 hashtag-keyword">{tag.x}</span>
                        <StyledHashtagCount className="hashtag-count">
                          {tag.y}
                        </StyledHashtagCount>
                      </StyledHashtagTile>
                    </StyledHashtagTileWrapper>
                  );
                })}
              </div>
            </StyledCardContainer>
          ) : (
            <StyledCardContainer
              className="d-flex align-items-center justify-content-center"
              style={{ height: 390 }}
            >
              <div style={{ fontSize: 23, color: "#64748b" }}>
                No data to display
              </div>
            </StyledCardContainer>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

const StyledCardContainer = styled.div(() => ({
  maxHeight: "390px",
  overflowY: "auto",
  paddingTop: "24px",
  marginBottom: "36px",
}));

const StyledHashtagTileWrapper = styled.div<{ tileNumber: number }>(
  ({ tileNumber }) => {
    return {
      flex: "0 0 auto",
      minWidth: `calc(100%/${tileNumber})`,
      padding: "0 7px 14px",
      cursor: "pointer",
    };
  }
);

const StyledHashtagCount = styled.small`
  color: ${THEME_COLOUR_PRIMARY};
`;

const StyledHashtagTile = styled.div`
  background-color: #f1f4f9;
  padding: 26px 20px;
  border-radius: 12px;
  font-size: 18px;
  &:hover {
    background-color: ${THEME_COLOUR_PRIMARY};
    color: #f1f4f9;
  }
  &:hover ${StyledHashtagCount} {
    color: #fff;
  }
`;
