import { AxiosResponse } from "axios";
import React from "react";
import { Row, Col, Tab } from "react-bootstrap";
import axiosInstance from "../../api";
import { StyledTabs } from "../../components/StyledTab";
import { DEFAULT_DROPDOWN_OPTION } from "../../constants";
import { FilterTypes } from "../../types/filterTypes";
import { DropdownOption } from "../../types/types";
import { ModifiedReactSelect } from "../../components/ModifiedReactSelect";
import { DigitalTab } from "./DigitalTab";
import { SingleValue, ActionMeta } from "react-select";
import { SocialTab } from "./SocialTab";

interface Filters {
  issue?: Array<DropdownOption>;
}

export interface SelectedFilters {
  issue: DropdownOption;
}

export interface Params {
  issue?: { type: string; value: string };
  region?: { type: string; value: string };
  source_language?: { type: string; value: string };
  tweet_language?: { type: string; value: string };
  country?: { type: string; value: string };
}

const DEFAULT_ISSUE_DROP_DOWN_OPTS: Array<DropdownOption> = [
  {
    label: "Air Pollution",
    value: "Air Pollution",
  },
  {
    label: "Axis of Resistance",
    value: "Axis of Resistance",
  },
  {
    label: "Economic Conditions",
    value: "Economic Conditions",
  },
  {
    label: "Economic Policies",
    value: "Economic Policies",
  },
  {
    label: "Environmental Issues",
    value: "Environmental Issues",
  },
  {
    label: "Progress of Nuclear Negotiations",
    value: "Progress of Nuclear Negotiations",
  },
  {
    label: "Regional Footprint",
    value: "Regional Footprint",
  },
  {
    label: "Regional Political Influence",
    value: "Regional Political Influence",
  },
  {
    label: "Sanctions",
    value: "Sanctions",
  },
  {
    label: "Stability of the Nezam",
    value: "Stability of the Nezam",
  },
  {
    label: "Water Management",
    value: "Water Management",
  },
  {
    label: "Women’s Religious and Social Constraints",
    value: "Women’s Religious and Social Constraints",
  },
];

export const OpinionAIM3 = () => {
  const [activeTab, setActiveTab] = React.useState<"Digital" | "Social">(
    "Digital"
  );
  const [filterOptions, setFilterOptions] = React.useState<Filters>({
    issue: undefined,
  });

  const [selectedFilters, setSelectedFilters] = React.useState<SelectedFilters>(
    {
      issue: { ...DEFAULT_ISSUE_DROP_DOWN_OPTS[0] },
    }
  );

  const selectedFiltersRef = React.useRef<SelectedFilters>({
    issue: { ...DEFAULT_ISSUE_DROP_DOWN_OPTS[0] },
  });

  const params: { filters: Params } = React.useMemo(() => {
    return {
      filters: {
        issue: selectedFilters.issue.value
          ? { type: FilterTypes.eq, value: selectedFilters.issue.value }
          : undefined,
        region: {
          type: FilterTypes.eq,
          value: "MENA",
        },
        country: {
          type: FilterTypes.eq,
          value: "IR",
        },
      },
    };
  }, [selectedFilters.issue.value]);

  /* const getOpinionAIFilters = React.useCallback(() => {
    axiosInstance
      .post("/m3/opinionAI/get-filters")
      .then((res: { data: AxiosResponse<Required<Filters>> }) => {
        setFilterOptions({
          issue: [...res.data.data.issue],
        });
        setSelectedFilters({
          issue: res.data.data.issue[0],
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []); */

  /* React.useEffect(() => {
    getOpinionAIFilters();
  }, [getOpinionAIFilters]); */

  const onChangeFilterOption = (
    newValue: SingleValue<unknown>,
    meta: ActionMeta<unknown>
  ) => {
    selectedFiltersRef.current = {
      ...selectedFiltersRef.current,
      [meta.name ?? ""]: newValue,
    };
    setSelectedFilters({
      ...selectedFilters,
      [meta.name ?? ""]: newValue,
    });
  };

  return (
    <>
      <Row className="my-3">
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <div>Issue</div>

          <ModifiedReactSelect
            isSearchable={false}
            options={DEFAULT_ISSUE_DROP_DOWN_OPTS}
            name="issue"
            onChange={onChangeFilterOption}
            value={selectedFilters.issue}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} xl={12} lg={12}>
          <StyledTabs
            defaultActiveKey={activeTab}
            onSelect={(tab: any, event: any) => {
              setActiveTab(tab);
            }}
          >
            <Tab
              eventKey={"Digital"}
              title={`Digital Outlet`}
              color="#94a3b8"
              style={{ marginBottom: "8px" }}
            >
              <DigitalTab filters={params} />
            </Tab>
            <Tab
              eventKey={"Social"}
              title={`Twitter`}
              color="#94a3b8"
              style={{ marginBottom: "8px" }}
            >
              <>
                <SocialTab filters={params} />{" "}
              </>
            </Tab>
          </StyledTabs>
        </Col>
      </Row>
    </>
  );
};
