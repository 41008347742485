import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { SUBSCRIBED_REGION } from "../../constants";
import { REGIONS } from "../../types/types";

interface IssuePreference {
  issues: {
    englishIssues: Array<string>;
    originalLanguageIssues: Array<string>;
    emergingIssueEnabledRecords: Record<string, boolean>;
    defaultSelectedIssue?: string;
  };
  taiwanIssues: {
    englishIssues: Array<string>;
    originalLanguageIssues: Array<string>;
    emergingIssueEnabledRecords: Record<string, boolean>;
    defaultSelectedIssue?: string;
  };
  chinaIssues: {
    englishIssues: Array<string>;
    originalLanguageIssues: Array<string>;
    emergingIssueEnabledRecords: Record<string, boolean>;
    defaultSelectedIssue?: string;
  };
  issuesM3: {
    englishIssues: Array<string>;
    originalLanguageIssues: Array<string>;
    emergingIssueEnabledRecords: Record<string, boolean>;
    defaultSelectedIssue?: string;
  };
  preferredLanguage: "English" | "Original";
  preferredRegion: REGIONS;
  selectedIssue?: string;
  selectedIssueM3?: string;
  selectedTaiwanIssue?: string;
  selectedChinaIssue?: string;
  defaultSelectedIssueM3?: string;
  defaultSelectedTaiwanIssue?: string;
  selectedOriginalIssue?: string;
  selectedOriginalIssueM3?: string;
  selectedOriginalIssueTaiwan?: string;
  selectedTab?: "Article" | "Twitter" | "Telegram";
  defaultSelectedChinaIssue?: string;
  selectedOriginalIssueChina?: string;
}

const initialState: IssuePreference = {
  issues: {
    englishIssues: [],
    originalLanguageIssues: [],
    emergingIssueEnabledRecords: {},
  },
  taiwanIssues: {
    englishIssues: [],
    originalLanguageIssues: [],
    emergingIssueEnabledRecords: {},
    defaultSelectedIssue: undefined,
  },
  chinaIssues: {
    englishIssues: [],
    originalLanguageIssues: [],
    emergingIssueEnabledRecords: {},
    defaultSelectedIssue: undefined,
  },
  issuesM3: {
    englishIssues: [],
    originalLanguageIssues: [],
    emergingIssueEnabledRecords: {},
    defaultSelectedIssue: undefined,
  },
  preferredLanguage: "English",
  preferredRegion: "IRAN",
  selectedIssue: undefined,
  selectedIssueM3: undefined,
  selectedTaiwanIssue: undefined,
  selectedChinaIssue: undefined,

  selectedTab: undefined,
  selectedOriginalIssue: undefined,
  defaultSelectedIssueM3: undefined,
  defaultSelectedTaiwanIssue: undefined,
  selectedOriginalIssueTaiwan: undefined,
  defaultSelectedChinaIssue: undefined,
  selectedOriginalIssueChina: undefined,
};

export const Preference = createSlice({
  name: "preference",
  initialState,
  reducers: {
    setPreferredLanguage: (
      state,
      action: PayloadAction<Pick<IssuePreference, "preferredLanguage">>
    ) => {
      state.preferredLanguage = action.payload.preferredLanguage;
    },
    setPreferredRegion: (
      state,
      action: PayloadAction<Pick<IssuePreference, "preferredRegion">>
    ) => {
      state.preferredRegion = action.payload.preferredRegion;
    },
    setIssues: (
      state,
      action: PayloadAction<
        Pick<IssuePreference, "issues" | "taiwanIssues" | "chinaIssues">
      >
    ) => {
      return {
        ...state,
        selectedIssue: action.payload.issues.englishIssues[0],
        selectedOriginalIssue: action.payload.issues.originalLanguageIssues[0],
        issues: action.payload.issues,
        selectedTaiwanIssue: action.payload.taiwanIssues.englishIssues[0],
        taiwanIssues: action.payload.taiwanIssues,
        selectedOriginalIssueTaiwan:
          action.payload.taiwanIssues.originalLanguageIssues[0],
        selectedOriginalIssueChina:
          action.payload.chinaIssues.originalLanguageIssues[0],
      };
    },
    setIssuesM3: (
      state,
      action: PayloadAction<
        Pick<IssuePreference, "issues" | "taiwanIssues" | "chinaIssues">
      >
    ) => {
      return {
        ...state,
        issuesM3: action.payload.issues,
        taiwanIssues: action.payload.taiwanIssues,
        chinaIssues: action.payload.chinaIssues,
        defaultSelectedIssueM3: action.payload.issues.defaultSelectedIssue,
        defaultSelectedTaiwanIssue:
          action.payload.taiwanIssues.defaultSelectedIssue,
        defaultSelectedChinaIssue:
          action.payload.chinaIssues.defaultSelectedIssue,
      };
    },
    setSelectedIssueM3: (
      state,
      action: PayloadAction<
        Pick<IssuePreference, "selectedIssue" | "selectedTab"> & {
          region: string;
        }
      >
    ) => {
      switch (action.payload.region) {
        case SUBSCRIBED_REGION.IRAN_REGION:
          state.selectedIssueM3 = action.payload.selectedIssue;
          state.selectedTab = action.payload.selectedTab ?? undefined;
          break;

        case SUBSCRIBED_REGION.TAIWAN_REGION:
          state.selectedTaiwanIssue = action.payload.selectedIssue;
          state.selectedTab = action.payload.selectedTab ?? undefined;
          break;

        case SUBSCRIBED_REGION.CHINA_REGION:
          state.selectedChinaIssue = action.payload.selectedIssue;
          state.selectedTab = action.payload.selectedTab ?? undefined;
          break;
        default:
          break;
      }
    },
    setSelectedIssue: (
      state,
      action: PayloadAction<
        Pick<IssuePreference, "selectedIssue" | "selectedTab"> & {
          region: string;
        }
      >
    ) => {
      switch (action.payload.region) {
        case SUBSCRIBED_REGION.IRAN_REGION:
          state.selectedIssue = action.payload.selectedIssue;
          const indexOfIssue = state.issues.englishIssues.findIndex(
            (issue) => issue === action.payload.selectedIssue
          );
          state.selectedOriginalIssue =
            state.issues.originalLanguageIssues[indexOfIssue];
          state.selectedTab = action.payload.selectedTab ?? undefined;
          break;

        case SUBSCRIBED_REGION.TAIWAN_REGION:
          state.selectedTaiwanIssue = action.payload.selectedIssue;
          const indexOfTaiwanIssue = state.taiwanIssues.englishIssues.findIndex(
            (issue) => issue === action.payload.selectedIssue
          );
          state.selectedOriginalIssueTaiwan =
            state.issues.originalLanguageIssues[indexOfTaiwanIssue];
          state.selectedTab = action.payload.selectedTab ?? undefined;
          break;

        default:
          break;
      }
    },
    setDefaultSelectedIssueM3: (
      state,
      action: PayloadAction<
        Pick<IssuePreference, "selectedIssue"> & {
          region: string;
        }
      >
    ) => {
      switch (action.payload.region) {
        case SUBSCRIBED_REGION.IRAN_REGION:
          state.defaultSelectedIssueM3 = action.payload.selectedIssue;

          break;
        case SUBSCRIBED_REGION.TAIWAN_REGION:
          state.defaultSelectedTaiwanIssue = action.payload.selectedIssue;

          break;

        default:
          break;
      }
    },
  },
});

export const {
  setIssues,
  setPreferredLanguage,
  setSelectedIssue,
  setIssuesM3,
  setSelectedIssueM3,
  setDefaultSelectedIssueM3,
  setPreferredRegion,
} = Preference.actions;
export const PreferenceReducer = Preference.reducer;
