import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { RightStyledToolTip } from "./StyledToolTip";

export type IconPositionProps =
  | {
      icon?: never;
      iconPosition?: never;
    }
  | {
      icon: React.ReactNode;
      iconPosition: "Leading" | "Trailing";
    };

type Props = {
  text: string;
  description?: string;
  cssClasses?: string;
} & IconPositionProps;

export const SectionSubTitle = ({
  text,
  icon,
  iconPosition,
  description,
  cssClasses,
}: Props) => {
  return (
    <Row className={`${cssClasses ? cssClasses : ""}`}>
      <Col className="d-flex align-items-center">
        <StyledTitle>{text}</StyledTitle>

        {icon &&
          iconPosition === "Trailing" &&
          (description ? (
            <>
              <div
                style={{ marginLeft: "10px" }}
                id={`tooltop-anchor-${text.replaceAll(" ", "-")}`}
              >
                {icon}
              </div>
              <RightStyledToolTip
                place="right"
                anchorSelect={`#tooltop-anchor-${text.replaceAll(" ", "-")}`}
                html={description}
              ></RightStyledToolTip>
            </>
          ) : (
            <div style={{ marginLeft: "10px" }}>{icon}</div>
          ))}
      </Col>
    </Row>
  );
};

const StyledTitle = styled.div`
  font-size: 16px;
  line-height: 36px;
  font-weight: bold;
  color: #475569;
  letter-spacing: 1px;
`;
