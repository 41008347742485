import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface Query {
  query?: string;
  date?: string;
}

const DEFAULT_INITIAL_STATE: Query = {
  query: "",
  date: "",
};

export const SearchScreenRedux = createSlice({
  name: "searchScreenFilters",
  initialState: { ...DEFAULT_INITIAL_STATE },
  reducers: {
    setSearchScreenFilter: (
      state,
      action: PayloadAction<{
        query: string;
        date: string;
      }>
    ) => {
      state.query = action.payload.query;
      state.date = action.payload.date;
    },
    resetSearchScreenFilters: (state) => {
      return { ...DEFAULT_INITIAL_STATE };
    },
  },
});

export const { resetSearchScreenFilters, setSearchScreenFilter } =
  SearchScreenRedux.actions;

export const SearchScreenReducer = SearchScreenRedux.reducer;
