import { Circle } from "@styled-icons/material";
import { ApexOptions } from "apexcharts";
import React from "react";
import ReactApexChart from "react-apexcharts";
import styled from "styled-components";

const BAR_COLORS = ["#38DC2A", "#D63C3C"];

interface Props {
  series: Array<number>;
  labels: Array<string>;
  chartHeight?: number;
}

export const RadialChart = ({ series, labels, chartHeight }: Props) => {
  const chartRef = React.useRef(null);
  const options: ApexOptions = React.useMemo(() => {
    return {
      series,
      labels,
      stroke: {
        curve: "smooth",
        lineCap: "round",
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            show: false,
          },
        },
      },
      options: {
        chart: {
          height: chartHeight ?? "370px",
          type: "radialBar",
        },
      },
      colors: BAR_COLORS,
    };
  }, [chartHeight, labels, series]);

  return (
    <div className="d-flex align-items-center justify-content-center w-100">
      <div className="me-5">
        <ReactApexChart
          options={options}
          type="radialBar"
          height={chartHeight ?? "370px"}
          series={series}
          ref={chartRef}
        />
      </div>
      <div>
        <div>
          <div className="d-flex">
            <Indicator
              size={8}
              color={BAR_COLORS[0]}
              className="ms-0 me-2 ps-0 mt-2"
            />
            <div>
              <Label>{labels[0]}</Label>
              <Percentage>{series[0]}%</Percentage>
            </div>
          </div>
        </div>
        <div>
          <div className="d-flex">
            <Indicator
              size={8}
              color={BAR_COLORS[1]}
              className="ms-0 me-2 ps-0 mt-2"
            />
            <div>
              <Label>{labels[1]}</Label>
              <Percentage>{series[1]}%</Percentage>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Indicator = styled(Circle)<{ color: string }>`
  color: ${(p) => p.color};
  margin-right: 12px;
  margin-left: 12px;
`;

const Label = styled.div`
  color: #94a3b8;
  font-size: 12px;
  line-height: 20px;
`;

const Percentage = styled.div`
  color: #475569;
  font-size: 16px;
  line-height: 24px;
`;
