import { ReactGA } from "../components/GoogleAnalytics";

type GA_Data = {
  category: string;
  action: string;
  includeUserDetail: boolean;
  label?: string;
};

export const SendGAEvent = ({
  category,
  action,
  label = "",
  includeUserDetail,
}: GA_Data) => {
  if (
    !["sotos.quantumpolitics.ai", "sotos.persumedia.com"].includes(
      window.location.host
    )
  ) {
    return;
  }
  const userDetail = localStorage.getItem("user");
  const parsedUserDetail = userDetail && JSON.parse(userDetail);
  const formattedUserName =
    parsedUserDetail &&
    `${parsedUserDetail.email
      .replaceAll("@", "-at-")
      .replaceAll(".", "-dot-")},`;

  ReactGA.event({
    category,
    action,
    label:
      includeUserDetail && formattedUserName
        ? formattedUserName + label
        : label,
  });
};
