import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router";
import { TrendingHashtag } from "./MoversAndShapers";
import { setSearchScreenFilter } from "../../store/slice/search";
import styled from "styled-components";
import { SendGAEvent } from "../../helpers/googleAnalyticsHepler";
import { RootState } from "../../store";

interface Props {
  trendingHashtags: Array<TrendingHashtag>;
}

export const TrendingHashtags = ({ trendingHashtags }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { preferredRegion } = useSelector(
    (state: RootState) => state.preference
  );

  const handleHashtagClick = (hashtag: string) => {
    SendGAEvent({
      category: "SOTOS_Emerging_Issue_Trending_Hashtag_Click",
      action: "SOTOS_Emerging_Issue_Trending_Hashtag_Click",
      includeUserDetail: true,
      label: `hashtag=${hashtag}`,
    });
    dispatch(
      setSearchScreenFilter({
        query: hashtag,
        date: "90",
      })
    );
    window.scrollTo({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      navigate(`/${preferredRegion.toLowerCase()}/discover`);
    }, 500);
  };
  if (!trendingHashtags.length) {
    return null;
  }

  return (
    <Card style={{ borderRadius: 16 }}>
      <Card.Body>
        <Card.Title
          className="px-1"
          style={{
            color: "#475569",
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          Related Hashtags
        </Card.Title>
        {trendingHashtags.map((hashtag) => (
          <HashtagContainer
            key={`${hashtag.single_hashtag}-${hashtag.hashtag_count}`}
            onClick={() => {
              handleHashtagClick(`#${hashtag.single_hashtag.trim()}`);
            }}
            style={{ cursor: "pointer" }}
          >
            #{hashtag.single_hashtag.trim()}
          </HashtagContainer>
        ))}
      </Card.Body>
    </Card>
  );
};

const HashtagContainer = styled.div`
  display: inline-block;
  margin: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 30px;
  word-break: break-all;
`;
