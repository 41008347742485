import { SUBSCRIBED_REGION } from "../constants";

type Option = { value: string; label: string };
interface FilterParams {
  social_media?: Option;
  source_language?: Option;
  region?: Option;
  date?: Option;
  issue?: Option | Array<Option>;
}
export const getRedisCacheKey = (
  params: FilterParams,
  preferredRegion = SUBSCRIBED_REGION.IRAN_REGION
) => {
  let cacheKey = undefined;

  const issueParams: Array<string> = [];
  if (Array.isArray(params.issue)) {
    params.issue?.forEach((issue) => {
      if (issue.value) {
        issueParams.push(issue.value);
      }
    });
  } else {
    if (params.issue?.value) {
      issueParams.push(params.issue.value);
    }
  }

  if (
    !params.source_language?.value &&
    !params.region?.value &&
    !issueParams.length
  ) {
    switch (params.date?.value) {
      case "30":
        cacheKey = `${params.social_media?.value.toLocaleLowerCase()}_thirty_days_all_filters`;
        break;

      case "60":
        cacheKey = `${params.social_media?.value.toLocaleLowerCase()}_sixty_days_all_filters`;
        break;

      case "90":
        cacheKey = `${params.social_media?.value.toLocaleLowerCase()}_ninety_days_all_filters`;
        break;

      default:
        break;
    }
  }

  if (preferredRegion === SUBSCRIBED_REGION.TAIWAN_REGION) {
    cacheKey += `_${preferredRegion.toLowerCase()}`;
  }
  return cacheKey;
};
