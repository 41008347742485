import React from "react";

export const EmptyChartMessage = () => {
  return (
    <div
      style={{
        height: 320,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <div style={{ fontSize: 23 }}>There is no data to display</div>
    </div>
  );
};
