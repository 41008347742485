import * as React from "react";
import { ProgressBar } from "react-bootstrap";
import styled from "styled-components";

const LeftPoliticalAffiliationKeyword = "Left";
const CenterPoliticalAffiliationKeyword = "Center";
const RightPoliticalAffiliationKeyword = "Right";

export const PoliticalAffiliationBars = React.memo(
  ({
    politicalAffiliation,
    width,
    showPercentage,
  }: {
    politicalAffiliation: string;
    width: number;
    showPercentage: boolean;
  }) => {
    const totalPoliticalAffiliationSourceCount =
      politicalAffiliation.split(",").length;
    const LeftPoilitcalAffiliation = React.useMemo(() => {
      if (!politicalAffiliation.includes(LeftPoliticalAffiliationKeyword)) {
        return 0;
      }
      return Math.ceil(
        ((politicalAffiliation.split(LeftPoliticalAffiliationKeyword).length -
          1) /
          totalPoliticalAffiliationSourceCount) *
          100
      );
    }, [politicalAffiliation, totalPoliticalAffiliationSourceCount]);

    const CenterPoliticalAffiliation = React.useMemo(() => {
      if (!politicalAffiliation.includes(CenterPoliticalAffiliationKeyword)) {
        return 0;
      }
      return Math.ceil(
        ((politicalAffiliation.split(CenterPoliticalAffiliationKeyword).length -
          1) /
          totalPoliticalAffiliationSourceCount) *
          100
      );
    }, [politicalAffiliation, totalPoliticalAffiliationSourceCount]);

    const RightPoliticalAffiliation = React.useMemo(() => {
      if (!politicalAffiliation.includes(RightPoliticalAffiliationKeyword)) {
        return 0;
      }
      return Math.ceil(
        ((politicalAffiliation.split(RightPoliticalAffiliationKeyword).length -
          1) /
          totalPoliticalAffiliationSourceCount) *
          100
      );
    }, [politicalAffiliation, totalPoliticalAffiliationSourceCount]);

    return (
      <div style={{ width: width }} className="d-flex">
        <div
          style={{ width: width / 3 }}
          className="pe-3 me-3 border-2 border-end"
        >
          <div className="d-flex align-items-center justify-content-between mb-1">
            <BarNameLabel>Left</BarNameLabel>
            {showPercentage && (
              <BarPercentageLabel>
                {LeftPoilitcalAffiliation}%
              </BarPercentageLabel>
            )}
          </div>
          <StyledProgressBar variant="danger" now={LeftPoilitcalAffiliation} />
        </div>
        <div style={{ width: width / 3 }} className="pe-3 border-2 border-end">
          <div className="d-flex align-items-center justify-content-between mb-1">
            <BarNameLabel>Center</BarNameLabel>
            {showPercentage && (
              <BarPercentageLabel>
                {CenterPoliticalAffiliation}%
              </BarPercentageLabel>
            )}
          </div>
          <StyledProgressBar now={CenterPoliticalAffiliation} />
        </div>
        <div style={{ width: width / 3 }} className="ps-3">
          <div className="d-flex align-items-center justify-content-between mb-1">
            <BarNameLabel>Right</BarNameLabel>
            {showPercentage && (
              <BarPercentageLabel>
                {RightPoliticalAffiliation}%
              </BarPercentageLabel>
            )}
          </div>
          <StyledProgressBar
            variant="success"
            now={RightPoliticalAffiliation}
          />
        </div>
      </div>
    );
  }
);

const StyledProgressBar = styled(ProgressBar)`
  height: 7px;
`;

const BarNameLabel = styled.div`
  color: #94a3b8;
  font-size: inherit;
`;

const BarPercentageLabel = styled.div`
  color: #475569;
  font-size: 12px;
`;
