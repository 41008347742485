import React from "react";
import axios, { AxiosResponse } from "axios";
import { Card, Row, Tab } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from "luxon";
import { axiosInstance } from "../../api/index";
import { FilterTypes, FilterOperations } from "../../types/filterTypes";
import {
  DEFAULT_END_DATE,
  DEFAULT_POLITICAL_AFFILIATION,
  DEFAULT_START_DATE,
  PIE_CHART_COLOUR_PALLETE,
  POLITICAL_AFFILIATION,
  SUBSCRIBED_REGION,
  V2_THEME_BLACK,
} from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setArticlesFilterValues } from "../../store/slice/filters";
import { RootState } from "../../store";
import { TopIssueTileMeta } from "../../types/types";
import { StyledTabs } from "../../components/StyledTab";
import { TileListing } from "./TileListing";
import styled from "styled-components";
import { ArticleIcon } from "../../components/TabIcons";
import { ProgressBarStack } from "./ProgressBarStack";
import { setSelectedIssueM3 } from "../../store/slice/issuePreference";
import { SendGAEvent } from "../../helpers/googleAnalyticsHepler";

export const DigitalMediaCoverage = () => {
  const { preferredRegion } = useSelector(
    (state: RootState) => state.preference
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { articles: reduxArticleFilter } = useSelector(
    (state: RootState) => state.reduxFilter
  );

  const [digitalMediaData, setDigitalMediaData] = React.useState<{
    all_affiliation: Array<TopIssueTileMeta>;
    Left: Array<TopIssueTileMeta>;
    Center: Array<TopIssueTileMeta>;
    Right: Array<TopIssueTileMeta>;
  } | null>(null);

  const refinedParams: {
    model: string;
    subscribed_region: string;
    filters: {
      political_affiliation?: {
        value: string;
        type: string;
      };
      date: {
        operator: string;
        conditions: Array<{
          value: string;
          type: string;
        }>;
      };
      source_language: {
        value: string;
        type: string;
      };
    };
  } = React.useMemo(() => {
    setDigitalMediaData(null);
    return {
      model: "M3",
      subscribed_region: preferredRegion,
      filters: {
        political_affiliation: undefined,
        source_language: {
          value: reduxArticleFilter.source_language.value,
          type: FilterTypes.eq,
        },
        date: {
          operator: FilterOperations.AND,
          conditions: [
            {
              value: reduxArticleFilter.date.startDate
                ? DateTime.fromJSDate(
                    DateTime.fromMillis(
                      reduxArticleFilter.date.startDate
                    ).toJSDate()
                  ).toFormat("yyyy-LL-dd")
                : DateTime.fromJSDate(
                    DateTime.fromMillis(DEFAULT_START_DATE).toJSDate()
                  ).toFormat("yyyy-LL-dd"),
              type: FilterTypes.gte,
            },
            {
              value: reduxArticleFilter.date.endDate
                ? DateTime.fromJSDate(
                    DateTime.fromMillis(
                      reduxArticleFilter.date.endDate
                    ).toJSDate()
                  ).toFormat("yyyy-LL-dd")
                : DateTime.fromJSDate(
                    DateTime.fromMillis(DEFAULT_END_DATE).toJSDate()
                  ).toFormat("yyyy-LL-dd"),
              type: FilterTypes.lte,
            },
          ],
        },
      },
    };
  }, [
    preferredRegion,
    reduxArticleFilter.source_language.value,
    reduxArticleFilter.date.startDate,
    reduxArticleFilter.date.endDate,
  ]);

  React.useEffect(() => {
    axios
      .all(
        ["", ...POLITICAL_AFFILIATION].map((political_affiliation) => {
          const newRefinedParams = { ...refinedParams };
          newRefinedParams.filters["political_affiliation"] =
            political_affiliation
              ? {
                  value: political_affiliation,
                  type: FilterTypes.eq,
                }
              : undefined;
          return axiosInstance.post("insights/digital-media-coverage", {
            params: refinedParams,
          });
        })
      )
      .then(
        (
          res: Array<
            AxiosResponse<{
              pieChartData: Array<{
                label: string;
                issue_count: number;
                overall_percentage: number;
              }>;
            }>
          >
        ) => {
          /* --- Calculate additional percentage --- */
          const allTotalPercentage = res[0].data.pieChartData
            .slice(0, 6)
            .reduce((acc, current) => {
              return current.overall_percentage + acc;
            }, 0);

          const allEachAdditionalPercentage = (100 - allTotalPercentage) / 6;

          /* --- Calculate left percentage ---  */
          const leftTotalPercentage = res[1].data.pieChartData
            .slice(0, 6)
            .reduce((acc, current) => {
              return current.overall_percentage + acc;
            }, 0);

          const leftEachAdditionalPercentage = (100 - leftTotalPercentage) / 6;

          /* --- Calculate center percentage --- */
          const centerTotalPercentage = res[2].data.pieChartData
            .slice(0, 6)
            .reduce((acc, current) => {
              return current.overall_percentage + acc;
            }, 0);

          const centerEachAdditionalPercentage =
            (100 - centerTotalPercentage) / 6;

          /* --- Calculate Right percentage --- */
          const rightTotalPercentage = res[3].data.pieChartData
            .slice(0, 6)
            .reduce((acc, current) => {
              return current.overall_percentage + acc;
            }, 0);

          const rightEachAdditionalPercentage =
            (100 - rightTotalPercentage) / 6;
          const formattedResponse = {
            all_affiliation: res[0].data.pieChartData
              .slice(0, 6)
              .map((allAffiliationRec, index) => {
                return {
                  issueName: allAffiliationRec.label,
                  indicatorColor: PIE_CHART_COLOUR_PALLETE[index]
                    ? PIE_CHART_COLOUR_PALLETE[index]
                    : PIE_CHART_COLOUR_PALLETE[0],
                  percentage: allAffiliationRec.overall_percentage,
                  progressBarPercentage:
                    allAffiliationRec.overall_percentage +
                    allEachAdditionalPercentage,
                  recordCount: allAffiliationRec.issue_count,
                };
              }),
            Left: res[1].data.pieChartData
              .slice(0, 6)
              .map((leftAffiliationRec, index) => {
                return {
                  issueName: leftAffiliationRec.label,

                  indicatorColor: PIE_CHART_COLOUR_PALLETE[index]
                    ? PIE_CHART_COLOUR_PALLETE[index]
                    : PIE_CHART_COLOUR_PALLETE[0],
                  progressBarPercentage:
                    leftAffiliationRec.overall_percentage +
                    leftEachAdditionalPercentage,
                  percentage: leftAffiliationRec.overall_percentage,
                  recordCount: leftAffiliationRec.issue_count,
                };
              }),
            Center: res[2].data.pieChartData
              .slice(0, 6)
              .map((centerAffiliationRec, index) => {
                return {
                  issueName: centerAffiliationRec.label,

                  indicatorColor: PIE_CHART_COLOUR_PALLETE[index]
                    ? PIE_CHART_COLOUR_PALLETE[index]
                    : PIE_CHART_COLOUR_PALLETE[0],
                  percentage: centerAffiliationRec.overall_percentage,
                  progressBarPercentage:
                    centerAffiliationRec.overall_percentage +
                    centerEachAdditionalPercentage,
                  recordCount: centerAffiliationRec.issue_count,
                };
              }),
            Right: res[3].data.pieChartData
              .slice(0, 6)
              .map((rightAffiliationRec, index) => {
                return {
                  issueName: rightAffiliationRec.label,

                  indicatorColor: PIE_CHART_COLOUR_PALLETE[index]
                    ? PIE_CHART_COLOUR_PALLETE[index]
                    : PIE_CHART_COLOUR_PALLETE[0],
                  percentage: rightAffiliationRec.overall_percentage,
                  progressBarPercentage:
                    rightAffiliationRec.overall_percentage +
                    rightEachAdditionalPercentage,
                  recordCount: rightAffiliationRec.issue_count,
                };
              }),
          };
          setDigitalMediaData(formattedResponse);
        }
      );
  }, [refinedParams]);

  const onChangeTab = (tabName: string) => {
    SendGAEvent({
      category: "SOTOS_Insights_Digital_PA_Change",
      action: "SOTOS_Insights_Digital_PA_Change",
      label: `PA-${tabName}`,
      includeUserDetail: true,
    });
    dispatch(
      setArticlesFilterValues({
        ...reduxArticleFilter,
        political_affiliation: tabName
          ? {
              label: tabName,
              value: tabName,
            }
          : { ...DEFAULT_POLITICAL_AFFILIATION },
      })
    );
  };

  const handleTileClick = React.useCallback(
    (tileMeta: TopIssueTileMeta) => {
      SendGAEvent({
        category: "SOTOS_Insights_Digital_Chart_Issue_Clicked",
        action: "SOTOS_Insights_Digital_Chart_Issue_Clicked",
        label: `Issue-${tileMeta.issueName}`,
        includeUserDetail: true,
      });
      dispatch(
        setSelectedIssueM3({
          selectedIssue: tileMeta.issueName,
          selectedTab: "Article",
          region: preferredRegion,
        })
      );
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        navigate(`/${preferredRegion.toLowerCase()}/issue-watch`);
      }, 100);
    },
    [dispatch, navigate, preferredRegion]
  );

  return (
    <>
      <Card style={{ borderRadius: 24 }}>
        <StyledCardHeader>
          <StyledCardTitle className="d-flex align-items-center">
            <div
              style={{ backgroundColor: "#F1F4F9" }}
              className="p-2 rounded-circle"
            >
              <ArticleIcon isActive={false} color={V2_THEME_BLACK} />
            </div>
            <div className="ps-2">Digital Media Issues</div>
          </StyledCardTitle>
        </StyledCardHeader>
        <Card.Body className="pt-0">
          {digitalMediaData ? (
            <>
              <Row>
                <StyledTabs
                  defaultActiveKey={
                    reduxArticleFilter.political_affiliation.value
                  }
                  onSelect={(tab: any, event: any) => {
                    onChangeTab(tab);
                  }}
                  noBgOnActive={true}
                >
                  <Tab
                    eventKey={""}
                    title={`All Affiliations`}
                    color="#94a3b8"
                    style={{ minHeight: 340 }}
                  >
                    <div className="d-flex pt-3">
                      <ProgressBarStack
                        affiliation={digitalMediaData.all_affiliation}
                      />
                    </div>
                    <TileListing
                      affiliation={digitalMediaData.all_affiliation}
                      onClickTile={handleTileClick}
                    />
                  </Tab>
                  <Tab
                    eventKey={"Left"}
                    title={`Left`}
                    color="#94a3b8"
                    style={{ minHeight: 340 }}
                  >
                    <div className="d-flex pt-3">
                      <ProgressBarStack affiliation={digitalMediaData.Left} />
                    </div>
                    <TileListing
                      affiliation={digitalMediaData.Left}
                      onClickTile={handleTileClick}
                    />
                  </Tab>
                  <Tab
                    eventKey={"Center"}
                    title={`Center`}
                    color="#94a3b8"
                    style={{ minHeight: 340 }}
                  >
                    <div className="d-flex pt-3">
                      <ProgressBarStack affiliation={digitalMediaData.Center} />
                    </div>
                    <TileListing
                      affiliation={digitalMediaData.Center}
                      onClickTile={handleTileClick}
                    />
                  </Tab>
                  <Tab
                    eventKey={"Right"}
                    title={`Right`}
                    color="#94a3b8"
                    style={{ minHeight: 340 }}
                  >
                    <div className="d-flex pt-3">
                      <ProgressBarStack affiliation={digitalMediaData.Right} />
                    </div>
                    <TileListing
                      affiliation={digitalMediaData.Right}
                      onClickTile={handleTileClick}
                    />
                  </Tab>
                </StyledTabs>
              </Row>
            </>
          ) : (
            <div
              style={{ minHeight: 388 }}
              className="d-flex align-items-center justify-content-center"
            >
              <div>Loading...</div>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export const StyledCardHeader = styled(Card.Header)`
  background-color: transparent;
  border-bottom: 0;
  padding-top: 29px;
  color: ${V2_THEME_BLACK};
  padding-bottom: 0;
`;

export const StyledCardTitle = styled(Card.Title)`
  font-size: 16px !important;
  font-weight: bold;
`;
