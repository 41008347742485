import * as React from "react";
import { Card, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { PageHeading } from "../../components/PageHeading";
import { THEME_COLOUR_PRIMARY } from "../../constants";
import { ChangePasswordContent } from "./ChangePasswordContent";
import { ProfileTabContent } from "./ProfileTabContent";

type TAB = "profile" | "change-password";

export const Profile = () => {
  const [activeTab, setActiveTab] = React.useState<TAB>("profile");

  const onClickSidebarItem = (name: TAB) => {
    setActiveTab(name);
  };

  return (
    <>
      <StyledPageTitle>
        <PageHeading title="My Profile" />
      </StyledPageTitle>

      <Card>
        <Card.Body className="p-0">
          <Row>
            <Col
              xl={3}
              lg={3}
              md={5}
              sm={12}
              xs={12}
              style={{ borderRight: "1px solid #CCC" }}
              className="pt-3 pe-0"
            >
              <StyledSidebarItem
                onClick={() => onClickSidebarItem("profile")}
                isActive={activeTab === "profile"}
              >
                Profile Information
              </StyledSidebarItem>
              <StyledSidebarItem
                isActive={activeTab === "change-password"}
                onClick={() => onClickSidebarItem("change-password")}
              >
                Change Password
              </StyledSidebarItem>
            </Col>
            <Col xl={9} lg={9} md={7} sm={12} xs={12}>
              {activeTab === "profile" ? (
                <ProfileTabContent />
              ) : (
                <ChangePasswordContent />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

const StyledPageTitle = styled.div`
  margin-top: 3rem;
  margin-bottom: 1.5rem;
`;

const StyledSidebarItem = styled("div").withConfig({
  shouldForwardProp: (prop) => ["isActive"].includes(prop) === false,
})<{ isActive: boolean }>(({ isActive }) => ({
  cursor: "pointer",
  backgroundColor: isActive ? "#F0F5FF" : "transparent",
  color: isActive ? `${THEME_COLOUR_PRIMARY}` : "#475569",
  margin: "0 2px",
  fontSize: "18px",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  paddingLeft: "2rem",
  borderRight: isActive ? `${THEME_COLOUR_PRIMARY}` : "none",
  borderStyle: isActive ? "solid" : "none",
  borderWidth: isActive ? 5 : "0",
  borderTop: "none",
  borderLeft: "none",
  borderBottom: "none",
}));
