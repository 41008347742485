import { AxiosResponse } from "axios";
import React from "react";
import { useSelector } from "react-redux";
import { Params } from ".";
import axiosInstance from "../../api";
import { Loader } from "../../components/Loader";
import {
  CHINESE_SIMPLIFIED,
  CHINESE_TRADITIONAL,
  SUBSCRIBED_REGION,
  V2_THEME_BLACK,
} from "../../constants";
import { RootState } from "../../store";
import { FilterTypes } from "../../types/filterTypes";
import { AreaChartOption } from "./areaChartOptions";
import { Card, Col, Spinner } from "react-bootstrap";
import { StyledCardTitle } from "../Insights-m3-v2/DigitalMediaCoverage";
import { TwitterIcon } from "../../components/TabIcons";
import { utils, writeFileXLSX } from "xlsx";
import {
  DownloadButtonWapper,
  StyledMonthlyTrend,
} from "../Insights-m3-v2/OpinionAI";
import { FileDownload } from "@styled-icons/material-outlined/FileDownload";
//import html2canvas from "html2canvas";
import { triggerDownload } from "../../helpers";
import { toJpeg } from "html-to-image";

interface Props {
  filters: { filters: Params };
}

interface ChartData {
  data: Array<{ name: string; data: Array<number> }>;
  categories: Array<string>;
}

export const SocialTab = ({ filters }: Props) => {
  const { preferredRegion } = useSelector(
    (state: RootState) => state.preference
  );
  const chartRef = React.useRef<any>(null);
  const [loader, setLoader] = React.useState(false);
  const htmlElementRef = React.useRef(null);
  const [showDownloadLoader, setShowDownloadLoader] = React.useState<{
    jpeg: boolean;
    excel: boolean;
  }>({
    jpeg: false,
    excel: false,
  });
  const [monthlyData, setMonthlyData] = React.useState<
    | {
        data: Array<{ name: string; data: Array<number> }>;
        categories: Array<string>;
      }
    | undefined
  >(undefined);

  const csvData = React.useRef<
    Array<{
      Date: string;
      Optimism: number;
      Pessimism: number;
    }>
  >([]);

  const getOpinionAIMonthlyData = React.useCallback(() => {
    setLoader(true);
    const filtersParams = filters.filters;
    const filtersCopy = {
      ...filtersParams,
      tweet_language: {
        type: FilterTypes.eq,
        value:
          preferredRegion === SUBSCRIBED_REGION.TAIWAN_REGION
            ? CHINESE_TRADITIONAL
            : preferredRegion === SUBSCRIBED_REGION.CHINA_REGION
            ? CHINESE_SIMPLIFIED
            : "FA",
      },
    };

    axiosInstance
      .post("/m3/opinionAI/social/get-monthly-data", {
        params: { filters: filtersCopy, subscribed_region: preferredRegion },
      })
      .then((res: AxiosResponse<ChartData>) => {
        setMonthlyData({
          categories: res.data.categories,
          data: res.data.data,
        });

        csvData.current = res.data.categories.map((category, index) => {
          return {
            Date: category,
            Optimism: res.data.data[0].data[index],
            Pessimism: res.data.data[1].data[index],
          };
        });
      })
      .catch((error) => {})
      .finally(() => {
        setLoader(false);
      });
  }, [filters, preferredRegion]);

  React.useEffect(() => {
    if (!filters.filters.issue) {
      return;
    }
    getOpinionAIMonthlyData();
  }, [filters.filters.issue, getOpinionAIMonthlyData]);

  const onClickCSVDownload = () => {
    setShowDownloadLoader({
      jpeg: false,
      excel: true,
    });
    const ws = utils.json_to_sheet(csvData.current, {
      header: ["Date", "Optimism", "Pessimism"],
    });
    const wb = utils.book_new();
    utils.book_append_sheet(
      wb,
      ws,
      filters.filters.issue?.value ?? `Opinion AI Monthly View`
    );
    writeFileXLSX(
      wb,
      `Opinion_AI_Social_Monthly_Trend_${
        filters.filters.issue?.value.replaceAll(" ", "_") ?? ""
      }.xlsx`
    );
    setTimeout(() => {
      setShowDownloadLoader({
        jpeg: false,
        excel: false,
      });
    }, 1000);
  };

  /* const onClickJPEGDownload = () => {
    if (htmlElementRef.current) {
      setShowDownloadLoader({
        jpeg: true,
        excel: false,
      });
      html2canvas(htmlElementRef.current, {
        height: 700,
        width: 600,
        x: -25,
        y: -25,
      })
        .then((canvas: HTMLCanvasElement) => {
          if (canvas) {
            const ctx = canvas.getContext("2d");
            if (ctx) {
              const brandLogo = new Image();
              brandLogo.src = require("../../assets/retailer_stite_logo.png");
              brandLogo.height = 40;
              brandLogo.width = 40;
              brandLogo.addEventListener("load", () => {
                ctx.resetTransform();
                ctx.font = "bold 48px serif";
                ctx.fillStyle = "#CCC";
                ctx.drawImage(brandLogo, canvas.width - 100, 40, 44, 44);
                ctx.globalAlpha = 0.2;
                ctx.save();
                triggerDownload(
                  canvas.toDataURL("image/jpeg", 1.0),
                  `Social_Media_Monthly_Trend_${filters.filters.issue?.value.replaceAll(
                    " ",
                    "_"
                  )}`,
                  ".jpeg"
                );
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setShowDownloadLoader({
            jpeg: false,
            excel: false,
          });
        });
    }
  }; */

  const newDownloadable = () => {
    setShowDownloadLoader({
      jpeg: true,
      excel: false,
    });
    setTimeout(() => {
      if (htmlElementRef.current) {
        toJpeg(htmlElementRef.current, {
          backgroundColor: "#FFF",
          width: 600,
          height: 700,
          style: {
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          filter: (node) => {
            const classList = ["data-html2canvas-ignore"];
            return !classList.some((clsname) =>
              node.classList?.contains(clsname)
            );
          },
        })
          .then((dataUrl) => {
            triggerDownload(
              dataUrl,
              `Social_Media_Monthly_Trend_${filters.filters.issue?.value.replaceAll(
                " ",
                "_"
              )}`,
              ".jpeg"
            );
          })
          .finally(() => {
            setShowDownloadLoader({
              jpeg: false,
              excel: false,
            });
          });
      }
    }, 500);
  };

  if (loader || !monthlyData) {
    return <Loader />;
  }

  return (
    <Col xl={6} lg={6} md={12} sm={12} ref={htmlElementRef}>
      <Card style={{ borderRadius: 24 }}>
        <Card.Body className="px-0 pt-0">
          <StyledCardTitle className="d-flex align-items-center mt-3 ms-3 justify-content-between">
            <div className="d-flex align-items-center">
              <div
                style={{ backgroundColor: "#F1F4F9" }}
                className="p-2 rounded-circle"
              >
                <TwitterIcon isActive={false} color={V2_THEME_BLACK} />
              </div>
              <div className="ps-2" style={{ color: V2_THEME_BLACK }}>
                Social Media (Twitter)
              </div>
            </div>
            <div
              className="d-flex data-html2canvas-ignore"
              data-html2canvas-ignore
            >
              {showDownloadLoader.jpeg ? (
                <span>
                  <Spinner variant="success" />
                </span>
              ) : (
                <span onClick={newDownloadable} title="Download this chart">
                  <DownloadButtonWapper
                    className="ms-3"
                    style={{ cursor: "pointer" }}
                  >
                    <div>
                      <StyledMonthlyTrend>
                        <FileDownload size={20} />
                        <span className="d-inline-block ps-1">JPEG</span>
                      </StyledMonthlyTrend>
                    </div>
                  </DownloadButtonWapper>
                </span>
              )}

              {showDownloadLoader.excel ? (
                <span className="mx-3">
                  <Spinner variant="success" />
                </span>
              ) : (
                <span className="pe-3" onClick={onClickCSVDownload}>
                  <DownloadButtonWapper
                    className="ms-3"
                    style={{ cursor: "pointer" }}
                  >
                    <div>
                      <StyledMonthlyTrend>
                        <FileDownload size={20} />
                        <span className="d-inline-block ps-1">Excel</span>
                      </StyledMonthlyTrend>
                    </div>
                  </DownloadButtonWapper>
                </span>
              )}
            </div>
          </StyledCardTitle>

          <div className="mt-5">
            <AreaChartOption
              series1={monthlyData.data[0]}
              series2={monthlyData.data[1]}
              categories={monthlyData.categories}
              xAxisTitle="Month"
              yAxisTitle="Percentage change in opinions"
              ref={chartRef}
            />
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};
