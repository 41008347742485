import axios, { AxiosResponse } from "axios";
import React from "react";
import { Card, Col, Modal, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ActionMeta, SingleValue } from "react-select";
import styled from "styled-components";
import axiosInstance from "../../api";
import { SectionSubTitle } from "../../components/SectionSubTitle";
import { ArticleIcon, TwitterIcon } from "../../components/TabIcons";
import {
  CHINESE_SIMPLIFIED,
  CHINESE_TRADITIONAL,
  SUBSCRIBED_REGION,
  V2_THEME_BLACK,
} from "../../constants";
import { getLastTwelveMonthNames, triggerDownload } from "../../helpers";
import { RootState } from "../../store";
import { FilterTypes } from "../../types/filterTypes";
import { DropdownOption } from "../../types/types";
import { ModifiedReactSelectV2 } from "../../components/ModifiedReactSelect";
import { Params } from "../opinion-ai-m3";
import { DigitalTab } from "../opinion-ai-m3/DigitalTab";
import { SocialTab } from "../opinion-ai-m3/SocialTab";
import { RadialChart } from "./RadialChart";
import { ShowChart } from "@styled-icons/material-outlined";
import { StyledCardHeader, StyledCardTitle } from "./DigitalMediaCoverage";
import { Loader } from "../../components/Loader";
import { SendGAEvent } from "../../helpers/googleAnalyticsHepler";
import { FileDownload } from "@styled-icons/material-outlined/FileDownload";
import { toJpeg } from "html-to-image";
//import html2canvas from "html2canvas";

interface Filters {
  issue?: Array<DropdownOption>;
  date_year_month: Array<DropdownOption>;
}

interface SelectedFilters {
  issue?: DropdownOption;
  date_year_month: DropdownOption;
}

const lastTwelveMonthOptions = getLastTwelveMonthNames();

export const InsightsOpinionAI = () => {
  const { preferredRegion } = useSelector(
    (state: RootState) => state.preference
  );

  const digitalChartDownloadRef = React.useRef(null);
  const socialChartDownloadRef = React.useRef(null);

  const [showDownloadProgress, setShowDownloadProgress] = React.useState({
    digital: false,
    social: false,
  });
  const [error, showError] = React.useState("");

  const [isRequestInProgress, setIsRequestInProgress] = React.useState(false);

  const [selectedFilters, setSelectedFilters] = React.useState<
    SelectedFilters | undefined
  >({
    issue: undefined,
    date_year_month: lastTwelveMonthOptions[0],
  });

  const [showMonthlyTrend, setShowMonthlyTrend] = React.useState(false);

  const [radialChartData, setRadialChartData] = React.useState<{
    twitter: Array<number>;
    telegram: Array<number>;
  }>({
    twitter: [],
    telegram: [],
  });

  React.useEffect(() => {
    SendGAEvent({
      category: "SOTOS_Insights_Opinion_AI_Screen_Landed",
      action: "SOTOS_Insights_Opinion_AI_Screen_Landed",
      includeUserDetail: false,
    });
  }, []);

  const selectedFiltersRef = React.useRef<SelectedFilters | undefined>({
    issue: undefined,
    date_year_month: lastTwelveMonthOptions[0],
  });

  const [filterOptions, setFilterOptions] = React.useState<Filters>({
    issue: [],
    date_year_month: lastTwelveMonthOptions,
  });

  const params: { filters: Params } = React.useMemo(() => {
    return {
      subscribed_region: preferredRegion,
      filters: {
        issue: selectedFilters?.issue?.value
          ? { type: FilterTypes.eq, value: selectedFilters?.issue.value }
          : undefined,
        region: {
          type: FilterTypes.eq,
          value: [
            SUBSCRIBED_REGION.TAIWAN_REGION,
            SUBSCRIBED_REGION.CHINA_REGION,
          ].includes(preferredRegion)
            ? "EA"
            : "MENA",
        },
        country: {
          type: FilterTypes.eq,
          value:
            preferredRegion === SUBSCRIBED_REGION.TAIWAN_REGION
              ? "TW"
              : preferredRegion === SUBSCRIBED_REGION.CHINA_REGION
              ? "CN"
              : "IR",
        },
      },
    };
  }, [preferredRegion, selectedFilters?.issue?.value]);

  React.useEffect(() => {
    const endPoints = [
      "/m3/opinionAI/get-monthly-data",
      "/m3/opinionAI/social/get-monthly-data",
    ];

    const formattedSchema = endPoints.map((endPoint, index) => {
      return {
        endPoint,
        param: {
          subscribed_region: preferredRegion,
          filters: {
            issue: selectedFilters?.issue?.value
              ? { type: FilterTypes.eq, value: selectedFilters?.issue.value }
              : undefined,
            region: {
              type: FilterTypes.eq,
              value: [
                SUBSCRIBED_REGION.TAIWAN_REGION,
                SUBSCRIBED_REGION.CHINA_REGION,
              ].includes(preferredRegion)
                ? "EA"
                : "MENA",
            },
            country: {
              type: FilterTypes.eq,
              value:
                preferredRegion === SUBSCRIBED_REGION.TAIWAN_REGION
                  ? "TW"
                  : preferredRegion === SUBSCRIBED_REGION.CHINA_REGION
                  ? "CN"
                  : "IR",
            },
            date_year_month: {
              type: FilterTypes.eq,
              value: selectedFilters?.date_year_month.value,
            },
            [index === 1 ? "tweet_language" : "source_language"]: {
              type: FilterTypes.eq,
              value:
                preferredRegion === SUBSCRIBED_REGION.TAIWAN_REGION
                  ? CHINESE_TRADITIONAL
                  : preferredRegion === SUBSCRIBED_REGION.CHINA_REGION
                  ? CHINESE_SIMPLIFIED
                  : "FA",
            },
          },
        },
      };
    });

    setIsRequestInProgress(true);
    axios
      .all(
        formattedSchema.map((schema) => {
          return axiosInstance.post(schema.endPoint, {
            params: schema.param,
          });
        })
      )
      .then(
        (
          res: Array<
            AxiosResponse<{
              data: Array<{
                name: string;
                data: Array<number>;
              }>;
            }>
          >
        ) => {
          const formattedResponse: {
            twitter: Array<number>;
            telegram: Array<number>;
          } = {
            twitter: [],
            telegram: [],
          };
          res[0].data.data.forEach((twitter_rec) => {
            if (twitter_rec.data.length) {
              formattedResponse.twitter.push(twitter_rec.data[0]);
            } else {
              formattedResponse.twitter.push(0);
            }
          });

          res[1].data.data.forEach((telegram_rec) => {
            if (telegram_rec.data.length) {
              formattedResponse.telegram.push(telegram_rec.data[0]);
            } else {
              formattedResponse.telegram.push(0);
            }
          });
          setRadialChartData(formattedResponse);
        }
      )
      .catch((err) => console.log(err))
      .finally(() => {
        setIsRequestInProgress(false);
      });
  }, [
    preferredRegion,
    selectedFilters?.date_year_month.value,
    selectedFilters?.issue?.value,
  ]);

  const getOpinionAIFilters = React.useCallback(() => {
    axiosInstance
      .post("/m3/opinionAI/get-filters", {
        params: { subscribed_region: preferredRegion },
      })
      .then((res: { data: AxiosResponse<Required<Filters>> }) => {
        setFilterOptions({
          issue: [...res.data.data.issue],
          date_year_month: [...lastTwelveMonthOptions],
        });
        selectedFiltersRef.current = {
          issue: res.data.data.issue[0],
          date_year_month: lastTwelveMonthOptions[0],
        };
        setSelectedFilters({
          issue: res.data.data.issue[0],
          date_year_month: lastTwelveMonthOptions[0],
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [preferredRegion]);

  React.useEffect(() => {
    getOpinionAIFilters();
  }, [getOpinionAIFilters]);

  const onChangeFilterOption = React.useCallback(
    (newValue: SingleValue<unknown>, meta: ActionMeta<unknown>) => {
      SendGAEvent({
        category: "SOTOS_Insights_Opinion_AI_Filter_changed",
        action: "SOTOS_Insights_Opinion_AI_Filter_Changed",
        label: `issue-${selectedFiltersRef.current?.issue?.value},date-${selectedFiltersRef.current?.date_year_month.value}`,
        includeUserDetail: true,
      });
      if (selectedFiltersRef.current) {
        selectedFiltersRef.current = {
          ...selectedFiltersRef.current,
          [meta.name ?? ""]: newValue as DropdownOption,
        };
        setSelectedFilters({
          ...selectedFiltersRef.current,
          [meta.name ?? ""]: newValue as DropdownOption,
        });
      }
    },
    []
  );

  const handleMonthlyTrendClick = () => {
    setShowMonthlyTrend(true);
  };

  /* const handleChartDownload = (chartType: "Digital" | "Social") => {
    const htmlElement =
      chartType === "Digital"
        ? digitalChartDownloadRef.current
        : socialChartDownloadRef.current;
    if (htmlElement) {
      setShowDownloadProgress({
        digital: chartType === "Digital",
        social: chartType === "Social",
      });
      const YearMonthText = lastTwelveMonthOptions.find(
        (object) => object.value === selectedFilters?.date_year_month.value
      );
      const selectedIssue = selectedFilters?.issue?.value ?? "SOTOS";
      const prefix =
        chartType === "Digital" ? "Digital_Media_" : "Social_Media_";
      html2canvas(htmlElement, {
        height: 500,
        width: 700,
        x: -25,
        y: -25,
        scale: 0.5,
      })
        .then((canvas: HTMLCanvasElement) => {
          if (canvas) {
            const ctx = canvas.getContext("2d");
            if (ctx) {
              const brandLogo = new Image();
              brandLogo.src = require("../../assets/retailer_stite_logo.png");
              brandLogo.height = 40;
              brandLogo.width = 40;
              brandLogo.addEventListener("load", () => {
                ctx.resetTransform();
                ctx.font = "bold 48px serif";
                ctx.fillStyle = "#CCC";
                ctx.drawImage(brandLogo, canvas.width - 100, 50, 44, 44);
                ctx.globalAlpha = 0.2;
                ctx.save();
                triggerDownload(
                  canvas.toDataURL("image/jpeg", 1.0),
                  prefix +
                    selectedIssue.replaceAll(" ", "_") +
                    "_" +
                    YearMonthText?.label.replaceAll(" ", "_"),
                  ".jpeg"
                );
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          showError(
            "Sorry, We could not process your download at the moment!!!"
          );
        })
        .finally(() => {
          setShowDownloadProgress({
            digital: false,
            social: false,
          });
        });
    }
  }; */

  const newDownloadable = (chartType: "Digital" | "Social") => {
    const htmlElement =
      chartType === "Digital"
        ? digitalChartDownloadRef.current
        : socialChartDownloadRef.current;
    if (htmlElement) {
      setShowDownloadProgress({
        digital: chartType === "Digital",
        social: chartType === "Social",
      });
      const YearMonthText = lastTwelveMonthOptions.find(
        (object) => object.value === selectedFilters?.date_year_month.value
      );
      const selectedIssue = selectedFilters?.issue?.value ?? "SOTOS";
      const prefix =
        chartType === "Digital" ? "Digital_Media_" : "Social_Media_";
      setTimeout(() => {
        toJpeg(htmlElement, {
          backgroundColor: "#FFF",
          width: 600,
          height: 700,
          style: {
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          filter: (node) => {
            const classList = ["data-html2canvas-ignore"];
            return !classList.some((clsname) =>
              node.classList?.contains(clsname)
            );
          },
        })
          .then((dataUrl) => {
            triggerDownload(
              dataUrl,
              prefix +
                selectedIssue.replaceAll(" ", "_") +
                "_" +
                YearMonthText?.label.replaceAll(" ", "_"),
              ".jpeg"
            );
          })
          .finally(() => {
            setShowDownloadProgress({
              digital: false,
              social: false,
            });
          });
      }, 500);
    }
  };

  return (
    <>
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <SectionSubTitle text="Opinion AI" />
          <div className="d-flex my-3">
            <ModifiedReactSelectV2
              isSearchable={false}
              options={filterOptions.issue}
              name="issue"
              onChange={onChangeFilterOption}
              value={selectedFilters?.issue}
              className="pe-3"
              isDisabled={isRequestInProgress}
              inputWidth={250}
            />
            <ModifiedReactSelectV2
              isSearchable={false}
              options={filterOptions.date_year_month}
              name="date_year_month"
              onChange={onChangeFilterOption}
              value={selectedFilters?.date_year_month}
              isDisabled={isRequestInProgress}
              inputWidth={200}
            />
            <MonthlyTrendWrapper
              className="me-3 ms-3"
              style={{ cursor: "pointer" }}
              onClick={handleMonthlyTrendClick}
            >
              <div>
                <StyledMonthlyTrend>
                  <ShowChart size={20} />
                  <span className="d-inline-block ps-2">Monthly Trend</span>
                </StyledMonthlyTrend>
              </div>
            </MonthlyTrendWrapper>
          </div>
        </div>
      </div>
      {isRequestInProgress ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: 500 }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <Row>
            <Col
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              ref={digitalChartDownloadRef}
            >
              <Card style={{ borderRadius: 24 }}>
                <StyledCardHeader className="d-flex align-items-center justify-content-between">
                  <div>
                    <StyledCardTitle className="d-flex align-items-center">
                      <div
                        style={{ backgroundColor: "#F1F4F9" }}
                        className="p-2 rounded-circle"
                      >
                        <ArticleIcon color={V2_THEME_BLACK} isActive={false} />
                      </div>
                      <div className="ps-2" style={{ fontStyle: "inherit" }}>
                        Digital Media
                      </div>
                    </StyledCardTitle>
                  </div>
                  <div
                    data-html2canvas-ignore
                    className="data-html2canvas-ignore"
                  >
                    <div className="d-flex align-items-center justify-content-center">
                      {showDownloadProgress.digital ? (
                        <span>
                          <Spinner variant="success" />
                        </span>
                      ) : (
                        <span
                          onClick={() => newDownloadable("Digital")}
                          title="Download this chart"
                        >
                          <DownloadButtonWapper
                            className="ms-3"
                            style={{ cursor: "pointer" }}
                          >
                            <div>
                              <StyledMonthlyTrend>
                                <FileDownload size={20} />
                                <span className="d-inline-block ps-1">
                                  JPEG
                                </span>
                              </StyledMonthlyTrend>
                            </div>
                          </DownloadButtonWapper>
                        </span>
                      )}
                    </div>
                  </div>
                </StyledCardHeader>
                <Card.Body className="px-0 pt-0">
                  <RadialChart
                    labels={["Optimism", "Pessimism"]}
                    series={radialChartData.twitter}
                  />
                </Card.Body>
              </Card>
            </Col>

            <Col
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              ref={socialChartDownloadRef}
            >
              <Card style={{ borderRadius: 24 }}>
                <StyledCardHeader className="d-flex align-items-center justify-content-between">
                  <div>
                    <StyledCardTitle className="d-flex align-items-center">
                      <div
                        style={{ backgroundColor: "#F1F4F9" }}
                        className="p-2 rounded-circle"
                      >
                        <TwitterIcon color={V2_THEME_BLACK} isActive={false} />
                      </div>
                      <div className="ps-2" style={{ fontStyle: "inherit" }}>
                        Social Media (Twitter)
                      </div>
                    </StyledCardTitle>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-center data-html2canvas-ignore"
                    title="Capture this chart"
                  >
                    {showDownloadProgress.social ? (
                      <span>
                        <Spinner variant="success" />
                      </span>
                    ) : (
                      <span
                        onClick={() => newDownloadable("Social")}
                        title="Download this chart"
                      >
                        <DownloadButtonWapper
                          className="ms-3"
                          style={{ cursor: "pointer" }}
                        >
                          <div>
                            <StyledMonthlyTrend>
                              <FileDownload size={20} />
                              <span className="d-inline-block ps-1">JPEG</span>
                            </StyledMonthlyTrend>
                          </div>
                        </DownloadButtonWapper>
                      </span>
                    )}
                  </div>
                </StyledCardHeader>
                <Card.Body className="px-0 pt-0">
                  <RadialChart
                    series={radialChartData.telegram}
                    labels={["Optimism", "Pessimism"]}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Modal
            onHide={() => setShowMonthlyTrend(false)}
            show={showMonthlyTrend}
            size="xl"
          >
            <Modal.Header closeButton style={{ borderBottom: 0 }}>
              <Modal.Title style={{ color: "#000", fontWeight: "bold" }}>
                Opinion AI - Monthly Trend
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="mb-3">
                <Col xl={4} lg={4}>
                  <ModifiedReactSelectV2
                    isSearchable={false}
                    options={filterOptions.issue}
                    name="issue"
                    onChange={onChangeFilterOption}
                    value={selectedFilters?.issue}
                    className="pe-3"
                  />
                </Col>
              </Row>
              <Row>
                <DigitalTab filters={params} />
                <SocialTab filters={params} />
              </Row>
            </Modal.Body>
          </Modal>
        </>
      )}

      {!!error && (
        <Modal centered size="lg" onHide={() => showError("")} show={true}>
          <Modal.Header closeButton>
            <Modal.Title>OOPS!!!</Modal.Title>
          </Modal.Header>
          <Modal.Body>{error}</Modal.Body>
        </Modal>
      )}
    </>
  );
};

export const StyledMonthlyTrend = styled.div`
  color: ${V2_THEME_BLACK};
  font-size: 14px;
`;

export const MonthlyTrendWrapper = styled.div`
  border: 1px solid #ccc;
  border-radius: 24px;
  padding: 8px 16px;
`;

export const DownloadButtonWapper = styled.div`
  border: transparent;
  border-radius: 24px;
  padding: 5px 15px;
  background-color: rgb(241, 244, 249);
  font-weight: bold;
`;
