import * as React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../store";
import { Form, Row, Col, InputGroup, Button } from "react-bootstrap";
import { Visibility, VisibilityOff } from "@styled-icons/material-outlined";
import { toast, ToastContainer } from "react-toastify";
import { SITE_CONSTANTS, THEME_COLOUR_PRIMARY } from "../../constants";
import { isPasswordValid } from "../../helpers";
import axiosInstance from "../../api";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";

const DEFAULT_ERRORS = {
  currentPassword: false,
  newPassword: false,
  rePassword: false,
};

export const ChangePasswordContent = () => {
  const navigate = useNavigate();

  const { profile } = useSelector((state: RootState) => state.user);
  const [form, setForm] = React.useState<{
    currentPassword: string;
    newPassword: string;
    rePassword: string;
  }>({
    currentPassword: "",
    newPassword: "",
    rePassword: "",
  });

  const [fieldVisibility, setFieldVisibility] = React.useState({
    currentPassword: false,
    newPassword: false,
    rePassword: false,
  });

  const [formErrors, setFormErrors] = React.useState({
    ...DEFAULT_ERRORS,
  });
  const [showCancelButton, setShowCancelButton] = React.useState(false);

  const onChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    setShowCancelButton(true);
  };

  const onChangeVisibility = (
    name: "currentPassword" | "newPassword" | "rePassword"
  ) => {
    setFieldVisibility({
      ...fieldVisibility,
      [name]: !fieldVisibility[name],
    });
  };

  const validateForm = () => {
    let isFormValid = true;
    let newErrors = { ...DEFAULT_ERRORS };
    if (!form.currentPassword.trim()) {
      isFormValid = false;
      newErrors["currentPassword"] = true;
    }
    if (!form.newPassword.trim() || !isPasswordValid(form.newPassword)) {
      isFormValid = false;
      newErrors["newPassword"] = true;
    }
    if (!form.rePassword.trim() || form.rePassword !== form.newPassword) {
      isFormValid = false;
      newErrors["rePassword"] = true;
    }

    setFormErrors({ ...newErrors });
    return isFormValid;
  };

  const onSubmitForm = () => {
    if (!validateForm()) {
      return;
    }

    axiosInstance
      .post("/user/profile-update-password", {
        params: {
          email: profile?.email,
          oldPassword: form.currentPassword,
          newPassword: form.newPassword,
        },
      })
      .then(
        (
          res: AxiosResponse<{
            error: boolean;
            message: string;
          }>
        ) => {
          if (res.status === 200) {
            toast.success(res.data.message, {
              position: "top-right",
              autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
              pauseOnHover: true,
              toastId: "success-toast",
            });
          }

          if (res.status === 500) {
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
              pauseOnHover: true,
              toastId: "error-toast",
            });
          }
        }
      )
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
          pauseOnHover: true,
          toastId: "error-toast",
        });
      });
  };

  const onClickCancel = () => {
    navigate(-1);
  };

  return (
    <ContentContainer>
      <StyledTabContentTitle>Change Password</StyledTabContentTitle>
      <FormContainer>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row className="pb-3" style={{ borderBottom: "1px solid #CBD4E1" }}>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <StyledLabel>
                  <CompulsoryAsterisk>*</CompulsoryAsterisk>Current Password
                </StyledLabel>
                <InputGroup>
                  <StyledInput
                    onChange={onChangeField}
                    name="currentPassword"
                    type={fieldVisibility.currentPassword ? "text" : "password"}
                    value={form.currentPassword}
                    isInvalid={formErrors.currentPassword}
                    placeholder="Enter Current Password"
                  />
                  <EyeIcon
                    onClick={() => onChangeVisibility("currentPassword")}
                  >
                    {fieldVisibility.currentPassword ? (
                      <Visibility color={"#94A3B8"} size={20} />
                    ) : (
                      <VisibilityOff color={"#94A3B8"} size={20} />
                    )}
                  </EyeIcon>
                  <Form.Control.Feedback type="invalid">
                    Current password is required
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row className="pt-3 mt-3">
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <StyledLabel>
                  <CompulsoryAsterisk>*</CompulsoryAsterisk>New Password
                </StyledLabel>
                <InputGroup>
                  <StyledInput
                    onChange={onChangeField}
                    name="newPassword"
                    type={fieldVisibility.newPassword ? "text" : "password"}
                    value={form.newPassword}
                    isInvalid={formErrors.newPassword}
                    placeholder="Enter New Password"
                  />
                  <EyeIcon onClick={() => onChangeVisibility("newPassword")}>
                    {fieldVisibility.newPassword ? (
                      <Visibility color={"#94A3B8"} size={20} />
                    ) : (
                      <VisibilityOff color={"#94A3B8"} size={20} />
                    )}
                  </EyeIcon>
                  <Form.Control.Feedback type="invalid">
                    New Password must be 8 characters long, should contain 1
                    uppercase and 1 special character
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <StyledLabel>
                  <CompulsoryAsterisk>*</CompulsoryAsterisk>Re-enter New
                  Password
                </StyledLabel>
                <InputGroup>
                  <StyledInput
                    onChange={onChangeField}
                    name="rePassword"
                    type={fieldVisibility.rePassword ? "text" : "password"}
                    value={form.rePassword}
                    isInvalid={formErrors.rePassword}
                    placeholder="Re-enter new Password"
                  />
                  <EyeIcon onClick={() => onChangeVisibility("rePassword")}>
                    {fieldVisibility.rePassword ? (
                      <Visibility color={"#94A3B8"} size={20} />
                    ) : (
                      <VisibilityOff color={"#94A3B8"} size={20} />
                    )}
                  </EyeIcon>
                  <Form.Control.Feedback type="invalid">
                    Please enter same password as new password.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </FormContainer>
      <SubmitContainer>
        <Button
          style={{
            backgroundColor: `${THEME_COLOUR_PRIMARY}`,
            borderColor: `${THEME_COLOUR_PRIMARY}`,
            borderRadius: 10,
            padding: "15px 24px",
          }}
          onClick={onSubmitForm}
        >
          Update &amp; Save
        </Button>
        {showCancelButton && (
          <Button
            style={{
              backgroundColor: `transparent`,
              borderColor: `#CBD4E1`,
              color: `#475569`,
              borderRadius: 10,
              padding: "15px 24px",
              marginLeft: "0.75rem",
            }}
            onClick={onClickCancel}
          >
            Cancel
          </Button>
        )}
      </SubmitContainer>
      <ToastContainer />
    </ContentContainer>
  );
};

const StyledTabContentTitle = styled.div`
  font-size: 24px;
  color: #27364b;
  padding-top: 1rem;
`;

const ContentContainer = styled.div`
  padding: 1rem 1rem 1rem 0.5rem;
`;

const FormContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const StyledLabel = styled(Form.Label)`
  font-size: 12px;
  color: #64748b;
`;

const CompulsoryAsterisk = styled.span`
  color: red;
  padding-right: 2px;
`;

const StyledInput = styled(Form.Control)`
  border-radius: 0.5rem !important;
  &::placeholder,
  ::-webkit-input-placeholder {
    font-size: 14px !important;
  }
  &.is-invalid {
    background-image: none !important;
  }
`;

const EyeIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
  z-index: 9;
`;

const SubmitContainer = styled.div`
  padding-bottom: 8.6rem;
`;
