import React from "react";
import { styled } from "styled-components";
import { Button, Form, InputGroup, Row, Col, Spinner } from "react-bootstrap";
import axiosInstance from "../../api";
import { AxiosResponse } from "axios";
import { toast, ToastContainer } from "react-toastify";
import { ArrowBack, Lock } from "@styled-icons/material-outlined";
import { SITE_CONSTANTS, THEME_COLOUR_PRIMARY } from "../../constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isPasswordValid } from "../../helpers";

const DEFAULT_FORM_ERROR = {
  password: false,
  reEnteredPassword: false,
};

export const ForgotPassword = () => {
  const [searchParam] = useSearchParams();
  const navigate = useNavigate();

  const uuid = searchParam.get("id");
  const [form, setForm] = React.useState({
    password: "",
    reEnteredPassword: "",
  });

  const [requestProgress, setIsRequestInProgress] = React.useState(false);

  const [formError, setFormError] = React.useState({
    password: false,
    reEnteredPassword: false,
  });

  const onInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    setForm({
      ...form,
      [fieldName]: event.target.value,
    });
  };

  const validateForm = () => {
    let isFormValid = true;
    const errors = { ...DEFAULT_FORM_ERROR };

    if (!form.password?.trim() || !isPasswordValid(form.password)) {
      errors["password"] = true;
      isFormValid = false;
    }

    if (
      !form.reEnteredPassword?.trim() ||
      form.reEnteredPassword !== form.password
    ) {
      errors["reEnteredPassword"] = true;
      isFormValid = false;
    }

    setFormError({
      ...errors,
    });

    return isFormValid;
  };

  const handleForgotPassword = () => {
    if (!validateForm()) {
      return;
    }
    if (!uuid) {
      toast.error(
        "We can not recognise you, Please make sure you have correct link.",
        {
          position: "top-right",
          autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
          pauseOnHover: true,
          toastId: "error-toast",
        }
      );
    }
    setIsRequestInProgress(true);
    axiosInstance
      .post("/user/update-password", {
        params: {
          uuid,
          password: form.password,
        },
      })
      .then(
        (
          res: AxiosResponse<{
            error: boolean;
            message: string;
          }>
        ) => {
          if (res.status === 200) {
            toast.success(res.data.message, {
              position: "top-right",
              autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
              pauseOnHover: true,
              toastId: "error-toast",
            });

            setTimeout(() => {
              navigate("/login");
            }, 2000);
          }
        }
      )
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
          pauseOnHover: true,
          toastId: "error-toast",
        });
      })
      .finally(() => {
        setIsRequestInProgress(false);
      });
  };

  const navigateToLogin = () => {
    navigate("/login");
  };

  return (
    <div>
      <Row
        className="g-0 justify-content-center align-items-center"
        style={{ height: "85vh" }}
      >
        <Col xl={3} lg={3} md={12} sm={12}>
          <LoginContainer>
            <div className="d-flex justify-content-center pt-3 pb-5">
              <img height={85} src="quantum_politics_logo.png" alt="logo" />
            </div>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              className="login-form"
            >
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <InputGroup className="border-0 rounded-pill">
                  <InputGroup.Text>
                    <Lock size={20} />
                  </InputGroup.Text>
                  <Form.Control
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onInputChange(e, "password")
                    }
                    type="password"
                    placeholder="Password"
                    name="password"
                    isInvalid={formError.password}
                    value={form.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    Your password must contain
                    <ul>
                      <li>1 Alphabet</li>
                      <li>1 Number</li>
                      <li>1 Special Character</li>
                    </ul>
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <InputGroup className="border-0 rounded-pill">
                  <InputGroup.Text>
                    <Lock size={20} />
                  </InputGroup.Text>
                  <Form.Control
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onInputChange(e, "reEnteredPassword")
                    }
                    type="password"
                    placeholder="Re-enter Password"
                    name="reEnteredPassword"
                    isInvalid={formError.reEnteredPassword}
                    value={form.reEnteredPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                    {`${
                      !form.reEnteredPassword.length
                        ? "This Field is Required. "
                        : ""
                    }This field should be same as your new password`}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <ButtonContainer>
                {requestProgress ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  <Button
                    style={{
                      color: "#63f3dc",
                      borderColor: THEME_COLOUR_PRIMARY,
                      backgroundColor: THEME_COLOUR_PRIMARY,
                    }}
                    onClick={handleForgotPassword}
                  >
                    Update Password
                  </Button>
                )}
              </ButtonContainer>
            </Form>
            <ButtonContainer>
              <Button onClick={navigateToLogin} variant="link">
                <ArrowBack size={20} />
                Back to Login
              </Button>
            </ButtonContainer>
          </LoginContainer>
        </Col>
      </Row>
      <div className="d-flex justify-content-between align-items-center pb-5">
        <div className="ps-5">
          <img src="QP.svg" alt="Quantum Politics Logo" />
        </div>
        {/* <a
          href="https://www.persumedia.com/privacy-policy/"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a> */}
        <div className="pe-5">
          <img
            title="Powered By: PersuMedia"
            src="PersuMedia.svg"
            alt="Persumedia Logo"
            className="img-fluid"
          />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

const LoginContainer = styled.div`
  margin: 50px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;

  button {
    width: 100%;
    border-radius: 30px;
  }
`;
