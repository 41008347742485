import React from "react";
import HighChart, { Color } from "highcharts";
import { AssignmentReturnedDimensions } from "@styled-icons/material-outlined/AssignmentReturned";
//import highcharts3d from "highcharts/highcharts-3d";

interface Props {
  data: Array<{
    label: string;
    issue_count: number;
    overall_percentage: number;
  }>;
  onSelect?: (issueName: string) => void;
}

export const HighChartDonut = ({ data, onSelect }: Props) => {
  const refinedData: Array<[string, number]> = React.useMemo(() => {
    return data.map((obj) => {
      return [obj.label, obj.issue_count];
    });
  }, [data]);
  const chartComponentRef = React.useRef<HTMLDivElement>(null);
  const chartOptions: HighChart.Options = React.useMemo(() => {
    return {
      chart: {
        type: "pie",
        plotShadow: false,
        height: 320,
      },
      title: {
        text: "",
      },
      /* subtitle: {
        verticalAlign: "middle",
        text: "subText",
        style: {
          fontSize: 30,
        },
      }, */
      plotOptions: {
        pie: {
          borderWidth: 2,
        },
      },
      tooltip: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },

      series: [
        {
          cursor: "pointer",
          dataLabels: {
            connectorColor: "rgba(128,128,128,0.5)",
            connectorWidth: 2,
            distance: 10,
            position: "right",
            verticalAlign: "top",
            crop: false,
            style: {
              fontSize: "14px",
              fontWeight: "normal",
            },
          },
          events: {
            click: (event) => {
              const issueName = event.point.name;
              onSelect && onSelect(issueName);
            },
          },
          states: {
            hover: {
              enabled: false,
              opacity: 1,
              shadow: false,
            },
            inactive: {
              enabled: false,
            },
            select: {
              enabled: false,
            },
          },
          name: "",
          type: "pie",
          gapUnit: "relative",
          thickness: 50,
          colors: ["#FF9D4E", "#9967BC", "#75CBED", "#F96363", "#299998"],
          /* colors: HighChart.map(
            ["#FF9D4E", "#9967BC", "#75CBED", "#F96363", "#299998"],
            function (color: any) {
              return {
                radialGradient: {
                  cx: 0.5,
                  cy: 0.5,
                  r: 1,
                },
                stops: [
                  [0, color],
                  [1, HighChart.color(color).brighten(-0.3).get("rgb")], // darken
                ],
              };
            }
          ), */
          data: refinedData.slice(0, 5),
        },
      ],
    };
  }, [onSelect, refinedData]);

  React.useEffect(() => {
    if (chartComponentRef.current) {
      HighChart.chart(chartComponentRef.current, chartOptions);
    }
  }, [chartOptions]);

  return <div id="chartContainer" ref={chartComponentRef}></div>;
};
