import * as React from "react";
import { Modal, Button } from "react-bootstrap";
import { ConfirmationModal } from "../types/types";

export const ConfirmationDialog = ({
  onConfirm,
  onCancel,
  message,
  title = "Are you sure?",
}: ConfirmationModal) => {
  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal show>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {message ?? <p>Record once deleted can not be recoverd.</p>}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel}>
            Close
          </Button>
          <Button variant="danger" onClick={onConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
