import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Twitter } from "../types/types";
import {
  StyledContent,
  StyledMeta,
  StyledSubTitle,
  StyledTitle,
  StyledRow,
  MetaLabel,
  StyledTagPill,
} from "./ModalCss";
import {
  getIssueNameInNative,
  isContentRtl,
  isDirtyHashtag,
  replacer,
  showEnglishContent,
} from "../helpers";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { SUBSCRIBED_REGION, THEME_COLOUR_PRIMARY } from "../constants";
import styled from "styled-components";
import { Circle } from "@styled-icons/material";
import { LanguageIcon } from "./LanguageIcon";

interface Props extends Twitter {
  onHide: () => void;
  issue: string;
  highlightKeywords?: string[];
}

export const TwitterDetailModal = ({
  twitter_handle,
  english_sub_issue,
  language,
  date,
  tweet_text_translated,
  hashtag,
  tweet_favorite_count,
  tweet_quote_count,
  tweet_reply_count,
  tweet_retweet_count,
  user_current_following_count,
  user_tweet_count,
  user_current_follower_count,
  tweet_original_link,
  tweet_text,
  issue,
  highlightKeywords,
  onHide,
}: Props) => {
  const { preferredLanguage, issuesM3, preferredRegion, taiwanIssues } =
    useSelector((state: RootState) => state.preference);

  const handleTitleClick = React.useCallback(() => {
    window.open(tweet_original_link, "_blank");
  }, [tweet_original_link]);

  const hashTagArr = React.useMemo(() => {
    return hashtag?.length ? hashtag.split(",").map((tag) => `${tag}`) : [];
  }, [hashtag]);

  const content = React.useMemo(() => {
    return showEnglishContent(tweet_text_translated, preferredLanguage)
      ? tweet_text_translated
      : tweet_text;
  }, [preferredLanguage, tweet_text, tweet_text_translated]);

  const highlightedContent = React.useMemo(() => {
    let formattedContent = content;
    if (highlightKeywords && highlightKeywords.length) {
      highlightKeywords.forEach((keyword) => {
        formattedContent = replacer(
          formattedContent,
          keyword,
          "<span style='background-color:yellow; font-weight: bold'>$1</span>"
        );
      });
    }
    formattedContent.replaceAll("\n", "<br />");
    return formattedContent;
  }, [content, highlightKeywords]);

  const highlitedTitle = React.useMemo(() => {
    let formattedContent = twitter_handle;
    if (highlightKeywords && highlightKeywords.length) {
      highlightKeywords.forEach((keyword) => {
        formattedContent = replacer(
          formattedContent,
          keyword,
          "<span style='background-color:yellow; font-weight: bold'>$1</span>"
        );
      });
    }
    formattedContent.replaceAll("\n", "<br />");
    return formattedContent;
  }, [highlightKeywords, twitter_handle]);

  const originalIssueName = React.useMemo(() => {
    return getIssueNameInNative(preferredRegion);
  }, [preferredRegion]);

  return (
    <Modal onHide={onHide} keyboard scrollable show size={"lg"} centered>
      <Modal.Header>
        <Modal.Title className="w-100">
          <StyledTitle>
            <div
              style={{
                direction: isContentRtl(preferredLanguage, language)
                  ? "rtl"
                  : "ltr",
              }}
              dangerouslySetInnerHTML={{ __html: highlitedTitle }}
            />
            <button
              type="button"
              className="btn-close float-end"
              aria-label="Close"
              onClick={onHide}
            ></button>
          </StyledTitle>
          <StyledSubTitle className="mt-2 mb-2">
            {issue} ({originalIssueName})
          </StyledSubTitle>
          <div
            className="d-flex align-items-center"
            style={{ fontSize: 12.5, lineHeight: 1.75 }}
          >
            <div>
              <StyledFooterItem>
                <LanguageIcon postLanguage={language} />
                {/* <span className="ps-1">{postLanguage}</span> */}
              </StyledFooterItem>
            </div>
            <div>
              <SeparatorIcon height={10} width={10} />
            </div>
            <div>
              <StyledFooterItem>{date}</StyledFooterItem>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <StyledContent
          style={{ padding: 0 }}
          isrtl={isContentRtl(preferredLanguage, language)}
          dangerouslySetInnerHTML={{
            __html: highlightedContent,
          }}
        ></StyledContent>
        {hashTagArr.length ? (
          <>
            <div className="mt-3">Hashtags</div>
            <div className="my-3">
              {hashTagArr.map((hashtag) =>
                isDirtyHashtag(hashtag) ? (
                  ""
                ) : (
                  <StyledTagPill className="me-2">#{hashtag}</StyledTagPill>
                )
              )}
            </div>
          </>
        ) : null}
      </Modal.Body>
      <Modal.Footer style={{ display: "block" }} className="mb-4">
        <Row>
          <Col xl={5} lg={5} sm={12} xs={12}>
            <StyledRow className="my-3">
              <MetaLabel>Favorite Count</MetaLabel>
              <MetaLabel>{tweet_favorite_count}</MetaLabel>
            </StyledRow>
            <StyledRow className="my-3">
              <MetaLabel>Quote Count</MetaLabel>
              <MetaLabel>{tweet_quote_count}</MetaLabel>
            </StyledRow>
            <StyledRow className="my-3">
              <MetaLabel>Reply Count</MetaLabel>
              <MetaLabel>{tweet_reply_count}</MetaLabel>
            </StyledRow>
            <StyledRow className="my-3">
              <MetaLabel>Retweet Count</MetaLabel>
              <MetaLabel>{tweet_retweet_count}</MetaLabel>
            </StyledRow>
          </Col>
          <Col
            xl={1}
            lg={1}
            sm={12}
            xs={12}
            style={{ borderRight: "1px solid #CCC" }}
          ></Col>
          <Col xl={1} lg={1} sm={12} xs={12}></Col>
          <Col xl={5} lg={5} sm={12} xs={12}>
            <StyledRow className="my-3">
              <MetaLabel>Username</MetaLabel>
              <MetaLabel
                onClick={handleTitleClick}
                style={{ cursor: "pointer", color: `${THEME_COLOUR_PRIMARY}` }}
              >
                {twitter_handle}
              </MetaLabel>
            </StyledRow>
            <StyledRow className="my-3">
              <MetaLabel>Following Count</MetaLabel>
              <MetaLabel>{user_current_following_count}</MetaLabel>
            </StyledRow>
            <StyledRow className="my-3">
              <MetaLabel>User Tweet Count</MetaLabel>
              <MetaLabel>{user_tweet_count}</MetaLabel>
            </StyledRow>
            <StyledRow className="my-3">
              <MetaLabel>User Current Follower Count</MetaLabel>
              <MetaLabel>{user_current_follower_count}</MetaLabel>
            </StyledRow>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

const StyledFooterItem = styled.div`
  color: #64748b;
`;

const SeparatorIcon = styled(Circle)`
  color: #cdd4e1;
  margin-right: 12px;
  margin-left: 12px;
`;

const StyledFooterImage = styled.img`
  border: 1px solid #ccc !important;
`;
