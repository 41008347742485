import React from "react";
import { Spinner } from "react-bootstrap";

export const Loader = () => {
  return (
    <div
      style={{
        height: 500,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spinner
        style={{ width: "4rem", height: "4rem" }}
        animation="border"
        variant="success"
      />
    </div>
  );
};
