import * as React from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { InfluencerCard } from "../../components/InfluencerCard";
import { getNavBarHeight } from "../../helpers";
import {
  TaiwanDigitalMoversAndShapers,
  TaiwanTwitterMoversAndShapers,
} from "../../types/types";
import { SendGAEvent } from "../../helpers/googleAnalyticsHepler";
import { setSearchScreenFilter } from "../../store/slice/search";
import { THEME_COLOUR_PRIMARY } from "../../constants";
import { Info } from "@styled-icons/material-outlined";
import { ArticleIcon, TwitterIcon } from "../../components/TabIcons";
import { RootState } from "../../store";

interface Props {
  taiwanDigitalMoversAndShapers: Array<TaiwanDigitalMoversAndShapers>;
  taiwanTwitterMoversAndShapers: Array<TaiwanTwitterMoversAndShapers>;
}

export const TaiwanMoversAndShapers = ({
  taiwanDigitalMoversAndShapers,
  taiwanTwitterMoversAndShapers,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { preferredRegion } = useSelector(
    (state: RootState) => state.preference
  );

  const handleTwitterInfluencerClick = (
    influencer: TaiwanTwitterMoversAndShapers
  ) => {
    SendGAEvent({
      category: "SOTOS_Taiwan_Emerging_Issue_MNS_Clicked_Twitter",
      action: "SOTOS_Taiwan_Emerging_Issue_MNS_Clicked_Twitter",
      includeUserDetail: true,
      label: `TN=${influencer.twitter_name}`,
    });
    dispatch(
      setSearchScreenFilter({
        query: `@${influencer.twitter_name}`,
        date: "90",
      })
    );
    window.scrollTo({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      navigate(`/${preferredRegion.toLowerCase()}/discover`);
    }, 500);
  };

  const handleDigitalInfluencerClick = (
    influencer: TaiwanDigitalMoversAndShapers
  ) => {
    SendGAEvent({
      category: "SOTOS_Taiwan_Emerging_Issue_MNS_Clicked_Digital",
      action: "SOTOS_Taiwan_Emerging_Issue_MNS_Clicked_Digital",
      includeUserDetail: true,
      label: `PNE=${influencer.person_name_in_english},PNT=${influencer.person_name}`,
    });
    const searchQuery = [];
    if (influencer.person_name_in_english) {
      searchQuery.push(influencer.person_name_in_english);
    }
    if (influencer.person_name) {
      searchQuery.push(influencer.person_name);
    }
    dispatch(
      setSearchScreenFilter({
        query: searchQuery.join(" OR "),
        date: "90",
      })
    );

    window.scrollTo({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      navigate(`/${preferredRegion.toLowerCase()}/discover`);
    }, 500);
  };

  return (
    <MoversAndShapersContainer top={getNavBarHeight()} className="pb-2">
      <SectionTitle style={{ fontSize: 16 }}>
        Influence AI &nbsp;
        <Info
          id={`tooltop-anchor-Influence-AI`}
          size={25}
          color="#475569"
          style={{ paddingBottom: 2 }}
          className="info-icon"
        />
      </SectionTitle>
      {!!taiwanDigitalMoversAndShapers.length && (
        <DigitalMoversAndShapersContainer>
          <Card
            style={{
              border: "transparent",
            }}
          >
            <Card.Title
              style={{
                color: "#475569",
                fontSize: 14,
                fontWeight: "bold",
              }}
              className="m-3 mb-1"
            >
              Related Movers &amp; Shapers{" "}
              <span className="ps-2">
                <ArticleIcon isActive={false} color={THEME_COLOUR_PRIMARY} />
              </span>
            </Card.Title>
            <ScrollableCardBody className="custom-scroll pt-1 mb-3">
              <div>
                {taiwanDigitalMoversAndShapers.map((digitalMNS) => {
                  return (
                    <div
                      className="my-2"
                      onClick={() => handleDigitalInfluencerClick(digitalMNS)}
                    >
                      <InfluencerCard
                        name={digitalMNS.person_name_in_english}
                        probability={digitalMNS.weighted_cdf_probability}
                      />
                    </div>
                  );
                })}
              </div>
            </ScrollableCardBody>
          </Card>
        </DigitalMoversAndShapersContainer>
      )}

      {!!taiwanTwitterMoversAndShapers.length && (
        <>
          <Seprator></Seprator>
          <DigitalMoversAndShapersContainer className="mt-1">
            <Card
              style={{
                border: "transparent",
              }}
            >
              <Card.Title
                style={{
                  color: "#475569",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
                className="m-3 mb-1"
              >
                Related Movers &amp; Shapers
                <span className="ps-2">
                  <TwitterIcon isActive={false} color={THEME_COLOUR_PRIMARY} />
                </span>
              </Card.Title>
              <ScrollableCardBody className="custom-scroll pt-1 mb-3">
                <div>
                  {taiwanTwitterMoversAndShapers.map((twitterMNS) => {
                    return (
                      <div
                        className="my-2"
                        onClick={() => handleTwitterInfluencerClick(twitterMNS)}
                      >
                        <InfluencerCard
                          name={twitterMNS.twitter_name}
                          probability={twitterMNS.ensemble_probability}
                        />
                      </div>
                    );
                  })}
                </div>
              </ScrollableCardBody>
            </Card>
          </DigitalMoversAndShapersContainer>
        </>
      )}
    </MoversAndShapersContainer>
  );
};

const MoversAndShapersContainer = styled("div").withConfig({
  shouldForwardProp: (prop) => prop !== "top",
})<{ top: number }>(({ top }) => ({
  border: "1px solid #CCC",
  borderRadius: 16,
}));

const ScrollableCardBody = styled(Card.Body)`
  max-height: 100px;
  overflow: auto;
`;

const DigitalMoversAndShapersContainer = styled.div``;
const Seprator = styled.div`
  border-top: 2px solid #ccc;
`;

const SectionTitle = styled.div`
  color: rgb(71, 85, 105);
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid rgba(0, 0, 0, 0.176);
  padding-top: 1.2rem;
  padding-left: 1.2rem;
  padding-bottom: 0.6rem;
`;
