import {
  EMPTY_CONTENT_STRING,
  LAST_DATABASE_UPDATE_DATE,
  SUBSCRIBED_REGION,
} from "../constants";
import { DateTime } from "luxon";
import { DropdownOption, REGIONS } from "../types/types";
import { MultiValue } from "react-select";
import { store } from "../store";

export const showEnglishContent = (
  content: string,
  preferredLanguage: string
) => {
  return (
    !EMPTY_CONTENT_STRING.includes(content) && preferredLanguage !== "Original"
  );
};

export const isContentRtl = (
  preferredLanguage: string,
  sourceLanguage: string
) => {
  if (preferredLanguage === "Original" && sourceLanguage === "Persian") {
    return true;
  }

  return false;
};

export const getBackDate = (
  timeFrame: "week" | "month" | "year" | "days" | "today",
  value: number
) => {
  switch (timeFrame) {
    case "week":
      return LAST_DATABASE_UPDATE_DATE.minus({ weeks: value }).toFormat(
        "yyyy-LL-dd"
      );

    case "month":
      return LAST_DATABASE_UPDATE_DATE.minus({ months: value }).toFormat(
        "yyyy-LL-dd"
      );

    case "year":
      return LAST_DATABASE_UPDATE_DATE.minus({ years: value }).toFormat(
        "yyyy-LL-dd"
      );

    case "days":
      return LAST_DATABASE_UPDATE_DATE.minus({ days: value }).toFormat(
        "yyyy-LL-dd"
      );

    default:
      return LAST_DATABASE_UPDATE_DATE.toFormat("yyyy-LL-dd");
  }
};

export const pluckSelectedOptionsValues = (
  selectedFilters: MultiValue<DropdownOption>
) => {
  return selectedFilters
    .filter((filter) => filter.value)
    .map((filter) => filter.value);
};

export const isPossiblyPersian = (text: string): boolean => {
  const ArabicContextualForms = /[ي|ﻱ|ﻲ|ﻚ|ك|ﻚ|ﺔ|ﺓ|ة]/g;
  const trimPattern = /["'-+()\s.]/g;
  const textToTest = text.replace(trimPattern, "");

  return (
    /^[\u0600-\u06FF\s]+$/.test(textToTest) &&
    !ArabicContextualForms.test(textToTest)
  );
};

export const isOnlyNumeric = (text: string) => {
  var regexp = new RegExp(/^[0-9]*$/);
  return regexp.test(text);
};

export const isValidSearchString = (text: string) => {
  const regexp = new RegExp(/[`~!$%^&*()_|+=?;:,.<>\{\}\[\]\\\/]/gi);
  return !regexp.test(text);
};

export const isAlphaNumeric = (text: string) => {
  var regexp = new RegExp(/^[a-zA-Z0-9_@"#\u0600-\u06FF\s ]*$/);
  ///[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi
  return regexp.test(text);
};

export const isOnlyCharacters = (text: string) => {
  var regexp = new RegExp(/^[a-zA-Z ]*$/);
  return regexp.test(text);
};

export const isSearchStringValid = (text: string) => {
  let isStringValid = true;

  if (!text.trim().length) {
    isStringValid = false;
  } else if (
    text.startsWith("AND ") ||
    text.startsWith(" AND ") ||
    text.endsWith("AND") ||
    text.endsWith(" AND") ||
    text.endsWith("AND ") ||
    text.endsWith(" AND ")
  ) {
    isStringValid = false;
  } else if (
    text.startsWith("OR ") ||
    text.startsWith(" OR ") ||
    text.endsWith("OR") ||
    text.endsWith(" OR") ||
    text.endsWith("OR ") ||
    text.endsWith(" OR ")
  ) {
    isStringValid = false;
  } else if (text.indexOf(" AND ") > -1 && text.indexOf(" OR ") > -1) {
    isStringValid = false;
  } else if (
    text.indexOf(" AND ") === -1 &&
    text.indexOf(" OR ") === -1 &&
    text[0] !== '"' &&
    text[text.length - 1] !== '"' &&
    text.split(" ").length > 5
  ) {
    isStringValid = false;
  } else {
    const andKeywords = text.split(" AND ");
    const orKeywprds = text.split(" OR ");
    if (
      (andKeywords.length && andKeywords.length > 5) ||
      (orKeywprds.length && orKeywprds.length > 5)
    ) {
      isStringValid = false;
    }
  }

  return isStringValid;
};

export const replacer = (
  sourceString: string,
  keywordToBeChanged: string,
  keywordToReplaceWith: string
) => {
  const re = new RegExp(`(${keywordToBeChanged})`, "gi");
  return sourceString.replaceAll(re, keywordToReplaceWith);
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const getNavBarHeight = () => {
  let height = 72;
  if (document.getElementsByTagName("nav").length) {
    height = document.getElementsByTagName("nav")[0].clientHeight;
  }

  return height;
};

export const isPasswordValid = (password: string) => {
  /**
   * Password must contain 8 characters
   * Password must contain one alphabet, one special character, and one number
   */
  const regex = new RegExp(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z]).{8,}$/);
  return regex.test(password);
};

export const isDirtyHashtag = (hashtag: string) => {
  return hashtag.includes("u200c");
};

export const htmlStringEncoder = (text: string) => {
  const textarea = document.createElement("textarea");
  textarea.innerHTML = text;
  return textarea.value;
};

export const hex2rgba = (hexCode: string, opacity: number = 1) => {
  const pattern_color = "^#([A-Fa-f0-9]{6})$";
  if (hexCode.match(pattern_color)) {
    var hex_color = hexCode.replace("#", ""),
      r = parseInt(hex_color.substring(0, 2), 16),
      g = parseInt(hex_color.substring(2, 4), 16),
      b = parseInt(hex_color.substring(4, 6), 16);
    return "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
  } else {
    alert("Error Color Format");
  }
};

export const getActiveIssue = (preferredRegion: REGIONS) => {
  const { preference } = store.getState();
  switch (preferredRegion) {
    case SUBSCRIBED_REGION.CHINA_REGION:
      return {
        englishIssueName:
          preference.selectedChinaIssue ?? preference.defaultSelectedChinaIssue,
        defaultSelectedIssue: preference.defaultSelectedChinaIssue,
      };

    case SUBSCRIBED_REGION.TAIWAN_REGION:
      return {
        englishIssueName:
          preference.selectedTaiwanIssue ??
          preference.defaultSelectedTaiwanIssue,
        defaultSelectedIssue: preference.defaultSelectedTaiwanIssue,
      };

    default:
      return {
        englishIssueName:
          preference.selectedIssueM3 ?? preference.defaultSelectedIssueM3,
        defaultSelectedIssue: preference.defaultSelectedIssueM3,
      };
  }
};

export const getIssueNameInNative = (
  preferredRegion: REGIONS,
  englishIssueName?: string
) => {
  const reduxState = store.getState();
  let activeEnglishIssue = englishIssueName ?? undefined;
  if (!englishIssueName) {
    activeEnglishIssue = getActiveIssue(preferredRegion).englishIssueName;
  }

  if (!activeEnglishIssue) {
    return null;
  }

  const {
    englishIssues: iranIssues,
    originalLanguageIssues: iranOriginalIssueNames,
  } = reduxState.preference.issuesM3;
  const {
    englishIssues: taiwaneseIssues,
    originalLanguageIssues: taiwanOriginalIssueNames,
  } = reduxState.preference.taiwanIssues;

  const {
    englishIssues: chinaIssues,
    originalLanguageIssues: chinaOriginalIssueNames,
  } = reduxState.preference.chinaIssues;

  const findEnglishIssueIndex =
    preferredRegion === SUBSCRIBED_REGION.IRAN_REGION
      ? iranIssues.findIndex((issue) => issue === activeEnglishIssue)
      : preferredRegion === SUBSCRIBED_REGION.CHINA_REGION
      ? chinaIssues.findIndex((issue) => issue === activeEnglishIssue)
      : taiwaneseIssues.findIndex((issue) => issue === activeEnglishIssue);

  if (findEnglishIssueIndex > -1) {
    return preferredRegion === SUBSCRIBED_REGION.IRAN_REGION
      ? iranOriginalIssueNames[findEnglishIssueIndex]
      : preferredRegion === SUBSCRIBED_REGION.CHINA_REGION
      ? chinaOriginalIssueNames[findEnglishIssueIndex]
      : taiwanOriginalIssueNames[findEnglishIssueIndex];
  }
  return "";
};

export const getLastTwelveMonthNames = (): Array<DropdownOption> => {
  const today = LAST_DATABASE_UPDATE_DATE;
  const prevMonths = [];
  for (let i = 1; i < 13; i++) {
    prevMonths.push({
      label: today.minus({ months: i }).toFormat("LLL yyyy"),
      value: today.minus({ months: i }).toFormat("yyyy-LL"),
    });
  }

  return prevMonths;
};

export const USFormattedNumber = (number: number) => {
  const language = "en";

  return Intl.NumberFormat(language, { notation: "compact" }).format(number); //output - "234K"
};

export const triggerDownload = (
  href: string,
  filename: string,
  ext: string
) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = href;
  downloadLink.download = filename + ext;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
