import { Tabs } from "react-bootstrap";
import styled from "styled-components";
import { THEME_COLOUR_PRIMARY } from "../constants";

export const StyledTabs = styled(Tabs).withConfig({
  shouldForwardProp: (prop) => prop !== "noBgOnActive",
})<{
  noBgOnActive?: boolean;
}>`
  .nav-item {
    position: relative;
  }
  .nav-item .nav-link:hover {
    border-color: transparent;
  }
  .nav-item:not(:first-child) {
    margin-left: 3rem;
  }
  .nav-item button {
    font-size: 16px;
    line-height: 24px;
    color: #94a3b8;
    padding-bottom: 16px;
  }

  .nav-item button.active {
    &::after {
      content: "";
      border-bottom: 3px solid ${THEME_COLOUR_PRIMARY};
      position: absolute;
      width: 40px;
      bottom: -2px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  .nav-link {
    padding: 6px 12px;
    height: 38px;
    border-radius: 40px;
  }

  .nav-link.active {
    border-color: transparent;
    background: ${(p) =>
      p.noBgOnActive ? "transparent" : THEME_COLOUR_PRIMARY};
    color: ${(p) => (p.noBgOnActive ? THEME_COLOUR_PRIMARY : "#FFF")};
    font-weight: ${(p) => (p.noBgOnActive ? "normal" : "bold")};
    border-radius: 40px;
    margin-bottom: 10px;
    padding: 6px 12px;
    height: 38px;
  }
`;
