import React from "react";
import { Person, Email } from "@styled-icons/material-outlined";
import { Row, Col, Form, InputGroup, Spinner, Button } from "react-bootstrap";
import { ButtonContainer } from "../../components/CommonStyledComponents";
import { CreateInvitee as CreateInviteeType } from "./types";

interface Props {
  createInvitee: (params: CreateInviteeType) => void;
  isCreateInviteeInProgress: boolean;
}

const DEFAULT_ERRORS = {
  name: false,
  lastName: false,
  email: false,
  organisation: false,
};

export const CreateInvitee = ({
  createInvitee,
  isCreateInviteeInProgress,
}: Props) => {
  const [form, setForm] = React.useState({
    name: "",
    lastName: "",
    email: "",
    organisation: "",
  });

  const [formError, setFormError] = React.useState({
    ...DEFAULT_ERRORS,
  });

  const onInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    setForm({
      ...form,
      [fieldName]: e.target.value,
    });
  };

  const validateForm = () => {
    let error = { ...DEFAULT_ERRORS };
    let isValid = true;

    if (!form.name.trim()) {
      isValid = false;
      error["name"] = true;
    }

    if (!form.email.trim()) {
      isValid = false;
      error["email"] = true;
    }

    if (!form.lastName.trim()) {
      isValid = false;
      error["lastName"] = true;
    }

    if (!form.organisation.trim()) {
      isValid = false;
      error["organisation"] = true;
    }

    setFormError(error);
    return isValid;
  };

  const handleFormSubmit = () => {
    if (!validateForm()) {
      return;
    }
    createInvitee(form);
  };

  return (
    <Row className="g-0 justify-content-center align-items-center">
      <Col xl={8} lg={8} md={12} sm={12} xs={12}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <InputGroup>
              <InputGroup.Text>
                <Person size={20} />
              </InputGroup.Text>
              <Form.Control
                name="namformErrore"
                type="input"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onInputChange(e, "name")
                }
                placeholder="First Name"
                isInvalid={formError.name}
                value={form.name}
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid name.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <InputGroup>
              <InputGroup.Text>
                <Person size={20} />
              </InputGroup.Text>
              <Form.Control
                name="lastName"
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onInputChange(e, "lastName")
                }
                placeholder="Last Name"
                isInvalid={formError.lastName}
                value={form.lastName}
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid last name.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <InputGroup>
              <InputGroup.Text>
                <Email size={20} />
              </InputGroup.Text>
              <Form.Control
                type="email"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onInputChange(e, "email")
                }
                isInvalid={formError.email}
                value={form.email}
                placeholder="Email"
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid email address
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <InputGroup>
              <InputGroup.Text>
                <Email size={20} />
              </InputGroup.Text>
              <Form.Control
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onInputChange(e, "organisation")
                }
                isInvalid={formError.organisation}
                value={form.organisation}
                placeholder="Organisation"
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid organisation
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <ButtonContainer>
            {isCreateInviteeInProgress ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <Button
                onClick={handleFormSubmit}
                variant="primary"
                type="submit"
                style={{ borderRadius: 30 }}
              >
                Create Invitee
              </Button>
            )}
          </ButtonContainer>
        </Form>
      </Col>
    </Row>
  );
};
