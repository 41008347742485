import React from "react";
import { ArrowUpward } from "@styled-icons/material-outlined";
import styled from "styled-components";
import { useScrollPosition } from "../helpers/useScrollPosition";
import { THEME_COLOUR_PRIMARY } from "../constants";

export const GoToTop = ({ offset = 80 }: { offset?: number }) => {
  const currentScrollPosition = useScrollPosition();

  const handleArrowClick = React.useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return currentScrollPosition > offset ? (
    <StyledArrowContainer onClick={handleArrowClick}>
      <ArrowUpward size={30} color="#FFF" />
    </StyledArrowContainer>
  ) : null;
};

const StyledArrowContainer = styled.div`
  background-color: ${THEME_COLOUR_PRIMARY};
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
`;
