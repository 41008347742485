import * as React from "react";
import { Cancel, ModeEdit } from "@styled-icons/material-outlined";
import { Button, Form, InputGroup, Row, Col, Spinner } from "react-bootstrap";
import styled from "styled-components";
import {
  PROFILE_PICTURE_IMG_PREFIX,
  SITE_CONSTANTS,
  SUBSCRIBED_REGION,
  THEME_COLOUR_PRIMARY,
} from "../../constants";
import axiosInstance from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { AxiosResponse } from "axios";
import { updateUser } from "../../store/slice/user";
import { isOnlyCharacters } from "../../helpers";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ReactGA } from "../../components/GoogleAnalytics";
import { UserProfile } from "../../types/types";

const DEFAULT_ERRORS = {
  name: false,
  lastName: false,
};

export const ProfileTabContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { profile } = useSelector((state: RootState) => state.user);

  const [isRequestInProgress, setIsRequestInProgress] = React.useState(false);

  const [, setUploadedFiles] = React.useState<File | undefined>(undefined);

  const [form, setForm] = React.useState<{
    name: string;
    lastName: string;
    email: string;
    organisation?: string;
    avatar?: string;
  }>({
    name: "",
    lastName: "",
    email: "",
    organisation: "",
    avatar: "",
  });

  const [formErrors, setFormErrors] = React.useState({ ...DEFAULT_ERRORS });

  const [showCancelButton, setShowCancelButton] = React.useState(false);

  React.useEffect(() => {
    ReactGA.event({
      category: "Profile_Information_Landed",
      action: "Profile_Information_Landed",
      label: profile?.email,
    });
  }, [profile?.email]);

  React.useEffect(() => {
    if (profile) {
      setForm({
        name: profile.name,
        lastName: profile.lastName,
        email: profile.email,
      });
    }
  }, [profile]);

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const onClickUploadButton = () => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.click();
  };

  const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = e.target;
    if (newFile.files?.length) {
      console.log(newFile.files[0].size);

      if (newFile.files[0].size / 1000 > 2000) {
        toast.error("Please upload file smaller than 2MB of size");
        if (inputRef.current) {
          inputRef.current.value = "";
        }
      } else if (!["image/jpeg", "image/png"].includes(newFile.files[0].type)) {
        toast.error("Please upload JPG or PNG file only");
        if (inputRef.current) {
          inputRef.current.value = "";
        }
      }
      setUploadedFiles(newFile.files[0]);
    }
  };

  const validateForm = () => {
    let isFormValid = true;
    let newError = { ...DEFAULT_ERRORS };
    if (!form.name.trim() || !isOnlyCharacters(form.name)) {
      newError["name"] = true;
      isFormValid = false;
    }
    if (!form.lastName.trim() || !isOnlyCharacters(form.lastName)) {
      newError["lastName"] = true;
      isFormValid = false;
    }
    setFormErrors({ ...newError });
    return isFormValid;
  };

  const onSubmitForm = () => {
    if (!validateForm()) {
      return;
    }
    setIsRequestInProgress(true);
    const formData = new FormData();
    inputRef.current &&
      inputRef.current.files &&
      formData.append("file", inputRef.current?.files[0]);
    formData.append("name", form.name);
    formData.append("lastName", form.lastName);
    formData.append("email", profile?.email ?? "");
    axiosInstance
      .post("/user/update-user", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(
        (
          res: AxiosResponse<{
            message: string;
            data: UserProfile;
          }>
        ) => {
          if (res.status === 500) {
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
              pauseOnHover: true,
              toastId: "error-toast",
            });
            return;
          }
          if (inputRef.current) {
            inputRef.current.value = "";
          }
          dispatch(
            updateUser({
              profile: {
                name: res.data.data.name,
                lastName: res.data.data.lastName,
                email: res.data.data.email,
                role: res.data.data.role,
                organisation: res.data.data?.organisation ?? undefined,
                avatar: res.data.data.avatar,
                subscribed_regions: res.data.data.subscribed_regions ?? [
                  SUBSCRIBED_REGION.IRAN_REGION,
                ],
              },
            })
          );
          localStorage.setItem("user", JSON.stringify(res.data.data));
          toast.success("Record updated successfully.", {
            position: "top-right",
            autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
            pauseOnHover: true,
            toastId: "success-toast",
          });
        }
      )
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
          pauseOnHover: true,
          toastId: "error-toast",
        });
      })
      .finally(() => {
        setIsRequestInProgress(false);
      });
  };

  const onChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    setShowCancelButton(true);
  };

  const removeSelectedFile = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setUploadedFiles(undefined);
  };

  const onClickCancel = () => {
    navigate(-1);
  };

  return (
    <ContentContainer>
      <StyledTabContentTitle>Profile Information</StyledTabContentTitle>
      <AvatarContainer>
        <img
          src={
            profile?.avatar
              ? `${PROFILE_PICTURE_IMG_PREFIX}${profile.avatar}`
              : "default_profile_image.png"
          }
          height="108"
          width="108"
          alt="profile"
          className="rounded-circle"
        />
        <StyledUploadButton>
          <Button
            style={{
              background: "rgba(240, 245, 255, 1)",
              border: `1px solid ${THEME_COLOUR_PRIMARY}`,
              color: THEME_COLOUR_PRIMARY,
            }}
            onClick={onClickUploadButton}
          >
            <ModeEdit size={20} /> Change Avatar
          </Button>
          <input
            onChange={onFileInputChange}
            type="file"
            ref={inputRef}
            style={{ display: "none" }}
            accept="image/jpeg, image/png"
          />
          {!!inputRef.current && (
            <span className="ps-2">
              {inputRef.current.files && inputRef.current.files.length ? (
                <>
                  <span>{inputRef.current.files[0].name}</span>{" "}
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={removeSelectedFile}
                  >
                    <Cancel size={20} />
                  </span>
                </>
              ) : null}
            </span>
          )}
        </StyledUploadButton>
      </AvatarContainer>
      <FormContainer>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <StyledLabel>
                  <CompulsoryAsterisk>*</CompulsoryAsterisk>First Name
                </StyledLabel>
                <InputGroup>
                  <StyledInput
                    onChange={onChangeField}
                    name="name"
                    type="input"
                    value={form.name}
                    isInvalid={formErrors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter valid first Name.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <StyledLabel>
                  <CompulsoryAsterisk>*</CompulsoryAsterisk>Last Name
                </StyledLabel>
                <InputGroup>
                  <StyledInput
                    onChange={onChangeField}
                    name="lastName"
                    type="input"
                    value={form.lastName}
                    isInvalid={formErrors.lastName}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter valid last Name.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <StyledLabel>Company Name</StyledLabel>
                <InputGroup>
                  <StyledInput
                    value={profile?.organisation ?? ""}
                    disabled
                    type="input"
                    readOnly
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <StyledLabel>Email address</StyledLabel>
                <InputGroup>
                  <StyledInput
                    value={profile?.email ?? ""}
                    disabled
                    readOnly
                    type="input"
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </FormContainer>
      <SubmitContainer>
        {isRequestInProgress ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <>
            <Button
              style={{
                backgroundColor: `${THEME_COLOUR_PRIMARY}`,
                borderColor: `${THEME_COLOUR_PRIMARY}`,
                borderRadius: 10,
                padding: "15px 24px",
              }}
              onClick={onSubmitForm}
            >
              Update &amp; Save
            </Button>
            {showCancelButton && (
              <Button
                style={{
                  backgroundColor: `transparent`,
                  borderColor: `#CBD4E1`,
                  color: `#475569`,
                  borderRadius: 10,
                  padding: "15px 24px",
                  marginLeft: "0.75rem",
                }}
                onClick={onClickCancel}
              >
                Cancel
              </Button>
            )}
          </>
        )}
      </SubmitContainer>
      <ToastContainer />
    </ContentContainer>
  );
};

const StyledTabContentTitle = styled.div`
  font-size: 24px;
  color: #27364b;
  padding-top: 1rem;
`;

const ContentContainer = styled.div`
  padding: 1rem 1rem 1rem 0.5rem;
`;

const AvatarContainer = styled.div`
  padding-top: 2.5rem;
`;

const FormContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const StyledUploadButton = styled.span`
  padding-left: 2rem;
`;

const StyledInput = styled(Form.Control)`
  border-radius: 0.5rem !important;
`;

const StyledLabel = styled(Form.Label)`
  font-size: 12px;
  color: #64748b;
`;

const CompulsoryAsterisk = styled.span`
  color: red;
  padding-right: 2px;
`;

const SubmitContainer = styled.div`
  padding-bottom: 2.5rem;
`;
