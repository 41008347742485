import * as React from "react";
import { ChevronLeft } from "@styled-icons/material";
import { Modal, Button } from "react-bootstrap";
import styled from "styled-components";
import { THEME_COLOUR_PRIMARY } from "../constants";
import { ConfirmationModal } from "../types/types";

const DEFAULT_MESSAGE =
  "<span style='font-weight: bold'>This link will take you to a page that's not on SOTOS.</span> Because it is external link we can't verify it for safety.";

export const ExternalLinkModal = ({
  onConfirm,
  onCancel,
  message = DEFAULT_MESSAGE,
  title = "Are you sure?",
}: ConfirmationModal) => {
  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial", borderRadius: 24 }}
    >
      <StyledModal
        show
        style={{
          borderRadius: 24,
        }}
      >
        <Modal.Body>
          <div className="d-flex p-3">
            <div>
              <svg
                viewBox="0 0 24 24"
                height="48"
                width="48"
                focusable="false"
                role="img"
                fill="#ff001e"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g data-name="Layer 2">
                  <g data-name="external-link">
                    <path d="M20 11a1 1 0 0 0-1 1v6a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h6a1 1 0 0 0 0-2H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-6a1 1 0 0 0-1-1z"></path>
                    <path d="M16 5h1.58l-6.29 6.28a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0L19 6.42V8a1 1 0 0 0 1 1 1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-4a1 1 0 0 0 0 2z"></path>
                  </g>
                </g>
              </svg>
            </div>
            <div>
              <div dangerouslySetInnerHTML={{ __html: message }}></div>
              <div className="mt-3 d-flex justify-content-between">
                <Button
                  style={{
                    background: "none",
                    border: `1px solid ${THEME_COLOUR_PRIMARY}`,
                    color: THEME_COLOUR_PRIMARY,
                    borderRadius: 24,
                    padding: 3,
                    width: "49%",
                    textDecoration: "none",
                  }}
                  variant="link"
                  onClick={onCancel}
                >
                  <ChevronLeft size={24} /> <span>Go Back</span>
                </Button>
                <Button
                  style={{
                    borderRadius: 24,
                    padding: 3,
                    width: "49%",
                  }}
                  variant="danger"
                  onClick={onConfirm}
                >
                  <svg
                    viewBox="0 0 24 24"
                    height="24"
                    width="24"
                    focusable="false"
                    role="img"
                    fill="#FFF"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g data-name="Layer 2">
                      <g data-name="external-link">
                        <path d="M20 11a1 1 0 0 0-1 1v6a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h6a1 1 0 0 0 0-2H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-6a1 1 0 0 0-1-1z"></path>
                        <path d="M16 5h1.58l-6.29 6.28a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0L19 6.42V8a1 1 0 0 0 1 1 1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-4a1 1 0 0 0 0 2z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="d-inline-block ps-2">Follow Link</span>
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </StyledModal>
    </div>
  );
};

const StyledModal = styled(Modal)`
  position: "relative";
  & .modal-content {
    border-radius: 24px;
  }
`;
