import React from "react";
import { components, MenuProps } from "react-select";
import styled from "styled-components";

type Option = { label: string; value: string };

interface Props {
  onConfirmSubIssueSelection: () => void;
  menuProps: MenuProps<Option>;
}

export const MultiSelectMenu = React.memo(
  ({ onConfirmSubIssueSelection, menuProps }: Props) => {
    const Menu = React.useCallback((menuProps: MenuProps<Option>) => {
      return (
        <div>
          <components.Menu {...menuProps}>
            <div>
              <div>{menuProps.children}</div>

              {/* <div className="d-flex justify-content-between h-30 p-3">
                  <StyledApplyButton
                    className="pl-2"
                    onClick={() => {
                      menuProps.selectProps.onMenuClose();
                      onConfirmSubIssueSelection &&
                        onConfirmSubIssueSelection();
                    }}
                  >
                    Apply
                  </StyledApplyButton>
                  <StyledCancelButton
                    onClick={() => {
                      menuProps.selectProps.onMenuClose();
                    }}
                  >
                    Cancel
                  </StyledCancelButton>
                </div> */}
            </div>
          </components.Menu>
        </div>
      );
    }, []);

    return <>{Menu(menuProps)}</>;
  }
);

const StyledApplyButton = styled.button`
  background-color: #2f6fed;
  color: #fff;
  padding: 5px 20px;
  border-radius: 30px;
  border-color: #2f6fed;
`;
const StyledCancelButton = styled.button`
  background-color: #000;
  border-color: #000;
  border-radius: 30px;
  color: #fff;
  padding: 5px 20px;
`;
