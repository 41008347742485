import React from "react";
import { Card } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { SearchResultImprovised } from "../pages/search/SearchResults";

interface Props {
  data: SearchResultImprovised;
  tab: "article" | "twitter" | "telegram";
  handleItemClick?: (itemId?: string) => void;
}

export const IssueSubIssueMapTreeM3 = React.memo<Props>(
  ({ data, tab, handleItemClick }) => {
    const issues = React.useMemo(() => {
      return Object.keys(data);
    }, [data]);

    const handleTreeItemClick = (
      e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
      handleItemClick &&
        handleItemClick(
          e.currentTarget.attributes.getNamedItem("data-id")?.value
        );
    };

    return (
      <Card
        className="custom-scroll"
        style={{
          zIndex: 0,
          position: "sticky",
          top: 285,
          maxHeight: 450,
          overflowY: "auto",
        }}
      >
        <Accordion>
          {issues.map((issue) => {
            return (
              <Card.Body
                style={{
                  borderBottom: "1px solid #ccc",
                  paddingTop: "0.8rem",
                  paddingBottom: "0.8rem",
                  cursor: "pointer",
                }}
                onClick={handleTreeItemClick}
                data-id={`${tab}-${issue}`}
              >
                <div>
                  {issue} ({data[issue].length})
                </div>
              </Card.Body>
            );
          })}
        </Accordion>
      </Card>
    );
  }
);
