import React from "react";
import { ToastContainer } from "react-toastify";
import axiosInstance from "../../api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import { SendGAEvent } from "../../helpers/googleAnalyticsHepler";
import { setUser } from "../../store/slice/user";
import { setPreferredRegion } from "../../store/slice/issuePreference";
import { useDispatch } from "react-redux";
import { validateEmail } from "../../helpers";
import styled from "styled-components";
import { Button, Spinner } from "react-bootstrap";
import { ArrowBack } from "@styled-icons/material-outlined";

export const AutoLogin = () => {
  const [error, setError] = React.useState("");
  const [searchParam] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const email = searchParam.get("cmail");
  const userId = searchParam.get("cid");
  const otp = searchParam.get("otp");

  React.useEffect(() => {
    if (email && userId && otp && validateEmail(email)) {
      axiosInstance
        .post("/user/validate-otp", {
          params: {
            otp,
            email,
            userId,
            isAutoLogin: true,
          },
        })
        .then(
          (
            res: AxiosResponse<{
              data: {
                userDetail: {
                  name: string;
                  lastName: string;
                  email: string;
                  role: string;
                  subscribed_regions: Array<"IRAN" | "TAIWAN">;
                };
                token: string;
              };
            }>
          ) => {
            SendGAEvent({
              category: "SOTOS_OTP_Login_Success",
              action: "SOTOS_OTP_Login_Success",
              includeUserDetail: false,
              label: `${email?.replace("@", "-at-").replaceAll(".", "-dot-")}`,
            });
            localStorage.setItem(
              "user",
              JSON.stringify(res.data.data.userDetail)
            );
            localStorage.setItem("Auth", `Bearer ${res.data.data.token}`);
            dispatch(
              setUser({
                isLoggedIn: true,
                isSessionVaidationInProgress: false,
                profile: { ...res.data.data.userDetail },
              })
            );
            dispatch(
              setPreferredRegion({
                preferredRegion: res.data.data.userDetail
                  .subscribed_regions[0] ?? ["IRAN"],
              })
            );
          }
        )
        .catch((error) => {
          setError(error?.response?.data?.message);
        });
    } else {
      setError("Your Link is either invalid or expired. Please re-login.");
    }
  }, [dispatch, email, otp, userId]);

  const navigateToLogin = () => {
    navigate("/");
  };

  return (
    <>
      <div>
        <div className="d-flex justify-content-center pt-3 pb-5">
          <img height={100} src="quantum_politics_logo.png" alt="logo" />
        </div>
      </div>
      <Wrapper>
        {error ? (
          <div className="text-center">
            <div>{error}</div>
            <div>
              <Button onClick={navigateToLogin} variant="link">
                <ArrowBack size={20} />
                Back to Login
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div className="text-center">
              <Spinner
                style={{ width: "4rem", height: "4rem" }}
                animation="border"
                variant="success"
              />
            </div>
            <div>Please wait while we validate...</div>
          </div>
        )}
      </Wrapper>
      <ToastContainer />
    </>
  );
};

const Wrapper = styled.div`
  height: calc(70vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
`;
