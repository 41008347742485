import React from "react";
import { Card, Col, Row, Tab } from "react-bootstrap";
import {
  DEFAULT_ALL_ISSUES,
  DEFAULT_POST_LANGUAGE,
  DEFAULT_POST_LOCATION,
  SUBSCRIBED_REGION,
  V2_THEME_BLACK,
} from "../../constants";
import { ActionMeta, SingleValue } from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { FilterOperations, FilterTypes } from "../../types/filterTypes";
import axiosInstance from "../../api";
import axios, { AxiosResponse } from "axios";
import { getBackDate } from "../../helpers";
import { Loader } from "../../components/Loader";
import { useNavigate } from "react-router";
import { getRedisCacheKey } from "../../helpers/redisCacheKeyHelper";
import { ModifiedReactSelectV2 } from "../../components/ModifiedReactSelect";
import { setSearchScreenFilter } from "../../store/slice/search";
import { useDispatch, useSelector } from "react-redux";
import { SendGAEvent } from "../../helpers/googleAnalyticsHepler";
import { RootState } from "../../store";
import { SectionSubTitle } from "../../components/SectionSubTitle";
import { StyledTabs } from "../../components/StyledTab";
import { TwitterTabContent } from "./TwitterTabContent";
import { HashtagIcon } from "../../components/TabIcons";
import { StyledCardHeader, StyledCardTitle } from "./DigitalMediaCoverage";
import { MetaTabContent, TweeterMetaProps } from "./MetaTabContent";

const DEFAULT_SOCIAL_MEDIA_OPTIONS = [
  { label: "Twitter", value: "Twitter" },
  { label: "Telegram", value: "Telegram" },
];

type Option = { label: string; value: string };
type TweeterMeta = { issue: string; twitter_handle: string };
const DEFAULT_DATE_OPTIONS: Array<Option> = [
  {
    label: "Last 30 Days",
    value: "30",
  },
  {
    label: "Last 60 Days",
    value: "60",
  },
  {
    label: "Last 90 Days",
    value: "90",
  },
];

type FilterValue = {
  social_media: Option;
  source_language: Option;
  region: Option;
  date: Option;
  issue: Option;
};

const getParam = (
  selectedFilters: FilterValue,
  filterOptions: {
    social_media?: Array<Option>;
    source_language?: Array<Option>;
    region?: Array<Option>;
    issue: Array<Option>;
  }
) => {
  return {
    limit: 10,
    filters: {
      social_media: selectedFilters.social_media.value
        ? {
            value: selectedFilters.social_media.value,
            type: FilterTypes.eq,
          }
        : undefined,
      source_language: selectedFilters.source_language.value
        ? {
            value: selectedFilters.source_language.value,
            type: FilterTypes.eq,
          }
        : undefined,
      language: selectedFilters.source_language.value
        ? {
            value: selectedFilters.source_language.value,
            type: FilterTypes.eq,
          }
        : undefined,
      country: selectedFilters.region.value
        ? {
            value: selectedFilters.region.value,
            type: FilterTypes.eq,
          }
        : undefined,
      date: {
        operator: FilterOperations.AND,
        conditions: [
          {
            value: getBackDate("days", parseInt(selectedFilters.date.value)),
            type: FilterTypes.gte,
          },
          {
            value: getBackDate("today", 0),
            type: FilterTypes.lte,
          },
        ],
      },
      issue: selectedFilters.issue.value
        ? {
            value: [selectedFilters.issue.value],
            type: FilterTypes.includes,
          }
        : undefined,
    },
  };
};

export const HashtageCoverage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { preferredRegion } = useSelector(
    (state: RootState) => state.preference
  );

  const [activeTab, setActiveTab] = React.useState<"Twitter" | "Telegram">(
    "Twitter"
  );

  const [showLoader, setShowLoader] = React.useState(false);
  const [filterOptions, setFilterOptions] = React.useState<{
    social_media?: Array<Option>;
    source_language?: Array<Option>;
    region?: Array<Option>;
    issue: Array<Option>;
  }>({
    social_media: DEFAULT_SOCIAL_MEDIA_OPTIONS,
    source_language: undefined,
    region: undefined,
    issue: [],
  });

  const filterOptionsRef = React.useRef<{
    social_media?: Array<Option>;
    source_language?: Array<Option>;
    region?: Array<Option>;
    issue: Array<Option>;
  }>({
    social_media: DEFAULT_SOCIAL_MEDIA_OPTIONS,
    source_language: undefined,
    region: undefined,
    issue: [],
  });

  const [chartData, setChartData] = React.useState<{
    twitter: Array<{ x: string; y: number }>;
    telegram: Array<{ x: string; y: number }>;
  }>({
    twitter: [],
    telegram: [],
  });

  const [chartMetaData, setChartMetaData] = React.useState<
    Partial<TweeterMetaProps>
  >({
    tabMeta: undefined,
  });

  const [selectedIssue, setSelectedIssue] = React.useState<Array<Option>>([]);

  const selectedIssueRef = React.useRef<Array<Option>>([]);

  const [selectedFilters, setSelectedFilters] = React.useState<FilterValue>({
    social_media: { label: "Twitter", value: "Twitter" },
    source_language: { ...DEFAULT_POST_LANGUAGE },
    region: { ...DEFAULT_POST_LOCATION },
    date: { ...DEFAULT_DATE_OPTIONS[0] },
    issue: { ...DEFAULT_ALL_ISSUES },
  });

  React.useEffect(() => {
    setSelectedFilters({
      social_media: { label: "Twitter", value: "Twitter" },
      source_language: { ...DEFAULT_POST_LANGUAGE },
      region: { ...DEFAULT_POST_LOCATION },
      date: { ...DEFAULT_DATE_OPTIONS[0] },
      issue: { ...DEFAULT_ALL_ISSUES },
    });
  }, [preferredRegion]);

  React.useEffect(() => {
    const refinedParams = {
      ...getParam(selectedFilters, filterOptionsRef.current),
    };
    refinedParams.filters.social_media = undefined;
    refinedParams.filters.source_language = undefined;
    axiosInstance
      .post("temp/emerging-issues/get-top-tweets-by-meta", {
        params: {
          model: "M3",
          subscribed_region: preferredRegion,
          filters: refinedParams.filters,
        },
      })
      .then(
        (
          res: AxiosResponse<{
            data: {
              favouriteCount: Array<
                TweeterMeta & { tweet_favorite_count: number }
              >;
              quoteCount: Array<TweeterMeta & { tweet_quote_count: number }>;
              retweetCount: Array<
                TweeterMeta & { tweet_retweet_count: number }
              >;
              tweetCount: Array<TweeterMeta & { user_tweet_count: number }>;
            };
          }>
        ) => {
          setChartMetaData({
            tabMeta: {
              quoteCount: res.data.data.quoteCount,
              retweetCount: res.data.data.retweetCount,
              tweetCount: res.data.data.tweetCount,
              favouriteCount: res.data.data.favouriteCount,
            },
          });
        }
      );
  }, [
    preferredRegion,
    selectedFilters,
    selectedFilters.date.value,
    selectedFilters.region.value,
    selectedFilters.source_language.value,
  ]);

  React.useEffect(() => {
    axiosInstance
      .post("insights/hashtags/filter", {
        params: {
          model: "M3",
          subscribed_region: preferredRegion,
        },
      })
      .then(
        (
          res: AxiosResponse<{
            data: {
              social_media: Array<Option>;
              sourceLanguage: Array<Option>;
              region: Array<Option>;
              issue: Array<Option>;
            };
          }>
        ) => {
          setFilterOptions({
            social_media: DEFAULT_SOCIAL_MEDIA_OPTIONS,
            source_language: [
              { ...DEFAULT_POST_LANGUAGE },
              ...res.data.data.sourceLanguage,
            ],
            region: [{ ...DEFAULT_POST_LOCATION }, ...res.data.data.region],
            issue: [{ ...DEFAULT_ALL_ISSUES }, ...res.data.data.issue],
          });
          filterOptionsRef.current = {
            social_media: DEFAULT_SOCIAL_MEDIA_OPTIONS,
            source_language: [
              { ...DEFAULT_POST_LANGUAGE },
              ...res.data.data.sourceLanguage,
            ],
            region: [{ ...DEFAULT_POST_LOCATION }, ...res.data.data.region],
            issue: [{ ...DEFAULT_ALL_ISSUES }, ...res.data.data.issue],
          };
          selectedIssueRef.current = [
            { ...DEFAULT_ALL_ISSUES },
            ...res.data.data.issue,
          ];
          setSelectedIssue([{ ...DEFAULT_ALL_ISSUES }, ...res.data.data.issue]);
        }
      )
      .catch((err) => console.log(err));
  }, [preferredRegion]);

  React.useEffect(() => {
    SendGAEvent({
      category: "SOTOS_Insights_Top_Hashtag_Filter_Updated",
      action: "SOTOS_Insights_Top_Hashtag_Filter_Updated",
      includeUserDetail: true,
      label: `SL-${selectedFilters.source_language.value},region-${selectedFilters.region.value},date-${selectedFilters.date.value},issue-${selectedFilters.issue.value}`,
    });
    setShowLoader(true);
    axios
      .all(
        ["Twitter", "Telegram"].map((social_media) => {
          const refinedParams = getParam(
            selectedFilters,
            filterOptionsRef.current
          );
          refinedParams.filters.language = undefined;
          const updatedParams = {
            ...refinedParams,
            subscribed_region: preferredRegion,
            filters: {
              ...refinedParams.filters,
              social_media: {
                value: social_media,
                type: FilterTypes.eq,
              },
            },
          };
          const cacheKey = getRedisCacheKey(
            {
              ...selectedFilters,
              social_media: { label: "social_media", value: social_media },
            },
            preferredRegion
          );
          return axiosInstance.post("insights/hashtags", {
            params: { ...updatedParams, model: "M3", rck: cacheKey },
          });
        })
      )
      .then(
        (
          res: Array<
            AxiosResponse<{
              data: Array<{ x: string; y: number }>;
            }>
          >
        ) => {
          let twitterData: Array<{ x: string; y: number }> = [];
          let telegramData: Array<{ x: string; y: number }> = [];

          res.forEach((data, index) => {
            if (index === 0) {
              twitterData = data.data.data;
            }
            if (index === 1) {
              telegramData = data.data.data;
            }
          });

          setChartData({
            twitter: twitterData,
            telegram: telegramData,
          });
        }
      )
      .finally(() => {
        setShowLoader(false);
      });
  }, [selectedFilters, preferredRegion]);

  const onChangeFilterOption = React.useCallback(
    (newValue: SingleValue<unknown>, meta: ActionMeta<unknown>) => {
      setSelectedFilters({
        ...selectedFilters,
        [meta.name ?? ""]: newValue,
      });
    },
    [selectedFilters]
  );

  const handleClickOnHashtagRow = React.useCallback(
    (hashtag: string) => {
      SendGAEvent({
        category: "SOTOS_Insights_Top_Hashtag_Click",
        action: "SOTOS_Insights_Top_Hashtag_Click",
        includeUserDetail: true,
        label: hashtag,
      });
      dispatch(
        setSearchScreenFilter({
          query: hashtag,
          date: selectedFilters.date.value,
        })
      );
      window.scrollTo({ top: 0, behavior: "smooth" });
      setTimeout(() => {
        navigate(`/${preferredRegion.toLowerCase()}/discover`);
      }, 500);
    },
    [dispatch, navigate, selectedFilters.date.value, preferredRegion]
  );

  const handleIssueClick = React.useCallback(
    (selectedIssue: Option) => {
      SendGAEvent({
        category: "SOTOS_Insights_Top_Hashtag_Issue_Changed",
        action: "SOTOS_Insights_Top_Hashtag_Issue_Changed",
        label: `issue-${selectedIssue}`,
        includeUserDetail: true,
      });
      setSelectedFilters({
        ...selectedFilters,
        issue: { label: selectedIssue.label, value: selectedIssue.value },
      });
    },
    [selectedFilters]
  );

  const onClickChart = React.useCallback(
    (seriesIndex: number, seriesName?: string) => {
      if (chartMetaData.tabMeta) {
        const handleName =
          chartMetaData.tabMeta[
            seriesName as
              | "favouriteCount"
              | "quoteCount"
              | "retweetCount"
              | "tweetCount"
          ][seriesIndex].twitter_handle;
        SendGAEvent({
          category: "SOTOS_Insights_Top_Hashtag_TwitterHandle_Click",
          action: "SOTOS_Insights_Top_Hashtag_TwitterHandle_Click",
          label: `handle-@${handleName}`,
          includeUserDetail: true,
        });
        dispatch(
          setSearchScreenFilter({
            query: `@${handleName}`,
            date: selectedFilters.date.value,
          })
        );
        setTimeout(() => {
          navigate(`/${preferredRegion.toLowerCase()}/discover`);
        }, 0);
      }
    },
    [
      chartMetaData.tabMeta,
      dispatch,
      navigate,
      preferredRegion,
      selectedFilters.date.value,
    ]
  );

  const onClickHashtagTile = React.useCallback(
    (hashtag: string) => {
      dispatch(
        setSearchScreenFilter({
          query: `${hashtag}`,
          date: selectedFilters.date.value,
        })
      );
      setTimeout(() => {
        navigate(`/${preferredRegion.toLowerCase()}/discover`);
      }, 0);
    },
    [dispatch, navigate, preferredRegion, selectedFilters.date.value]
  );

  const onClickTwitterHandle = React.useCallback(
    (handle: string) => {
      dispatch(
        setSearchScreenFilter({
          query: `@${handle}`,
          date: selectedFilters.date.value,
        })
      );
      setTimeout(() => {
        navigate(`/${preferredRegion.toLowerCase()}/discover`);
      }, 0);
    },
    [dispatch, navigate, preferredRegion, selectedFilters.date.value]
  );

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <SectionSubTitle text="Top Twitter Handles & Hashtags" />
        <div className="d-flex my-3">
          <ModifiedReactSelectV2
            isSearchable={false}
            options={filterOptions.issue}
            name="issue"
            onChange={onChangeFilterOption}
            value={selectedFilters.issue}
            isDisabled={showLoader}
            className="pe-3"
            inputWidth={260}
          />
          <ModifiedReactSelectV2
            isSearchable={false}
            options={DEFAULT_DATE_OPTIONS}
            name="date"
            onChange={onChangeFilterOption}
            value={selectedFilters.date}
            isDisabled={showLoader}
            className="pe-3"
            inputWidth={200}
          />
          <ModifiedReactSelectV2
            isSearchable={false}
            options={filterOptions.source_language}
            name="source_language"
            onChange={onChangeFilterOption}
            value={selectedFilters.source_language}
            isDisabled={showLoader}
            className="pe-3"
            inputWidth={200}
            extraStyles={{
              menu: (baseStyles) => {
                return {
                  ...baseStyles,
                  width: 250,
                };
              },
            }}
          />
          <ModifiedReactSelectV2
            isSearchable={false}
            options={filterOptions.region}
            name="region"
            onChange={onChangeFilterOption}
            value={selectedFilters.region}
            isDisabled={showLoader}
            inputWidth={200}
          />
        </div>
      </div>

      {showLoader ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: 500 }}
        >
          <Loader />
        </div>
      ) : (
        <Row>
          <Col xl={6} lg={6} md={12} sm={12} xs={12}>
            {chartMetaData.tabMeta && (
              <MetaTabContent
                tabMeta={{ ...chartMetaData.tabMeta }}
                onClickChart={onClickChart}
                onClickTwitterHandle={onClickTwitterHandle}
              />
            )}
          </Col>
          <Col xl={6} lg={6} md={12} sm={12} xs={12}>
            <Card style={{ borderRadius: 24 }}>
              <StyledCardHeader className="d-flex align-items-center justify-content-between">
                <div>
                  <StyledCardTitle className="d-flex align-items-center">
                    <div
                      style={{ backgroundColor: "#F1F4F9" }}
                      className="p-2 rounded-circle"
                    >
                      <HashtagIcon isActive={false} color={V2_THEME_BLACK} />
                    </div>
                    <div className="ps-2" style={{ fontStyle: "inherit" }}>
                      Hashtag
                    </div>
                  </StyledCardTitle>
                </div>
              </StyledCardHeader>
              <Card.Body className="pt-0">
                <StyledTabs
                  defaultActiveKey={activeTab}
                  noBgOnActive={true}
                  onSelect={(tab: any, event: any) => {
                    setActiveTab(tab);
                  }}
                >
                  <Tab
                    eventKey="Twitter"
                    title={`Twitter`}
                    color="#94a3b8"
                    style={{ minHeight: 350 }}
                  >
                    <TwitterTabContent
                      selectedIssue={selectedFilters.issue.value}
                      onClickIssueName={handleIssueClick}
                      data={chartData.twitter}
                      onClickHashtagTile={onClickHashtagTile}
                    />
                  </Tab>
                  {preferredRegion === SUBSCRIBED_REGION.IRAN_REGION ? (
                    <Tab
                      eventKey="Telegram"
                      title={`Telegram`}
                      color="#94a3b8"
                      style={{ minHeight: 350 }}
                    >
                      <TwitterTabContent
                        selectedIssue={selectedFilters.issue.value}
                        onClickIssueName={handleIssueClick}
                        data={chartData.telegram}
                        onClickHashtagTile={onClickHashtagTile}
                      />
                    </Tab>
                  ) : null}
                </StyledTabs>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};
