import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { DropdownOption } from "../../types/types";

import { SingleValue } from "react-select";
import {
  DEFAULT_END_DATE,
  DEFAULT_POLITICAL_AFFILIATION,
  DEFAULT_POST_LANGUAGE,
  DEFAULT_POST_LOCATION,
  DEFAULT_START_DATE,
} from "../../constants";

interface ArticlesFilter {
  political_affiliation: DropdownOption;
  source_language: DropdownOption;
  date: {
    startDate: number | null;
    endDate: number | null;
  };
  sub_issue: Array<DropdownOption>;
}

interface TwitterFilter {
  country: SingleValue<DropdownOption>;
  language: SingleValue<DropdownOption>;
  date: {
    startDate: number | null;
    endDate: number | null;
  };
}

interface TelegramFilter {
  country: SingleValue<DropdownOption>;
  language: SingleValue<DropdownOption>;
  date: {
    startDate: number | null;
    endDate: number | null;
  };
}

interface Filters {
  articles: ArticlesFilter;
  twitter: TwitterFilter;
  telegram: TelegramFilter;
}

const DEFAULT_FILTER_VALUES = {
  articles: {
    political_affiliation: { ...DEFAULT_POLITICAL_AFFILIATION },
    source_language: { ...DEFAULT_POST_LANGUAGE },
    sub_issue: [],
    date: {
      startDate: DEFAULT_START_DATE,
      endDate: DEFAULT_END_DATE,
    },
  },
  twitter: {
    country: { ...DEFAULT_POST_LOCATION },
    language: { ...DEFAULT_POST_LANGUAGE },
    date: {
      startDate: DEFAULT_START_DATE,
      endDate: DEFAULT_END_DATE,
    },
  },
  telegram: {
    country: { ...DEFAULT_POST_LOCATION },
    language: { ...DEFAULT_POST_LANGUAGE },
    date: {
      startDate: DEFAULT_START_DATE,
      endDate: DEFAULT_END_DATE,
    },
  },
};

const initialState: Filters = {
  articles: {
    political_affiliation: { ...DEFAULT_POLITICAL_AFFILIATION },
    source_language: { ...DEFAULT_POST_LANGUAGE },
    sub_issue: [],
    date: {
      startDate: DEFAULT_START_DATE,
      endDate: DEFAULT_END_DATE,
    },
  },
  twitter: {
    country: { ...DEFAULT_POST_LOCATION },
    language: { ...DEFAULT_POST_LANGUAGE },
    date: {
      startDate: DEFAULT_START_DATE,
      endDate: DEFAULT_END_DATE,
    },
  },
  telegram: {
    country: { ...DEFAULT_POST_LOCATION },
    language: { ...DEFAULT_POST_LANGUAGE },
    date: {
      startDate: DEFAULT_START_DATE,
      endDate: DEFAULT_END_DATE,
    },
  },
};

export const reduxFilters = createSlice({
  name: "reduxFilters",
  initialState,
  reducers: {
    setArticlesFilterValues: (state, action: PayloadAction<ArticlesFilter>) => {
      state.articles = action.payload;
      state.twitter.date = action.payload.date;
      state.telegram.date = action.payload.date;
      state.twitter.language = action.payload.source_language;
      state.telegram.language = action.payload.source_language;
    },
    setTwitterFilterValue: (state, action: PayloadAction<TwitterFilter>) => {
      state.twitter = action.payload;
      state.articles.date = action.payload.date;
      state.telegram.date = action.payload.date;
      state.articles.source_language =
        action.payload.language ?? state.articles.source_language;
      state.telegram.language = action.payload.language;
    },
    setTelegramFilterValue: (state, action: PayloadAction<TelegramFilter>) => {
      state.telegram = action.payload;
      state.articles.date = action.payload.date;
      state.twitter.date = action.payload.date;
      state.articles.source_language =
        action.payload.language ?? state.articles.source_language;
      state.twitter.language = action.payload.language;
    },
    resetAllFilters: (state) => {
      return DEFAULT_FILTER_VALUES;
    },
  },
});

export const {
  resetAllFilters,
  setArticlesFilterValues,
  setTelegramFilterValue,
  setTwitterFilterValue,
} = reduxFilters.actions;

export const ReduxFilterReducer = reduxFilters.reducer;
