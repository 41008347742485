import React from "react";
import { ProgressBarComponent } from "../../components/ProgressBar";
import { PIE_CHART_COLOUR_PALLETE } from "../../constants";
import { TopIssueTileMeta } from "../../types/types";

export const ProgressBarStack = ({
  affiliation,
}: {
  affiliation: Array<TopIssueTileMeta>;
}) => {
  return (
    <>
      {affiliation.map((aff, index) => {
        return (
          <ProgressBarComponent
            color={
              PIE_CHART_COLOUR_PALLETE[index]
                ? PIE_CHART_COLOUR_PALLETE[index]
                : PIE_CHART_COLOUR_PALLETE[0]
            }
            extraCss={{
              width: `${aff.progressBarPercentage}%`,
              marginLeft: index === 0 ? "" : "5px",
            }}
            progress={100}
            key={index}
          />
        );
      })}
    </>
  );
};
