import {
  PersonAddAlt,
  PersonOff,
  Search,
} from "@styled-icons/material-outlined";
import * as React from "react";
import { Button, Form, InputGroup, Table } from "react-bootstrap";
import { ConfirmationDialog } from "../../components/ConfirmationDialog";
import { THEME_COLOUR_PRIMARY } from "../../constants";
import { UserListRecord } from "../../types/types";

interface Props {
  data: Array<UserListRecord>;
  onDeactivateUser: (email: string, activationStatus: boolean) => void;
  searchUser: (keyword: string) => void;
}

export const UsersList = ({ data, onDeactivateUser, searchUser }: Props) => {
  const setSearchUserTimer = React.useRef<any>();
  const userRef = React.useRef<any>("");
  const [showDeactivateConfirmation, setShowDeactivateConfirmation] =
    React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState<string>("");

  const handleConfirmation = (email: string, activationStatus: boolean) => {
    setShowDeactivateConfirmation(true);
    userRef.current = {
      email,
      activationStatus,
    };
  };

  const onConfirmDeactivation = () => {
    onDeactivateUser(userRef.current.email, userRef.current.activationStatus);
    setShowDeactivateConfirmation(false);
  };
  const onCancelConfirmation = () => {
    setShowDeactivateConfirmation(false);
    userRef.current = "";
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let term = e.target.value;
    setSearchTerm(e.target.value);
    if (setSearchUserTimer.current) {
      clearTimeout(setSearchUserTimer.current);
    }

    setSearchUserTimer.current = setTimeout(() => {
      searchUser(term);
    }, 1000);
  };
  return (
    <div className="my-5">
      {showDeactivateConfirmation && (
        <ConfirmationDialog
          onCancel={onCancelConfirmation}
          onConfirm={onConfirmDeactivation}
          message={`Are you sure you want to ${
            userRef.current?.activationStatus ? "Activate" : "Deactivate"
          } User?`}
        />
      )}
      <InputGroup>
        <InputGroup.Text>
          <Search size={20} />
        </InputGroup.Text>
        <Form.Control
          placeholder="Search Invitee by name, email or organisation"
          aria-label="Username"
          aria-describedby="basic-addon1"
          value={searchTerm}
          onChange={onInputChange}
        />
      </InputGroup>
      {data.length ? (
        <>
          <Table striped bordered hover className="my-5">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Organisation</th>
                <th>Is Active</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((record) => {
                return (
                  <>
                    <tr key={record.email}>
                      <td>{record.name}</td>
                      <td>{record.lastName}</td>
                      <td>{record.email}</td>
                      <td>{record.organisation}</td>
                      <td>{record.isUserActive ? "Yes" : "No"}</td>
                      <td>
                        <Button
                          style={{ backgroundColor: THEME_COLOUR_PRIMARY }}
                          onClick={() => {
                            handleConfirmation(
                              record.email,
                              !record.isUserActive
                            );
                          }}
                        >
                          {record.isUserActive ? (
                            <PersonOff size={20} />
                          ) : (
                            <PersonAddAlt size={20} />
                          )}
                        </Button>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </>
      ) : (
        <div className="mt-5 text-center">No Record Available</div>
      )}
    </div>
  );
};
