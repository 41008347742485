import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface InfluencerEntity {
  name: string;
  photo: string;
  farsi_name: string;
}

const INITIAL_STATE: Record<string, InfluencerEntity> = {};

export const InfluencerEntitySlice = createSlice({
  name: "influencer_entity",
  initialState: INITIAL_STATE,
  reducers: {
    setInfluencer: (state, payload: PayloadAction<Array<InfluencerEntity>>) => {
      payload.payload.forEach((entity) => {
        state[entity.name] = entity;
      });
      return state;
    },
    getInfluencer: (state) => {
      return state;
    },
    resetInfuencer: (state) => {
      state = {};
      return state;
    },
  },
});

export const { reducer: InfluencerReducer } = InfluencerEntitySlice;

export const { setInfluencer, resetInfuencer } = InfluencerEntitySlice.actions;
