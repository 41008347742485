import React from "react";
import { styled } from "styled-components";
import { Row, Col, Spinner, Modal } from "react-bootstrap";
import { Button, Form, InputGroup } from "react-bootstrap";
import axiosInstance from "../../api";
import { AxiosResponse } from "axios";
import { isMobile, isIPad13, isTablet } from "react-device-detect";
import { isOnlyCharacters, validateEmail } from "../../helpers";
import { toast, ToastContainer } from "react-toastify";
import {
  SITE_CONSTANTS,
  TAB_TITLE,
  THEME_COLOUR_PRIMARY,
} from "../../constants";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/slice/user";
import {
  Close,
  Email,
  Lock,
  Password,
  Person,
} from "@styled-icons/material-outlined";
import { ButtonContainer } from "../../components/CommonStyledComponents";
import { setPreferredRegion } from "../../store/slice/issuePreference";
import { useNavigate } from "react-router";
import { ReactGA } from "../../components/GoogleAnalytics";
import { SendGAEvent } from "../../helpers/googleAnalyticsHepler";

const DEFAULT_FORM_ERRORS = {
  name: false,
  lastName: false,
  email: false,
  password: false,
  otp: false,
};

interface FormError {
  name: boolean;
  lastName: boolean;
  email: boolean;
  password: boolean;
  otp: boolean;
}

export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [contactUsAlert, setShowContactUsAlert] = React.useState(false);
  const [requestProgress, setIsRequestInProgress] = React.useState({
    login: false,
    register: false,
    otpValidation: false,
    forgotPasword: false,
  });
  const [isOtpTriggered, setIsOtpTriggered] = React.useState(false);
  const [submitType, setSubmitType] = React.useState<"login" | "register">(
    "login"
  );
  const [isForgotPasswordActive, setIsForgotPasswordActive] =
    React.useState(false);
  const userDetailRef = React.useRef<{
    name?: string;
    lastName?: string;
    email?: string;
    id?: number;
  }>({
    name: undefined,
    lastName: undefined,
    email: undefined,
    id: undefined,
  });
  const [formError, setFormError] = React.useState<FormError>({
    ...DEFAULT_FORM_ERRORS,
  });
  const [form, setForm] = React.useState<{
    name?: string;
    lastName?: string;
    email?: string;
    password?: string;
    otp?: string;
  }>({
    name: "",
    lastName: "",
    email: "",
    password: "",
    otp: "",
  });

  const [showMobileDeviceAlert, setShowMobileDeviceAlert] = React.useState(
    isMobile || isIPad13 || isTablet || window.innerWidth < 1280
  );

  React.useEffect(() => {
    document.title = TAB_TITLE.default;
    ReactGA.event({
      category: "SOTOS_Login_Page_Landed",
      action: "SOTOS_Login_Page_Landed",
      label: "SOTOS Login Page",
    });
  }, []);

  const handleSubmitType = React.useCallback(() => {
    setSubmitType(submitType === "login" ? "register" : "login");
    setIsOtpTriggered(false);
    setForm({
      name: "",
      lastName: "",
      email: "",
      password: "",
      otp: "",
    });
    setFormError({
      name: false,
      lastName: false,
      email: false,
      password: false,
      otp: false,
    });
    setIsForgotPasswordActive(false);
  }, [submitType]);

  const onInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputName: string
  ) => {
    setForm({
      ...form,
      [inputName]: e.target.value,
    });
  };

  const validateForm = React.useCallback(
    (type: "Login" | "Register" | "OTP" | "Forgot-Password") => {
      let isFormValid = true;
      const errors = { ...DEFAULT_FORM_ERRORS };
      switch (type) {
        case "OTP":
          if (!form.otp?.trim()) {
            errors["otp"] = true;
            isFormValid = false;
          }
          break;
        case "Register":
          if (
            !form.email?.trim() ||
            (form.email && !validateEmail(form.email))
          ) {
            errors.email = true;
            isFormValid = false;
          }
          if (!form.password?.trim()) {
            errors["password"] = true;
            isFormValid = false;
          }
          if (
            !form.name?.trim() ||
            (form.name && !isOnlyCharacters(form.name))
          ) {
            errors.name = true;
            isFormValid = false;
          }
          if (
            !form.lastName?.trim() ||
            (form.lastName && !isOnlyCharacters(form.lastName))
          ) {
            errors["lastName"] = true;
            isFormValid = false;
          }
          break;

        case "Login":
          if (
            !form.email?.trim() ||
            (form.email && !validateEmail(form.email))
          ) {
            errors.email = true;
            isFormValid = false;
          }
          if (!form.password?.trim()) {
            errors["password"] = true;
            isFormValid = false;
          }
          break;

        case "Forgot-Password":
          if (
            !form.email?.trim() ||
            (form.email && !validateEmail(form.email))
          ) {
            errors.email = true;
            isFormValid = false;
          }
          break;

        default:
          break;
      }
      setFormError(errors);
      return isFormValid;
    },
    [form.email, form.lastName, form.name, form.otp, form.password]
  );

  const handleUserRegistration = React.useCallback(() => {
    if (!validateForm("Register")) {
      return;
    }
    setIsRequestInProgress({
      ...requestProgress,
      register: true,
    });
    axiosInstance
      .post("/user/register", {
        params: {
          name: form.name,
          lastName: form.lastName,
          email: form.email,
          password: form.password,
        },
      })
      .then(
        (
          res: AxiosResponse<{
            user: {
              email: string;
              id: number;
            };
          }>
        ) => {
          if (res.data.user.id) {
            toast.success(
              "You have successfully registered. Please login to access the portal.",
              {
                position: "top-right",
                autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
                pauseOnHover: true,
                toastId: "error-toast",
              }
            );

            setSubmitType("login");
            setForm({
              name: "",
              lastName: "",
              email: "",
              password: "",
            });
          }
        }
      )
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
          pauseOnHover: true,
          toastId: "error-toast",
        });
      })
      .finally(() => {
        setIsRequestInProgress({
          ...requestProgress,
          register: false,
          otpValidation: false,
          login: false,
          forgotPasword: false,
        });
      });
  }, [
    form.email,
    form.lastName,
    form.name,
    form.password,
    validateForm,
    requestProgress,
  ]);

  const handleUserLogin = React.useCallback(() => {
    if (!validateForm("Login")) {
      return;
    }
    ReactGA.event({
      category: "SOTOS_Login_Submit_Clicked",
      action: "SOTOS_Login_Submit_Clicked",
      label: `${form.email?.replace("@", "-at-").replaceAll(".", "-dot-")}`,
    });
    setIsRequestInProgress({
      ...requestProgress,
      login: true,
    });
    axiosInstance
      .post("/user/login", {
        params: {
          email: form.email,
          password: form.password,
        },
      })
      .then(
        (
          res: AxiosResponse<{
            doByPassOTP?: boolean;
            user: {
              id: number;
              email: string;
            };
            data?: {
              userDetail: {
                name: string;
                lastName: string;
                email: string;
                role: string;
                subscribed_regions: Array<string>;
              };
              token: string;
            };
          }>
        ) => {
          if (res.data.doByPassOTP && res.data.data) {
            localStorage.setItem(
              "user",
              JSON.stringify(res.data.data.userDetail)
            );
            localStorage.setItem("Auth", `Bearer ${res.data.data.token}`);
            dispatch(
              setUser({
                isLoggedIn: true,
                isSessionVaidationInProgress: false,
                profile: { ...res.data.data.userDetail },
              })
            );
          } else if (res.data.user.id) {
            ReactGA.event({
              category: "SOTOS_Login_OTP_Trigger_Success",
              action: "SOTOS_Login_OTP_Trigger_Success",
              label: `${form.email
                ?.replace("@", "-at-")
                .replaceAll(".", "-dot-")}`,
            });
            userDetailRef.current = {
              ...userDetailRef.current,
              id: res.data.user.id,
              email: res.data.user.email,
            };
            setIsOtpTriggered(true);
            toast.success(
              "Please enter OTP sent to your registered email id.",
              {
                position: "top-right",
                autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
                pauseOnHover: true,
                toastId: "error-toast",
              }
            );
          }
        }
      )
      .catch((error) => {
        ReactGA.event({
          category: "SOTOS_Login_Error",
          action: "SOTOS_Login_Error",
          label: `${form.email
            ?.replaceAll("@", "-at-")
            .replaceAll(".", "-dot-")}/${error.response.data.message}`,
        });
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
          pauseOnHover: true,
          toastId: "error-toast",
        });
      })
      .finally(() => {
        setIsRequestInProgress({
          ...requestProgress,
          register: false,
          otpValidation: false,
          login: false,
          forgotPasword: false,
        });
      });
  }, [validateForm, requestProgress, form.email, form.password, dispatch]);

  const handleOTPVerification = () => {
    if (!validateForm("OTP")) {
      return;
    }
    setIsRequestInProgress({
      ...requestProgress,
      otpValidation: true,
    });
    setSubmitType("register");
    axiosInstance
      .post("/user/validate-otp", {
        params: {
          otp: form.otp,
          email: userDetailRef.current.email,
          userId: userDetailRef.current.id,
        },
      })
      .then(
        (
          res: AxiosResponse<{
            data: {
              userDetail: {
                name: string;
                lastName: string;
                email: string;
                role: string;
                subscribed_regions: Array<"IRAN" | "TAIWAN">;
              };
              token: string;
            };
          }>
        ) => {
          SendGAEvent({
            category: "SOTOS_OTP_Login_Success",
            action: "SOTOS_OTP_Login_Success",
            includeUserDetail: false,
            label: `${form.email
              ?.replace("@", "-at-")
              .replaceAll(".", "-dot-")}`,
          });
          localStorage.setItem(
            "user",
            JSON.stringify(res.data.data.userDetail)
          );
          localStorage.setItem("Auth", `Bearer ${res.data.data.token}`);
          dispatch(
            setUser({
              isLoggedIn: true,
              isSessionVaidationInProgress: false,
              profile: { ...res.data.data.userDetail },
            })
          );
          dispatch(
            setPreferredRegion({
              preferredRegion: res.data.data.userDetail
                .subscribed_regions[0] ?? ["IRAN"],
            })
          );
        }
      )
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
          pauseOnHover: true,
          toastId: "error-toast",
        });
      })
      .finally(() => {
        setIsRequestInProgress({
          ...requestProgress,
          register: false,
          otpValidation: false,
          login: false,
          forgotPasword: false,
        });
      });
  };

  const handleForgotPassword = () => {
    if (!validateForm("Forgot-Password")) {
      return;
    }
    setIsRequestInProgress({
      ...requestProgress,
      forgotPasword: true,
    });
    axiosInstance
      .post("/user/forgot-password", {
        params: {
          email: form.email,
        },
      })
      .then(
        (
          res: AxiosResponse<{
            error: boolean;
            message: string;
          }>
        ) => {
          if (res.status === 200) {
            toast.success(res.data.message, {
              position: "top-right",
              autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
              pauseOnHover: true,
              toastId: "error-toast",
            });
          }
        }
      )
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
          pauseOnHover: true,
          toastId: "error-toast",
        });
      })
      .finally(() => {
        setIsRequestInProgress({
          ...requestProgress,
          register: false,
          otpValidation: false,
          login: false,
          forgotPasword: false,
        });
      });
  };

  const handleClickRegister = () => {
    navigate("/register");
  };

  return (
    <PageContainer>
      <Row
        className="g-0 justify-content-center align-items-center"
        style={{ height: "85vh" }}
      >
        <Col xl={3} lg={3} md={12} sm={12}>
          <LoginContainer>
            <div className="d-flex justify-content-center pt-3 pb-5">
              <img height={100} src="quantum_politics_logo.png" alt="logo" />
            </div>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              className="login-form"
            >
              {!!(submitType === "register") &&
                !isOtpTriggered &&
                !isForgotPasswordActive && (
                  <>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <InputGroup>
                        <InputGroup.Text>
                          <Person size={20} />
                        </InputGroup.Text>
                        <Form.Control
                          name="namformErrore"
                          type="input"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onInputChange(e, "name")
                          }
                          placeholder="First Name"
                          isInvalid={formError.name}
                          value={form.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter valid name.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <InputGroup>
                        <InputGroup.Text>
                          <Person size={20} />
                        </InputGroup.Text>
                        <Form.Control
                          name="lastName"
                          type="text"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onInputChange(e, "lastName")
                          }
                          placeholder="Last Name"
                          isInvalid={formError.lastName}
                          value={form.lastName}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter valid last name.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </>
                )}

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <InputGroup className="border-0 rounded-pill">
                  <InputGroup.Text>
                    <Email size={20} />
                  </InputGroup.Text>
                  <Form.Control
                    type="email"
                    disabled={isOtpTriggered}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onInputChange(e, "email")
                    }
                    isInvalid={formError.email}
                    value={form.email}
                    placeholder={
                      submitType === "register" || isForgotPasswordActive
                        ? "Email"
                        : ""
                    }
                    className="bg-transparent"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter valid email address
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              {isForgotPasswordActive ? (
                <>
                  {requestProgress.forgotPasword ? (
                    <ButtonContainer>
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </ButtonContainer>
                  ) : (
                    <ButtonContainer>
                      <Button
                        onClick={handleForgotPassword}
                        variant="primary"
                        type="submit"
                        style={{
                          borderRadius: 30,
                          color: "#63f3dc",
                          borderColor: THEME_COLOUR_PRIMARY,
                          backgroundColor: THEME_COLOUR_PRIMARY,
                        }}
                      >
                        Reset Password
                      </Button>
                    </ButtonContainer>
                  )}
                </>
              ) : (
                !isOtpTriggered && (
                  <>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <InputGroup className="border-0 rounded-pill">
                        <InputGroup.Text>
                          <Lock size={20} />
                        </InputGroup.Text>
                        <Form.Control
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onInputChange(e, "password")
                          }
                          type="password"
                          name="password"
                          isInvalid={formError.password}
                          value={form.password}
                          placeholder={
                            submitType === "register" ? "Password" : ""
                          }
                          className="bg-transparent"
                        />
                        <Form.Control.Feedback
                          className="border-0"
                          type="invalid"
                        >
                          Password is required
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    {requestProgress.login || requestProgress.register ? (
                      <ButtonContainer>
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </ButtonContainer>
                    ) : (
                      <ButtonContainer>
                        <Button
                          onClick={
                            submitType === "login"
                              ? handleUserLogin
                              : handleUserRegistration
                          }
                          type="submit"
                          style={{
                            borderRadius: 30,
                            color: "#63f3dc",
                            backgroundColor: THEME_COLOUR_PRIMARY,
                            borderColor: THEME_COLOUR_PRIMARY,
                          }}
                        >
                          {submitType === "login" ? "Login" : "Register"}
                        </Button>
                      </ButtonContainer>
                    )}
                  </>
                )
              )}

              {isOtpTriggered && (
                <>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <InputGroup>
                      <InputGroup.Text>
                        <Password size={20} />
                      </InputGroup.Text>
                      <Form.Control
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onInputChange(e, "otp")
                        }
                        type="text"
                        isInvalid={formError.otp}
                        value={form.otp}
                        placeholder="OTP"
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        Otp is required
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  {requestProgress.otpValidation ? (
                    <>
                      <ButtonContainer>
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </ButtonContainer>
                    </>
                  ) : (
                    <ButtonContainer>
                      <Button
                        onClick={handleOTPVerification}
                        variant="primary"
                        type="submit"
                        style={{
                          borderRadius: 30,
                          color: "#63f3dc",
                          backgroundColor: THEME_COLOUR_PRIMARY,
                          borderColor: THEME_COLOUR_PRIMARY,
                        }}
                      >
                        Validate OTP
                      </Button>
                    </ButtonContainer>
                  )}
                </>
              )}
            </Form>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: 20,
              }}
            >
              {submitType === "login" ? (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={handleClickRegister}
                >
                  Request Access
                </div>
              ) : (
                <div style={{ cursor: "pointer" }} onClick={handleSubmitType}>
                  Back to Login
                </div>
              )}
              {!isForgotPasswordActive && !isOtpTriggered ? (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsForgotPasswordActive(true);
                    setFormError({
                      ...DEFAULT_FORM_ERRORS,
                    });
                  }}
                >
                  Forgot password?
                </div>
              ) : (
                submitType === "login" &&
                !isOtpTriggered && (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSubmitType("login");
                      setIsForgotPasswordActive(false);
                      setFormError({
                        ...DEFAULT_FORM_ERRORS,
                      });
                    }}
                  >
                    Back to Login
                  </div>
                )
              )}
            </div>
          </LoginContainer>
        </Col>
        {contactUsAlert ? (
          <div
            className="mx-5 text-center"
            onClick={() => setShowContactUsAlert(!contactUsAlert)}
          >
            For inquiries about purchasing our web tool, please contact us
            at&nbsp;
            <a
              style={{ color: THEME_COLOUR_PRIMARY }}
              href="mailto:support@persumedia.com?subject=Inquiry for SOTOS web portal"
            >
              email
            </a>
            <span className="ps-5" onClick={() => setShowContactUsAlert(false)}>
              <Close size={20} />
            </span>
          </div>
        ) : null}
      </Row>
      <div className="d-flex justify-content-between align-items-center pb-5">
        <div className="ps-5">
          <img src="QP.svg" alt="Quantum Politics Logo" />
        </div>
        {/* <a
          href="https://www.persumedia.com/privacy-policy/"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a> */}
        <div className="pe-5">
          <img
            title="Powered By: PersuMedia"
            src="PersuMedia.svg"
            alt="Persumedia Logo"
            className="img-fluid"
          />
        </div>
      </div>

      <Modal
        onHide={() => setShowMobileDeviceAlert(false)}
        show={showMobileDeviceAlert}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Dear User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          SOTOS is not currently optimized for mobile version. For optimal
          experience please use your Desktop/Laptop.
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: `${THEME_COLOUR_PRIMARY}`,
              borderColor: `${THEME_COLOUR_PRIMARY}`,
            }}
            onClick={() => setShowMobileDeviceAlert(false)}
          >
            Dismiss
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </PageContainer>
  );
};

const PageContainer = styled.div``;

const LoginContainer = styled.div`
  margin: 50px;
`;
