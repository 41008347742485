import React from "react";
import axios, { AxiosResponse } from "axios";
import { ActionMeta, SingleValue } from "react-select";
import { Card, Row, Tab } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from "luxon";
import { axiosInstance } from "../../api/index";
import { FilterTypes, FilterOperations } from "../../types/filterTypes";
import {
  DEFAULT_END_DATE,
  DEFAULT_POST_LOCATION,
  DEFAULT_START_DATE,
  M3_PARAM,
  PIE_CHART_COLOUR_PALLETE,
  SUBSCRIBED_REGION,
  V2_THEME_BLACK,
} from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setSelectedIssueM3 } from "../../store/slice/issuePreference";
import {
  setTelegramFilterValue,
  setTwitterFilterValue,
} from "../../store/slice/filters";

import { ModifiedReactSelectV2 } from "../../components/ModifiedReactSelect";

import { RootState } from "../../store";
import { DropdownOption, TopIssueTileMeta } from "../../types/types";
import { StyledCardHeader, StyledCardTitle } from "./DigitalMediaCoverage";
import { TwitterIcon } from "../../components/TabIcons";
import { StyledTabs } from "../../components/StyledTab";
import { ProgressBarStack } from "./ProgressBarStack";
import { TileListing } from "./TileListing";
import { SendGAEvent } from "../../helpers/googleAnalyticsHepler";

export const SocialMediaCoverage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { preferredRegion, issuesM3, taiwanIssues } = useSelector(
    (state: RootState) => state.preference
  );
  const { twitter: reduxTwitterFilter, telegram: reduxTelegramFilter } =
    useSelector((state: RootState) => state.reduxFilter);

  const [activeTab, setActiveTab] = React.useState<"Twitter" | "Telegram">(
    "Twitter"
  );

  const [filterOptions, setFilterOptions] = React.useState<{
    location?: Array<DropdownOption>;
  }>({
    location: undefined,
  });

  const [topSocialIssue, setTopSocialIssue] = React.useState<{
    twitter: Array<TopIssueTileMeta>;
    telegram: Array<TopIssueTileMeta>;
  } | null>(null);

  const refinedParams = React.useMemo(() => {
    setTopSocialIssue(null);
    return {
      subscribed_region: preferredRegion,
      filters: {
        social_media: {
          value: "",
          type: FilterTypes.eq,
        },
        language: {
          value: reduxTwitterFilter.language?.value,
          type: FilterTypes.eq,
        },
        location: {
          value: reduxTwitterFilter.country?.value,
          type: FilterTypes.eq,
        },
        date: {
          operator: FilterOperations.AND,
          conditions: [
            {
              value: reduxTwitterFilter.date.startDate
                ? DateTime.fromJSDate(
                    DateTime.fromMillis(
                      reduxTwitterFilter.date.startDate
                    ).toJSDate()
                  ).toFormat("yyyy-LL-dd")
                : DateTime.fromJSDate(
                    DateTime.fromMillis(DEFAULT_START_DATE).toJSDate()
                  ).toFormat("yyyy-LL-dd"),
              type: FilterTypes.gte,
            },
            {
              value: reduxTwitterFilter.date.endDate
                ? DateTime.fromJSDate(
                    DateTime.fromMillis(
                      reduxTwitterFilter.date.endDate
                    ).toJSDate()
                  ).toFormat("yyyy-LL-dd")
                : DateTime.fromJSDate(
                    DateTime.fromMillis(DEFAULT_END_DATE).toJSDate()
                  ).toFormat("yyyy-LL-dd"),
              type: FilterTypes.lte,
            },
          ],
        },
      },
    };
  }, [
    preferredRegion,
    reduxTwitterFilter.language?.value,
    reduxTwitterFilter.country?.value,
    reduxTwitterFilter.date.startDate,
    reduxTwitterFilter.date.endDate,
  ]);

  const getOriginalIssueName = React.useCallback(
    (englishIssueName: string) => {
      if (preferredRegion === SUBSCRIBED_REGION.IRAN_REGION) {
        const englishIssueIndex = issuesM3.englishIssues.findIndex(
          (issue) => issue === englishIssueName
        );
        if (englishIssueIndex > -1) {
          return issuesM3.originalLanguageIssues[englishIssueIndex];
        }
        return "";
      } else {
        const englishIssueIndex = taiwanIssues.englishIssues.findIndex(
          (issue) => issue === englishIssueName
        );
        if (englishIssueIndex > -1) {
          return taiwanIssues.originalLanguageIssues[englishIssueIndex];
        }
        return "";
      }
    },
    [
      issuesM3.englishIssues,
      issuesM3.originalLanguageIssues,
      preferredRegion,
      taiwanIssues.englishIssues,
      taiwanIssues.originalLanguageIssues,
    ]
  );

  React.useEffect(() => {
    const Social_Media_Opts =
      refinedParams.subscribed_region === SUBSCRIBED_REGION.IRAN_REGION
        ? ["Twitter", "Telegram"]
        : ["Twitter"];

    axios
      .all(
        Social_Media_Opts.map((social_media) => {
          refinedParams.filters.social_media.value = social_media;
          return axiosInstance.post("insights/social-media-coverage", {
            params: { ...refinedParams, ...M3_PARAM },
          });
        })
      )
      .then(
        (
          res: Array<
            AxiosResponse<{
              pieChartData: Array<{
                label: string;
                issue_count: number;
                overall_percentage: number;
              }>;
            }>
          >
        ) => {
          /* --- Calculate twitter additional percentage --- */
          const twitterTotalPercentage = res[0].data.pieChartData
            .slice(0, 6)
            .reduce((acc, current) => {
              return current.overall_percentage + acc;
            }, 0);

          const twitterEachAdditionalPercentage =
            (100 - twitterTotalPercentage) / 6;

          /* --- Calculate telegram additional percentage --- */

          const telegramTotalPercentage =
            res.length > 1
              ? res[1].data.pieChartData.slice(0, 6).reduce((acc, current) => {
                  return current.overall_percentage + acc;
                }, 0)
              : 0;

          const telegramEachAdditionalPercentage =
            res.length > 1 ? (100 - telegramTotalPercentage) / 6 : 0;

          const formattedResponse = {
            twitter: res[0].data.pieChartData
              .slice(0, 6)
              .map((twitterRec, index) => {
                return {
                  issueName: twitterRec.label,
                  indicatorColor: PIE_CHART_COLOUR_PALLETE[index]
                    ? PIE_CHART_COLOUR_PALLETE[index]
                    : PIE_CHART_COLOUR_PALLETE[0],
                  percentage: twitterRec.overall_percentage,
                  progressBarPercentage:
                    twitterRec.overall_percentage +
                    twitterEachAdditionalPercentage,
                  recordCount: twitterRec.issue_count,
                };
              }),
            telegram:
              res.length > 1
                ? res[1].data.pieChartData
                    .slice(0, 6)
                    .map((telegramRec, index) => {
                      return {
                        issueName: telegramRec.label,
                        indicatorColor: PIE_CHART_COLOUR_PALLETE[index]
                          ? PIE_CHART_COLOUR_PALLETE[index]
                          : PIE_CHART_COLOUR_PALLETE[0],
                        progressBarPercentage:
                          telegramRec.overall_percentage +
                          telegramEachAdditionalPercentage,
                        percentage: telegramRec.overall_percentage,
                        recordCount: telegramRec.issue_count,
                      };
                    })
                : [],
          };
          setTopSocialIssue(formattedResponse);
        }
      )
      .catch((err) => {
        console.log(err);
      });
  }, [getOriginalIssueName, refinedParams]);

  React.useEffect(() => {
    axiosInstance
      .post("insights/social-media-coverage/filters", {
        params: {
          ...M3_PARAM,
          subscribed_region: preferredRegion,
        },
      })
      .then(
        (
          res: AxiosResponse<{
            data: {
              location: Array<DropdownOption>;
            };
          }>
        ) => {
          setFilterOptions({
            location: [
              { ...DEFAULT_POST_LOCATION, label: "All Locations" },
              ...res.data.data.location,
            ],
          });
        }
      )
      .catch((err) => console.log(err));
  }, [preferredRegion]);

  const onChangeFilterOption = React.useCallback(
    (newValue: SingleValue<unknown>, meta: ActionMeta<unknown>) => {
      dispatch(
        activeTab === "Twitter"
          ? setTwitterFilterValue({
              ...reduxTwitterFilter,
              [meta.name ?? ""]: newValue,
            })
          : setTelegramFilterValue({
              ...reduxTelegramFilter,
              [meta.name ?? ""]: newValue,
            })
      );
    },
    [activeTab, dispatch, reduxTelegramFilter, reduxTwitterFilter]
  );

  const handleTileClick = React.useCallback(
    (tileMeta: TopIssueTileMeta) => {
      SendGAEvent({
        category: "SOTOS_Insights_Social_Chart_Issue_Clicked",
        action: "SOTOS_Insights_Social_Chart_Issue_Clicked",
        label: `Issue-${tileMeta.issueName}`,
        includeUserDetail: true,
      });
      dispatch(
        setSelectedIssueM3({
          selectedIssue: tileMeta.issueName,
          selectedTab: activeTab,
          region: preferredRegion,
        })
      );
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        navigate(`/${preferredRegion.toLowerCase()}/issue-watch`);
      }, 100);
    },
    [dispatch, navigate, preferredRegion, activeTab]
  );

  return (
    <>
      <Card style={{ borderRadius: 24 }}>
        <StyledCardHeader className="d-flex align-items-center justify-content-between">
          <div>
            <StyledCardTitle className="d-flex align-items-center">
              <div
                style={{ backgroundColor: "#F1F4F9" }}
                className="p-2 rounded-circle"
              >
                <TwitterIcon isActive={false} color={V2_THEME_BLACK} />
              </div>
              <div className="ps-2" style={{ fontStyle: "inherit" }}>
                Social Media Issues
              </div>
            </StyledCardTitle>
          </div>
          <div>
            <ModifiedReactSelectV2
              isSearchable={false}
              options={filterOptions.location}
              name="country"
              onChange={onChangeFilterOption}
              value={reduxTwitterFilter.country}
              inputWidth={200}
            />
          </div>
        </StyledCardHeader>
        <Card.Body className="pt-0">
          {topSocialIssue ? (
            <>
              <Row>
                <StyledTabs
                  defaultActiveKey={"Twitter"}
                  onSelect={(tab: any, event: any) => {
                    setActiveTab(tab);
                  }}
                  noBgOnActive={true}
                >
                  <Tab
                    eventKey={"Twitter"}
                    title={`Twitter`}
                    color="#94a3b8"
                    style={{ minHeight: 340 }}
                  >
                    <div className="d-flex pt-3">
                      <ProgressBarStack affiliation={topSocialIssue.twitter} />
                    </div>
                    <TileListing
                      affiliation={topSocialIssue.twitter}
                      onClickTile={handleTileClick}
                    />
                  </Tab>
                  {preferredRegion === SUBSCRIBED_REGION.IRAN_REGION && (
                    <Tab
                      eventKey={"Telegram"}
                      title={`Telegram`}
                      color="#94a3b8"
                      style={{ minHeight: 340 }}
                    >
                      <div className="d-flex pt-3">
                        <ProgressBarStack
                          affiliation={topSocialIssue.telegram}
                        />
                      </div>
                      <TileListing
                        affiliation={topSocialIssue.telegram}
                        onClickTile={handleTileClick}
                      />
                    </Tab>
                  )}
                </StyledTabs>
              </Row>
            </>
          ) : (
            <div
              style={{ minHeight: 388 }}
              className="d-flex align-items-center justify-content-center"
            >
              <div>Loading...</div>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
