import React from "react";
import { AxiosResponse } from "axios";
import axiosInstance from "../../api";
import {
  DATE_ORDER_BY_OPTIONS,
  DEFAULT_ALL_ISSUES,
  DEFAULT_END_DATE,
  DEFAULT_POLITICAL_AFFILIATION,
  DEFAULT_POST_LANGUAGE,
  DEFAULT_START_DATE,
  EMERGING_ISSUES_ARTICLE_CHAR_LIMIT,
  EMERGING_ISSUES_RECORD_LIMIT,
  EMPTY_CONTENT_STRING,
  ERROR_MSG_GENERIC,
  labels,
  M3_PARAM,
  MAX_ALLOWED_BACK_DATE,
  STATIC_SITE_DATE,
  SUBSCRIBED_REGION,
} from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { FilterOperations, FilterTypes } from "../../types/filterTypes";
import { SectionSubTitle } from "../../components/SectionSubTitle";
import { Info } from "@styled-icons/material-outlined";
import {
  ActionMeta,
  MultiValue,
  SingleValue,
  components,
  ValueContainerProps,
} from "react-select";
import { DateTime } from "luxon";
import { Col, Row, Spinner } from "react-bootstrap";
import { ArticeDetailsModal } from "../../components/ArticleDetailsModal";
import useOnScreen from "../../helpers/useOnScreen";
//import { usePrevious } from "../../helpers/usePrevious";
import { GoToTop } from "../../components/GoToTop";
import { StyledTabSubHeading } from ".";
import { setArticlesFilterValues } from "../../store/slice/filters";
import { getBackDate, showEnglishContent } from "../../helpers";
import { Article, GenAISummary } from "../../types/types";
import { StyledDateSelecterM3V2 } from "../../components/CommonStyledComponents";
import { PostCardM3 } from "../../components/PostCardM3";
import { SendGAEvent } from "../../helpers/googleAnalyticsHepler";
import { SummaryCard } from "../../components/SummaryCard";
import { ModifiedReactSelectV2 } from "../../components/ModifiedReactSelect";
import { CalendarIcon } from "../../components/CalendarIcon";
import { PDFDownloadable } from "../../components/PDFDownloadable";
import {
  DownloadButtonWapper,
  StyledMonthlyTrend,
} from "../Insights-m3-v2/OpinionAI";
import { FileDownload } from "@styled-icons/material-outlined/FileDownload";

type Option = { label: string; value: string };

interface Filters {
  political_affiliation: Array<Option>;
  sub_issue: Array<Option>;
  source_language: Array<Option>;
}

interface Props {
  activeIssue: string;
  updateCounter: (count: number) => void;
  activeTab: string;
}

export const ArticleList = ({
  activeIssue,
  updateCounter,
  activeTab,
}: Props) => {
  const dispatch = useDispatch();
  const dateSelectorRef = React.useRef<any>();

  const preferenceIssues = useSelector((state: RootState) => state.preference);
  const {
    selectedIssueM3: selectedIranIssue,
    selectedTaiwanIssue,
    preferredLanguage,
    preferredRegion,
  } = preferenceIssues;

  const [selectedIssueM3, setSelectedIssue] = React.useState<
    string | undefined
  >();

  React.useEffect(() => {
    setSelectedIssue(activeIssue);
  }, [activeIssue]);

  const { articles: reduxArticleFilter } = useSelector(
    (state: RootState) => state.reduxFilter
  );
  const elementRef = React.useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(elementRef);
  //const prevVisibility = usePrevious(isOnScreen);
  const [enableGenAIDownload, setEnableGenAIDownload] = React.useState(false);
  const [articles, setArticles] = React.useState<Array<Article>>([]);
  const [genAISummary, setGenAISummary] = React.useState<GenAISummary | null>(
    null
  );
  const [hasMoreRecords, setHasMoreRecords] = React.useState(false);
  const [error, setError] = React.useState("");

  const [articleDetailModal, setArticleDetailModal] = React.useState<{
    show: boolean;
    article?: Article;
  }>({
    show: false,
    article: undefined,
  });
  const [filterOptions, setFilterOptions] = React.useState<{
    source_language?: Array<Option>;
    political_affiliation?: Array<Option>;
    sub_issue?: Array<Option>;
  }>({
    source_language: undefined,
    political_affiliation: undefined,
    sub_issue: undefined,
  });

  const [dateOrder, setDateOrder] = React.useState<SingleValue<Option>>(
    DATE_ORDER_BY_OPTIONS[0]
  );

  const [selectedSubIssueState, setSelectedSubIssueState] = React.useState<
    Array<Option>
  >([]);
  const [selectedSubIssue, setSelectedSubIssue] = React.useState<Array<Option>>(
    []
  );
  const [dateFilter, setDateFilter] = React.useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: reduxArticleFilter.date.startDate
      ? new Date(reduxArticleFilter.date.startDate)
      : new Date(DEFAULT_START_DATE),
    endDate: reduxArticleFilter.date.endDate
      ? new Date(reduxArticleFilter.date.endDate)
      : new Date(DateTime.now().toMillis()),
  });
  const isApiReqInProgressRef = React.useRef(false);
  const articlesRef = React.useRef<Array<Article>>([]);
  const offsetLimitRef = React.useRef({
    limit: EMERGING_ISSUES_RECORD_LIMIT,
    offset: 0,
  });

  React.useEffect(() => {
    if (!selectedIssueM3) {
      return;
    }
    axiosInstance
      .post("temp/emerging-issues/articles-list/gen-ai-summary", {
        params: {
          subscribed_region: preferredRegion,
          filters: {
            issue: {
              type: FilterTypes.eq,
              value: selectedIssueM3,
            },
            date:
              process.env.REACT_APP_STATIC_SITE_FLAG === "true"
                ? {
                    type: FilterTypes.eq,
                    value: STATIC_SITE_DATE,
                  }
                : undefined,
          },
        },
      })
      .then(
        (
          res: AxiosResponse<{
            data: GenAISummary;
          }>
        ) => {
          if (Object.values(res.data.data).length) {
            setGenAISummary({
              date: res.data.data.date,
              issueName: selectedIssueM3,
              languages: res.data.data.languages,
              numberOfArticles: !!res.data.data.numberOfArticles
                ? res.data.data.numberOfArticles
                : res.data.data.sources.length,
              sources: res.data.data.sources,
              summary: res.data.data.summary,
            });
          } else {
            setGenAISummary(null);
          }
        }
      )
      .catch((err) => {
        console.log(err);
      });
  }, [selectedIssueM3, preferredRegion]);

  React.useEffect(() => {
    if (reduxArticleFilter.date.startDate && reduxArticleFilter.date.endDate) {
      setDateFilter({
        startDate: new Date(reduxArticleFilter.date.startDate),
        endDate: new Date(reduxArticleFilter.date.endDate),
      });
    }
  }, [reduxArticleFilter]);

  React.useEffect(() => {
    if (!selectedIssueM3) {
      return;
    }
    const params = {
      filters: {
        political_affiliation: reduxArticleFilter.political_affiliation.value
          ? {
              type: FilterTypes.eq,
              value: reduxArticleFilter.political_affiliation.value,
            }
          : undefined,
        issue: { type: FilterTypes.eq, value: selectedIssueM3 },
      },
      ...M3_PARAM,
    };
    axiosInstance
      .post("temp/emerging-issues/article-list-filters", {
        params: { ...params, subscribed_region: preferredRegion },
      })
      .then((res: { data: AxiosResponse<Filters> }) => {
        setFilterOptions({
          political_affiliation: [
            { ...DEFAULT_POLITICAL_AFFILIATION },
            ...res.data.data.political_affiliation,
          ],
          source_language: [
            { ...DEFAULT_POST_LANGUAGE },
            ...res.data.data.source_language,
          ],
          sub_issue: [{ ...DEFAULT_ALL_ISSUES }, ...res.data.data.sub_issue],
        });
        setSelectedSubIssue([
          { label: "All", value: "" },
          ...res.data.data.sub_issue,
        ]);
      })
      .catch((err) => console.log(err));
  }, [
    reduxArticleFilter.political_affiliation.value,
    selectedIssueM3,
    preferredRegion,
  ]);

  React.useEffect(() => {
    offsetLimitRef.current.offset = 0;
    articlesRef.current = [];
    setArticles([]);
    setSelectedSubIssueState([]);
    setHasMoreRecords(false);
  }, [selectedIssueM3, preferredRegion]);

  const fetchArticleList = React.useCallback(
    (params: { [key: string]: unknown }, reset?: boolean) => {
      if (!isApiReqInProgressRef.current) {
        isApiReqInProgressRef.current = true;
        axiosInstance
          .post("temp/emerging-issues/articles-list", {
            params: { ...params, subscribed_region: preferredRegion },
          })
          .then((res: { data: AxiosResponse<Array<Article>> }) => {
            if (reset) {
              updateCounter(res.data.data[0]?.total_records ?? 0);
              if (!res.data.data.length) {
                setError("No Records Available with this criteria");
              } else if (error) {
                setError("");
              }
            }

            if (res.data.data.length < EMERGING_ISSUES_RECORD_LIMIT) {
              setHasMoreRecords(false);
            } else if (res.data.data.length) {
              setHasMoreRecords(true);
            }

            if (reset && res.data.data.length) {
              articlesRef.current = res.data.data;
              setArticles([...articlesRef.current]);
            } else if (res.data.data.length) {
              articlesRef.current =
                res.data.data.length &&
                articlesRef.current.length >= EMERGING_ISSUES_RECORD_LIMIT
                  ? articlesRef.current.concat(res.data.data)
                  : ([] as Article[]).concat(res.data.data);
              setArticles([...articlesRef.current]);
            }
          })
          .catch((error) => {
            setError(ERROR_MSG_GENERIC);
          })
          .finally(() => {
            isApiReqInProgressRef.current = false;
          });
      }
    },
    [error, updateCounter, preferredRegion]
  );

  const filters = React.useMemo(() => {
    const subIssueFilterVal: Array<string> = [];
    selectedSubIssueState.forEach((data) => {
      if (data.value) {
        subIssueFilterVal.push(data.value);
      }
    });

    const leftAffiliationFilter =
      reduxArticleFilter.political_affiliation.value === "Left"
        ? {
            operator: FilterOperations.OR,
            conditions: [
              {
                value: 33.33,
                type: FilterTypes.eq,
              },
              {
                value: 50,
                type: FilterTypes.gte,
              },
            ],
          }
        : undefined;

    const centerAffiliationFilter =
      reduxArticleFilter.political_affiliation.value === "Center"
        ? {
            operator: FilterOperations.OR,
            conditions: [
              {
                value: 33.33,
                type: FilterTypes.eq,
              },
              {
                value: 50,
                type: FilterTypes.gte,
              },
            ],
          }
        : undefined;

    const rightAffiliationFilter =
      reduxArticleFilter.political_affiliation.value === "Right"
        ? {
            operator: FilterOperations.OR,
            conditions: [
              {
                value: 33.33,
                type: FilterTypes.eq,
              },
              {
                value: 50,
                type: FilterTypes.gte,
              },
            ],
          }
        : undefined;

    return {
      order: [["date", dateOrder?.value]],
      filters: {
        issue: {
          value: selectedIssueM3,
          type: FilterTypes.eq,
        },
        left_percentage: leftAffiliationFilter,
        right_percentage: rightAffiliationFilter,
        center_percentage: centerAffiliationFilter,
        source_language: reduxArticleFilter.source_language.value
          ? {
              value: reduxArticleFilter.source_language.value,
              type: FilterTypes.eq,
            }
          : undefined,
        sub_issue: subIssueFilterVal.length
          ? {
              value: subIssueFilterVal,
              type: FilterTypes.includes,
            }
          : undefined,
        date: {
          operator: FilterOperations.AND,
          conditions: [
            {
              value: reduxArticleFilter.date.startDate
                ? DateTime.fromMillis(
                    reduxArticleFilter.date.startDate
                  ).toFormat("yyyy-LL-dd")
                : getBackDate("week", 1),
              type: FilterTypes.gte,
            },
            {
              value: reduxArticleFilter.date.endDate
                ? DateTime.fromMillis(reduxArticleFilter.date.endDate).toFormat(
                    "yyyy-LL-dd"
                  )
                : getBackDate("today", 0),
              type: FilterTypes.lte,
            },
          ],
        },
      },
    };
  }, [
    dateOrder?.value,
    reduxArticleFilter.date?.endDate,
    reduxArticleFilter.date?.startDate,
    reduxArticleFilter.political_affiliation?.value,
    reduxArticleFilter.source_language?.value,
    selectedIssueM3,
    selectedSubIssueState,
  ]);
  React.useEffect(() => {
    if (!selectedIssueM3 || isApiReqInProgressRef.current) {
      return;
    }
    const params = {
      limit: offsetLimitRef.current.limit,
      offset: offsetLimitRef.current.offset,
      ...filters,
      ...M3_PARAM,
    };
    fetchArticleList(params, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, selectedIssueM3]);

  const getPostParams = React.useCallback(
    (article: Article) => {
      return {
        handle: showEnglishContent(article.english_title, preferredLanguage)
          ? article.english_title
          : article.title,
        date: article.date,
        postCategory: article.sub_issue,
        postContent: showEnglishContent(
          article.english_related_segment,
          preferredLanguage
        )
          ? article.english_related_segment
          : article.related_segment,
        postLanguage: article.source_language,
        charLimit: EMERGING_ISSUES_ARTICLE_CHAR_LIMIT,
        source: article.content_source,
        postLink: article.website_url,
        politicalAffiliation: article.political_affiliation,
        selectedFilterLang:
          reduxArticleFilter.source_language?.value ?? "English",
      };
    },
    [preferredLanguage, reduxArticleFilter.source_language?.value]
  );

  const onDateChange = React.useCallback(
    ([newStartDate, newEndDate]: [Date | null, Date | null]) => {
      if (dateSelectorRef.current.state.open && newEndDate) {
        dateSelectorRef.current.setOpen(false);
      }
      articlesRef.current = [];
      offsetLimitRef.current.offset = 0;
      setDateFilter({
        startDate: newStartDate
          ? new Date(DateTime.fromJSDate(newStartDate).toMillis())
          : null,
        endDate: newEndDate
          ? new Date(DateTime.fromJSDate(newEndDate).toMillis())
          : null,
      });
      if (newEndDate) {
        dispatch(
          setArticlesFilterValues({
            ...reduxArticleFilter,
            date: {
              startDate: newStartDate
                ? DateTime.fromJSDate(newStartDate).toMillis()
                : null,
              endDate: newEndDate
                ? DateTime.fromJSDate(newEndDate).toMillis()
                : null,
            },
          })
        );
      }
    },
    [dispatch, reduxArticleFilter]
  );

  const onChangeFilterOption = React.useCallback(
    (newValue: SingleValue<unknown>, meta: ActionMeta<unknown>) => {
      articlesRef.current = [];
      offsetLimitRef.current.offset = 0;

      dispatch(
        setArticlesFilterValues({
          ...reduxArticleFilter,
          [meta.name ?? ""]: newValue,
        })
      );
    },
    [dispatch, reduxArticleFilter]
  );

  const onChangeMultiFilter = React.useCallback(
    (newValue: MultiValue<Option>, meta: ActionMeta<Option>) => {
      if (meta.action === "select-option" && meta.option?.label === "All") {
        const filterOptsSub = filterOptions.sub_issue?.length
          ? filterOptions.sub_issue
          : [];
        setSelectedSubIssue([...filterOptsSub]);
        return;
      }

      if (meta.action === "deselect-option" && meta.option?.label === "All") {
        setSelectedSubIssue([]);
        return;
      }

      if (newValue.length && !newValue[0].value) {
        const selectedOpts = [...newValue].slice(1);
        setSelectedSubIssue([...selectedOpts]);
      } else {
        setSelectedSubIssue([...newValue]);
      }
    },
    [filterOptions.sub_issue]
  );

  const ValueContainer = React.useCallback(
    ({ children, ...props }: ValueContainerProps<Option>) => {
      let label = "";
      if (props.getValue().length === filterOptions.sub_issue?.length) {
        label = "All";
      } else if (props.getValue().length) {
        label = `${props.getValue().length} option selected`;
      }

      return (
        <components.ValueContainer {...props}>
          {label}
        </components.ValueContainer>
      );
    },
    [filterOptions.sub_issue?.length]
  );

  const onClickArticleDetail = React.useCallback((article: Article) => {
    SendGAEvent({
      category: "SOTOS_Emerging_Issue_Article_Detail_View",
      action: "SOTOS_Emerging_Issue_Article_Detail_View",
      includeUserDetail: true,
      label: `article-title:${article.english_title},source:${article.content_source},article-date:${article.date}`,
    });
    setArticleDetailModal({
      show: true,
      article: article,
    });
  }, []);

  const onHideArticleModal = React.useCallback(() => {
    setArticleDetailModal({
      show: false,
      article: undefined,
    });
  }, []);

  React.useEffect(() => {
    if (isOnScreen && hasMoreRecords && !isApiReqInProgressRef.current) {
      const params = {
        ...offsetLimitRef.current,
        offset: articles.length,
        ...filters,
        ...M3_PARAM,
      };
      fetchArticleList(params);
    }
  }, [articles.length, fetchArticleList, filters, hasMoreRecords, isOnScreen]);

  const onChangeFilterOrderBy = React.useCallback(
    (newValue: SingleValue<unknown>, actionMeta: ActionMeta<unknown>) => {
      articlesRef.current = [];
      offsetLimitRef.current = {
        offset: 0,
        limit: 20,
      };
      setDateOrder(newValue as Option);
    },
    []
  );

  const calendarDates = React.useMemo(() => {
    return {
      startDate: dateFilter.startDate,
      endDate: dateFilter.endDate,
    };
  }, [dateFilter]);

  const downloadPDF = () => {
    setEnableGenAIDownload(true);
  };

  return (
    <div id="scrollableDiv">
      {!!genAISummary && (
        <>
          <div className="mb-0 mt-0 d-flex justify-content-between align-items-center">
            <SectionSubTitle
              text="Discourse AI Summary"
              icon={<Info size={25} color="#475569" className="info-icon" />}
              iconPosition="Trailing"
              description="GenAI key takeaways"
              cssClasses="mt-2"
            />
            {enableGenAIDownload ? (
              <>
                <span>
                  <Spinner variant="success" />
                </span>
              </>
            ) : (
              <DownloadButtonWapper
                onClick={downloadPDF}
                style={{ cursor: "pointer" }}
              >
                <div>
                  <StyledMonthlyTrend>
                    <FileDownload size={20} />
                    <span className="d-inline-block ps-1">PDF</span>
                  </StyledMonthlyTrend>
                </div>
              </DownloadButtonWapper>
            )}
          </div>
          <SummaryCard {...genAISummary} />
        </>
      )}

      <StyledTabSubHeading>
        <SectionSubTitle
          text="Discourse AI"
          icon={<Info size={25} color="#475569" className="info-icon" />}
          iconPosition="Trailing"
          description="GenAI summaries of debates surrounding issues of interest."
        />
      </StyledTabSubHeading>
      <div className="mb-2">
        <Row className="d-flex flex-row">
          <Col lg={3} sm={12} xs={12} xl={3}>
            <ModifiedReactSelectV2
              isSearchable={false}
              options={filterOptions.political_affiliation}
              name="political_affiliation"
              onChange={onChangeFilterOption}
              value={reduxArticleFilter.political_affiliation}
              className="rounded-border-select ps-1"
            />
          </Col>
          {/* <Col lg={3} sm={12} xs={12} xl={3}>
            <div>{labels.subIssue}</div>
            <ReactSelect
              isSearchable={false}
              options={filterOptions.sub_issue}
              name="sub_issue"
              isMulti
              onChange={onChangeMultiFilter}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              components={{
                Option: MultiSelectOptionsWithCB,
                ValueContainer,
                Menu: (menuProps) => (
                  <MultiSelectMenu
                    onConfirmSubIssueSelection={onConfirmSubIssueSelection}
                    menuProps={menuProps}
                  />
                ),
              }}
              value={selectedSubIssue}
            />
          </Col> */}
          <Col lg={3} sm={12} xs={12} xl={3}>
            <ModifiedReactSelectV2
              isSearchable={false}
              options={filterOptions.source_language}
              name="source_language"
              onChange={onChangeFilterOption}
              value={reduxArticleFilter.source_language}
            />
          </Col>
          <Col lg={3} xs={12} sm={12} xl={3}>
            <StyledDateSelecterM3V2
              className="w-100 text-center"
              selected={calendarDates.startDate}
              wrapperClassName="w-100"
              onChange={onDateChange}
              minDate={new Date(MAX_ALLOWED_BACK_DATE)}
              maxDate={new Date(DEFAULT_END_DATE)}
              startDate={calendarDates.startDate}
              endDate={calendarDates.endDate}
              selectsRange
              dateFormat={"MM/dd"}
              showDisabledMonthNavigation
              ref={dateSelectorRef}
              onCalendarOpen={() => {
                dateSelectorRef.current.input.classList.add("primary-border");
              }}
              onCalendarClose={() => {
                dateSelectorRef.current.input.classList.remove(
                  "primary-border"
                );
              }}
              showIcon
              icon={
                <span
                  style={{
                    right: 10,
                    lineHeight: 0,
                    margin: "auto",
                    top: 0,
                    bottom: 0,
                  }}
                  onClick={() => {
                    dateSelectorRef.current.setOpen(true);
                  }}
                >
                  <CalendarIcon />
                </span>
              }
            />
          </Col>
          <Col
            lg={3}
            xl={3}
            md={3}
            sm={12}
            xs={12}
            className="d-flex justify-content-between align-items-center"
          >
            <ModifiedReactSelectV2
              isSearchable={false}
              options={DATE_ORDER_BY_OPTIONS}
              onChange={onChangeFilterOrderBy}
              value={dateOrder}
              className="flex-fill"
            />
          </Col>
        </Row>
        {/* <Row style={{ marginTop: 24, marginBottom: 24 }}></Row> */}
      </div>
      {articleDetailModal.show && articleDetailModal.article && (
        <ArticeDetailsModal
          title={
            showEnglishContent(
              articleDetailModal.article.english_title,
              preferredLanguage
            )
              ? articleDetailModal.article.english_title
              : articleDetailModal.article.title
          }
          issue={selectedIssueM3 ?? ""}
          sub_issue={articleDetailModal.article.sub_issue}
          source={articleDetailModal.article.content_source}
          source_language={articleDetailModal.article.source_language}
          date={articleDetailModal.article.date}
          content={
            showEnglishContent(
              articleDetailModal.article.english_related_segment,
              preferredLanguage
            )
              ? articleDetailModal.article.english_related_segment
              : articleDetailModal.article.related_segment
          }
          article_link={articleDetailModal.article.website_url}
          onHide={onHideArticleModal}
          politicalAffiliation={
            articleDetailModal.article.political_affiliation
          }
        />
      )}

      {error ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 30,
          }}
          className="py-5"
        >
          {error}
        </div>
      ) : !articles.length ? (
        <div
          style={{
            height: "600px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>Loading...</div>
        </div>
      ) : (
        <div>
          {articles.map((article, index) => {
            return (
              <PostCardM3
                onClickReadMore={() => onClickArticleDetail(article)}
                key={index}
                {...getPostParams(article)}
              />
            );
          })}
        </div>
      )}
      <GoToTop offset={800} />
      {hasMoreRecords && <div ref={elementRef}>Loading...</div>}
      {enableGenAIDownload && genAISummary ? (
        <PDFDownloadable
          content={genAISummary.summary}
          fileName={`Discourse_AI_Summary_${activeIssue.replaceAll(" ", "_")}_${
            genAISummary.date
          }.pdf`}
          pdfTitle={`${activeIssue} Summary (${genAISummary.date})`}
          onCompleteDownload={() => setEnableGenAIDownload(false)}
        />
      ) : null}
    </div>
  );
};
