import React from "react";
import { Delete, Search } from "@styled-icons/material-outlined";
import { InputGroup, Table, Form, Alert, Button } from "react-bootstrap";
import { RegisteredMember } from "./types";
import { DateTime } from "luxon";
import { THEME_COLOUR_PRIMARY } from "../../constants";
import { ConfirmationDialog } from "../../components/ConfirmationDialog";

export const RegisteredQueue = ({
  membersList,
  searchMember: searchMemberFunction,
  moveToInvitee: moveToInviteeListFunc,
  isRequestInProgress,
  onConfirmDelete,
}: {
  membersList: Array<RegisteredMember>;
  searchMember: (keyword: string) => void;
  moveToInvitee: (uuid: string) => void;
  isRequestInProgress: boolean;
  onConfirmDelete: (uuid: string) => void;
}) => {
  const [searchMember, setSearchMember] = React.useState("");
  const setSearchMemberTimer = React.useRef<any>();
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    React.useState(false);
  const uuidRef = React.useRef("");

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchMember(e.target.value);
  };

  React.useEffect(() => {
    if (setSearchMemberTimer.current) {
      clearTimeout(setSearchMemberTimer.current);
    }
    setSearchMemberTimer.current = setTimeout(() => {
      searchMemberFunction(searchMember);
    }, 1000);
  }, [searchMember, searchMemberFunction]);

  const confirmDelete = (uuid: string) => {
    uuidRef.current = uuid;
    setShowDeleteConfirmation(true);
  };

  const onConfirmDeleteRecord = React.useCallback(() => {
    onConfirmDelete && onConfirmDelete(uuidRef.current);
    uuidRef.current = "";
    setShowDeleteConfirmation(false);
  }, [onConfirmDelete]);

  const onCancelConfirmation = React.useCallback(() => {
    uuidRef.current = "";
    setShowDeleteConfirmation(false);
  }, []);

  return (
    <div>
      <div className="my-5">
        {isRequestInProgress ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ fontSize: 18 }}
          >
            Updating records...
          </div>
        ) : (
          !!membersList.length && (
            <InputGroup>
              <InputGroup.Text>
                <Search size={20} />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search Invitee by name, email or organisation"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={searchMember}
                onChange={onInputChange}
              />
            </InputGroup>
          )
        )}
      </div>
      {showDeleteConfirmation && (
        <ConfirmationDialog
          onCancel={onCancelConfirmation}
          onConfirm={onConfirmDeleteRecord}
        />
      )}

      {membersList.length ? (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <td>Phone</td>
                <th>Organisation</th>
                <th>Registered At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {membersList.map((member) => {
                return (
                  <tr key={member.uuid}>
                    <td>{member.name}</td>
                    <td>{member.lastName}</td>
                    <td>{member.email}</td>
                    <td>{member.phone}</td>
                    <td>{member.companyName}</td>
                    <td>
                      {DateTime.fromISO(member.createdAt).toFormat(
                        "dd LLL yyyy HH:mm"
                      )}
                    </td>
                    <td>
                      <span
                        style={{ cursor: "pointer" }}
                        title="Move member to invited list"
                      >
                        <Button
                          onClick={() => moveToInviteeListFunc(member.uuid)}
                          disabled={isRequestInProgress}
                          style={{
                            backgroundColor: `${THEME_COLOUR_PRIMARY}`,
                            borderColor: `${THEME_COLOUR_PRIMARY}`,
                          }}
                          className="me-1"
                        >
                          Move to Invitee
                        </Button>
                        <Button
                          style={{
                            backgroundColor: `${THEME_COLOUR_PRIMARY}`,
                            borderColor: `${THEME_COLOUR_PRIMARY}`,
                          }}
                          className=""
                          onClick={() => confirmDelete(member.uuid)}
                        >
                          <Delete size={20} />
                        </Button>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      ) : (
        <div className="mt-5 text-center">No Record Available</div>
      )}
    </div>
  );
};
