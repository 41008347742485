import React from "react";
import { Search, Delete } from "@styled-icons/material-outlined";
import { InputGroup, Table, Form, Alert, Button } from "react-bootstrap";
import { Invitee } from "./types";
import { DateTime } from "luxon";
import { THEME_COLOUR_PRIMARY } from "../../constants";
import { ConfirmationDialog } from "../../components/ConfirmationDialog";

export const InviteeTable = ({
  inviteeList,
  searchInvitee: searchInviteeFunction,
  resendInvitationLink,
  isRequestInProgress,
  onConfirmDelete,
}: {
  inviteeList: Array<Invitee>;
  searchInvitee: (keyword: string) => void;
  resendInvitationLink: (uuid: string) => void;
  isRequestInProgress: boolean;
  onConfirmDelete: (uuid: string) => void;
}) => {
  const [searchInvitee, setSearchInvitee] = React.useState("");
  const setSearchInviteeTimer = React.useRef<any>();
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    React.useState(false);

  const uuidRef = React.useRef<string>("");

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInvitee(e.target.value);
  };

  React.useEffect(() => {
    if (setSearchInviteeTimer.current) {
      clearTimeout(setSearchInviteeTimer.current);
    }
    setSearchInviteeTimer.current = setTimeout(() => {
      searchInviteeFunction(searchInvitee);
    }, 1000);
  }, [searchInvitee, searchInviteeFunction]);

  const confirmDelete = (uuid: string) => {
    uuidRef.current = uuid;
    setShowDeleteConfirmation(true);
  };

  const onConfirmDeleteRecord = React.useCallback(() => {
    onConfirmDelete && onConfirmDelete(uuidRef.current);
    uuidRef.current = "";
    setShowDeleteConfirmation(false);
  }, [onConfirmDelete]);

  const onCancelConfirmation = React.useCallback(() => {
    uuidRef.current = "";
    setShowDeleteConfirmation(false);
  }, []);

  return (
    <div>
      <div className="my-5">
        {isRequestInProgress ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ fontSize: 18 }}
          >
            Updating records...
          </div>
        ) : (
          !!inviteeList.length && (
            <InputGroup>
              <InputGroup.Text>
                <Search size={20} />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search Invitee by name, email or organisation"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={searchInvitee}
                onChange={onInputChange}
              />
            </InputGroup>
          )
        )}
      </div>
      {showDeleteConfirmation && (
        <ConfirmationDialog
          onCancel={onCancelConfirmation}
          onConfirm={onConfirmDeleteRecord}
        />
      )}

      {inviteeList.length ? (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Organisation</th>
                <th>Invited At</th>
                <th>Invitation Accepted</th>
                <th>Invitation Accepted Time</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {inviteeList.map((invitee) => {
                return (
                  <tr key={invitee.uuid}>
                    <td>{invitee.name}</td>
                    <td>{invitee.lastName}</td>
                    <td>{invitee.email}</td>
                    <td>{invitee.organisation}</td>
                    <td>
                      {DateTime.fromMillis(invitee.invitedAt).toFormat(
                        "dd LLL yyyy HH:mm"
                      )}
                    </td>
                    <td>{!!invitee.isInvitationAccepted ? "Yes" : "No"}</td>
                    <td>
                      {invitee.invitationAcceptedAt
                        ? DateTime.fromMillis(
                            invitee.invitationAcceptedAt
                          ).toFormat("dd LLL yyyy HH:mm")
                        : ""}
                    </td>
                    <td>
                      <div className="d-flex">
                        {!!invitee.invitationAcceptedAt ? (
                          <Button
                            style={{
                              backgroundColor: `${THEME_COLOUR_PRIMARY}`,
                              borderColor: `${THEME_COLOUR_PRIMARY}`,
                            }}
                            className=""
                            onClick={() => confirmDelete(invitee.uuid)}
                          >
                            <Delete size={20} />
                          </Button>
                        ) : (
                          <>
                            <Button
                              onClick={() => resendInvitationLink(invitee.uuid)}
                              disabled={isRequestInProgress}
                              style={{
                                backgroundColor: `${THEME_COLOUR_PRIMARY}`,
                                borderColor: `${THEME_COLOUR_PRIMARY}`,
                              }}
                              className="me-1"
                            >
                              Resend
                            </Button>
                            <Button
                              style={{
                                backgroundColor: `${THEME_COLOUR_PRIMARY}`,
                                borderColor: `${THEME_COLOUR_PRIMARY}`,
                              }}
                              className=""
                              onClick={() => confirmDelete(invitee.uuid)}
                            >
                              <Delete size={20} />
                            </Button>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      ) : (
        <div className="mt-5 text-center">No Record Available</div>
      )}
    </div>
  );
};
