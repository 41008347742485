import React from "react";
import { ProgressBar } from "react-bootstrap";
import styled, { CSSProperties } from "styled-components";

interface Iprops {
  color: string;
  progress: number;
  extraCss?: CSSProperties;
}

export const ProgressBarComponent = ({ color, progress, extraCss }: Iprops) => {
  return (
    <StyledProgressBar
      animated={false}
      style={{ backgroundColor: color, ...extraCss }}
      now={progress}
      color={color}
      variant="success"
    />
  );
};

const StyledProgressBar = styled(ProgressBar).withConfig({
  shouldForwardProp: (props) => props !== "color",
})<{ color: string }>`
  height: 22px;
  & .progress-bar {
    background-color: ${(p) => p.color} !important;
  }
`;
