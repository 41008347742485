import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { UserProfile } from "../../types/types";

interface UserState {
  isLoggedIn: boolean;
  isSessionVaidationInProgress: boolean;
  profile?: UserProfile;
}

const initialState: UserState = {
  isLoggedIn: false,
  profile: undefined,
  isSessionVaidationInProgress: false,
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSessionValidationTrue: (state) => {
      state.isSessionVaidationInProgress = true;
    },
    setSessionValidationFalse: (state) => {
      state.isSessionVaidationInProgress = false;
    },
    setUser: (state, action: PayloadAction<UserState>) => {
      state.isLoggedIn = action.payload?.isLoggedIn ?? false;
      state.profile = action.payload?.profile ?? undefined;
    },
    updateUser: (state, action: PayloadAction<Pick<UserState, "profile">>) => {
      state.profile = action.payload.profile;
    },
    logoutUser: (state) => {
      state.isLoggedIn = false;
      state.profile = undefined;
    },
  },
});

export const {
  logoutUser,
  setSessionValidationFalse,
  setSessionValidationTrue,
  setUser,
  updateUser,
} = UserSlice.actions;

export const UserReducer = UserSlice.reducer;
