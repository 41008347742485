import React from "react";
import { AxiosResponse } from "axios";
import axiosInstance from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { Card } from "react-bootstrap";
import {
  INFLUENCIAL_ENTITY_PREFIX_URL,
  MANDSPROFILE_PREFIX_URL,
  PIE_CHART_COLOUR_PALLETE,
  SUBSCRIBED_REGION,
} from "../../constants";
import styled from "styled-components";
import { setSearchScreenFilter } from "../../store/slice/search";
import { useNavigate } from "react-router";
import {
  BottomStyledToolTip,
  RightStyledToolTip,
} from "../../components/StyledToolTip";
import { getNavBarHeight } from "../../helpers";
import { TrendingHashtags } from "./TrendingHashtags";
import { SendGAEvent } from "../../helpers/googleAnalyticsHepler";
import {
  MoversAndShaperRecord,
  TaiwanDigitalMoversAndShapers,
  TaiwanTwitterMoversAndShapers,
} from "../../types/types";
import { TaiwanMoversAndShapers } from "./TaiwanMoversAndShapers";
import { Info } from "@styled-icons/material-outlined";
import { MandSProfileAvatar } from "./MandSProfile";

export interface TrendingHashtag {
  single_hashtag: string;
  hashtag_count: number;
}

type TaiwanResponseType = {
  digitalMoversAndShapers: Array<TaiwanDigitalMoversAndShapers>;
  twitterMoversAndShapers: Array<TaiwanTwitterMoversAndShapers>;
};

export const MoversAndShapers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    selectedIssueM3,
    defaultSelectedIssueM3,
    defaultSelectedTaiwanIssue,
    preferredRegion,
    selectedTaiwanIssue,
    selectedChinaIssue,
    defaultSelectedChinaIssue,
  } = useSelector((state: RootState) => state.preference);
  const influenceRedux = useSelector(
    (state: RootState) => state.influencerRedux
  );

  const [data, setData] = React.useState<{
    moversAndShapers: Array<MoversAndShaperRecord>;
    influencialEntities: Array<string>;
    trendingHashtags: Array<TrendingHashtag>;
    taiwanDigitalMoversAndShapers: Array<TaiwanDigitalMoversAndShapers>;
    taiwanTwitterMoversAndShapers: Array<TaiwanTwitterMoversAndShapers>;
  }>({
    moversAndShapers: [],
    influencialEntities: [],
    trendingHashtags: [],
    taiwanDigitalMoversAndShapers: [],
    taiwanTwitterMoversAndShapers: [],
  });

  const requestParamsByRegion = React.useMemo(() => {
    switch (preferredRegion) {
      case SUBSCRIBED_REGION.IRAN_REGION:
        return {
          theme: {
            value: selectedIssueM3 ?? defaultSelectedIssueM3,
            type: "equals",
          },
        };

      case SUBSCRIBED_REGION.CHINA_REGION:
        return {
          issue: {
            value: selectedChinaIssue ?? defaultSelectedChinaIssue,
            type: "equals",
          },
          language: { type: "equals", value: "Chinese (Simplified)" },
        };

      case SUBSCRIBED_REGION.TAIWAN_REGION:
        return {
          issue: {
            value: selectedTaiwanIssue ?? defaultSelectedTaiwanIssue,
            type: "equals",
          },
          language: { type: "equals", value: "Chinese (Traditional)" },
        };

      default:
        return undefined;
    }
  }, [
    defaultSelectedChinaIssue,
    defaultSelectedIssueM3,
    defaultSelectedTaiwanIssue,
    preferredRegion,
    selectedChinaIssue,
    selectedIssueM3,
    selectedTaiwanIssue,
  ]);

  React.useEffect(() => {
    if (!requestParamsByRegion) {
      return;
    }
    axiosInstance
      .post("/moversAndShapers/get-movers-and-shapers", {
        params: {
          subscribed_region: preferredRegion,
          filters: requestParamsByRegion,
        },
      })
      .then(
        (
          res: AxiosResponse<{
            data: Array<MoversAndShaperRecord> | TaiwanResponseType;
            influencerEntity: Array<string>;
            trendingHashtags: Array<TrendingHashtag>;
          }>
        ) => {
          if (preferredRegion === SUBSCRIBED_REGION.IRAN_REGION) {
            setData({
              influencialEntities: res.data.influencerEntity,
              moversAndShapers: res.data.data as Array<MoversAndShaperRecord>,
              trendingHashtags: res.data.trendingHashtags,
              taiwanDigitalMoversAndShapers: [],
              taiwanTwitterMoversAndShapers: [],
            });
          } else {
            setData({
              influencialEntities: [],
              moversAndShapers: [],
              trendingHashtags: res.data.trendingHashtags,
              taiwanDigitalMoversAndShapers: (
                res.data.data as TaiwanResponseType
              ).digitalMoversAndShapers,
              taiwanTwitterMoversAndShapers: (
                res.data.data as TaiwanResponseType
              ).twitterMoversAndShapers,
            });
          }
        }
      );
  }, [preferredRegion, requestParamsByRegion]);

  const handleMandSClick = (mands: MoversAndShaperRecord) => {
    SendGAEvent({
      category: "SOTOS_Emerging_Issue_MNS_Clicked",
      action: "SOTOS_Emerging_Issue_MNS_Clicked",
      includeUserDetail: true,
      label: `CN=${mands.corrected_name},NN=${mands.native_name},ON=${mands.other_name}`,
    });
    let searchQuery = [];
    if (mands.corrected_name) {
      searchQuery.push(mands.corrected_name);
    }
    if (mands.native_name) {
      searchQuery.push(mands.native_name);
    }
    if (mands.other_name) {
      searchQuery.push(mands.other_name);
    }
    dispatch(
      setSearchScreenFilter({
        query: searchQuery.join(" OR "),
        date: "90",
      })
    );

    window.scrollTo({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      navigate(`/${preferredRegion.toLowerCase()}/discover`);
    }, 500);
  };

  const handleRelatedEntityClick = (related_entity: string) => {
    const relatedEntitySearchStr = [];
    relatedEntitySearchStr.push(related_entity);
    if (influenceRedux[related_entity]?.farsi_name) {
      relatedEntitySearchStr.push(influenceRedux[related_entity].farsi_name);
    }
    dispatch(
      setSearchScreenFilter({
        query: relatedEntitySearchStr.join(" OR "),
        date: "90",
      })
    );
    window.scrollTo({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      navigate(`/${preferredRegion.toLowerCase()}/discover`);
    }, 500);
  };

  const onInfluencerImageLoadError = (elementId: string) => {
    const domEle = document.getElementById(elementId);
    if (domEle) {
      (domEle as HTMLImageElement).src =
        process.env.PUBLIC_URL + "default_profile_image.png";
    }
  };

  return (
    <StyledInfluencerContainer top={getNavBarHeight()}>
      {[
        SUBSCRIBED_REGION.TAIWAN_REGION,
        SUBSCRIBED_REGION.CHINA_REGION,
      ].includes(preferredRegion) ? (
        <TaiwanMoversAndShapers
          taiwanDigitalMoversAndShapers={data.taiwanDigitalMoversAndShapers}
          taiwanTwitterMoversAndShapers={data.taiwanTwitterMoversAndShapers}
        />
      ) : null}
      {data.influencialEntities.length ? (
        <Card
          style={{
            borderRadius: 16,
          }}
        >
          <Card.Title
            style={{
              color: "#475569",
              fontSize: 16,
              fontWeight: "bold",
              borderBottom: "1px solid rgba(0, 0, 0, 0.175)",
              paddingTop: "1.2rem",
              paddingLeft: "1.2rem",
              paddingBottom: "0.6rem",
            }}
          >
            <span>
              Influence AI &nbsp;
              <Info
                id={`tooltop-anchor-Influence-AI`}
                size={25}
                color="#475569"
                style={{ paddingBottom: 2 }}
                className="info-icon"
              />
            </span>
          </Card.Title>
          <Card.Body className="pt-1">
            <Card.Title
              className="px-1"
              style={{
                color: "#475569",
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              Related Entities
            </Card.Title>
            <div className="text-center">
              <div className="d-inline-block text-center">
                {data.influencialEntities.map((entity, index) => {
                  return (
                    <StyledInfluencerEntity className="my-2 pe-2" key={entity}>
                      <div
                        onClick={() => handleRelatedEntityClick(entity)}
                        style={{ cursor: "pointer" }}
                      >
                        <div>
                          <InfluencerImage
                            src={`${
                              influenceRedux[entity]
                                ? INFLUENCIAL_ENTITY_PREFIX_URL +
                                  influenceRedux[entity]["photo"]
                                : `${process.env.PUBLIC_URL}/default_profile_image.png`
                            }`}
                            alt="related-profile"
                            className="img-fluid rounded-circle"
                            id={`tooltip-anchor-related-entity-${index}`}
                            color={
                              PIE_CHART_COLOUR_PALLETE[index] ??
                              PIE_CHART_COLOUR_PALLETE[0]
                            }
                            onError={() =>
                              onInfluencerImageLoadError(
                                `tooltip-anchor-related-entity-${index}`
                              )
                            }
                          />
                        </div>
                        <BottomStyledToolTip
                          anchorSelect={`#tooltip-anchor-related-entity-${index}`}
                          place="bottom"
                        >
                          {entity}
                        </BottomStyledToolTip>
                      </div>
                    </StyledInfluencerEntity>
                  );
                })}
              </div>
            </div>

            {/* --- Movers and Shapers --- */}
            {data.moversAndShapers.length ? (
              <>
                <Card.Title
                  className="px-1 pt-2"
                  style={{
                    color: "#475569",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Related Movers &amp; Shapers
                </Card.Title>
                <div className="text-center">
                  <div className="d-inline-block text-center">
                    {data.moversAndShapers.map((mands, index) => {
                      return (
                        <MandSRowContainer
                          key={mands.corrected_name}
                          className="my-2 pe-2"
                          role="button"
                        >
                          <MandSProfileAvatar
                            {...mands}
                            onClick={() => handleMandSClick(mands)}
                            borderColor={
                              PIE_CHART_COLOUR_PALLETE[index] ??
                              PIE_CHART_COLOUR_PALLETE[0]
                            }
                          />
                        </MandSRowContainer>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : null}
          </Card.Body>
        </Card>
      ) : null}

      {data.trendingHashtags.length ? (
        <div
          className={`${
            data.moversAndShapers?.length ||
            data.taiwanDigitalMoversAndShapers?.length ||
            data.taiwanTwitterMoversAndShapers?.length
              ? "mt-4"
              : ""
          }`}
        >
          <TrendingHashtags trendingHashtags={data.trendingHashtags} />
        </div>
      ) : null}
      <RightStyledToolTip
        place="top"
        anchorSelect={`#tooltop-anchor-Influence-AI`}
        html={
          preferredRegion === SUBSCRIBED_REGION.IRAN_REGION
            ? `Influencers and related entities to selected issues.`
            : `Influencers to selected issues.`
        }
      ></RightStyledToolTip>
    </StyledInfluencerContainer>
  );
};

const StyledInfluencerContainer = styled("div").withConfig({
  shouldForwardProp: (prop) => prop !== "top",
})<{ top: number }>(({ top }) => ({
  position: "sticky",
  top: top,
}));

const MandSRowContainer = styled.div`
  display: inline-block;
  align-items: center;
  justify-content: space-between;
  text-align: center;
`;

export const MandSPhoto = styled("img").withConfig({
  shouldForwardProp: (prop) => prop !== "color",
})<{ color: string }>(({ color }) => ({
  height: "60px",
  width: "60px",
  objectFit: "cover",
  border: `3px solid ${color}`,
  span: {
    paddingTop: "0.2rem",
  },
}));

export const MoversAndShapersName = styled.span`
  font-size: 14px;
  color: #64748b;
`;

export const StyledInfluencerEntity = styled.div`
  display: inline-block;
  align-items: center;
  justify-content: space-between;
  text-align: center;
`;

export const EntityName = styled.div`
  font-size: 14px;
  color: #64748b;
`;

export const InfluencerImage = styled("img").withConfig({
  shouldForwardProp: (prop) => prop !== "color",
})<{ color: string }>(({ color }) => ({
  width: "60px",
  objectFit: "cover",
  border: `3px solid ${color}`,
}));
