import React from "react";
import { Form, InputGroup, Row, Col, Button, Alert } from "react-bootstrap";
import { Info, Search } from "@styled-icons/material-outlined";
import ReactSelect, {
  ActionMeta,
  components,
  MultiValue,
  SingleValue,
  ValueContainerProps,
} from "react-select";
import {
  DEFAULT_ALL_ISSUES,
  DEFAULT_DROPDOWN_OPTION,
  DEFAULT_POST_LANGUAGE,
  ERRORS,
  M3_PARAM,
  SITE_CONSTANTS,
  SUBSCRIBED_REGION,
  TAB_TITLE,
  THEME_COLOUR_PRIMARY,
} from "../../constants";
import { DropdownOption } from "../../types/types";
import axiosInstance from "../../api";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { FilterOperations, FilterTypes } from "../../types/filterTypes";
import {
  getBackDate,
  isAlphaNumeric,
  isSearchStringValid,
  isValidSearchString,
} from "../../helpers";
import { MultiSelectOptionsWithCB } from "../../components/MultiSelectCBOptions";
import {
  /* ArticleSearchResult, */

  SearchResultImprovised,
} from "./SearchResults";

import SearchResult from "./SearchResults";
import { GoToTop } from "../../components/GoToTop";
import {
  generateArticleSearchConditionsNew,
  splitSearchStringByANDOR,
} from "../../helpers/paramHelper";
import { toast, ToastContainer } from "react-toastify";
import { Loader } from "../../components/Loader";
import {
  ModifiedReactSelect,
  ModifiedReactSelectV2,
} from "../../components/ModifiedReactSelect";
import styled from "styled-components";
import { resetSearchScreenFilters } from "../../store/slice/search";
import { SendGAEvent } from "../../helpers/googleAnalyticsHepler";

const DEFAULT_DATE_OPTIONS: Array<DropdownOption> = [
  {
    label: "Today",
    value: "1",
  },
  {
    label: "Last 7 Days",
    value: "7",
  },
  {
    label: "Last 30 Days",
    value: "30",
  },
  {
    label: "Last 60 Days",
    value: "60",
  },
  {
    label: "Last 90 Days",
    value: "90",
  },
];

const SOCIAL_MEDIA_OPTIONS: Array<DropdownOption> = [
  {
    label: "All Media",
    value: "",
  },
  {
    label: "Digital",
    value: "Digital",
  },
  {
    label: "Twitter",
    value: "Twitter",
  },
  {
    label: "Telegram",
    value: "Telegram",
  },
];

const SOURCE_LANGUAGE_OPTIONS: Array<DropdownOption> = [
  { ...DEFAULT_POST_LANGUAGE },
  {
    label: "English",
    value: "English",
  },
  {
    label: "Persian",
    value: "Persian",
  },
];

const SOURCE_LANGUAGE_OPTIONS_TAIWAN: Array<DropdownOption> = [
  { ...DEFAULT_POST_LANGUAGE },
  {
    label: "Chinese (Traditional)",
    value: "Chinese (Traditional)",
  },
  {
    label: "English",
    value: "English",
  },
];

const SOURCE_LANGUAGE_OPTIONS_CHINA: Array<DropdownOption> = [
  { ...DEFAULT_POST_LANGUAGE },
  {
    label: "Chinese (Simplified)",
    value: "Chinese (Simplified)",
  },
  {
    label: "English",
    value: "English",
  },
];

export const SearchScreen = () => {
  const dispatch = useDispatch();
  const { issuesM3, preferredRegion, taiwanIssues, chinaIssues } = useSelector(
    (state: RootState) => state.preference
  );
  const { query, date: searchScreenReduxDate } = useSelector(
    (state: RootState) => state.searchScreen
  );

  const [search, setSearch] = React.useState(query ?? "");
  const [isSearchButtonEnabled, setIsSearchButtonEnabled] = React.useState(
    query ? true : false
  );

  const animationTimerRef = React.useRef<any>(null);

  const [date, setDate] = React.useState<DropdownOption>(() => {
    if (searchScreenReduxDate) {
      const matchDateObj = DEFAULT_DATE_OPTIONS.find(
        (dateObj) => dateObj.value === searchScreenReduxDate
      );
      if (matchDateObj) {
        return { ...matchDateObj };
      } else {
        return { ...DEFAULT_DATE_OPTIONS[0] };
      }
    } else {
      return { ...DEFAULT_DATE_OPTIONS[0] };
    }
  });

  const [language, setLanguage] = React.useState<DropdownOption>({
    ...SOURCE_LANGUAGE_OPTIONS[0],
  });

  const [socialMedia, setSocialMedia] = React.useState<DropdownOption>({
    ...SOCIAL_MEDIA_OPTIONS[0],
  });

  const [selectedIssue, setSelectedIssue] = React.useState<
    MultiValue<DropdownOption>
  >([{ ...DEFAULT_ALL_ISSUES }]);

  const [selectedSubIssue, setSelectedSubIssue] = React.useState<
    MultiValue<DropdownOption>
  >([{ ...DEFAULT_DROPDOWN_OPTION }]);

  const [issueFilterOptions, setIsseFilterOptions] = React.useState<
    Array<DropdownOption>
  >([{ ...DEFAULT_ALL_ISSUES }]);

  const [subIssueFilterOptions] = React.useState<Array<DropdownOption>>([
    { ...DEFAULT_DROPDOWN_OPTION },
  ]);

  const [loader, setLoader] = React.useState(false);
  const [searchKeywords, setSearchKeywords] = React.useState<string[]>([]);

  const [searchResult, setSearchResults] = React.useState<{
    articleResults: SearchResultImprovised;
    twitterResults: SearchResultImprovised;
    telegramResults: SearchResultImprovised;
    totalArticles: number;
    totalMessages: number;
    totalTweets: number;
  } | null>(null);

  const multiSelectRef = React.useRef<any>(null);
  const multiSelectWrapperRef = React.useRef(null);
  const buttonEnablerRef = React.useRef<any>(null);

  React.useEffect(() => {
    document.title = TAB_TITLE.discover;
    SendGAEvent({
      category: "SOTOS_Search_Screen_Landed",
      action: "SOTOS_Search_Screen_Landed",
      includeUserDetail: true,
    });
  }, []);

  React.useEffect(() => {
    return () => {
      dispatch(resetSearchScreenFilters());
    };
  }, [dispatch]);

  const flashingEffectRemover = () => {
    if (animationTimerRef.current) {
      clearTimeout(animationTimerRef.current);
    }
    animationTimerRef.current = setTimeout(() => {
      document
        .querySelector("#search-bar")
        ?.classList.remove("flashing-button");
    }, 45000);
  };

  const getParams = React.useCallback(() => {
    const selectedIssues = selectedIssue.length
      ? selectedIssue
          .filter((iss) => {
            return iss.value;
          })
          .map((iss) => iss.value)
      : issueFilterOptions
          .filter((iss) => {
            return iss.value;
          })
          .map((iss) => iss.value);
    const selectedSubIssues = selectedSubIssue
      .filter((si) => si.value)
      .map((si) => si.value);

    const language_filter = language.value
      ? { type: FilterTypes.eq, value: language.value }
      : undefined;

    const isSearchForOnlyTwitter =
      search.trim().split(" ").length === 1 && search.startsWith("@");

    const { OR, AND } = splitSearchStringByANDOR(search.replaceAll("@", ""));
    if (OR.length) {
      setSearchKeywords(OR.map((keyword) => keyword.replaceAll('"', "")));
    } else if (AND.length) {
      setSearchKeywords(AND.map((keyword) => keyword.replaceAll('"', "")));
    } else if (search.startsWith('"')) {
      setSearchKeywords([search.replaceAll('"', "")]);
    }

    const articleColumns = [
      "related_segment",
      "english_related_segment",
      "title",
      "english_title",
      "content_source",
    ];
    /* const articleSearchConditionOR = OR.length
      ? generateArticleSearchConditions(articleColumns, OR, "OR")
      : {};
    const articleSearchConditionAND = AND.length
      ? generateArticleSearchConditions(articleColumns, AND, "OR")
      : {}; */

    const articleSearchANDCondition = generateArticleSearchConditionsNew(
      articleColumns,
      AND,
      "OR",
      "AND"
    );
    const articleSearchORConditions = generateArticleSearchConditionsNew(
      articleColumns,
      OR,
      "OR",
      "OR"
    );

    const twitterColumns = isSearchForOnlyTwitter
      ? ["twitter_handle"]
      : ["tweet_text", "tweet_text_translated", "hashtag", "twitter_handle"];

    const twitterSearchANDCondition = generateArticleSearchConditionsNew(
      twitterColumns,
      AND,
      "OR",
      "AND"
    );

    const twitterSearchORConditions = generateArticleSearchConditionsNew(
      twitterColumns,
      OR,
      "OR",
      "OR"
    );

    const telegramColumns = [
      "message_text",
      "message_text_translated",
      "hashtags",
      "account_name",
    ];

    const telegramSearchANDCondition = generateArticleSearchConditionsNew(
      telegramColumns,
      AND,
      "OR",
      "AND"
    );

    const telegramSearchORConditions = generateArticleSearchConditionsNew(
      telegramColumns,
      OR,
      "OR",
      "OR"
    );

    const date_filter = date.value
      ? {
          operator: FilterOperations.AND,
          conditions: [
            {
              value: getBackDate("days", parseInt(date.value)),
              type: FilterTypes.gte,
            },
            {
              value: getBackDate("today", 0),
              type: FilterTypes.lte,
            },
          ],
        }
      : undefined;

    const issue = selectedIssues.length
      ? {
          value: selectedIssues,
          type: FilterTypes.includes,
        }
      : undefined;

    const sub_issue = selectedSubIssues.length
      ? {
          value: selectedSubIssues,
          type: FilterTypes.includes,
        }
      : undefined;

    const articleSearchFilters = [
      {
        columnName: "related_segment",
        value: search.replaceAll('"', ""),
      },
      { columnName: "title", value: search.replaceAll('"', "") },
      { columnName: "english_title", value: search.replaceAll('"', "") },
      {
        columnName: "english_related_segment",
        value: search.replaceAll('"', ""),
      },
      {
        columnName: "content_source",
        value: search.replaceAll('"', ""),
      },
    ];

    const articles_content = search
      ? {
          isMultiColumnFilter: true,
          operator: FilterOperations.OR,
          type: FilterTypes.contains,
          conditions: articleSearchFilters,
        }
      : undefined;

    const twitterSearchFilters = isSearchForOnlyTwitter
      ? [
          {
            columnName: "twitter_handle",
            value: search.replaceAll('"', "").replaceAll("@", ""),
          },
        ]
      : [
          {
            columnName: "tweet_text",
            value: search.replaceAll('"', ""),
          },
          {
            columnName: "tweet_text_translated",
            value: search.replaceAll('"', ""),
          },
          {
            columnName: "twitter_handle",
            value: search.replaceAll('"', ""),
          },
        ];

    const twitter_content = search
      ? {
          isMultiColumnFilter: true,
          operator: FilterOperations.OR,
          type: FilterTypes.contains,
          conditions: twitterSearchFilters,
        }
      : undefined;

    const telegramSearchFilters = [
      { columnName: "message_text", value: search.replaceAll('"', "") },
      {
        columnName: "message_text_translated",
        value: search.replaceAll('"', ""),
      },
      {
        columnName: "account_name",
        value: search.replaceAll('"', ""),
      },
    ];

    const telegram_content = search
      ? {
          isMultiColumnFilter: true,
          operator: FilterOperations.OR,
          type: FilterTypes.contains,
          conditions: telegramSearchFilters,
        }
      : undefined;

    return {
      articles:
        ["", "Digital"].indexOf(socialMedia.value) > -1 &&
        !search.startsWith("#") &&
        !isSearchForOnlyTwitter
          ? {
              filters: {
                issue,
                sub_issue,
                source_language: language_filter,
                date: date_filter,
                english_title:
                  !articleSearchANDCondition[0].conditions.length &&
                  !articleSearchORConditions[0].conditions.length
                    ? articles_content
                    : undefined,
                searchConditions: ([] as any).concat(
                  articleSearchANDCondition,
                  articleSearchORConditions
                ),
              },
            }
          : undefined,
      twitter:
        ["", "Twitter"].indexOf(socialMedia.value) > -1
          ? {
              filters: {
                issue,
                sub_issue,
                language: language_filter,
                date: date_filter,
                tweet_text:
                  !twitterSearchANDCondition[0].conditions.length &&
                  !twitterSearchORConditions[0].conditions.length
                    ? twitter_content
                    : undefined,
                searchConditions: ([] as any).concat(
                  twitterSearchANDCondition,
                  twitterSearchORConditions
                ),
              },
            }
          : undefined,
      telegram:
        ["", "Telegram"].indexOf(socialMedia.value) > -1 &&
        ![
          SUBSCRIBED_REGION.CHINA_REGION,
          SUBSCRIBED_REGION.TAIWAN_REGION,
        ].includes(preferredRegion)
          ? {
              filters: {
                issue,
                sub_issue,
                language: language_filter,
                date: date_filter,
                message_text:
                  !telegramSearchANDCondition[0].conditions.length &&
                  !telegramSearchORConditions[0].conditions.length
                    ? telegram_content
                    : undefined,
                searchConditions: ([] as any).concat(
                  telegramSearchANDCondition,
                  telegramSearchORConditions
                ),
              },
            }
          : undefined,
    };
  }, [
    date.value,
    issueFilterOptions,
    language.value,
    search,
    selectedIssue,
    selectedSubIssue,
    socialMedia.value,
    preferredRegion,
  ]);

  const getDataByFilters = React.useCallback(() => {
    if (!isSearchStringValid(search)) {
      toast.error(ERRORS.INVALID_SEARCH_STRING, {
        position: "top-right",
        autoClose: SITE_CONSTANTS.TOAST_AUTOMATIC_CLOSE_TIME_MS,
        pauseOnHover: true,
        toastId: "error-toast",
      });
      return;
    }
    setLoader(true);
    setIsSearchButtonEnabled(false);
    const subSequentRequestParams = [];

    const params = { ...getParams() };

    if (params.articles) {
      subSequentRequestParams.push({
        endPoint: "/m3/search/get-search-result/articles",
        params: {
          ...params.articles,
          ...M3_PARAM,
          subscribed_region: preferredRegion,
        },
      });
    }

    if (params.twitter) {
      subSequentRequestParams.push({
        endPoint: "/m3/search/get-search-result/twitter",
        params: {
          ...params.twitter,
          ...M3_PARAM,
          subscribed_region: preferredRegion,
        },
      });
    }

    if (params.telegram) {
      subSequentRequestParams.push({
        endPoint: "/m3/search/get-search-result/telegram",
        params: { ...params.telegram, ...M3_PARAM },
      });
    }
    SendGAEvent({
      category: "SOTOS_Search_Term",
      action: "SOTOS_Search_Term",
      includeUserDetail: true,
      label: search,
    });
    axios
      .all(
        subSequentRequestParams.map((objects) => {
          return axiosInstance.post(objects.endPoint, {
            params: objects.params,
          });
        })
      )
      .then((result) => {
        let articleResults = {};
        let totalArticles = 0;
        let twitterResults = {};
        let totalTweets = 0;
        let telegramResults = {};
        let totalMessages = 0;

        result.forEach(({ data }) => {
          if (data.data.articleResults) {
            articleResults = data.data.articleResults;
            totalArticles = data.data.totalArticles;
          } else if (data.data.twitterResults) {
            twitterResults = data.data.twitterResults;
            totalTweets = data.data.totalTweets;
          } else if (data.data.telegramResults) {
            telegramResults = data.data.telegramResults;
            totalMessages = data.data.totalMessages;
          }
        });

        setSearchResults({
          articleResults,
          totalArticles,
          twitterResults,
          totalTweets,
          telegramResults,
          totalMessages,
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoader(false);
      });
  }, [getParams, search, preferredRegion]);

  const handleClickOutside = (event: React.MouseEvent<HTMLElement>) => {
    if (
      multiSelectWrapperRef.current &&
      !(multiSelectWrapperRef.current as HTMLDivElement).contains(
        event.target as any
      )
    ) {
      if (multiSelectRef.current && multiSelectRef.current.props.menuIsOpen) {
        multiSelectRef.current.props.onMenuClose();
      }
    } else {
      document.querySelectorAll("input").forEach((element) => {
        element.blur();
      });
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside as any, false);

    return () => {
      document.removeEventListener("click", handleClickOutside as any, false);
    };
  }, []);

  React.useEffect(() => {
    if (preferredRegion === SUBSCRIBED_REGION.IRAN_REGION && !issuesM3) {
      return;
    }
    if (preferredRegion === SUBSCRIBED_REGION.TAIWAN_REGION && !taiwanIssues) {
      return;
    }

    if (preferredRegion === SUBSCRIBED_REGION.CHINA_REGION && !chinaIssues) {
      return;
    }
    const regionWiseIssue =
      preferredRegion === SUBSCRIBED_REGION.TAIWAN_REGION
        ? taiwanIssues
        : preferredRegion === SUBSCRIBED_REGION.CHINA_REGION
        ? chinaIssues
        : issuesM3;
    const issueOptions = regionWiseIssue.englishIssues.map((issue) => {
      return { label: issue, value: issue };
    });
    issueOptions.unshift({ ...DEFAULT_ALL_ISSUES });
    setSelectedIssue([...issueOptions]);
    setIsseFilterOptions([...issueOptions]);
  }, [chinaIssues, issuesM3, preferredRegion, taiwanIssues]);

  const onInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isValidSearchString(e.target.value)) {
        return;
      }
      setSearch(e.target.value.replace(/\s\s+/g, " "));
      if (e.target.value.replace(/\s\s+/g, " ").length) {
        if (buttonEnablerRef.current) {
          clearTimeout(buttonEnablerRef.current);
        }
        buttonEnablerRef.current = setTimeout(() => {
          setIsSearchButtonEnabled(true);
          document
            .querySelector("#search-bar")
            ?.classList.add("flashing-button");
          flashingEffectRemover();
        }, 500);
      } else if (!e.target.value.replace(/\s\s+/g, " ").length) {
        if (buttonEnablerRef.current) {
          clearTimeout(buttonEnablerRef.current);
        }
        setIsSearchButtonEnabled(false);
      }
    },
    []
  );

  const onKeyPress = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        getDataByFilters();
      }
    },
    [getDataByFilters]
  );

  const handleMultiValueClick = () => {
    if (multiSelectRef.current) {
      if (!multiSelectRef.current.props.menuIsOpen) {
        multiSelectRef.current.props.onMenuOpen();
      } else {
        multiSelectRef.current.props.onMenuClose();
      }
    }
  };

  const ValueContainer = React.useCallback(
    ({ children, ...props }: ValueContainerProps<DropdownOption>) => {
      let label = "";
      const checkFilterOpt =
        props.selectProps.name === "issue"
          ? issueFilterOptions
          : subIssueFilterOptions;
      if (props.getValue().length === checkFilterOpt.length) {
        label = DEFAULT_ALL_ISSUES.label;
      } else if (props.getValue().length) {
        label = `${props.getValue().length} Issue Selected`;
      }

      return (
        <div
          style={{ display: "flex", width: "85%", height: "37px" }}
          onClick={handleMultiValueClick}
        >
          <components.ValueContainer {...props}>
            {label}
          </components.ValueContainer>
        </div>
      );
    },
    [issueFilterOptions, subIssueFilterOptions]
  );

  const onSelectFilter = React.useCallback(
    (newValue: SingleValue<unknown>, meta: ActionMeta<unknown>) => {
      switch (meta.name) {
        case "language":
          newValue && setLanguage(newValue as DropdownOption);
          break;
        case "social_media":
          newValue && setSocialMedia(newValue as DropdownOption);
          break;
        case "date":
          newValue && setDate(newValue as DropdownOption);
          break;

        default:
          break;
      }
      if (search) {
        setIsSearchButtonEnabled(true);
        document.querySelector("#search-bar")?.classList.add("flashing-button");
        flashingEffectRemover();
      }
    },
    [search]
  );

  const onChangeMultiSelectFilter = React.useCallback(
    (
      newValue: MultiValue<DropdownOption>,
      meta: ActionMeta<DropdownOption>
    ) => {
      let selectedOpts: MultiValue<DropdownOption>;
      if (meta.action === "select-option" && !meta.option?.value) {
        selectedOpts =
          meta.name === "issue"
            ? [...issueFilterOptions]
            : [...subIssueFilterOptions];
      } else if (meta.action === "deselect-option" && !meta.option?.value) {
        selectedOpts = [];
      } else if (
        newValue.length === issueFilterOptions.length - 1 &&
        newValue[0].value
      ) {
        selectedOpts = [...issueFilterOptions];
      } else if (newValue.length && !newValue[0].value) {
        selectedOpts = [...newValue].slice(1);
      } else {
        selectedOpts = [...newValue];
      }

      if (search) {
        setIsSearchButtonEnabled(true);
        document.querySelector("#search-bar")?.classList.add("flashing-button");
        flashingEffectRemover();
      }

      switch (meta.name) {
        case "issue":
          newValue && setSelectedIssue(selectedOpts);
          break;
        case "sub_issue":
          newValue && setSelectedSubIssue(selectedOpts);
          break;
        default:
          break;
      }
    },
    [issueFilterOptions, subIssueFilterOptions, search]
  );

  const navbarHeight = React.useMemo(() => {
    let height = 72;
    if (document.getElementsByTagName("nav").length) {
      height = document.getElementsByTagName("nav")[0].clientHeight;
    }

    return height;
  }, []);

  const language_options = React.useMemo(() => {
    return preferredRegion === SUBSCRIBED_REGION.TAIWAN_REGION
      ? SOURCE_LANGUAGE_OPTIONS_TAIWAN
      : preferredRegion === SUBSCRIBED_REGION.CHINA_REGION
      ? SOURCE_LANGUAGE_OPTIONS_CHINA
      : SOURCE_LANGUAGE_OPTIONS;
  }, [preferredRegion]);

  const sociam_media_options = React.useMemo(() => {
    return [
      SUBSCRIBED_REGION.CHINA_REGION,
      SUBSCRIBED_REGION.TAIWAN_REGION,
    ].includes(preferredRegion)
      ? SOCIAL_MEDIA_OPTIONS.filter((option) => option.value !== "Telegram")
      : SOCIAL_MEDIA_OPTIONS;
  }, [preferredRegion]);

  return (
    <div>
      {/* <PageHeading title="Search" className="my-2" /> */}
      <StyledFilterWrapper id="search-filters" top={navbarHeight}>
        <Row className="d-flex flex-row mt-2">
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <InputGroup>
              <InputGroup.Text
                style={{
                  borderTopLeftRadius: 24,
                  borderBottomLeftRadius: 24,
                }}
              >
                <Search size={20} />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search by keyword"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={onInputChange}
                value={search}
                onKeyDown={onKeyPress}
                disabled={loader}
                style={{
                  borderTopRightRadius: 24,
                  borderBottomRightRadius: 24,
                }}
              />
            </InputGroup>
          </Col>
          <Col lg={3} sm={12} xs={12} xl={3}>
            <ModifiedReactSelectV2
              isSearchable={false}
              options={language_options}
              name="language"
              onChange={onSelectFilter}
              value={language}
            />
          </Col>
          <Col lg={3} sm={12} xs={12} xl={3}>
            <ModifiedReactSelectV2
              isSearchable={false}
              options={sociam_media_options}
              name="social_media"
              onChange={onSelectFilter}
              value={socialMedia}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg={3} xl={3} sm={12} xs={12}>
            <div ref={multiSelectWrapperRef}>
              <ReactSelect
                options={issueFilterOptions}
                name="issue"
                isClearable={false}
                value={selectedIssue}
                isMulti
                hideSelectedOptions={false}
                onChange={onChangeMultiSelectFilter}
                components={{
                  ValueContainer,
                  Option: MultiSelectOptionsWithCB,
                }}
                ref={multiSelectRef}
                closeMenuOnSelect={false}
                styles={{
                  control: (baseStyles, state) => {
                    return {
                      ...baseStyles,
                      borderRadius: "24px",
                      boxShadow: state.menuIsOpen
                        ? `0 0 0 1px ${THEME_COLOUR_PRIMARY}`
                        : baseStyles.boxShadow,
                      borderColor: state.menuIsOpen
                        ? `${THEME_COLOUR_PRIMARY}`
                        : baseStyles.borderColor,
                      ":hover": {
                        borderColor: state.menuIsOpen
                          ? `${THEME_COLOUR_PRIMARY}`
                          : baseStyles[":hover"]?.borderColor,
                      },
                    };
                  },
                  option: (baseStyles, { isSelected }) => {
                    return {
                      ...baseStyles,
                      backgroundColor: isSelected ? "#F0F5FF" : "",
                      color: isSelected ? `${THEME_COLOUR_PRIMARY}` : "",
                    };
                  },
                  valueContainer: (baseStyles) => {
                    return {
                      ...baseStyles,
                      fontSize: 16,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: "#333",
                      maxWidth: "100%",
                      display: "initial",
                      margin: "auto",
                      paddingLeft: "20px",
                    };
                  },
                }}
              />
            </div>
          </Col>

          {/*  <Col lg={4} xl={4} sm={12} xs={12}>
            <div>Sub Issue</div>
            <ReactSelect
              isSearchable={false}
              options={subIssueFilterOptions}
              name="sub_issue"
              value={selectedSubIssue}
              isMulti
              hideSelectedOptions={false}
              onChange={onChangeMultiSelectFilter}
              closeMenuOnSelect={false}
              components={{
                ValueContainer,
                Option: MultiSelectOptionsWithCB,
              }}
            />
          </Col> */}

          <Col lg={2} xl={2} sm={12} xs={12}>
            <ModifiedReactSelectV2
              isSearchable={false}
              options={DEFAULT_DATE_OPTIONS}
              name="date"
              value={date}
              hideSelectedOptions={false}
              onChange={onSelectFilter}
            />
          </Col>
          <Col lg={1} xl={1} md={3} sm={12} xs={12}>
            <Button
              onClick={getDataByFilters}
              disabled={!search || !isSearchButtonEnabled}
              style={{
                borderRadius: 24,
                opacity: 1,
                cursor: !!(!search || !isSearchButtonEnabled)
                  ? "not-allowed"
                  : "pointer",
                backgroundColor: THEME_COLOUR_PRIMARY,
                borderColor: THEME_COLOUR_PRIMARY,
                pointerEvents: "auto",
                boxShadow: `0 0 0 0 ${THEME_COLOUR_PRIMARY}`,
                animation: "auto ease 0s 1 normal none running none",
              }}
              className={`${
                search && isSearchButtonEnabled ? "flashing-button" : ""
              }`}
              id="search-bar"
            >
              Search
            </Button>
          </Col>
        </Row>
      </StyledFilterWrapper>
      {loader ? (
        <Loader />
      ) : (
        <>
          {searchResult ? (
            <SearchResult
              {...searchResult}
              filterLanguage={language.value ?? "English"}
              searchKeywords={searchKeywords}
            />
          ) : (
            <>
              <Row className="d-flex align-items-center justify-content-center mt-5">
                <Col className="ps-0">
                  <Alert variant="info" className="text-center">
                    <div>
                      <Info size={30} className="pb-1 info-icon" />
                      <span className="ps-1">
                        Search our <b>90-day repository</b> for name of
                        individuals, entities, keywords, and #hashtags in the
                        target language or in English.
                      </span>
                    </div>
                  </Alert>
                </Col>
              </Row>
              {/* <Row className="d-flex align-items-center justify-content-center mt-5">
                <Col lg={8} xl={8} md={12} sm={12} xs={12}>
                  <div className="border">
                    <div className="d-flex align-items-center justify-content-center">
                      <div>
                        <div className="" style={{ display: "inline-block" }}>
                          <div className="p-5 text-center">
                            <StyledInstuctionTitle>
                              Repository Search:&nbsp;
                            </StyledInstuctionTitle>
                            Search our <b>90-day repository</b> for name of
                            individuals, entities, keywords, and #hashtags in
                            the target language or in English.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-center">
                      <div className="p-5 pt-1 text-center">
                        <StyledInstuctionTitle>
                          Boolean Search:&nbsp;
                        </StyledInstuctionTitle>
                        Use <b>AND</b> to find results that include all the
                        specified terms. Use <b>OR</b> to find results that
                        include any of the specified terms.
                      </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-center">
                      <div className="p-5 pt-1 text-center">
                        <StyledInstuctionTitle>
                          Exact Pharase Search:&nbsp;
                        </StyledInstuctionTitle>
                        Use <b>quotation marks (" ")</b> around a set of words
                        to perform a literal search for that exact phrase.
                      </div>
                    </div>
                  </div>
                </Col>
              </Row> */}
            </>
          )}
        </>
      )}
      <GoToTop offset={800} />
      <ToastContainer />
    </div>
  );
};

const StyledFilterWrapper = styled("div").withConfig({
  shouldForwardProp: (prop) => prop !== "top",
})<{ top: number }>(({ top }) => ({
  position: "sticky",
  zIndex: 9,
  backgroundColor: "#FFF",
  paddingTop: 20,
  paddingBottom: 10,
  top,
}));

const StyledInstuctionTitle = styled.span`
  font-size: 1rem;
  color: ${THEME_COLOUR_PRIMARY};
  font-weight: bold;
`;
