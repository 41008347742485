import React from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import {
  NO_RECORD_MESSAGE,
  SUBSCRIBED_REGION,
  THEME_COLOUR_PRIMARY,
  V2_THEME_BLACK,
} from "../../constants";
import { RootState } from "../../store";

interface Props {
  selectedIssue: string;
  onClickIssueName: (value: { label: string; value: string }) => void;
  data: Array<{ x: string; y: number }>;
  onClickHashtagTile: (hashtag: string) => void;
}

export const TwitterTabContent = ({
  selectedIssue,
  onClickIssueName,
  data,
  onClickHashtagTile,
}: Props) => {
  const { issuesM3, taiwanIssues, preferredRegion } = useSelector(
    (state: RootState) => state.preference
  );
  const issueList = React.useMemo(() => {
    return preferredRegion === SUBSCRIBED_REGION.IRAN_REGION
      ? issuesM3.englishIssues
      : taiwanIssues.englishIssues;
  }, [issuesM3.englishIssues, preferredRegion, taiwanIssues.englishIssues]);

  const handleIssueNameClick = (issueName: string) => {
    onClickIssueName({ label: issueName, value: issueName });
  };

  return (
    <Row>
      <Col xl={12} lg={12} md={12} sm={12}>
        {!!data.length ? (
          <StyledCardContainer className="custom-scroll">
            <div className="d-flex flex-wrap mx-2">
              {data.map((record, index) => {
                return (
                  <StyledHashtagTileWrapper
                    key={index}
                    className="wrap-col"
                    tileNumber={2}
                    onClick={() => onClickHashtagTile(record.x)}
                  >
                    <StyledHashtagTile
                      bgContent={index + 1}
                      className="align-items-center justify-content-between"
                    >
                      <StyledHashtag className="me-3 hashtag-keyword">
                        {record.x}
                      </StyledHashtag>
                      <StyledHashtagCount className="hashtag-count">
                        {record.y}
                      </StyledHashtagCount>
                    </StyledHashtagTile>
                  </StyledHashtagTileWrapper>
                );
              })}
            </div>
          </StyledCardContainer>
        ) : (
          <div
            className="d-flex align-items-center justify-content-center mx-2"
            style={{ height: 300 }}
          >
            <div>{NO_RECORD_MESSAGE}</div>
          </div>
        )}
      </Col>
    </Row>
  );
};

const IssueWrapper = styled.div`
  border-right: 1px solid #cbd4e1;
  max-height: 350px;
  overflow-y: scroll;
`;

const StyledHashtag = styled.div`
  font-size: 14px;
  color: #2f6fed;
`;

const StyledIssueTile = styled("div").withConfig({
  shouldForwardProp: (props) => props !== "isActive",
})<{ isActive: boolean }>(({ isActive }) => {
  return {
    backgroundColor: isActive ? "#F6F8FC" : "none",
    padding: 12,
    borderRadius: 24,
    marginTop: 10,
    marginBottom: 10,
    color: isActive ? V2_THEME_BLACK : "#64748B",
    fontSize: 14,
    fontWeight: isActive ? 500 : 400,
    cursor: "pointer",
  };
});

const StyledCardContainer = styled.div(() => ({
  maxHeight: "350px",
  overflowY: "auto",
  paddingTop: "12px",
}));

const StyledHashtagTileWrapper = styled("div").withConfig({
  shouldForwardProp: (props) => {
    return !["tileNumber"].includes(props);
  },
})<{ tileNumber: number }>(({ tileNumber }) => {
  return css`
    flex: 0 0 auto;
    min-width: calc(100% / ${tileNumber});
    padding: 0 7px 10px 0;
    cursor: pointer;
  `;
});

const StyledHashtagCount = styled.small`
  color: ${V2_THEME_BLACK};
  font-size: 20px;
  font-weight: 500;
`;

const StyledHashtagTile = styled("div").withConfig({
  shouldForwardProp: (props) => props !== "bgContent",
})<{ bgContent: number }>(({ bgContent }) => {
  return css`
    background-color: #f1f4f9;
    padding: 8px 10px;
    border-radius: 12px;
    font-size: 18px;
    position: relative;
    overflow: hidden;
    &:hover {
      background-color: ${THEME_COLOUR_PRIMARY};
      color: #f1f4f9;
    }
    &:hover ${StyledHashtagCount} {
      color: #fff;
    }
    &:hover ${StyledHashtag} {
      color: #fff;
    }
  `;
});
