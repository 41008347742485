import { Circle } from "@styled-icons/material";
import React from "react";
import { Card, Row, Tab, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { StyledTabs } from "../../components/StyledTab";
import { TwitterIcon } from "../../components/TabIcons";
import {
  NO_RECORD_MESSAGE,
  SUBSCRIBED_REGION,
  THEME_COLOUR_PRIMARY,
  V2_THEME_BLACK,
} from "../../constants";
import { USFormattedNumber } from "../../helpers";
import { RootState } from "../../store";
import { StyledCardHeader, StyledCardTitle } from "./DigitalMediaCoverage";
import { PolarRadarChart } from "./PolarRadarChart";

export interface TweeterMetaProps {
  tabMeta: {
    favouriteCount: Array<{
      issue: string;
      twitter_handle: string;
      tweet_favorite_count: number;
    }>;
    quoteCount: Array<{
      issue: string;
      twitter_handle: string;
      tweet_quote_count: number;
    }>;
    retweetCount: Array<{
      issue: string;
      twitter_handle: string;
      tweet_retweet_count: number;
    }>;
    tweetCount: Array<{
      issue: string;
      twitter_handle: string;
      user_tweet_count: number;
    }>;
  };
}

interface Props extends TweeterMetaProps {
  onClickChart?: (seriesIndex: number, seriesName?: string) => void;
  onClickTwitterHandle: (handleName: string) => void;
}

const COLORS = ["#7666FA", "#F7C122", "#FF9D4E", "#62DAAA", "#657798"];

export const MetaTabContent = ({
  tabMeta,
  onClickChart,
  onClickTwitterHandle,
}: Props) => {
  const [activeTab, setActiveTab] = React.useState("tweet_count");

  return (
    <Card style={{ borderRadius: 24 }}>
      <StyledCardHeader className="d-flex align-items-center justify-content-between">
        <div>
          <StyledCardTitle className="d-flex align-items-center">
            <div
              style={{ backgroundColor: "#F1F4F9" }}
              className="p-2 rounded-circle"
            >
              <TwitterIcon isActive={false} color={V2_THEME_BLACK} />
            </div>
            <div className="ps-2" style={{ fontStyle: "inherit" }}>
              Twitter Handles by count
            </div>
          </StyledCardTitle>
        </div>
      </StyledCardHeader>
      <Card.Body className="pt-0">
        <Row>
          <StyledTabs
            defaultActiveKey={activeTab}
            noBgOnActive={true}
            onSelect={(tab: any, event: any) => {
              setActiveTab(tab);
            }}
          >
            <Tab
              title="Tweets"
              eventKey="tweet_count"
              color="#94a3b8"
              style={{ minHeight: 350 }}
            >
              {tabMeta.tweetCount.length ? (
                <div className="pt-3 d-flex">
                  <Col xl={8} lg={8}>
                    <PolarRadarChart
                      series={tabMeta.tweetCount
                        .map((favMeta) => favMeta.user_tweet_count)
                        .slice(0, 5)}
                      colors={COLORS}
                      seriesName="tweetCount"
                      onClickChart={onClickChart}
                    />
                  </Col>
                  <Col xl={4} lg={4}>
                    {tabMeta.tweetCount.slice(0, 5).map((favMeta, index) => (
                      <div className="d-flex my-3" key={index}>
                        <div>
                          <Indicator
                            size={8}
                            color={COLORS[index]}
                            className="ms-0 me-2 ps-0 mt-2"
                          />
                        </div>
                        <div>
                          <Label
                            onClick={() =>
                              onClickTwitterHandle(favMeta.twitter_handle)
                            }
                          >
                            @{favMeta.twitter_handle}
                          </Label>
                          <Percentage>
                            {USFormattedNumber(favMeta.user_tweet_count)}
                          </Percentage>
                        </div>
                      </div>
                    ))}
                  </Col>
                </div>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center mx-2"
                  style={{ height: 300 }}
                >
                  <div>{NO_RECORD_MESSAGE}</div>
                </div>
              )}
            </Tab>
            <Tab
              title="Favourites"
              eventKey="favourite_count"
              color="#94a3b8"
              style={{ minHeight: 350 }}
            >
              {!!tabMeta.favouriteCount.length ? (
                <div className="pt-3 d-flex">
                  <Col xl={8} lg={8}>
                    <PolarRadarChart
                      series={tabMeta.favouriteCount
                        .map((favMeta) => favMeta.tweet_favorite_count)
                        .slice(0, 5)}
                      colors={COLORS}
                      seriesName="favouriteCount"
                      onClickChart={onClickChart}
                    />
                  </Col>
                  <Col xl={4} lg={4}>
                    {tabMeta.favouriteCount
                      .slice(0, 5)
                      .map((favMeta, index) => (
                        <div className="d-flex my-3" key={index}>
                          <div>
                            <Indicator
                              size={8}
                              color={COLORS[index]}
                              className="ms-0 me-2 ps-0 mt-2"
                            />
                          </div>
                          <div>
                            <Label
                              onClick={() =>
                                onClickTwitterHandle(favMeta.twitter_handle)
                              }
                            >
                              @{favMeta.twitter_handle}
                            </Label>
                            <Percentage>
                              {favMeta.tweet_favorite_count}
                            </Percentage>
                          </div>
                        </div>
                      ))}
                  </Col>
                </div>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center mx-2"
                  style={{ height: 300 }}
                >
                  <div>{NO_RECORD_MESSAGE}</div>
                </div>
              )}
            </Tab>
            <Tab
              title="Retweets"
              eventKey="retweet_count"
              color="#94a3b8"
              style={{ minHeight: 350 }}
            >
              {tabMeta.retweetCount.length ? (
                <div className="pt-3 d-flex">
                  <Col xl={8} lg={8}>
                    <PolarRadarChart
                      series={tabMeta.retweetCount
                        .map((favMeta) => favMeta.tweet_retweet_count)
                        .slice(0, 5)}
                      colors={COLORS}
                      seriesName="retweetCount"
                      onClickChart={onClickChart}
                    />
                  </Col>
                  <Col xl={4} lg={4}>
                    {tabMeta.retweetCount.slice(0, 5).map((favMeta, index) => (
                      <div className="d-flex my-3" key={index}>
                        <div>
                          <Indicator
                            size={8}
                            color={COLORS[index]}
                            className="ms-0 me-2 ps-0 mt-2"
                          />
                        </div>
                        <div>
                          <Label
                            onClick={() =>
                              onClickTwitterHandle(favMeta.twitter_handle)
                            }
                          >
                            @{favMeta.twitter_handle}
                          </Label>
                          <Percentage>{favMeta.tweet_retweet_count}</Percentage>
                        </div>
                      </div>
                    ))}
                  </Col>
                </div>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center mx-2"
                  style={{ height: 300 }}
                >
                  <div>{NO_RECORD_MESSAGE}</div>
                </div>
              )}
            </Tab>
            <Tab
              title="Quotes"
              eventKey="quote_count"
              color="#94a3b8"
              style={{ minHeight: 350 }}
            >
              {tabMeta.quoteCount.length ? (
                <div className="pt-3 d-flex">
                  <Col xl={8} lg={8}>
                    <PolarRadarChart
                      series={tabMeta.quoteCount
                        .map((favMeta) => favMeta.tweet_quote_count)
                        .slice(0, 5)}
                      colors={COLORS}
                      seriesName="quoteCount"
                      onClickChart={onClickChart}
                    />
                  </Col>
                  <Col xl={4} lg={4}>
                    {tabMeta.quoteCount.slice(0, 5).map((favMeta, index) => (
                      <div className="d-flex my-3" key={index}>
                        <div>
                          <Indicator
                            size={8}
                            color={COLORS[index]}
                            className="ms-0 me-2 ps-0 mt-2"
                          />
                        </div>
                        <div>
                          <Label
                            onClick={() =>
                              onClickTwitterHandle(favMeta.twitter_handle)
                            }
                          >
                            @{favMeta.twitter_handle}
                          </Label>
                          <Percentage>{favMeta.tweet_quote_count}</Percentage>
                        </div>
                      </div>
                    ))}
                  </Col>
                </div>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center mx-2"
                  style={{ height: 300 }}
                >
                  <div>{NO_RECORD_MESSAGE}</div>
                </div>
              )}
            </Tab>
          </StyledTabs>
        </Row>
      </Card.Body>
    </Card>
  );
};

const Indicator = styled(Circle)<{ color: string }>`
  color: ${(p) => p.color};
  margin-right: 12px;
  margin-left: 12px;
`;

const Label = styled.div`
  color: ${THEME_COLOUR_PRIMARY};
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
`;

const Percentage = styled.div`
  color: #475569;
  font-size: 12px;
  line-height: 24px;
`;
