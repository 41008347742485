import * as React from "react";
import styled from "styled-components";

export const LanguageIcon = React.memo(
  ({ postLanguage }: { postLanguage: string }) => {
    const Flag_URL = React.useMemo(() => {
      switch (postLanguage) {
        case "Chinese (Traditional)":
          return process.env.PUBLIC_URL + "/Taiwan_Language_logo.svg";
        case "Chinese (Simplified)":
          return process.env.PUBLIC_URL + "/China_Language_logo.svg";
        case "Persian":
          return process.env.PUBLIC_URL + "/Farsi_Language_logo.svg";
        default:
          return process.env.PUBLIC_URL + "/EN_Language_logo.svg";
      }
    }, [postLanguage]);
    return (
      <span>
        <StyledLanguageIcon
          height={30}
          src={Flag_URL}
          alt="Language logo"
          className="rounded-circle"
        />
      </span>
    );
  }
);

const StyledLanguageIcon = styled.img`
  border: 1px solid #ccc !important;
`;
