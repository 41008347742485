import React from "react";
import { AxiosResponse } from "axios";
import { ActionMeta, SingleValue } from "react-select";
import { Col, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from "luxon";
import { axiosInstance } from "../../api/index";
import { FilterTypes, FilterOperations } from "../../types/filterTypes";
import {
  DEFAULT_POLITICAL_AFFILIATION,
  DEFAULT_POST_LANGUAGE,
  DEFAULT_START_DATE,
  MAX_ALLOWED_BACK_DATE,
  SUBSCRIBED_REGION,
} from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedIssueM3 } from "../../store/slice/issuePreference";
import { useNavigate } from "react-router";
import { setArticlesFilterValues } from "../../store/slice/filters";
import { RootState } from "../../store";
import { MapStats } from "./MapStats";
import { StyledDateSelecterM3 } from "../../components/CommonStyledComponents";
import { ModifiedReactSelect } from "../../components/ModifiedReactSelect";
import { HighChartDonut } from "./highChartOption";
import { EmptyChartMessage } from "../../components/EmptyChartMessage";
import { ReactGA } from "../../components/GoogleAnalytics";

type Option = { label: string; value: string };
type FilterValue = {
  political_affiliation: Option;
  source_language: Option;
  date: {
    startDate: Date | null;
    endDate: Date | null;
  };
};

export const DigitalMediaCoverage = () => {
  const { preferredRegion } = useSelector(
    (state: RootState) => state.preference
  );

  const userDetailRef = React.useRef(
    JSON.parse(localStorage.getItem("user") ?? "")
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dateSelectorRef = React.useRef<any>();
  const isInitialIssueSelected = React.useRef(false);
  const { articles: reduxArticleFilter } = useSelector(
    (state: RootState) => state.reduxFilter
  );
  const [pieChartData, setPieChartData] = React.useState<
    Array<{ label: string; issue_count: number; overall_percentage: number }>
  >([]);

  const [filterOptions, setFilterOptions] = React.useState<{
    political_affiliation?: Array<Option>;
    source_language?: Array<Option>;
  }>({
    political_affiliation: undefined,
    source_language: undefined,
  });

  const [dateFilter, setDateFilter] = React.useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: reduxArticleFilter.date.startDate
      ? new Date(reduxArticleFilter.date.startDate)
      : new Date(DEFAULT_START_DATE),
    endDate: reduxArticleFilter.date.endDate
      ? new Date(reduxArticleFilter.date.endDate)
      : new Date(DateTime.now().toMillis()),
  });

  const [selectedFilters, setSelectedFilters] = React.useState<FilterValue>({
    political_affiliation: { ...DEFAULT_POLITICAL_AFFILIATION },
    source_language: reduxArticleFilter.source_language
      ? { ...reduxArticleFilter.source_language }
      : { ...DEFAULT_POST_LANGUAGE },
    date: {
      startDate: reduxArticleFilter.date.startDate
        ? new Date(reduxArticleFilter.date.startDate)
        : new Date(DEFAULT_START_DATE),
      endDate: reduxArticleFilter.date.endDate
        ? new Date(reduxArticleFilter.date.endDate)
        : new Date(DateTime.now().toMillis()),
    },
  });

  React.useEffect(() => {
    setSelectedFilters({
      political_affiliation: reduxArticleFilter.political_affiliation
        ? { ...reduxArticleFilter.political_affiliation }
        : { ...DEFAULT_POLITICAL_AFFILIATION },
      source_language: reduxArticleFilter.source_language
        ? { ...reduxArticleFilter.source_language }
        : { ...DEFAULT_POST_LANGUAGE },
      date: {
        startDate: reduxArticleFilter.date.startDate
          ? new Date(reduxArticleFilter.date.startDate)
          : new Date(DEFAULT_START_DATE),
        endDate: reduxArticleFilter.date.endDate
          ? new Date(reduxArticleFilter.date.endDate)
          : null,
      },
    });
    if (reduxArticleFilter.date.startDate && reduxArticleFilter.date.endDate) {
      setDateFilter({
        startDate: reduxArticleFilter.date.startDate
          ? new Date(reduxArticleFilter.date.startDate)
          : new Date(DEFAULT_START_DATE),
        endDate: reduxArticleFilter.date.endDate
          ? new Date(reduxArticleFilter.date.endDate)
          : null,
      });
    }
  }, [reduxArticleFilter]);

  React.useEffect(() => {
    setPieChartData([]);
    isInitialIssueSelected.current = false;
  }, [preferredRegion]);

  React.useEffect(() => {
    if (!pieChartData.length || isInitialIssueSelected.current) {
      return;
    }

    isInitialIssueSelected.current = true;
  }, [dispatch, pieChartData, preferredRegion]);

  const refinedParams = React.useMemo(() => {
    ReactGA.event({
      category: "SOTOS_Insights_Digital_Filter_Updated",
      action: "SOTOS_Insights_Digital_Filter_Updated",
      label: `User-${userDetailRef.current && userDetailRef.current.name}-${
        userDetailRef.current && userDetailRef.current.lastName
      },PA-${selectedFilters.political_affiliation.value},SL-${
        selectedFilters.source_language.value
      },SD-${DateTime.fromJSDate(
        selectedFilters.date.startDate ??
          DateTime.fromMillis(DEFAULT_START_DATE).toJSDate()
      ).toFormat("yyyy-LL-dd")},ED-${DateTime.fromJSDate(
        selectedFilters.date.endDate ?? DateTime.now().toJSDate()
      ).toFormat("yyyy-LL-dd")}`,
    });
    setPieChartData([]);
    return {
      model: "M3",
      subscribed_region: preferredRegion,
      filters: {
        political_affiliation: {
          value: selectedFilters.political_affiliation.value,
          type: FilterTypes.eq,
        },
        source_language: {
          value: selectedFilters.source_language.value,
          type: FilterTypes.eq,
        },
        date: {
          operator: FilterOperations.AND,
          conditions: [
            {
              value: DateTime.fromJSDate(
                selectedFilters.date.startDate ??
                  DateTime.fromMillis(DEFAULT_START_DATE).toJSDate()
              ).toFormat("yyyy-LL-dd"),
              type: FilterTypes.gte,
            },
            {
              value: DateTime.fromJSDate(
                selectedFilters.date.endDate ?? DateTime.now().toJSDate()
              ).toFormat("yyyy-LL-dd"),
              type: FilterTypes.lte,
            },
          ],
        },
      },
    };
  }, [
    selectedFilters.date.endDate,
    selectedFilters.date.startDate,
    selectedFilters.political_affiliation.value,
    selectedFilters.source_language.value,
    preferredRegion,
  ]);

  const getDigitalMediaCoverageData = React.useCallback(() => {
    axiosInstance
      .post("insights/digital-media-coverage", { params: refinedParams })
      .then((res: AxiosResponse) => {
        setPieChartData(res.data?.pieChartData ?? []);
      })
      .catch((error) => {
        console.log(error);
        ReactGA.event({
          category: "SOTOS_Insights_Digital_Media_Error",
          action: "SOTOS_Insights_Digital_Media_Error",
          label: `User-${userDetailRef.current && userDetailRef.current.name}-${
            userDetailRef.current && userDetailRef.current.lastName
          }, Error-${error?.response?.data?.message}`,
        });
      });
  }, [refinedParams]);

  React.useEffect(() => {
    getDigitalMediaCoverageData();
  }, [getDigitalMediaCoverageData]);

  React.useEffect(() => {
    axiosInstance
      .post("insights/digital-media-coverage/filters", {
        params: {
          model: "M3",
          subscribed_region: preferredRegion,
        },
      })
      .then(
        (
          res: AxiosResponse<{
            data: {
              political_affiliation: Array<Option>;
              source_language: Array<Option>;
            };
          }>
        ) => {
          setFilterOptions({
            political_affiliation: [
              { ...DEFAULT_POLITICAL_AFFILIATION },
              ...res.data.data.political_affiliation,
            ],
            source_language: [
              { ...DEFAULT_POST_LANGUAGE },
              ...res.data.data.source_language,
            ],
          });
        }
      )
      .catch((err) => console.log(err));
  }, [preferredRegion]);

  const onDateChange = React.useCallback(
    ([newStartDate, newEndDate]: [Date | null, Date | null]) => {
      if (dateSelectorRef.current.state.open && newEndDate) {
        dateSelectorRef.current.setOpen(false);
      }

      setDateFilter({
        startDate: newStartDate
          ? new Date(DateTime.fromJSDate(newStartDate).toMillis())
          : null,
        endDate: newEndDate
          ? new Date(DateTime.fromJSDate(newEndDate).toMillis())
          : null,
      });

      if (newEndDate) {
        dispatch(
          setArticlesFilterValues({
            ...reduxArticleFilter,
            date: {
              startDate: newStartDate
                ? DateTime.fromJSDate(newStartDate).toMillis()
                : null,
              endDate: newEndDate
                ? DateTime.fromJSDate(newEndDate).toMillis()
                : null,
            },
          })
        );
      }
    },
    [dispatch, reduxArticleFilter]
  );

  const onChangeFilterOption = React.useCallback(
    (newValue: SingleValue<unknown>, meta: ActionMeta<unknown>) => {
      dispatch(
        setArticlesFilterValues({
          ...reduxArticleFilter,
          [meta.name ?? ""]: newValue,
        })
      );
    },
    [dispatch, reduxArticleFilter]
  );

  const onSelectHighchartIssue = React.useCallback(
    (issueName: string) => {
      if (issueName) {
        ReactGA.event({
          category: "SOTOS_Insights_Digital_Chart_Issue_Clicked",
          action: "SOTOS_Insights_Digital_Chart_Issue_Clicked",
          label: `User-${userDetailRef.current && userDetailRef.current.name}-${
            userDetailRef.current && userDetailRef.current.lastName
          },Issue-${issueName}`,
        });
        dispatch(
          setSelectedIssueM3({
            selectedIssue: issueName,
            selectedTab: "Article",
            region: preferredRegion,
          })
        );
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          navigate(`/${preferredRegion.toLowerCase()}/issue-watch`);
        }, 300);
      }
    },
    [dispatch, navigate, preferredRegion]
  );

  return (
    <>
      <Row className="d-flex flex-row mt-2">
        <Col lg={2} md={3} sm={12} xs={12} xl={2}>
          <ModifiedReactSelect
            isSearchable={false}
            options={filterOptions.political_affiliation}
            name="political_affiliation"
            onChange={onChangeFilterOption}
            value={selectedFilters.political_affiliation}
          />
        </Col>

        <Col lg={3} md={4} sm={12} xs={12} xl={3}>
          <ModifiedReactSelect
            isSearchable={false}
            options={filterOptions.source_language}
            name="source_language"
            onChange={onChangeFilterOption}
            value={selectedFilters.source_language}
          />
        </Col>

        <Col lg={2} md={3} sm={12} xs={12} xl={2}>
          <StyledDateSelecterM3
            className="w-100 text-center"
            selected={dateFilter.startDate}
            wrapperClassName="w-100"
            onChange={onDateChange}
            minDate={new Date(MAX_ALLOWED_BACK_DATE)}
            maxDate={new Date(DateTime.now().toMillis())}
            startDate={dateFilter.startDate}
            endDate={dateFilter.endDate}
            selectsRange
            dateFormat={"MM/dd"}
            showDisabledMonthNavigation
            shouldCloseOnSelect
            ref={dateSelectorRef}
            onCalendarOpen={() => {
              dateSelectorRef.current.input.classList.add("primary-border");
            }}
            onCalendarClose={() => {
              dateSelectorRef.current.input.classList.remove("primary-border");
            }}
          />
        </Col>
      </Row>
      {/* <ReactApexChart
        series={[{ data: chartData }]}
        options={graphOptions}
        type="treemap"
        height={650}
      /> */}

      <Row className="my-3 align-items-center">
        {pieChartData.length ? (
          <>
            <Col xs={12} sm={12} md={7} lg={7} xl={7}>
              <HighChartDonut
                data={pieChartData}
                onSelect={onSelectHighchartIssue}
              />
            </Col>
            <Col xs={12} sm={12} md={5} lg={5} xl={5}>
              <MapStats data={pieChartData} />
            </Col>
          </>
        ) : (
          <EmptyChartMessage />
        )}
      </Row>
    </>
  );
};
