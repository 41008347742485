import React from "react";
import { SectionSubTitle } from "../../components/SectionSubTitle";
import { DigitalMediaCoverage } from "./DigitalMediaCoverage";
import { SocialMediaCoverage } from "./SocialMediaCoverage";
import { Info } from "@styled-icons/material-outlined";
import "./insights.css";
import { HashtageCoverage } from "./hashtagsCoverage";
import { InsightsOpinionAI } from "./OpinionAI";
import { SUBSCRIBED_REGION, TAB_TITLE } from "../../constants";
import { ReactGA } from "../../components/GoogleAnalytics";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const Insights = () => {
  const { preferredRegion } = useSelector(
    (state: RootState) => state.preference
  );
  React.useEffect(() => {
    document.title = TAB_TITLE.insights;
    ReactGA.event({
      category: "SOTOS_Insights_Page_Landed",
      action: "SOTOS_Insights_Page_Landed",
    });
  }, []);
  return (
    <>
      <div className="mb-5">
        <SectionSubTitle
          text={
            preferredRegion === SUBSCRIBED_REGION.TAIWAN_REGION
              ? "Digital Media Issues"
              : "Top 5 Digital Media Issues"
          }
          icon={<Info size={25} color="#475569" className="info-icon" />}
          iconPosition="Trailing"
          description="Destribution of coverage in selected digital media based on political affiliation."
        />
        <DigitalMediaCoverage />
      </div>
      <div className="mb-5">
        <SectionSubTitle
          text={
            preferredRegion === SUBSCRIBED_REGION.TAIWAN_REGION
              ? "Social Media Issues"
              : "Top 5 Social Media Issues"
          }
          icon={<Info size={25} color="#475569" className="info-icon" />}
          iconPosition="Trailing"
          description="Destribution of coverage in selected social media based on geographical locations."
        />
        <SocialMediaCoverage />
      </div>
      <div className="mb-5">
        <SectionSubTitle
          text="Opinion AI"
          icon={<Info size={25} color="#475569" className="info-icon" />}
          iconPosition="Trailing"
          description="Level of optimism and pessimism within debates surrounding specific issues."
        />
        <InsightsOpinionAI />
      </div>
      <div className="mb-5">
        <SectionSubTitle
          text="Top Hashtags"
          icon={<Info size={25} color="#475569" className="info-icon" />}
          iconPosition="Trailing"
          description="Trending hashtags on selected social media platforms related to the issues of interest."
        />
        <HashtageCoverage />
      </div>
    </>
  );
};
