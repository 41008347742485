import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  COLOR_PALEETE,
  DEFAULT_ALL_ISSUES,
  DEFAULT_POST_LANGUAGE,
  DEFAULT_POST_LOCATION,
  SUBSCRIBED_REGION,
  THEME_COLOUR_PRIMARY,
} from "../../constants";
import ReactSelect, {
  ActionMeta,
  components,
  MultiValue,
  SingleValue,
  ValueContainerProps,
} from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { FilterOperations, FilterTypes } from "../../types/filterTypes";
import axiosInstance from "../../api";
import axios, { AxiosError, AxiosResponse } from "axios";
import ReactApexChart from "react-apexcharts";
import { getGraphOptions } from "../../helpers/heatMapGraphOptions";
import { getBackDate } from "../../helpers";
import { Loader } from "../../components/Loader";
import { DropdownOption } from "../../types/types";
import { MultiSelectOptionsWithCB } from "../../components/MultiSelectCBOptions";
import { MultiSelectMenu } from "../../components/MultiSelectMenu";
import { useNavigate } from "react-router";
import { getRedisCacheKey } from "../../helpers/redisCacheKeyHelper";
import { ModifiedReactSelect } from "../../components/ModifiedReactSelect";
import { setSearchScreenFilter } from "../../store/slice/search";
import { useDispatch, useSelector } from "react-redux";
import { DateSwitcher } from "../../components/DateSwitcher";
import { TrendingHashtagCard } from "./TrendingHashtagCard";
import { SendGAEvent } from "../../helpers/googleAnalyticsHepler";
import { HashtagTiles } from "./HashtagTile";
import { RootState } from "../../store";

const DEFAULT_SOCIAL_MEDIA_OPTIONS = [
  { label: "Twitter", value: "Twitter" },
  { label: "Telegram", value: "Telegram" },
];

type Option = { label: string; value: string };
const DEFAULT_DATE_OPTIONS: Array<Option> = [
  {
    label: "Last 30 Days",
    value: "30",
  },
  {
    label: "Last 60 Days",
    value: "60",
  },
  {
    label: "Last 90 Days",
    value: "90",
  },
];

type FilterValue = {
  social_media: Option;
  source_language: Option;
  region: Option;
  date: Option;
  issue: Array<Option>;
};

const getParam = (
  selectedFilters: FilterValue,
  filterOptions: {
    social_media?: Array<Option>;
    source_language?: Array<Option>;
    region?: Array<Option>;
    issue: Array<Option>;
  }
) => {
  const issueParams: Array<string> = [];
  selectedFilters.issue.forEach((issue) => {
    if (issue.value) {
      issueParams.push(issue.value);
    }
  });
  const newSelectedFilters = { ...selectedFilters };
  if (newSelectedFilters.issue.length === filterOptions.issue.length) {
    newSelectedFilters.issue = [];
  }

  return {
    limit: 10,
    filters: {
      social_media: {
        value: selectedFilters.social_media.value,
        type: FilterTypes.eq,
      },
      source_language: selectedFilters.source_language.value
        ? {
            value: selectedFilters.source_language.value,
            type: FilterTypes.eq,
          }
        : undefined,
      country: selectedFilters.region.value
        ? {
            value: selectedFilters.region.value,
            type: FilterTypes.eq,
          }
        : undefined,
      date: {
        operator: FilterOperations.AND,
        conditions: [
          {
            value: getBackDate("days", parseInt(selectedFilters.date.value)),
            type: FilterTypes.gte,
          },
          {
            value: getBackDate("today", 0),
            type: FilterTypes.lte,
          },
        ],
      },
      issue: issueParams.length
        ? {
            value: issueParams,
            type: FilterTypes.includes,
          }
        : undefined,
    },
  };
};

export const HashtageCoverage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const multiSelectRef = React.useRef<any>(null);
  const multiSelectWrapperRef = React.useRef(null);
  const issueSelectionModifiedByUser = React.useRef(false);
  const { preferredRegion } = useSelector(
    (state: RootState) => state.preference
  );

  const [showLoader, setShowLoader] = React.useState(false);
  const [filterOptions, setFilterOptions] = React.useState<{
    social_media?: Array<Option>;
    source_language?: Array<Option>;
    region?: Array<Option>;
    issue: Array<Option>;
  }>({
    social_media: DEFAULT_SOCIAL_MEDIA_OPTIONS,
    source_language: undefined,
    region: undefined,
    issue: [],
  });

  const filterOptionsRef = React.useRef<{
    social_media?: Array<Option>;
    source_language?: Array<Option>;
    region?: Array<Option>;
    issue: Array<Option>;
  }>({
    social_media: DEFAULT_SOCIAL_MEDIA_OPTIONS,
    source_language: undefined,
    region: undefined,
    issue: [],
  });

  const [chartData, setChartData] = React.useState<{
    twitter: Array<{ x: string; y: number }>;
    telegram: Array<{ x: string; y: number }>;
  }>({
    twitter: [],
    telegram: [],
  });

  const [selectedIssue, setSelectedIssue] = React.useState<Array<Option>>([]);

  const selectedIssueRef = React.useRef<Array<Option>>([]);

  const [selectedFilters, setSelectedFilters] = React.useState<FilterValue>({
    social_media: { label: "Twitter", value: "Twitter" },
    source_language: { ...DEFAULT_POST_LANGUAGE },
    region: { ...DEFAULT_POST_LOCATION },
    date: { ...DEFAULT_DATE_OPTIONS[0] },
    issue: [{ ...DEFAULT_ALL_ISSUES }],
  });

  React.useEffect(() => {
    setSelectedFilters({
      social_media: { label: "Twitter", value: "Twitter" },
      source_language: { ...DEFAULT_POST_LANGUAGE },
      region: { ...DEFAULT_POST_LOCATION },
      date: { ...DEFAULT_DATE_OPTIONS[0] },
      issue: [{ ...DEFAULT_ALL_ISSUES }],
    });
  }, [preferredRegion]);

  React.useEffect(() => {
    axiosInstance
      .post("insights/hashtags/filter", {
        params: {
          model: "M3",
          subscribed_region: preferredRegion,
        },
      })
      .then(
        (
          res: AxiosResponse<{
            data: {
              social_media: Array<Option>;
              sourceLanguage: Array<Option>;
              region: Array<Option>;
              issue: Array<Option>;
            };
          }>
        ) => {
          setFilterOptions({
            social_media: DEFAULT_SOCIAL_MEDIA_OPTIONS,
            source_language: [
              { ...DEFAULT_POST_LANGUAGE },
              ...res.data.data.sourceLanguage,
            ],
            region: [{ ...DEFAULT_POST_LOCATION }, ...res.data.data.region],
            issue: [{ ...DEFAULT_ALL_ISSUES }, ...res.data.data.issue],
          });
          filterOptionsRef.current = {
            social_media: DEFAULT_SOCIAL_MEDIA_OPTIONS,
            source_language: [
              { ...DEFAULT_POST_LANGUAGE },
              ...res.data.data.sourceLanguage,
            ],
            region: [{ ...DEFAULT_POST_LOCATION }, ...res.data.data.region],
            issue: [{ ...DEFAULT_ALL_ISSUES }, ...res.data.data.issue],
          };
          selectedIssueRef.current = [
            { ...DEFAULT_ALL_ISSUES },
            ...res.data.data.issue,
          ];
          setSelectedIssue([{ ...DEFAULT_ALL_ISSUES }, ...res.data.data.issue]);
        }
      )
      .catch((err) => console.log(err));
  }, [preferredRegion]);

  React.useEffect(() => {
    SendGAEvent({
      category: "SOTOS_Insights_Top_Hashtag_Filter_Updated",
      action: "SOTOS_Insights_Top_Hashtag_Filter_Updated",
      includeUserDetail: true,
      label: `SL-${selectedFilters.source_language.value},region-${
        selectedFilters.region.value
      },date-${selectedFilters.date.value},issue-${selectedFilters.issue
        .map((is) => is.value)
        .join("-")}`,
    });
    setShowLoader(true);
    axios
      .all(
        ["Twitter", "Telegram"].map((social_media) => {
          const refinedParams = getParam(
            selectedFilters,
            filterOptionsRef.current
          );
          const updatedParams = {
            ...refinedParams,
            subscribed_region: preferredRegion,
            filters: {
              ...refinedParams.filters,
              social_media: {
                value: social_media,
                type: FilterTypes.eq,
              },
            },
          };
          const cacheKey = getRedisCacheKey(
            {
              ...selectedFilters,
              social_media: { label: "social_media", value: social_media },
            },
            preferredRegion
          );
          return axiosInstance.post("insights/hashtags", {
            params: { ...updatedParams, model: "M3", rck: cacheKey },
          });
        })
      )
      .then(
        (
          res: Array<
            AxiosResponse<{
              data: Array<{ x: string; y: number }>;
            }>
          >
        ) => {
          let twitterData: Array<{ x: string; y: number }> = [];
          let telegramData: Array<{ x: string; y: number }> = [];

          res.forEach((data, index) => {
            if (index === 0) {
              twitterData = data.data.data;
            }
            if (index === 1) {
              telegramData = data.data.data;
            }
          });

          setChartData({
            twitter: twitterData,
            telegram: telegramData,
          });
        }
      )
      .finally(() => {
        setShowLoader(false);
      });
    /* axiosInstance
      .post("insights/hashtags", {
        params: {
          ...refinedParams,
          model: "M3",
          rck: cacheKeyRef.current,
        },
      })
      .then((res: AxiosResponse) => {
        setChartData(res.data?.data);
      })
      .catch((err: AxiosError) => console.log(err)); */
  }, [selectedFilters, preferredRegion]);

  const onChangeFilterOption = React.useCallback(
    (newValue: SingleValue<unknown>, meta: ActionMeta<unknown>) => {
      setSelectedFilters({
        ...selectedFilters,
        [meta.name ?? ""]: newValue,
      });
    },
    [selectedFilters]
  );

  const onChangeDateSwitcher = React.useCallback(
    (value: DropdownOption) => {
      setSelectedFilters({
        ...selectedFilters,
        date: value,
      });
    },
    [selectedFilters]
  );

  /* const onHashtagTileClicked = React.useCallback(
    (event: any, chartContext: any, config: any) => {
      const clickedTileData =
        config.w.config.series[0].data[config.dataPointIndex];
      const { x: hashtag } = clickedTileData;
      dispatch(
        setSearchScreenFilter({
          query: hashtag,
          date: selectedFilters.date.value,
        })
      );
      window.scrollTo({ top: 0, behavior: "smooth" });
      setTimeout(() => {
        navigate(`/search`);
      }, 500);
    },
    [navigate, selectedFilters, dispatch]
  ); */

  /* const graphOptions = React.useMemo(() => {
    return getGraphOptions(
      chartData,
      onHashtagTileClicked,
      COLOR_PALEETE.HASHTAG
    );
  }, [chartData, onHashtagTileClicked]); */

  const handleMultiValueClick = () => {
    if (multiSelectRef.current) {
      if (!multiSelectRef.current.props.menuIsOpen) {
        multiSelectRef.current.props.onMenuOpen();
      } else {
        multiSelectRef.current.props.onMenuClose();
      }
    }
  };

  const ValueContainer = React.useCallback(
    ({ children, ...props }: ValueContainerProps<DropdownOption>) => {
      let label = "";
      if (props.getValue().length === filterOptions.issue?.length) {
        label = DEFAULT_ALL_ISSUES.label;
      } else if (props.getValue().length) {
        label = `${props.getValue().length} Issue Selected`;
      }

      return (
        <div
          style={{ display: "flex", width: "75%", height: "37px" }}
          onClick={handleMultiValueClick}
        >
          <components.ValueContainer {...props}>
            {label}
          </components.ValueContainer>
        </div>
      );
    },
    [filterOptions.issue?.length]
  );

  const onChangeMultiSelectFilter = React.useCallback(
    (
      newValue: MultiValue<DropdownOption>,
      meta: ActionMeta<DropdownOption>
    ) => {
      let selectedOpts: MultiValue<DropdownOption>;
      if (
        meta.action === "select-option" &&
        meta.option?.label === DEFAULT_ALL_ISSUES.label
      ) {
        selectedOpts = [...filterOptions.issue];
      } else if (
        meta.action === "deselect-option" &&
        meta.option?.label === DEFAULT_ALL_ISSUES.label
      ) {
        selectedOpts = [];
      } else if (newValue.length && !newValue[0].value) {
        selectedOpts = [...newValue].slice(1);
      } else if (
        newValue.length === filterOptions.issue.length - 1 &&
        newValue[0].value
      ) {
        selectedOpts = [...filterOptions.issue];
      } else {
        selectedOpts = [...newValue];
      }
      issueSelectionModifiedByUser.current = true;
      selectedIssueRef.current = selectedOpts as Array<Option>;
      setSelectedIssue(selectedOpts as Array<Option>);
    },
    [filterOptions.issue]
  );

  const onConfirmSubIssueSelection = React.useCallback(() => {
    setSelectedFilters({
      ...selectedFilters,
      issue: selectedIssueRef.current,
    });
  }, [selectedFilters]);

  /* const ReactDropDownMenu = React.useCallback(
    (props: any) => {
      return (
        <MultiSelectMenu
          onConfirmSubIssueSelection={onConfirmSubIssueSelection}
          menuProps={props}
        />
      );
    },
    [onConfirmSubIssueSelection]
  ); */

  const handleClickOutside = (event: React.MouseEvent<HTMLElement>) => {
    if (
      multiSelectWrapperRef.current &&
      !(multiSelectWrapperRef.current as HTMLDivElement).contains(
        event.target as any
      )
    ) {
      if (multiSelectRef.current && multiSelectRef.current.props.menuIsOpen) {
        multiSelectRef.current.props.onMenuClose();
        if (issueSelectionModifiedByUser.current) {
          onConfirmSubIssueSelection();
          issueSelectionModifiedByUser.current = false;
        }
      }
    } else {
      document.querySelectorAll("input").forEach((element) => {
        element.blur();
      });
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside as any, false);

    return () => {
      document.removeEventListener("click", handleClickOutside as any, false);
    };
  }, []);

  const fetchDataOnIssueFilterClose = React.useCallback(() => {
    if (issueSelectionModifiedByUser.current) {
      onConfirmSubIssueSelection();
      issueSelectionModifiedByUser.current = false;
    }
  }, [onConfirmSubIssueSelection]);

  const handleClickOnHashtagRow = React.useCallback(
    (hashtag: string) => {
      SendGAEvent({
        category: "SOTOS_Insights_Top_Hashtag_Click",
        action: "SOTOS_Insights_Top_Hashtag_Click",
        includeUserDetail: true,
        label: hashtag,
      });
      dispatch(
        setSearchScreenFilter({
          query: hashtag,
          date: selectedFilters.date.value,
        })
      );
      window.scrollTo({ top: 0, behavior: "smooth" });
      setTimeout(() => {
        navigate(`/${preferredRegion.toLowerCase()}/discover`);
      }, 500);
    },
    [dispatch, navigate, selectedFilters.date.value, preferredRegion]
  );

  if (showLoader) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: 500 }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <>
      <Row className="d-flex flex-row mt-2">
        <Col lg={2} xl={2} sm={12} xs={12}>
          <div ref={multiSelectWrapperRef}>
            <ReactSelect
              ref={multiSelectRef}
              isDisabled={showLoader}
              isSearchable={true}
              isClearable={false}
              options={filterOptions.issue}
              name="issue"
              value={selectedIssue}
              isMulti
              hideSelectedOptions={false}
              onChange={onChangeMultiSelectFilter}
              onMenuClose={fetchDataOnIssueFilterClose}
              components={{
                Option: MultiSelectOptionsWithCB,
                ValueContainer,
              }}
              closeMenuOnSelect={false}
              styles={{
                control: (baseStyles, state) => {
                  return {
                    ...baseStyles,
                    borderRadius: "8px",
                    boxShadow: state.menuIsOpen
                      ? `0 0 0 1px ${THEME_COLOUR_PRIMARY}`
                      : baseStyles.boxShadow,
                    borderColor: state.menuIsOpen
                      ? `${THEME_COLOUR_PRIMARY}`
                      : baseStyles.borderColor,
                    ":hover": {
                      borderColor: state.menuIsOpen
                        ? `${THEME_COLOUR_PRIMARY}`
                        : baseStyles[":hover"]?.borderColor,
                    },
                  };
                },
                option: (baseStyles, { isSelected }) => {
                  return {
                    ...baseStyles,
                    backgroundColor: isSelected ? "#F0F5FF" : "",
                    color: isSelected ? `${THEME_COLOUR_PRIMARY}` : "",
                  };
                },
                menu: (baseStyles) => {
                  return {
                    ...baseStyles,
                    width: 250,
                  };
                },
                valueContainer: (baseStyles) => {
                  return {
                    ...baseStyles,
                    fontSize: 16,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color: "#333",
                    maxWidth: "100%",
                    display: "initial",
                    margin: "auto",
                  };
                },
              }}
            />
          </div>
        </Col>
        {/* <Col xl={2} lg={2} md={6} sm={12} xs={12}>
          <ModifiedReactSelect
            isSearchable={false}
            options={filterOptions.social_media}
            name="social_media"
            onChange={onChangeFilterOption}
            value={selectedFilters.social_media}
            isDisabled={showLoader}
          />
        </Col> */}

        <Col xl={2} lg={2} md={6} sm={12} xs={12}>
          <ModifiedReactSelect
            isSearchable={false}
            options={filterOptions.source_language}
            name="source_language"
            onChange={onChangeFilterOption}
            value={selectedFilters.source_language}
            isDisabled={showLoader}
            extraStyles={{
              menu: (baseStyles) => {
                return {
                  ...baseStyles,
                  width: 250,
                };
              },
            }}
          />
        </Col>

        <Col xl={2} lg={2} md={6} sm={12} xs={12}>
          <ModifiedReactSelect
            isSearchable={false}
            options={filterOptions.region}
            name="region"
            onChange={onChangeFilterOption}
            value={selectedFilters.region}
            isDisabled={showLoader}
          />
        </Col>

        {/* <Col lg={2} xl={2} sm={12} xs={12}>
          <ModifiedReactSelect
            isSearchable={false}
            options={DEFAULT_DATE_OPTIONS}
            name="date"
            value={selectedFilters.date}
            hideSelectedOptions={false}
            onChange={onChangeFilterOption}
            isDisabled={showLoader}
          />
        </Col> */}

        <Col lg={6} xl={6} sm={12} xs={12}>
          <DateSwitcher
            options={DEFAULT_DATE_OPTIONS}
            onClick={onChangeDateSwitcher}
            selectedValue={selectedFilters.date.value}
          />
        </Col>
      </Row>

      {preferredRegion === SUBSCRIBED_REGION.IRAN_REGION ? (
        <Row>
          <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mt-3">
            <HashtagTiles
              data={chartData.twitter}
              onClickRow={handleClickOnHashtagRow}
              sectionTitle="Twitter"
            />
          </Col>
          <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mt-3">
            <HashtagTiles
              data={chartData.telegram}
              onClickRow={handleClickOnHashtagRow}
              sectionTitle="Telegram"
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mt-3">
            <HashtagTiles
              data={chartData.twitter}
              onClickRow={handleClickOnHashtagRow}
              sectionTitle="Twitter"
              tileNumber={4}
            />
          </Col>
        </Row>
      )}

      {/* <div style={{ height: 650 }} id="hashtagChart">
        {showLoader ? (
          <Loader />
        ) : (
          <ReactApexChart
            options={graphOptions}
            series={[{ data: chartData }]}
            type="treemap"
            height={650}
          />
        )}
      </div> */}
    </>
  );
};
