export const FilterTypes = {
  eq: "equals",
  gt: "greaterThan",
  lt: "lessThan",
  lte: "lessThanEqual",
  gte: "greaterThanEqual",
  includes: "includes",
  contains: "contains",
};

export const FilterOperations = {
  AND: "AND",
  OR: "OR",
};
