import React from "react";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { NO_RECORD_MESSAGE } from "../../constants";

interface Props {
  series1: { name: string; data: Array<number> };
  series2: { name: string; data: Array<number> };
  categories: Array<string>;
  xAxisTitle: string;
  yAxisTitle: string;
}

export const AreaChartOption = React.forwardRef<any, Props>(
  ({ series1, series2, categories, xAxisTitle = "", yAxisTitle = "" }, ref) => {
    const areaChartOption: ApexOptions = React.useMemo(() => {
      return {
        chart: {
          toolbar: {
            show: false,
            tools: {
              pan: false,
              zoomin: false,
              zoom: false,
            },
          },
        },
        grid: {
          show: categories.length ? true : false,
        },
        legend: {
          position: "top",
          fontSize: "15px",
          fontWeight: "bold",
        },
        colors: ["#38DC2A", "#D63C3C"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: 3,
        },
        noData: {
          text: NO_RECORD_MESSAGE,
          align: "center",
          verticalAlign: "middle",
          style: {
            fontSize: "30",
            color: "#000",
          },
        },
        xaxis: {
          categories,
          labels: {
            rotate: -45,
            rotateAlways: true,
          },
          title: {
            text: categories.length ? xAxisTitle : "",
            style: {
              fontSize: "15px",
            },
          },
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 6,
          forceNiceScale: true,
          title: {
            text: categories.length ? yAxisTitle : "",
            style: {
              fontSize: "15px",
            },
          },
          labels: {
            show: categories.length ? true : false,
            style: {
              fontWeight: "bold",
              fontSize: "12px",
            },
            formatter: (val, index) => {
              return `${val}%`;
            },
          },
        },
      };
    }, [categories, xAxisTitle, yAxisTitle]);

    return (
      <ReactApexChart
        series={[series1, series2]}
        width={"100%"}
        type="area"
        options={areaChartOption}
        height={500}
        ref={ref}
      />
    );
  }
);

AreaChartOption.displayName = "AreaChartOption";
