import React from "react";
import { Container } from "react-bootstrap";
import {
  BrowserRouter,
  Outlet,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { AxiosResponse } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "./components/Header";
import axiosInstance from "./api";
import { NewsMediaAgency, UserProfile } from "./types/types";
import { M3_PARAM, SUBSCRIBED_REGION } from "./constants";
import { RouteWrapper } from "./components/RouteWrapper";

/* ---Redux --- */
import { RootState } from "./store";
import { setSessionValidationTrue, setUser } from "./store/slice/user";
import { setIssuesM3 } from "./store/slice/issuePreference";
import { setInfluencer } from "./store/slice/influencerEntities";
import { setAgencies } from "./store/slice/newsMediaAgency";

/* --- Unsecured Screens --- */

import { Login } from "./pages/Login";
import { Register } from "./pages/Register";
import { ForgotPassword } from "./pages/Forgot-Password";
import { CreatePassword } from "./pages/Create-Password";
import { AutoLogin } from "./pages/Login/Autologin";

/* --- Secured Screens --- */

import { Insights as InsightsM3 } from "./pages/Insights-m3/Insights";
import { Insights as InsightsM3V2 } from "./pages/Insights-m3-v2/Insights";
import { EmergingIssues as EmergingIssuesMultiSource } from "./pages/emerging-issue";
import { OpinionAIM3 } from "./pages/opinion-ai-m3";
import { SearchScreen as SearchScreenM3 } from "./pages/search";
import { InviteeScreen } from "./pages/Invitee";
import { Profile } from "./pages/Profile";

interface Issues {
  englishIssues: Array<string>;
  originalLanguageIssues: Array<string>;
  emergingIssueEnabledRecords: Record<string, boolean>;
  defaultSelectedIssue: string;
}

const UnProtectedRoutes = () => (
  <>
    <Route index path="/" element={<Login />} />
    <Route path="auto-login" element={<AutoLogin />} />
    <Route path="register" element={<Register />} />
    <Route path="forgot-password" element={<ForgotPassword />} />
    <Route path="create-password" element={<CreatePassword />} />
    <Route path="*" element={<Navigate to="/" />} />
  </>
);
const ProtectedRoutes = (isAdmin: boolean) => (
  <>
    <Route
      index
      path="/:region?"
      key={"Insight-Taiwan-V2"}
      element={<InsightsM3V2 />}
    />
    {isAdmin ? (
      <>
        <Route
          index
          path=":region?/insights-v1"
          key={"Insight-Iran"}
          element={<InsightsM3 />}
        />
      </>
    ) : null}
    <Route
      path=":region?/issue-watch"
      element={<EmergingIssuesMultiSource />}
    />
    <Route path=":region?/discover" element={<SearchScreenM3 />} />
    <Route path="opinion-ai" element={<OpinionAIM3 />} />
    <Route path="profile" element={<Profile />} />
    {isAdmin ? <Route path="invitee-list" element={<InviteeScreen />} /> : null}
    <Route path="*" element={<Navigate to="/" />} />
  </>
);

export const RouterComponent = () => {
  const { isLoggedIn, isSessionVaidationInProgress, profile } = useSelector(
    (state: RootState) => state.user
  );

  const dispatch = useDispatch();
  const isApiReqInProgress = React.useRef(false);

  React.useEffect(() => {
    if (localStorage.getItem("Auth")) {
      dispatch(setSessionValidationTrue());
      axiosInstance
        .post("user/get-user")
        .then(
          (
            res: AxiosResponse<{
              user: UserProfile;
            }>
          ) => {
            if (res?.data?.user) {
              dispatch(
                setUser({
                  profile: {
                    name: res.data.user.name,
                    lastName: res.data.user.lastName,
                    email: res.data.user.email,
                    role: res.data.user.role,
                    subscribed_regions: res.data.user.subscribed_regions ?? [
                      SUBSCRIBED_REGION.IRAN_REGION,
                    ],
                    organisation: res.data.user.organisation,
                    avatar: res.data.user.avatar,
                  },
                  isLoggedIn: true,
                  isSessionVaidationInProgress: false,
                })
              );
              localStorage.setItem("user", JSON.stringify(res.data.user));
            }
          }
        )
        .catch((err) => {
          console.log(err);
        });
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (!isApiReqInProgress.current && isLoggedIn) {
      isApiReqInProgress.current = true;
      /* axiosInstance
        .post("emerging-issues/get-issues")
        .then((res: AxiosResponse<{ data: Issues }>) => {
          dispatch(setIssues({ issues: res.data.data }));
        })
        .catch((err) => console.log(err)); */

      axiosInstance
        .post("temp/emerging-issues/get-issues", {
          params: {
            ...M3_PARAM,
          },
        })
        .then(
          (
            res: AxiosResponse<{
              data: Issues;
              taiwanIssues: Issues;
              chinaIssues: Issues;
            }>
          ) => {
            dispatch(
              setIssuesM3({
                issues: res.data.data,
                taiwanIssues: res.data.taiwanIssues,
                chinaIssues: res.data.chinaIssues,
              })
            );
          }
        )
        .catch((err) => console.log(err));

      axiosInstance
        .post("/influencer/get-influencer-entities")
        .then(
          (
            res: AxiosResponse<{
              data: {
                entitiesPhotos: Array<{
                  name: string;
                  photo: string;
                  farsi_name: string;
                }>;
                newsMediaPhotos: Array<NewsMediaAgency>;
                taiwaneseMediaPhotos: Array<NewsMediaAgency>;
                chineseMediaPhotos: Array<NewsMediaAgency>;
              };
            }>
          ) => {
            dispatch(setInfluencer(res.data.data.entitiesPhotos));
            dispatch(
              setAgencies({
                iran: res.data.data.newsMediaPhotos,
                taiwan: res.data.data.taiwaneseMediaPhotos,
                china: res.data.data.chineseMediaPhotos,
              })
            );
          }
        )
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dispatch, isLoggedIn]);

  const routes = React.useMemo(() => {
    return isSessionVaidationInProgress && !isLoggedIn ? (
      <>Loading...</>
    ) : isLoggedIn ? (
      ProtectedRoutes(profile?.role === "Admin")
    ) : (
      UnProtectedRoutes()
    );
  }, [isLoggedIn, isSessionVaidationInProgress, profile]);

  return (
    <BrowserRouter>
      <RouteWrapper>
        {isLoggedIn ? <Header /> : ""}
        {isLoggedIn ? (
          <Container>
            <Routes>
              <Route path="/" element={<Outlet />}>
                {routes}
              </Route>
            </Routes>
          </Container>
        ) : (
          <Routes>
            <Route path="/" element={<Outlet />}>
              {routes}
            </Route>
          </Routes>
        )}
      </RouteWrapper>
    </BrowserRouter>
  );
};
